import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { UserService } from '../../../services/user.service';
import { ErrorsComponent } from '../dashboard-schedules/popups/errors/errors.component';
import { ShareService } from 'src/app/services/share.service';
import * as moment from 'moment';
import { StorageBrowser } from '../../../services/storage.browser';
import { EditShortNameDialogComponent } from '../../dialogs/edit-short-name/edit-short-name-dialog.component';
import { CustomFilterMenuComponent } from '../custom-filter-menu/custom-filter-menu.component';
import { MixpanelService } from 'src/app/services/mixpanel.service';
@Component({
  selector: 'ninja-orders-dashboard',
  templateUrl: './orders-dashboard.component.html',
  styleUrls: ['./orders-dashboard.component.scss']
})

export class OrdersDashboardComponent implements OnInit {
  @ViewChild('componentChild') customFilterMenuComponent: CustomFilterMenuComponent;
  orderData: any;
  orderContext: any;
  topValues: any;
  dashboardSchedulesFilterFlag: boolean = true;
  currentTitle: any;
  previousTitle: any;
  prevToken: any = 0;
  nextToken: any = 50;
  isNextDisabled: boolean;
  userData: any;
  constructor(
    public userService: UserService,
    public mixpanelService: MixpanelService,
    public matDialog: MatDialog, private shareService: ShareService,public storageBrowser: StorageBrowser,) {
      this.mixpanelService.init(null);
  }


  public ngOnInit() {
    setTimeout(() => {
      this.userService.loaderObesrver.next(true);
      this.shareService.currentOrderData.subscribe((data: any) => {
        this.orderData = data;
        this.storageBrowser.set('$Currency', this.orderData.currency);
        if (data && data.revenuePie && data.revenuePie.scopeValues.length > 0) {
          this.topValues = data.revenuePie.scopeValues.sort((a, b) => b.value - a.value).slice(0, 5);
        }
        // if (data && data.requestedPie) {
        //   data.requestedPie.from = moment(data.requestedPie.from).format('DD/MM/YY');
        //   data.requestedPie.to = moment(data.requestedPie.to).format('DD/MM/YY');
        // }
        // if (data && data.previousePie) {
        //   data.previousePie.from = moment(data.previousePie.from).format('DD/MM/YY');
        //   data.previousePie.to = moment(data.previousePie.to).format('DD/MM/YY');
        // }
        // if (data && data.revenuePie) {
        //   data.revenuePie.from = moment(data.revenuePie.from).format('DD/MM/YY');
        //   data.revenuePie.to = moment(data.revenuePie.to).format('DD/MM/YY');
        // }
        if (data && data.orders && data.orders.length > 0) {
          data.orders.map(resData => {
            resData.img = this.marketPlaceIcon(resData.channel.split("."));
          });
          this.userService.loaderObesrver.next(false);
        }
      }, (err) => {
        this.matDialog.open(ErrorsComponent, { panelClass: 'errorPopupCls' });
      });

      let token = 0;
      setTimeout(() => {
        if (this.orderData && this.orderContext) {
          this.userService.orderDashboardPaginate(this.orderContext, token).subscribe(resPaginate => {
            this.prevToken = resPaginate.prevToken;
            this.nextToken = resPaginate.nextToken;
            if (resPaginate.orders && resPaginate.orders.length > 0) {
              resPaginate.orders.map(resData => {
                resData.img = this.marketPlaceIcon(resData.channel.split("."));
              });
            }
            this.orderData.orders = resPaginate.orders;
            this.updateOrderIndex();
          });
        }
      }, 1000);
    });
  }

  marketPlaceIcon(marketPlaceChangeIcon1) {
    let mapImage = '';
    if (marketPlaceChangeIcon1 && marketPlaceChangeIcon1[1] == "ca") {
      mapImage = 'assets/images/flag_canada.svg'
    } else if (marketPlaceChangeIcon1 && marketPlaceChangeIcon1[1] == "com" && marketPlaceChangeIcon1[2] == "mx") {
      mapImage = 'assets/images/flag_mexico.svg'
    } else if (marketPlaceChangeIcon1 && marketPlaceChangeIcon1[1] == "co" && marketPlaceChangeIcon1[2] == "uk") {
      mapImage = 'assets/images/flag_uk.svg'
    } else if (marketPlaceChangeIcon1 && marketPlaceChangeIcon1[1] == "fr") {
      mapImage = 'assets/images/flag_france.svg'
    } else if (marketPlaceChangeIcon1 && marketPlaceChangeIcon1[1] == "de") {
      mapImage = 'assets/images/flag_germany.svg'
    } else if (marketPlaceChangeIcon1 && marketPlaceChangeIcon1[1] == "it") {
      mapImage = 'assets/images/flag_italy.svg'
    } else if (marketPlaceChangeIcon1 && marketPlaceChangeIcon1[1] == "es") {
      mapImage = 'assets/images/flag_spain.svg'
    } else if (marketPlaceChangeIcon1 && marketPlaceChangeIcon1[1] == "co" && marketPlaceChangeIcon1[2] == "jp") {
      mapImage = 'assets/images/japan.svg'
    } else if (marketPlaceChangeIcon1 && marketPlaceChangeIcon1[1] == "com" && marketPlaceChangeIcon1[2] == "au") {
      mapImage = 'assets/images/australia.svg'
    } else if (marketPlaceChangeIcon1 && marketPlaceChangeIcon1[1] == "in") {
      mapImage = 'assets/images/india.svg'
    } else if (marketPlaceChangeIcon1 && marketPlaceChangeIcon1[1] == "ae") {
      mapImage = 'assets/images/UAE_Flag.svg'
    } else if (marketPlaceChangeIcon1 && marketPlaceChangeIcon1[1] == "com" && marketPlaceChangeIcon1[2] == "br") {
      mapImage = 'assets/images/Brazil_br.jpeg'
    } else if (marketPlaceChangeIcon1 && marketPlaceChangeIcon1[1] == "com/Saudi-Arabia") {
      mapImage = 'assets/images/SoudiArabia.jpeg'
    } else if (marketPlaceChangeIcon1 && marketPlaceChangeIcon1[1] == "com" && marketPlaceChangeIcon1[2] == "tr") {
      mapImage = 'assets/images/turkey_tr.jpeg'
    } else if (marketPlaceChangeIcon1 && marketPlaceChangeIcon1[1] == "com") {
      mapImage = 'assets/images/marketplace.png'
    } else if (marketPlaceChangeIcon1 && marketPlaceChangeIcon1[1] == "sg") {
      mapImage = 'assets/images/Singapore_sg.jpeg'
    } else if (marketPlaceChangeIcon1 && marketPlaceChangeIcon1[1] == "nl") {
      mapImage = 'assets/images/Netherlands_nl.jpeg'
    } else {
      mapImage = 'assets/images/noImage.png';
    }
    return mapImage;
  }

  searchOrders(token) {
    setTimeout(() => {
      if (this.orderData) {
        this.userService.orderDashboardPaginate(this.orderContext, token).subscribe(resPaginate => {
          resPaginate.orders.map(resData => {
            resData.img = this.marketPlaceIcon(resData.channel.split("."));
          });
          this.prevToken = resPaginate.prevToken;
          this.nextToken = resPaginate.nextToken;
          this.orderData.orders = resPaginate.orders;
          if (resPaginate.orders.length == 0) {
            this.isNextDisabled = true;
          } else {
            this.prevToken = resPaginate.prevToken;
            this.isNextDisabled = false;
          }
          this.userService.loaderObesrver.next(false);
          this.updateOrderIndex();
        });
      }
    }, 1000);
  }


  updateOrderIndex(): void {
    let curPage = 0;
    if (this.nextToken < 50)
      curPage = 0;
    else
      curPage = (((this.nextToken - (this.nextToken % 50)) / 50) - 1);
    if (this.orderData.orders.length) {
      for (let i = 0; i < this.orderData.orders.length; i++) {
        this.orderData.orders[i].index = i + 1 + (curPage * 50);
      }
    }
  }

  passBall(message: string) {
    this.orderContext = message;
    this.currentTitle = this.orderContext.timeFrame == 'TODAY' ? 'Today' : 'Current';
    this.previousTitle = this.orderContext.timeFrame == 'TODAY' ? 'Yesterday' : 'Previous';
  }

  editShortName(main): void {
    this.matDialog.open(EditShortNameDialogComponent, {
      data: {
        'dialogFor': 'edit-short-name-dialog',
        'product': Object.assign(main, main.shortNames),
      },
      width: '800px'
    }).afterClosed().subscribe((result) => {
      if(result) {
        for (let i = 0; i < this.orderData.orders.length; i++) {
          if (this.orderData.orders[i].sku === result.sku) {
            this.orderData.orders[i].shortName = result.defaultShortName;
          }
        }
      }
    });
  }
  resetFilter(filterName) {
    this.userService.loaderObesrver.next(true);
    if(filterName == 'brand') {
      this.orderContext.brands = [];
      this.customFilterMenuComponent.productForm.controls.brands.reset();
      this.getOrderData(this.orderContext);
    } else if (filterName == 'marketplace') {
      this.orderContext.sc = [];
      this.customFilterMenuComponent.productForm.controls.marketplace.reset();
      this.getOrderData(this.orderContext);
    } else if (filterName == 'asin') {
      this.orderContext.skus = [];
      this.customFilterMenuComponent.productForm.controls.asin.reset();
      this.getOrderData(this.orderContext);
    }
  }
  getOrderData(formData) {
    if (this.storageBrowser.get('$UserToken$user')) {
      this.userService.orderDashboard(formData).subscribe(res => {
        if (res) {
          this.orderData = res;
          this.shareService.changeOrderData('');
          setTimeout(() => {
            this.shareService.changeOrderData(res);
            this.userService.loaderObesrver.next(false);
          });
        }
      });
    }
  }
}
