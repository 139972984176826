<div class="login-container">
  <div class="right-container">
    <div class="logo">
      <a href="https://www.quantifyninja.com">
        <img class="d-block d-lg-none" src="assets/images/ninja-logo2.svg"
          alt="">
        <img class="d-none d-lg-block" width="200"
          src="assets/images/logo-white.svg" alt="">
      </a>
    </div>
    <!-- <div class="top-container">
      <p>{{textService.currentText.swithToPrevInterface}}</p>
      <a (click)="googleAnalyticsEventsService.emitEvent('Button', 'Switch', 'Click', 1)"
        href="https://app.quantifyninja.com/#!login">
        <button mat-button class="default-btn">{{textService.currentText.switch}}</button>
      </a>
    </div>
    <div class="content">
      <div class="img-container">
        <img src="../../../../assets/images/login-img.png" alt="login-img">
      </div>
      <h3>{{textService.currentText.loginTitle}}</h3>
      <p>
        {{textService.currentText.loginTitleText}}
        <br>
        {{textService.currentText.loginTitleText2}}
      </p>
    </div> -->
  </div>
  <div class="left-container">
    <div class="container">
      <h2>{{textService.currentText.readyTo}}</h2>
      <p class="text-center">{{textService.currentText.pleaseComplete}}</p>
      <form class="login-form" name="form" [formGroup]="submitForm" novalidate>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group mb-md-0"
              [class.has-danger]="errors?.firstName">
              <!-- <label for="firstName">
                <i class="icon email"
                  [ngStyle]="{'background-image': 'url(' + '../../../../assets/icons/ion-android-person-ionicons.svg' + ')'}"></i>
              </label> -->
              <input type="text" id="firstName" #firstNameInput
                class="form-control-x" formControlName="firstName"
                [placeholder]="textService.currentText.firstName"
                [(ngModel)]="firstName"
                (focus)="firstNameInputCaps= true"
                (focusout)="firstNameInputCaps= false" />
              <div class="icon-container">
                <div class="capslock-icon help-icon inside-input"
                  title="CAPSLOCK enabled"
                  *ngIf="capsOn && firstNameInputCaps"></div>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group mb-0" [class.has-danger]="errors?.lastName">
              <!-- <label for="lastName">
                <i class="icon email"
                  [ngStyle]="{'background-image': 'url(' + '../../../../assets/icons/ion-android-person-ionicons.svg' + ')'}"></i>
              </label> -->
              <input type="text" id="lastName" class="form-control-x"
                formControlName="lastName"
                [placeholder]="textService.currentText.lastName"
                [(ngModel)]="lastName" #lastNameInput
                (focus)="lastNameInputCaps= true" (focusout)="lastNameInputCaps=
                false" />
              <div class="icon-container">
                <div class="capslock-icon help-icon inside-input"
                  title="CAPSLOCK enabled"
                  *ngIf="capsOn && lastNameInputCaps"></div>
              </div>
            </div>
          </div>
        </div>
        <div class="form-group" [class.has-danger]="errors.email ||
          errors.emailInvalid">
          <!-- <label for="email">
            <i class="icon email"
              [ngStyle]="{'background-image': 'url(' + '../../../../assets/icons/mail.svg' + ')'}"></i>
          </label> -->
          <input type="email" id="email" #email class="form-control-x"
            formControlName="email"
            [placeholder]="textService.currentText.email"
            [(ngModel)]="userEmail" (focus)="emailCaps= true"
            (focusout)="emailCaps= false" />
          <div class="icon-container">
            <div class="capslock-icon help-icon inside-input" title="CAPSLOCK
              enabled" *ngIf="capsOn && emailCaps">
            </div>
          </div>
        </div>
        <div class="form-group" [class.has-danger]="errors?.password">
          <!-- <label for="password"
            (click)="passwordInputType === 'password' ? passwordInputType = 'text' : passwordInputType = 'password'">
            <i class="icon password" [class.visible]="passwordInputType === 'text'"></i>
          </label> -->
          <input [attr.type]="passwordInputType" id="password" #passwordInput
            class="form-control-x"
            formControlName="password"
            [placeholder]="textService.currentText.password"
            [(ngModel)]="userPass"
            (focus)="passwordInputCaps= true" (focusout)="passwordInputCaps=
            false" />
          <div class="icon-container big-indent">
            <div class="capslock-icon help-icon inside-input" title="CAPSLOCK
              enabled"
              *ngIf="capsOn && passwordInputCaps"></div>
          </div>
          <div class="icon-container inside-input">
            <div class="q-icon help-icon" (click)="isPasswordTooltip=
              !isPasswordTooltip"
              [class.active]="isPasswordTooltip">
              ?
            </div>
            <ninja-tooltip (toggledChange)="showTooltip($event)"
              [text]="tooltipPassword" *ngIf="isPasswordTooltip">
            </ninja-tooltip>
          </div>
        </div>
        <div class="form-group" [class.has-danger]="errors.confirmPassword">
          <!-- <label for="confirmPassword"
            (click)="rePasswordInputType === 'password' ? rePasswordInputType = 'text' : rePasswordInputType = 'password'">
            <i class="icon password" [class.visible]="rePasswordInputType === 'text'"></i>
          </label> -->
          <input [attr.type]="rePasswordInputType" id="confirmPassword"
            #confirmPassInput class="form-control-x"
            formControlName="confirmPassword"
            [placeholder]="textService.currentText.repeatPassword"
            [(ngModel)]="userPassConfirm" (focus)="confirmPassInputCaps= true"
            (focusout)="confirmPassInputCaps= false" />
          <div class="icon-container">
            <div class="capslock-icon help-icon inside-input" title="CAPSLOCK
              enabled"
              *ngIf="capsOn && confirmPassInputCaps"></div>
          </div>
        </div>
        <div class="input-hint">
          <!-- <label class="input-container">
            <input formControlName="checkboxName" type="checkbox" [(ngModel)]="checkboxTAC">
            <span class="checkmark"></span>
          </label> -->
          <mat-checkbox [checked]="checkboxTAC" color="primary"
            (change)="mixpanelService.track('q sign up page - agree to terms clicked',{'page':
            'Signup', 'page type':'quantifyninja'});agreeCheckboxChange($event.checked)">
            <span for="terms" class="terms-text">{{textService.currentText.iAgreeWith}}</span>
          </mat-checkbox>
          <span for="terms" class="terms-text ml-1">
            <a (click)="openTAC()">{{textService.currentText.termsAndCond}}</a>
          </span>
          <!-- <span for="terms" class="terms-text">{{textService.currentText.iAgree}} <a
              (click)="openTAC()">{{textService.currentText.termsAndCond}}</a> {{textService.currentText.and}}
            <a (click)="openPP()">{{textService.currentText.privacyPolicy}}</a></span> -->
        </div>
        <div class="form-group-button text-center">
          <button mat-button class="secondary-btn btn-fill"
            (click)="googleAnalyticsEventsService.emitEvent('Button', 'Sign Up',
            'Click', 1);mixpanelService.track('q sign up page - sign up clicked',{'page':
            'Signup', 'page type':'quantifyninja'}); signUp()">{{textService.currentText.signUp}}</button>
        </div>
        <div class="login-here">
          <span><a (click)="mixpanelService.track('q sign up page - login clicked',{'page':
            'Signup', 'page type':'quantifyninja'});goToLoginWithAddLink()" class="pointer">{{textService.currentText.alreadyHave}}</a></span>
        </div>
        <div class="input-hint justify-content-center termsofuse">
          <span for="terms" class="terms-text">
            {{textService.currentText.termOfUse}}
            <a (click)="openPP()">{{textService.currentText.privacyPolicy}}</a></span>
        </div>
      </form>
    </div>
  </div>
</div>