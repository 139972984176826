<mat-card class="todaysection">
  <mat-card-content>
    <div class="d-flex justify-content-between mb-4">
      <div fxFlex="100" class="align-items-center d-flex" fxFlex.gt-sm="50">
        <h2 class="font-size-18 header-user-color mb-0">Profit Breakdown</h2>
      </div>
      <div class="d-flex">
        <div class="d-flex align-items-end mr-5 cursor-pointer" [matMenuTriggerFor]="filter" style="color: rgba(0, 0, 0, 0.54)">
            Columns
          <mat-icon class="mat-icon-size mb-0 align-middle header-icon-color cursor-pointer">
            filter_alt</mat-icon>
        </div>
        <mat-menu #filter="matMenu">
          <div class="px-25 pt-10 pb-0 columnFilter" (click)="$event.stopPropagation()" (keydown)="$event.stopPropagation()">
            <mat-checkbox [checked]="filters.selectAll" (change)="columnFilters($event.checked, 'selectAll')">Select All</mat-checkbox>
            <mat-checkbox [checked]="filters.title" (change)="columnFilters($event.checked, 'title')">Title</mat-checkbox>
            <mat-checkbox [checked]="filters.asin" (change)="columnFilters($event.checked, 'asin')">Asin</mat-checkbox>
            <mat-checkbox [checked]="filters.sku" (change)="columnFilters($event.checked, 'sku')">Sku</mat-checkbox>
            <mat-checkbox [checked]="filters.sales" (change)="columnFilters($event.checked, 'sales')">Sales</mat-checkbox>
            <mat-checkbox [checked]="filters.revenue" (change)="columnFilters($event.checked, 'revenue')">Revenue</mat-checkbox>
            <mat-checkbox [checked]="filters.cost" (change)="columnFilters($event.checked, 'cost')">Cost</mat-checkbox>
            <mat-checkbox [checked]="filters.profit" (change)="columnFilters($event.checked, 'profit')">Profit</mat-checkbox>
            <mat-checkbox [checked]="filters.margin" (change)="columnFilters($event.checked, 'margin')">Margin</mat-checkbox>
            <mat-checkbox [checked]="filters.promos" (change)="columnFilters($event.checked, 'promos')">Promos</mat-checkbox>
            <mat-checkbox [checked]="filters.ppcUnits" (change)="columnFilters($event.checked, 'ppcUnits')">PPC Units</mat-checkbox>
            <mat-checkbox [checked]="filters.ppcExpenses" (change)="columnFilters($event.checked, 'ppcExpenses')">PPC Expenses</mat-checkbox>
            <mat-checkbox [checked]="filters.ppcPerUnit" (change)="columnFilters($event.checked, 'ppcPerUnit')">PPC Per Unit</mat-checkbox>
            <mat-checkbox [checked]="filters.roi" (change)="columnFilters($event.checked, 'roi')">Roi</mat-checkbox>
            <mat-checkbox [checked]="filters.detail" (change)="columnFilters($event.checked, 'detail')">Detail</mat-checkbox>
          </div>
        </mat-menu>
        <mat-form-field>
          <mat-label>Group By </mat-label>
          <mat-select [formControl]="groupby" multiple [disableOptionCentering]="true">
            <mat-option value="brand">Brand</mat-option>
            <mat-option value="mp">Market Place</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <app-profitbreakdownwithoutgroup [columnFilters]="filters" [colspanCount]="tableColspanCount" [formData]="formData" *ngIf="selectedVal != 'brand' && selectedVal != 'mp' && selectedVal != 'brand,mp'"
      [profitbreakdowndataSource]="profitbreakdowndataSource">
    </app-profitbreakdownwithoutgroup>
    <app-profitbreakdownonegroup [columnFilters]="filters" [colspanCount]="tableColspanCount" [formData]="formData" *ngIf="selectedVal == 'brand' || selectedVal == 'mp' "
      [profitbreakdowndataSource]="profitbreakdowndataSource">
    </app-profitbreakdownonegroup>
    <app-profitbreakdowntwogroup [columnFilters]="filters" [colspanCount]="tableColspanCount" [formData]="formData" *ngIf="selectedVal == 'brand,mp'" [profitbreakdowndataSource]="profitbreakdowndataSource">
    </app-profitbreakdowntwogroup>
  </mat-card-content>
</mat-card>