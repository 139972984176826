import { Injectable } from '@angular/core';
// import {Http, Request, RequestOptions, RequestOptionsArgs, Response, XHRBackend} from '@angular/http';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpErrorResponse, HttpRequest, HttpResponse, HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';

import * as moment from 'moment';
import { ConfirmationDialogComponent } from '../components/dialogs/confirmation-dialog/confirmation-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { StorageBrowser } from './storage.browser';
import { environment } from '../../environments/environment.prod';
// import { UserService } from './user.service';
import { catchError } from 'rxjs/operators';
// import { ErrorService } from './error.service';


@Injectable()
export class AuthenticatedHttpService implements HttpInterceptor {
    token: any;
    constructor(public http: HttpClient, 
        // public userService: UserService,
        public storageBrowser: StorageBrowser,
        public matDialog: MatDialog,
        // public errorService: ErrorService,
        public router: Router) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        //const userToken = 'secure-user-token';
        this.token = this.storageBrowser.get('$UserToken$user') ? this.storageBrowser.get('$UserToken$user') : '';
        if(this.storageBrowser.get('$LoginTime')) {
            let liveTime = moment();
            var duration = moment.duration(liveTime.diff(this.storageBrowser.get('$LoginTime')));
            var minutes = duration.asMinutes();
            if(minutes > 1440) {
                localStorage.clear();
                window.location.reload(true);
            } else {
                this.storageBrowser.set('$LoginTime', moment());
            }
        } else {
            this.storageBrowser.set('$LoginTime', moment());
        }
        if (request.url.indexOf('assets/config.json') <= -1) {
            // if ((this.userService.currentUser.user) && (this.userService.currentUser.user.jwtToken)) {
            //     request = request.clone({
            //         setHeaders: {
            //             Authorization: `Bearer ${this.userService.currentUser.user.jwtToken}`
            //         }
            //     });
            // } else {
            //     request = request.clone({
            //         setHeaders: {
            //             Authorization: `Bearer ${this.userService.currentUser.jwtToken}`
            //         }
            //     });
            // }
            if (this.token && this.token.jwtToken) {
                request = request.clone({
                    setHeaders: {
                        Authorization: `Bearer ${this.token.jwtToken}`
                    }
                });
            }
        }
        return next.handle(request).pipe(
            catchError((err: any) => {
                if (err instanceof HttpErrorResponse) {
                    if (err.url !== 'https://www.api.quantifyninja.com/user/report'
                        && window.location.hostname === 'webapp.quantifyninja.com') {
                        // this.errorService.sendErrorReport(this.router.url, err.url, err.error, request.body).subscribe();
                        this.matDialog.open(ConfirmationDialogComponent, {
                            data: {
                                'dialogFor': 'api-error'
                            },
                        }).afterClosed().subscribe();
                    }
                }

                return throwError(err);
            })
        );
    }
}
