import {Injectable} from '@angular/core';
import {UserService} from './user.service';
import {Observable} from 'rxjs';

@Injectable()

export class AccountSettings extends UserService {
  public currentStep = 1;
  public settingsTitle: string;
  public settingsInfo = 'Set your profile information, business information, currency or subscription plan';

  public getAccSettings(publicId: string): Observable<any> {
    return this.post('user/get_user_acc_settings', {
      publicId: publicId
    });
  }

  public saveAccSettings(data: string): Observable<any> {
    return this.post('user/save_user_acc_settings', data);
  }

  public getProductSettings(publicId: string): Observable<any> {
    return this.post('product/get_product_settings', {
      publicId: publicId
    });
  }

  public getShortNames(publicId: string, sku): Observable<any> {
    return this.post('product/get_product_settings', {
      publicId: publicId,
      sku: sku
    });
  }

  public getAllProducts(publicId: string): Observable<any> {
    return this.post('product/get_all_products', {
      publicId: publicId
    });
  }

  public getFbmProductSettings(publicId: string): Observable<any> {
    return this.post('product/get_fbm_products', {
      publicId: publicId
    });
  }

  public saveShortName(sellerId, data: any): Observable<any> {
    return this.post(`product/${sellerId}/save_short_name`, data);
  }

  public saveProductSettings(data: any): Observable<any> {
    return this.post('product/save_product', data);
  }

  public saveProductsSettings(publicId: string, data: any): Observable<any> {
    return this.post('product/save_product_settings', {
      publicId: publicId,
      list: data
    });
  }

  public setHideValue(sellerId, asin, sku, data): Observable<any> {
    return this.post(`product/${sellerId}/${asin}/${sku}/set_hide`, data);
  }

  public setCost(sellerId, asin, sku, data): Observable<any> {
    return this.post(`product/${sellerId}/${asin}/${sku}/set_cost`, data);
  }

  public getExpensesSettings(publicId: string): Observable<any> {
    return this.post('user/get_expenses_settings', {
      publicId: publicId,
    });
  }

  public saveExpenses(data: any): Observable<any> {
    return this.post('user/save_expenses_settings', data);
  }

  public createExpenses(data: any): Observable<any> {
    return this.post('user/create_expenses', data);
  }

  public deleteExpenses(data: any): Observable<any> {
    return this.post('user/delete_expenses', data);
  }
}
