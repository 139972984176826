import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';
import * as moment from 'moment';
import { ErrorsComponent } from '../popups/errors/errors.component';
import { ProfitbreakdownpopupComponent } from '../popups/profitbreakdownpopup/profitbreakdownpopup.component';
import { FormControl } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { ShareService } from 'src/app/services/share.service';
import { UserService } from 'src/app/services/user.service';
import { EChartOption } from 'echarts';
import { Sort } from '@angular/material/sort';
import { StorageBrowser } from 'src/app/services/storage.browser';
import { EditShortNameDialogComponent } from '../../../dialogs/edit-short-name/edit-short-name-dialog.component';
import { EditProductCostDialogComponent } from '../../../dialogs/edit-product-cost/edit-product-cost-dialog.component';
@Component({
  selector: 'app-profitbreakdownwithoutgroup',
  templateUrl: './profitbreakdownwithoutgroup.component.html',
  styleUrls: ['./profitbreakdownwithoutgroup.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class ProfitbreakdownwithoutgroupComponent implements OnInit {
  @Input() profitbreakdowndataSource: any;
  @Input() formData: any;
  @Input() columnFilters;
  @Input() colspanCount;
  @Output("search") search = new EventEmitter();
  service: any;
  // selectMatrix = new FormControl();
  searchArray: any = [];
  chartExpandedElement: any;
  expandedElement: any;
  searchCtrl = new FormControl();
  totalSalesValue: any = 0;
  totalSalesChange: any = 0;
  totalPPCValue: any = 0;
  totalPPCChange: any = 0;
  totalPromosValue: any = 0;
  totalPromosChange: any = 0;
  totalRevenueValue: any = 0;
  totalRevenueChange: any = 0;
  totalCostValue: any = 0;
  totalCostChange: any = 0;
  totalProfitValue: any = 0;
  totalProfitChange: any = 0;
  totalRoiPerItem: any = 0;
  totalMarginValue: any = 0;
  totalMarginChange: any = 0;
  totalRoiValue: any = 0;
  totalRoiChange: any = 0;
  totalPPCExpensesValue: any = 0;
  totalPPCPerUnitValue: any = 0;
  totalPPCExpensesChange: any = 0;
  countProduct: any = 0;
  sortedData: any;
  echartPPUBarChangeOptions: any;
  constructor(private dialog: MatDialog, private shareService: ShareService, public userService: UserService, public storageBrowser: StorageBrowser) {
    this.currencySymbol = this.storageBrowser.get('$Currency');
  }
  currencySymbol: any;
  barMaxWidth = 10;

  echartProfitBreakdownBarOptions: EChartOption = {
    color: ['rgba(77,153,255,0.49)', 'rgba(77,255,178,0.49)', 'rgba(77,107,255,0.49)', '#f48229', '#ff7aac', '#d9df20', '#5792cc', '#8bc53e', '#6bf7aa'],
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow'
      }
    },
    legend: {
      data: ['Units Sold', 'PPC Units', 'Promotions Units', 'Profit', 'Revenue', 'Cost', 'Margin', 'Roi', 'Refunds'],
      left: '10px',
      selected: {
        'Revenue': false,
        'Cost': false,
        'Margin': false,
        'Roi': false,
        'Refunds': false
      }
    },
    calculable: true,
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true
    },
    xAxis: [
      {
        type: 'category',
        axisTick: { show: false },
        data: [],

        axisLine: {
          lineStyle: {
            // color: "rgba(36, 56, 83, 0.5)"
            color: {
              type: 'linear',
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [{
                offset: 0, color: 'red' // color at 0% position
              }],
              global: false // false by default
            }
          },
        }
      },
      {
        type: 'category',
        axisTick: { show: false },
        data: [],
        axisLine: {
          lineStyle: {
            // color: "rgba(36, 56, 83, 0.5)"
            color: {
              type: 'linear',
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [{
                offset: 0, color: 'red' // color at 0% position
              }],
              global: false // false by default
            }

          },
        }
      }
    ],
    yAxis: [
      {
        type: 'value',
        axisLine: {
          lineStyle: {
            color: "rgba(36, 56, 83, 0.5)"
          },
        },
        axisLabel: {
          formatter: function (value) {
            return value >= 1000 ? value / 1000 + 'k' : value;
          },
          textStyle: {
            color: function (value, index) {
              return value == 0 ? 'red' : "rgba(36, 56, 83, 0.5)";
            }
          }
        },
        splitLine: {
          show: false
        },
        name: 'Units Sold',
        nameGap: 37,
        nameLocation: 'center'
      },
      {
        type: 'value',
        axisLine: {
          lineStyle: {
            color: "rgba(36, 56, 83, 0.5)"
          }
        },
        axisLabel: {
          // formatter: function (value) {
          //   return value >= 1000 ? this.getCurrencySymbol(value / 1000) + 'k' : this.getCurrencySymbol(value);
          // },
          // textStyle: {
          //   color: function (value, index) {
          //     return value == 0 ? 'red' : "rgba(36, 56, 83, 0.5)";
          //   }
          // },
        },
        name: 'Profit',
        nameGap: 55,
        nameRotate: -90,
        nameLocation: 'center',
      }
    ],
    dataZoom: [
      {
        type: "inside"
      }
    ],
    //   toolbox: {
    //     feature: {
    //         //dataView: {show: true, readOnly: false, title:"Data View"},
    //         magicType: {show: true, type: ['line', 'bar'], title:{"line":"Line","bar":"Bar"}},
    //         restore: {show: true, title:"Restore"},
    //         saveAsImage: {show: true, title:"Download"}
    //     }
    // },
    series: [
      {
        name: 'Units Sold',
        type: 'bar',
        data: [],
        smooth: true,
        showSymbol: false,
        itemStyle: {
          emphasis: {
            barBorderRadius: [50, 50]
          },
          normal: {
            barBorderRadius: [50, 50, 0, 0]
          }
        },
        barMaxWidth: this.barMaxWidth
      },
      {
        name: 'PPC Units',
        type: 'bar',
        data: [],
        smooth: true,
        showSymbol: false,
        itemStyle: {
          emphasis: {
            barBorderRadius: [50, 50]
          },
          normal: {
            barBorderRadius: [50, 50, 0, 0]
          }
        },
        barMaxWidth: this.barMaxWidth
      },
      {
        name: 'Promotions Units',
        type: 'bar',
        barGap: '-100%',  // this changed
        //barCategoryGap: '30%', // this changed
        data: [],
        smooth: true,
        showSymbol: false,
        itemStyle: {
          emphasis: {
            barBorderRadius: [50, 50]
          },
          normal: {
            barBorderRadius: [50, 50, 0, 0]
          }
        },
        barMaxWidth: this.barMaxWidth
      },
      {
        name: 'Profit',
        type: 'line',
        smooth: true,
        showSymbol: false,
        data: [],
        yAxisIndex: 1,
        itemStyle: {
          emphasis: {
            barBorderRadius: [50, 50]
          },
          normal: {
            barBorderRadius: [50, 50, 0, 0]
          }
        },
        barMaxWidth: this.barMaxWidth,
        lineStyle: { width: 3 }
      }
    ]
  };

  ngOnInit() {
    // this.selectMatrix.setValue(['sales', 'ppc', 'promotion', 'profit']);
    this.countProduct = this.profitbreakdowndataSource.rowData.length;
    this.profitbreakdowndataSource.rowData.map((resData, i) => {
      this.totalSalesValue += (resData.sales ? resData.sales.value : 0);
      this.totalSalesChange += (resData.sales ? resData.sales.change : 0);
      this.totalPPCValue += ((resData.ppc && resData.ppc.value) ? resData.ppc.value : 0);
      this.totalPPCChange += (resData.ppc ? resData.ppc.change : 0);
      this.totalPromosValue += (resData.promos ? resData.promos.value : 0);
      this.totalPromosChange += (resData.promos ? resData.promos.change : 0);
      this.totalRevenueValue += (resData.revenue ? resData.revenue.value : 0);
      this.totalRevenueChange += (resData.revenue ? resData.revenue.change : 0);
      this.totalCostValue += (resData.cost ? resData.cost.value : 0);
      this.totalCostChange += (resData.cost ? resData.cost.change : 0);
      this.totalProfitValue += (resData.profit ? resData.profit.value : 0);
      this.totalProfitChange += (resData.profit ? resData.profit.change : 0);
      this.totalRoiPerItem += ((resData.productCost ? resData.productCost : 0) + (resData.shippingCost ? resData.shippingCost : 0)) * (resData.sales ? resData.sales.value : 0);
      this.totalPPCExpensesValue += (resData.ppcExpenses ? resData.ppcExpenses.value : 0);
      // this.totalPPCPerUnitValue += (resData.ppcPerUnit ? resData.ppcPerUnit.value : 0);
      this.totalPPCExpensesChange += (resData.ppcExpenses ? resData.ppcExpenses.change : 0);
      // this.totalRoiValue = Math.round((this.totalProfitValue / this.totalCostValue) * 100);
      // this.totalRoiChange = Math.round((this.totalProfitChange / this.totalCostChange) * 100);
      //this.countProduct += this.countProduct;
    });
    this.totalMarginValue = Math.trunc((this.totalProfitValue / this.totalRevenueValue) * 100);
    this.totalMarginChange = Math.round((this.totalProfitChange / this.totalRevenueChange) * 100);
    this.totalPPCPerUnitValue = this.totalPPCExpensesValue / this.totalSalesValue;
    this.totalRoiValue = Math.round((this.totalProfitValue / this.totalRoiPerItem) * 100);
    this.totalRoiChange = this.totalProfitChange / (this.totalSalesChange * this.totalCostChange);
    this.searchCtrl.valueChanges.pipe(debounceTime(500)).subscribe(value => {
      //this.search.emit(value);
      if (value && value.length > 0) {
        value = value.toLowerCase();
        let filteredArray = this.profitbreakdowndataSource.rowData.filter((main) => {
          return main.title.toLowerCase().indexOf(value) > -1;
        });
        this.searchArray = this.profitbreakdowndataSource.rowData;
        this.profitbreakdowndataSource.rowData = filteredArray;
        this.getMessage(this.profitbreakdowndataSource);
      } else {
        this.profitbreakdowndataSource.rowData = this.profitbreakdowndataSource.rowData;
        this.chartExpandedElement = null;
      }
    });
  }

  getMessage(message) {
    this.totalSalesValue = this.totalPPCValue = this.totalPPCExpensesValue = this.totalRoiPerItem = this.totalPromosValue = this.totalRevenueValue = this.totalCostValue = this.totalProfitValue = this.totalMarginValue = this.totalRoiValue = 0;
    this.countProduct = message.rowData.length;
    this.profitbreakdowndataSource.rowData.map((resData, i) => {
      this.totalSalesValue += (resData.sales ? resData.sales.value : 0);
      this.totalSalesChange += (resData.sales ? resData.sales.change : 0);;
      this.totalPPCValue += (resData.ppc ? resData.ppc.value : 0);
      this.totalPPCChange += (resData.ppc ? resData.ppc.change : 0);
      this.totalPromosValue += (resData.promos ? resData.promos.value : 0);
      this.totalPromosChange += (resData.promos ? resData.promos.change : 0);
      this.totalRevenueValue += (resData.revenue ? resData.revenue.value : 0);
      this.totalRevenueChange += (resData.revenue ? resData.revenue.change : 0);
      this.totalCostValue += (resData.cost ? resData.cost.value : 0);
      this.totalCostChange += (resData.cost ? resData.cost.change : 0);
      this.totalProfitValue += (resData.profit ? resData.profit.value : 0);
      this.totalProfitChange += (resData.profit ? resData.profit.change : 0);
      this.totalRoiPerItem += ((resData.productCost ? resData.productCost : 0) + (resData.shippingCost ? resData.shippingCost : 0)) * (resData.sales ? resData.sales.value : 0);
      this.totalPPCExpensesValue += (resData.ppcExpenses ? resData.ppcExpenses.value : 0);
      // this.totalPPCPerUnitValue += (resData.ppcPerUnit ? resData.ppcPerUnit.value : 0);
      this.totalPPCExpensesChange += (resData.ppcExpenses ? resData.ppcExpenses.change : 0);
      this.totalMarginValue = Math.trunc((this.totalProfitValue / this.totalRevenueValue) * 100);
      this.totalMarginChange = Math.round((this.totalProfitChange / this.totalRevenueChange) * 100);
      // this.totalRoiValue = Math.round((this.totalProfitValue / this.totalCostValue) * 100);
      // this.totalRoiChange = Math.round((this.totalProfitChange / this.totalCostChange) * 100);
      this.totalPPCPerUnitValue = this.totalPPCExpensesValue / this.totalSalesValue;
      this.totalRoiValue = Math.round((this.totalProfitValue / this.totalRoiPerItem) * 100);
      this.totalRoiChange = this.totalProfitChange / (this.totalSalesChange * this.totalCostChange);
    });
  }

  sortData(sort: Sort) {
    const data = this.profitbreakdowndataSource.rowData.slice();
    if (!sort.active || sort.direction === '') {
      this.sortedData = data;
      return;
    }

    this.sortedData = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'title': return compare(a.title, b.title, isAsc);
        case 'asin': return compare(a.asin, b.asin, isAsc);
        case 'sku': return compare(a.sku, b.sku, isAsc);
        case 'sales': return compare((a.sales && a.sales.value != undefined) ? a.sales.value : 0, (b.sales && b.sales.value != undefined) ? b.sales.value : 0, isAsc);
        case 'ppc': return compare((a.ppc && a.ppc.value != undefined) ? a.ppc.value : 0, (b.ppc && b.ppc.value != undefined) ? b.ppc.value : 0, isAsc);
        case 'ppcExpenses': return compare((a.ppcExpenses && a.ppcExpenses.value != undefined) ? a.ppcExpenses.value : 0, (b.ppcExpenses && b.ppcExpenses.value != undefined) ? b.ppcExpenses.value : 0, isAsc);
        case 'ppcPerUnit': return compare((a.ppcPerUnit && a.ppcPerUnit.value != undefined) ? a.ppcPerUnit.value : 0, (b.ppcPerUnit && b.ppcPerUnit.value != undefined) ? b.ppcPerUnit.value : 0, isAsc);
        case 'promos': return compare((a.promos && a.promos.value != undefined) ? a.promos.value : 0, (b.promos && b.promos.value != undefined) ? b.promos.value : 0, isAsc);
        case 'revenue': return compare((a.revenue && a.revenue.value != undefined) ? a.revenue.value : 0, (b.revenue && b.revenue.value != undefined) ? b.revenue.value : 0, isAsc);
        case 'cost': return compare((a.cost && a.cost.value != undefined) ? a.cost.value : 0, (b.cost && b.cost.value != undefined) ? b.cost.value : 0, isAsc);
        case 'profit': return compare((a.profit && a.profit.value != undefined) ? a.profit.value : 0, (b.profit && b.profit.value != undefined) ? b.profit.value : 0, isAsc);
        case 'margin': return compare((a.margin && a.margin.value != undefined) ? a.margin.value : 0, (b.margin && b.margin.value != undefined) ? b.margin.value : 0, isAsc);
        case 'roi': return compare((a.roi && a.roi.value != undefined) ? a.roi.value : 0, (b.roi && b.roi.value != undefined) ? b.roi.value : 0, isAsc);
        default: return 0;
      }
    });
    this.profitbreakdowndataSource.rowData = this.sortedData;
  }



  showGraph(brand, currentIndex, sku) {
    if (this.chartExpandedElement == currentIndex) {
      this.chartExpandedElement = null;
    } else {
      this.chartExpandedElement = currentIndex;
      this.getTableChartData(brand, sku);
    }

  }

  getTableChartData(brand, sku) {
    this.echartProfitBreakdownBarOptions = JSON.parse(JSON.stringify(this.echartProfitBreakdownBarOptions));////reset chart options
    this.formData.brands = [brand];
    this.formData.skus = [sku];

    this.userService.getProfitBreakdownTableChart(this.formData).subscribe((res: any) => {
      if (res) {
        this.echartPPUBarChangeOptions = JSON.parse(JSON.stringify(this.echartProfitBreakdownBarOptions));
        this.echartProfitBreakdownBarOptions = JSON.parse(JSON.stringify(this.echartProfitBreakdownBarOptions));////reinit to resolve init load issue
        this.echartProfitBreakdownBarOptions.yAxis.map((item, i) => {
          item.axisLabel.textStyle = {
            color: function (value) {
              return value == 0 ? 'red' : "rgba(36, 56, 83, 0.5)";
            }
          }
        });
        this.formData.skus = [];
        this.formData.brands = [];
        let currency_name = this.storageBrowser.get('$Currency');
        this.echartProfitBreakdownBarOptions.yAxis[1].axisLabel = {
          // axisLabel: {
          formatter: function (value) {
            let currency_symbols = {
              'USD': '$', // US Dollar
              'AUD': '$',
              'CAD': '$',
              'MXN': '$',
              'GBP': '£', // British Pound Sterling
              'INR': '₹', // Indian Rupee
              'JPY': '¥', // Japanese Yen
            };
            if (currency_symbols[currency_name] !== undefined) {
              return value >= 1000 ? currency_symbols[currency_name] + (value / 1000) + 'k' : currency_symbols[currency_name] + value;
            } else {
              return value;
            }
          },
          textStyle: {
            color: function (value, index) {
              return value == 0 ? 'red' : "rgba(36, 56, 83, 0.5)";
            }
          },
          // },
        }
        this.echartProfitBreakdownBarOptions.xAxis[0].data = res.time.map(item => moment(item).format("MMM D, YYYY"));
        // this.echartProfitBreakdownBarOptions.series[0].data = res.units;
        // this.echartProfitBreakdownBarOptions.series[1].data = res.ppc;
        // this.echartProfitBreakdownBarOptions.series[2].data = res.promotions;
        // this.echartProfitBreakdownBarOptions.series[3].data = res.profit;
        this.echartProfitBreakdownBarOptions.series = [
          this.getBarChartData('Units Sold', res.units),
          this.getBarChartData('PPC Units', res.ppc),
          this.getBarChartData('Promotions Units', res.promotions),
          this.getLineChartData('Profit', res.profit),
          this.getLineChartData('Roi', res.roi),
          this.getBarChartData('Refunds', res.refunds),
          this.getLineChartData('Cost', res.cost),
          this.getLineChartData('Revenue', res.revenue),
          this.getLineChartData('Margin', res.margin)
        ];
        // this.selectMatrix.valueChanges.pipe().subscribe(value => {
        //   this.echartProfitBreakdownBarOptions.series = [];
        //   if (value.length > 0) {
        //     this.echartProfitBreakdownBarOptions = JSON.parse(JSON.stringify(this.echartProfitBreakdownBarOptions));
        //     if (value.indexOf('sales') > -1) {
        //       this.echartProfitBreakdownBarOptions.series[value.indexOf('sales')] = this.getBarChartData('Units Sold', res.units);
        //     }
        //     if (value.indexOf('ppc') > -1) {
        //       this.echartProfitBreakdownBarOptions.series[value.indexOf('ppc')] = this.getBarChartData('PPC Units', res.ppc);
        //     }
        //     if (value.indexOf('promotion') > -1) {
        //       this.echartProfitBreakdownBarOptions.series[value.indexOf('promotion')] = this.getBarChartData('Promotions Units', res.promotions);
        //     }
        //     if (value.indexOf('profit') > -1) {
        //       this.echartProfitBreakdownBarOptions.series[value.indexOf('profit')] = this.getLineChartData('Profit', res.profit);
        //     }
        //     if (value.indexOf('roi') > -1) {
        //       this.echartProfitBreakdownBarOptions.series[value.indexOf('roi')] = this.getLineChartData('Roi', res.roi);
        //     }
        //     if (value.indexOf('refunds') > -1) {
        //       this.echartProfitBreakdownBarOptions.series[value.indexOf('refunds')] = this.getBarChartData('Refunds', res.refunds);
        //     }
        //     if (value.indexOf('cost') > -1) {
        //       this.echartProfitBreakdownBarOptions.series[value.indexOf('cost')] = this.getLineChartData('Cost', res.cost);
        //     }
        //     if (value.indexOf('revenue') > -1) {
        //       this.echartProfitBreakdownBarOptions.series[value.indexOf('revenue')] = this.getLineChartData('Revenue', res.revenue);
        //     }
        //     if (value.indexOf('margin') > -1) {
        //       this.echartProfitBreakdownBarOptions.series[value.indexOf('margin')] = this.getLineChartData('Margin', res.margin);
        //     }
        //   } else {
        //     this.echartPPUBarChangeOptions.xAxis[0].data = res.time.map(item => moment(item).format("MMM D, YYYY"));
        //     this.echartPPUBarChangeOptions.series[0].data = res.units;
        //     this.echartPPUBarChangeOptions.series[1].data = res.ppc;
        //     this.echartPPUBarChangeOptions.series[2].data = res.promotions;
        //     this.echartPPUBarChangeOptions.series[3].data = res.profit;
        //     this.echartProfitBreakdownBarOptions = JSON.parse(JSON.stringify(this.echartPPUBarChangeOptions));
        //   }

        // });
        //this.appLoaderService.close();
      }
    }, (err) => {
      this.dialog.open(ErrorsComponent, { panelClass: 'errorPopupCls' });
    });
  }

  openProfitBrakedownDialog(brand, sku) {
    // this.appLoaderService.open();
    this.userService.loaderObesrver.next(true);
    this.formData.brands = [brand];
    this.formData.skus = [sku];
    this.userService.getProfitBreakdownList("time_frame/", this.formData).subscribe((res: any) => {
      if (res) {
        res.formData = JSON.parse(JSON.stringify(this.formData));
        this.dialog.open(ProfitbreakdownpopupComponent, {
          width: '470px',
          panelClass: 'profitbreakdownpopup',
          height: 'auto',
          data: res
        }).afterClosed().subscribe((result) => {
          if(result) {
            for (let i = 0; i < this.profitbreakdowndataSource.rowData.length; i++) {
              if (this.profitbreakdowndataSource.rowData[i].sku === result.SKU) {
                this.profitbreakdowndataSource.rowData[i].title = result.shortName;
              }
            }
          }
        });
        this.formData.skus = [];
        this.formData.brands = [];
        this.userService.loaderObesrver.next(false);
        // this.appLoaderService.close();
      }
    }, (err) => {
      this.dialog.open(ErrorsComponent, { panelClass: 'errorPopupCls' });
    });
  }

  getLineChartData(type, data) {
    return {
      name: type,
      type: 'line',
      smooth: true,
      showSymbol: false,
      data: data,
      yAxisIndex: 1,
      itemStyle: {
        emphasis: {
          barBorderRadius: [50, 50]
        },
        normal: {
          barBorderRadius: [50, 50, 0, 0]
        }
      },
      barMaxWidth: this.barMaxWidth,
      lineStyle: { width: 3 }
    }
  }
  getBarChartData(type, data) {
    // 
    return {
      name: type,
      type: 'bar',
      smooth: true,
      showSymbol: false,
      data: data,
      barGap: '-100%',
      itemStyle: {
        emphasis: {
          barBorderRadius: [50, 50]
        },
        normal: {
          barBorderRadius: [50, 50, 0, 0]
        }
      },
      barMaxWidth: this.barMaxWidth
    }
  }

  cancelSearch() {
    this.searchCtrl.reset();
    this.profitbreakdowndataSource.rowData = this.searchArray;
    this.getMessage(this.profitbreakdowndataSource);
  }

  editShortName(main): void {
    this.dialog.open(EditShortNameDialogComponent, {
      data: {
        'dialogFor': 'edit-short-name-dialog',
        'product': main,
      },
      width: '800px'
    }).afterClosed().subscribe((result) => {
      if(result) {
        for (let i = 0; i < this.profitbreakdowndataSource.rowData.length; i++) {
          if (this.profitbreakdowndataSource.rowData[i].sku === result.sku) {
            this.profitbreakdowndataSource.rowData[i].title = result.defaultShortName;
          }
        }
      }
    });
  }

  editProductCost(main): void {
    this.dialog.open(EditProductCostDialogComponent, {
      data: {
        'dialogFor': 'edit-product-dialog',
        'product': main,
      },
      panelClass: 'edit-product-dialog',
      width: '1350px'
    }).afterClosed().subscribe((result) => {
      if(result) {
        for (let i = 0; i < this.profitbreakdowndataSource.rowData.length; i++) {
          if (this.profitbreakdowndataSource.rowData[i].sku === result.SKU) {
            this.profitbreakdowndataSource.rowData[i].title = result.shortName;
          }
        }
      }
    });
  }

};
function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
} 