import { Component, OnInit, Input } from '@angular/core';
import * as moment from 'moment';
import { ErrorsComponent } from '../popups/errors/errors.component';
import { ProfitbreakdownpopupComponent } from '../popups/profitbreakdownpopup/profitbreakdownpopup.component';
import { FormControl } from '@angular/forms';
import { UserService } from 'src/app/services/user.service';
import { ShareService } from 'src/app/services/share.service';
import { MatDialog } from '@angular/material/dialog';
import { EChartOption } from 'echarts';
import { debounceTime } from 'rxjs/operators';
import { Sort } from '@angular/material/sort';
import { StorageBrowser } from 'src/app/services/storage.browser';
import { EditShortNameDialogComponent } from '../../../dialogs/edit-short-name/edit-short-name-dialog.component';
import { EditProductCostDialogComponent } from '../../../dialogs/edit-product-cost/edit-product-cost-dialog.component';
@Component({
  selector: 'app-profitbreakdowntwogroup',
  templateUrl: './profitbreakdowntwogroup.component.html',
  styleUrls: ['./profitbreakdowntwogroup.component.scss'],
})

export class ProfitbreakdowntwogroupComponent implements OnInit {
  @Input() profitbreakdowndataSource: any;
  @Input() formData: any;
  @Input() selectedVal: any;
  @Input() columnFilters;
  @Input() colspanCount;
  service: any;
  oneGrouping: any;
  searchArray: any = [];
  chartExpandedElement: any;
  sublevel1expandedElement: any;
  searchCtrl = new FormControl();
  expandedElement: any;
  countProduct: any = 0;
  totalSalesValue: any = 0;
  totalSalesChange: any = 0;
  totalPPCValue: any = 0;
  totalPPCChange: any = 0;
  totalPromosValue: any = 0;
  totalPromosChange: any = 0;
  totalRevenueValue: any = 0;
  totalRevenueChange: any = 0;
  totalCostValue: any = 0;
  totalCostChange: any = 0;
  totalProfitValue: any = 0;
  totalProfitChange: any = 0;
  totalRoiPerItem: any = 0;
  totalMarginValue: any = 0;
  totalMarginChange: any = 0;
  totalRoiValue: any = 0;
  totalRoiChange: any = 0;
  totalPPCExpensesValue: any = 0;
  totalPPCExpensesChange: any = 0;
  totalPPCPerUnitValue: any = 0;
  totalPPCPerUnitChange: any = 0;
  // selectMatrix = new FormControl();
  echartPPUBarChangeOptions: any;
  currencySymbol: any;
  constructor(private dialog: MatDialog, private shareService: ShareService, public userService: UserService, public storageBrowser: StorageBrowser) {
    this.currencySymbol = this.storageBrowser.get('$Currency');
  }

  barMaxWidth = 10;

  echartProfitBreakdownBarOptions: EChartOption = {
    color: ['rgba(77,153,255,0.49)', 'rgba(77,255,178,0.49)', 'rgba(77,107,255,0.49)', '#f48229', '#ff7aac', '#d9df20', '#5792cc', '#8bc53e', '#6bf7aa'],
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow'
      }
    },
    legend: {
      data: ['Units Sold', 'PPC Units', 'Promotions Units', 'Profit', 'Revenue', 'Cost', 'Margin', 'Roi', 'Refunds'],
      left: '10px',
      selected: {
        'Revenue': false,
        'Cost': false,
        'Margin': false,
        'Roi': false,
        'Refunds': false
      }
    },
    calculable: true,
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true
    },
    xAxis: [
      {
        type: 'category',
        axisTick: { show: false },
        data: [],

        axisLine: {
          lineStyle: {
            // color: "rgba(36, 56, 83, 0.5)"
            color: {
              type: 'linear',
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [{
                offset: 0, color: 'red' // color at 0% position
              }],
              global: false // false by default
            }
          },
        }
      },
      {
        type: 'category',
        axisTick: { show: false },
        data: [],
        axisLine: {
          lineStyle: {
            // color: "rgba(36, 56, 83, 0.5)"
            color: {
              type: 'linear',
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [{
                offset: 0, color: 'red' // color at 0% position
              }],
              global: false // false by default
            }

          },
        }
      }
    ],
    yAxis: [
      {
        type: 'value',
        axisLine: {
          lineStyle: {
            color: "rgba(36, 56, 83, 0.5)"
          },
        },
        axisLabel: {
          formatter: function (value) {
            return value >= 1000 ? value / 1000 + 'k' : value;
          },
          textStyle: {
            color: function (value, index) {
              return value == 0 ? 'red' : "rgba(36, 56, 83, 0.5)";
            }
          }
        },
        splitLine: {
          show: false
        },
        name: 'Units Sold',
        nameGap: 37,
        nameLocation: 'center'
      },
      {
        type: 'value',
        axisLine: {
          lineStyle: {
            color: "rgba(36, 56, 83, 0.5)"
          }
        },
        axisLabel: {
          // formatter: function (value) {
          //   return value >= 1000 ? this.getCurrencySymbol(value / 1000) + 'k' : this.getCurrencySymbol(value);
          // },
          // textStyle: {
          //   color: function (value, index) {
          //     return value == 0 ? 'red' : "rgba(36, 56, 83, 0.5)";
          //   }
          // },
        },
        name: 'Profit',
        nameGap: 55,
        nameRotate: -90,
        nameLocation: 'center',
      }
    ],
    dataZoom: [
      {
        type: "inside"
      }
    ],
    //   toolbox: {
    //     feature: {
    //         //dataView: {show: true, readOnly: false, title:"Data View"},
    //         magicType: {show: true, type: ['line', 'bar'], title:{"line":"Line","bar":"Bar"}},
    //         restore: {show: true, title:"Restore"},
    //         saveAsImage: {show: true, title:"Download"}
    //     }
    // },
    series: [
      {
        name: 'Units Sold',
        type: 'bar',
        data: [],
        smooth: true,
        showSymbol: false,
        itemStyle: {
          emphasis: {
            barBorderRadius: [50, 50]
          },
          normal: {
            barBorderRadius: [50, 50, 0, 0]
          }
        },
        barMaxWidth: this.barMaxWidth
      },
      {
        name: 'PPC Units',
        type: 'bar',
        data: [],
        smooth: true,
        showSymbol: false,
        itemStyle: {
          emphasis: {
            barBorderRadius: [50, 50]
          },
          normal: {
            barBorderRadius: [50, 50, 0, 0]
          }
        },
        barMaxWidth: this.barMaxWidth
      },
      {
        name: 'Promotions Units',
        type: 'bar',
        barGap: '-100%',  // this changed
        //barCategoryGap: '30%', // this changed
        data: [],
        smooth: true,
        showSymbol: false,
        itemStyle: {
          emphasis: {
            barBorderRadius: [50, 50]
          },
          normal: {
            barBorderRadius: [50, 50, 0, 0]
          }
        },
        barMaxWidth: this.barMaxWidth
      },
      {
        name: 'Profit',
        type: 'line',
        smooth: true,
        showSymbol: false,
        data: [],
        yAxisIndex: 1,
        itemStyle: {
          emphasis: {
            barBorderRadius: [50, 50]
          },
          normal: {
            barBorderRadius: [50, 50, 0, 0]
          }
        },
        barMaxWidth: this.barMaxWidth,
        lineStyle: { width: 3 }
      }
    ]
  };

  ngOnInit() {

    // this.selectMatrix.setValue(['sales', 'ppc', 'promotion', 'profit']);
    this.oneGrouping = this.profitbreakdowndataSource;
    this.oneGrouping.map((res) => {
      if (res[0].title) {
        if (res[0].resD.length > 0) {
          res[0].resD.map((resData) => {
            if (resData[0].resD.length > 0) {
              this.countProduct += resData[0].resD.length;
              resData[0].resD.map(item => {
                res[0].sku.push(item.sku);
                resData[0].sku.push(item.sku);
                if (item.sales && item.sales.value != undefined) {
                  resData[0].totalSalesValue += (item.sales ? item.sales.value : 0);
                }
                if (item.ppc && item.ppc.value != undefined) {
                  resData[0].totalSalesChange += (item.sales ? item.sales.change : 0);
                }
                if (item.ppcExpenses && item.ppcExpenses.value != undefined) {
                  resData[0].totalPPCExpensesValue += (item.ppcExpenses.value);
                }
                if (item.ppcExpenses && item.ppcExpenses.change != undefined) {
                  resData[0].totalPPCExpensesChange += (item.ppcExpenses ? item.ppcExpenses.change : 0);
                }
                // if (item.ppcPerUnit && item.ppcPerUnit.value != undefined) {
                //   resData[0].totalPPCPerUnitValue += (item.ppcPerUnit.value);
                // }
                if (item.ppcPerUnit && item.ppcPerUnit.change != undefined) {
                  resData[0].totalPPCPerUnitChange += (item.ppcPerUnit.change ? item.ppcPerUnit.change : 0);
                }
                if (item.ppc && item.ppc.value != undefined) {
                  resData[0].totalPPCValue += (item.ppc.value);
                }
                if (item.ppc && item.ppc.change != undefined) {
                  resData[0].totalPPCChange += (item.ppc ? item.ppc.change : 0);
                }
                if (item.promos && item.promos.value != undefined) {
                  resData[0].totalPromosValue += (item.promos ? item.promos.value : 0);
                }
                if (item.promos && item.promos.change != undefined) {
                  resData[0].totalPromosChange += (item.promos ? item.promos.change : 0);
                }
                if (item.revenue && item.revenue.value != undefined) {
                  resData[0].totalRevenueValue += (item.revenue ? item.revenue.value : 0);
                }
                if (item.revenue && item.revenue.change != undefined) {
                  resData[0].totalRevenueChange += (item.revenue ? item.revenue.change : 0);
                }
                if (item.cost && item.cost.value != undefined) {
                  resData[0].totalCostValue += (item.cost ? item.cost.value : 0);
                }
                if (item.cost && item.cost.value != undefined) {
                  resData[0].totalCostChange += (item.cost ? item.cost.change : 0);
                }
                if (item.profit && item.profit.value != undefined) {
                  resData[0].totalProfitValue += (item.profit ? item.profit.value : 0);
                }
                if (item.profit && item.profit.change != undefined) {
                  resData[0].totalProfitChange += (item.profit ? item.profit.change : 0);
                }
                resData[0].totalRoiPerItem += ((item.productCost ? item.productCost : 0) + (item.shippingCost ? item.shippingCost : 0)) * (item.sales && item.sales.value != undefined ? item.sales.value : 0);
              });
              resData[0].totalMarginValue = Math.trunc((resData[0].totalProfitValue / resData[0].totalRevenueValue) * 100);
              resData[0].totalMarginChange = Math.round((resData[0].totalProfitChange / resData[0].totalRevenueChange) * 100);
              resData[0].totalPPCPerUnitValue = resData[0].totalPPCExpensesValue / resData[0].totalSalesValue;
              // resData[0].totalRoiValue = Math.round((resData[0].totalProfitValue / resData[0].totalProductCost) * 100);
              resData[0].totalRoiValue = Math.round((resData[0].totalProfitValue / resData[0].totalRoiPerItem) * 100);
              resData[0].totalRoiChange = Math.round((resData[0].totalProfitChange / resData[0].totalCostChange) * 100);
            }
            res[0].totalSalesValue += resData[0].totalSalesValue;
            res[0].totalSalesChange += resData[0].totalSalesChange;
            res[0].totalPPCValue += resData[0].totalPPCValue;
            res[0].totalPPCChange += resData[0].totalPPCChange;
            res[0].totalPPCExpensesValue += resData[0].totalPPCExpensesValue;
            res[0].totalPPCExpensesChange += resData[0].totalPPCExpensesChange;
            // res[0].totalPPCPerUnitValue += resData[0].totalPPCPerUnitValue;
            res[0].totalPPCPerUnitChange += resData[0].totalPPCPerUnitChange;
            res[0].totalPromosValue += resData[0].totalPromosValue;
            res[0].totalPromosChange += resData[0].totalPromosChange;
            res[0].totalRevenueValue += resData[0].totalRevenueValue;
            res[0].totalRevenueChange += resData[0].totalRevenueChange;
            res[0].totalCostValue += resData[0].totalCostValue;
            res[0].totalCostChange += resData[0].totalCostChange;
            res[0].totalProfitValue += resData[0].totalProfitValue;
            res[0].totalProfitChange += resData[0].totalProfitChange;
            res[0].totalRoiPerItem += resData[0].totalRoiPerItem;

          });
          res[0].totalMarginValue = Math.trunc((res[0].totalProfitValue / res[0].totalRevenueValue) * 100);
          res[0].totalMarginChange = Math.round((res[0].totalProfitChange / res[0].totalRevenueChange) * 100);
          res[0].totalPPCPerUnitValue = res[0].totalPPCExpensesValue / res[0].totalSalesValue;
          // res[0].totalRoiValue = Math.round((res[0].totalProfitValue / res[0].totalProductCost) * 100);
          res[0].totalRoiValue = Math.round((res[0].totalProfitValue / res[0].totalRoiPerItem) * 100);
          res[0].totalRoiChange = Math.round((res[0].totalProfitChange / res[0].totalCostChange) * 100);
          this.totalSalesValue += res[0].totalSalesValue;
          this.totalSalesChange += res[0].totalSalesChange;
          this.totalPPCValue += res[0].totalPPCValue;
          this.totalPPCChange += res[0].totalPPCChange;
          this.totalPPCExpensesValue += res[0].totalPPCExpensesValue;
          this.totalPPCExpensesChange += res[0].totalPPCExpensesChange;
          // this.totalPPCPerUnitValue += res[0].totalPPCPerUnitValue;
          this.totalPPCPerUnitChange += res[0].totalPPCPerUnitChange;
          this.totalPromosValue += res[0].totalPromosValue;
          this.totalPromosChange += res[0].totalPromosChange;
          this.totalRevenueValue += res[0].totalRevenueValue;
          this.totalRevenueChange += res[0].totalRevenueChange;
          this.totalCostValue += res[0].totalCostValue;
          this.totalCostChange += res[0].totalCostChange;
          this.totalProfitValue += res[0].totalProfitValue;
          this.totalProfitChange += res[0].totalProfitChange;
          this.totalRoiPerItem += res[0].totalRoiPerItem;

          this.totalMarginValue = Math.trunc((this.totalProfitValue / this.totalRevenueValue) * 100);
          this.totalMarginChange = Math.round((this.totalProfitChange / this.totalRevenueChange) * 100);
          this.totalPPCPerUnitValue = this.totalPPCExpensesValue / this.totalSalesValue;
          this.totalRoiValue = Math.round((this.totalProfitValue / this.totalRoiPerItem) * 100);
          this.totalRoiChange = Math.round((this.totalProfitChange / this.totalCostChange) * 100);
        }
      }
    });

    this.searchCtrl.valueChanges.pipe(debounceTime(500)).subscribe(value => {
      if (value && value.length > 0) {
        value = value.toLowerCase();
        this.searchArray = JSON.parse(JSON.stringify(this.oneGrouping));
        this.oneGrouping.map((res, i) => {
          if (res[0].title) {
            res[0].resD.map((res, j) => {
              if (res[0].resD.length > 0) {
                let filteredArray = res[0].resD.filter((main) => {
                  return main.title.toLowerCase().indexOf(value) > -1;
                });
                res[0].resD = filteredArray;
              }
            });
          }
        });
        this.getMessage(this.oneGrouping);
      } else {
        this.oneGrouping = this.oneGrouping;
        this.chartExpandedElement = null;
      }
    });
  }

  getMessage(message) {
    this.totalSalesValue = this.totalPPCValue = this.totalPromosValue = this.totalPPCExpensesValue = this.totalRevenueValue = this.totalCostValue = this.totalRoiPerItem = this.totalProfitValue = this.totalMarginValue = this.totalRoiValue = this.countProduct = 0;
    if (message.length > 0) {
      this.oneGrouping.map((res) => {
        if (res[0].title) {
          res[0].totalSalesValue = res[0].totalSalesChange = res[0].totalPPCValue =
            res[0].totalPPCChange = res[0].totalPPCExpensesValue =
            res[0].totalPPCExpensesChange = res[0].totalPPCPerUnitValue = res[0].totalPPCExpensesChange = res[0].totalPromosValue = res[0].totalPromosChange =
            res[0].totalMarginValue = res[0].totalMarginChange = res[0].totalRoiValue = res[0].totalRoiChange =
            res[0].totalRevenueValue = res[0].totalRevenueChange = res[0].totalCostValue =
            res[0].totalCostChange = res[0].totalProfitValue = res[0].totalRoiPerItem = res[0].totalProfitChange = 0;
          if (res[0].resD.length > 0) {
            res[0].resD.map((resData) => {
              resData[0].totalSalesValue = resData[0].totalSalesChange = resData[0].totalPPCValue =
                resData[0].totalPPCChange = resData[0].totalPPCExpensesValue =
                resData[0].totalPPCExpensesChange = resData[0].totalPPCPerUnitValue = resData[0].totalPPCExpensesChange = resData[0].totalPromosValue = resData[0].totalPromosChange =
                resData[0].totalMarginValue = resData[0].totalMarginChange = resData[0].totalRoiValue = resData[0].totalRoiChange =
                resData[0].totalRevenueValue = resData[0].totalRevenueChange = resData[0].totalCostValue =
                resData[0].totalCostChange = resData[0].totalProfitValue = resData[0].totalRoiPerItem = resData[0].totalProfitChange = 0;
              if (resData[0].resD.length > 0) {
                this.countProduct += resData[0].resD.length;
                resData[0].resD.map(item => {
                  resData[0].sku.push(item.sku);
                  if (item.sales && item.sales.value != undefined) {
                    resData[0].totalSalesValue += (item.sales ? item.sales.value : 0);
                  }
                  if (item.sales && item.sales.change != undefined) {
                    resData[0].totalSalesChange += (item.sales ? item.sales.change : 0);
                  }
                  if (item.ppcExpenses && item.ppcExpenses.value != undefined) {
                    resData[0].totalPPCExpensesValue += (item.ppcExpenses.value);
                  }
                  if (item.ppcExpenses && item.ppcExpenses.change != undefined) {
                    resData[0].totalPPCCExpenseshange += (item.ppcExpenses ? item.ppcExpenses.change : 0);
                  }
                  // if (item.ppcPerUnit && item.ppcPerUnit.value != undefined) {
                  //   resData[0].totalPPCPerUnitValue += (item.ppcPerUnit.value);
                  // }
                  if (item.ppcPerUnit && item.ppcPerUnit.change != undefined) {
                    resData[0].totalPPCPerUnitChange += (item.ppcPerUnit.change ? item.ppcPerUnit.change : 0);
                  }
                  if (item.ppc && item.ppc.value != undefined) {
                    resData[0].totalPPCValue += (item.ppc.value);
                  }
                  if (item.ppc && item.ppc.change != undefined) {
                    resData[0].totalPPCChange += (item.ppc ? item.ppc.change : 0);
                  }
                  if (item.promos && item.promos.value != undefined) {
                    resData[0].totalPromosValue += (item.promos ? item.promos.value : 0);
                  }
                  if (item.promos && item.promos.change != undefined) {
                    resData[0].totalPromosChange += (item.promos ? item.promos.change : 0);
                  }
                  if (item.revenue && item.revenue.value != undefined) {
                    resData[0].totalRevenueValue += (item.revenue ? item.revenue.value : 0);
                  }
                  if (item.revenue && item.revenue.change != undefined) {
                    resData[0].totalRevenueChange += (item.revenue ? item.revenue.change : 0);
                  }
                  if (item.cost && item.cost.value != undefined) {
                    resData[0].totalCostValue += (item.cost ? item.cost.value : 0);
                  }
                  if (item.cost && item.cost.value != undefined) {
                    resData[0].totalCostChange += (item.cost ? item.cost.change : 0);
                  }
                  if (item.profit && item.profit.value != undefined) {
                    resData[0].totalProfitValue += (item.profit ? item.profit.value : 0);
                  }
                  if (item.profit && item.profit.change != undefined) {
                    resData[0].totalProfitChange += (item.profit ? item.profit.change : 0);
                  }
                  resData[0].totalRoiPerItem += ((item.productCost ? item.productCost : 0) + (item.shippingCost ? item.shippingCost : 0)) * (item.sales && item.sales.value != undefined ? item.sales.value : 0);
                });
                resData[0].totalMarginValue = Math.trunc((resData[0].totalProfitValue / resData[0].totalRevenueValue) * 100);
                resData[0].totalMarginChange = Math.round((resData[0].totalProfitChange / resData[0].totalRevenueChange) * 100);
                // resData[0].totalRoiValue = Math.round((resData[0].totalProfitValue / resData[0].totalCostValue) * 100);
                // resData[0].totalRoiChange = Math.round((resData[0].totalProfitChange / resData[0].totalCostChange) * 100);
                resData[0].totalPPCPerUnitValue = resData[0].totalPPCExpensesValue / resData[0].totalSalesValue;
                resData[0].totalRoiValue = Math.round((resData[0].totalProfitValue / resData[0].totalRoiPerItem) * 100);
                resData[0].totalRoiChange = resData[0].totalProfitChange / (resData[0].totalSalesChange * resData[0].totalCostChange);

                res[0].totalSalesValue += resData[0].totalSalesValue;
                res[0].totalSalesChange += resData[0].totalSalesChange;
                res[0].totalPPCValue += resData[0].totalPPCValue;
                res[0].totalPPCChange += resData[0].totalPPCChange;
                res[0].totalPPCExpensesValue += resData[0].totalPPCExpensesValue;
                res[0].totalPPCExpensesChange += resData[0].totalPPCExpensesChange;
                // res[0].totalPPCPerUnitValue += resData[0].totalPPCPerUnitValue;
                res[0].totalPPCPerUnitChange += resData[0].totalPPCPerUnitChange;
                res[0].totalPromosValue += resData[0].totalPromosValue;
                res[0].totalPromosChange += resData[0].totalPromosChange;
                res[0].totalRevenueValue += resData[0].totalRevenueValue;
                res[0].totalRevenueChange += resData[0].totalRevenueChange;
                res[0].totalCostValue += resData[0].totalCostValue;
                res[0].totalCostChange += resData[0].totalCostChange;
                res[0].totalProfitValue += resData[0].totalProfitValue;
                res[0].totalProfitChange += resData[0].totalProfitChange;
                res[0].totalRoiPerItem += resData[0].totalRoiPerItem;
              }
            });
            res[0].totalMarginValue = Math.trunc((res[0].totalProfitValue / res[0].totalRevenueValue) * 100);
            res[0].totalMarginChange = Math.round((res[0].totalProfitChange / res[0].totalRevenueChange) * 100);
            // res[0].totalRoiValue = Math.round((res[0].totalProfitValue / res[0].totalCostValue) * 100);
            // res[0].totalRoiChange = Math.round((res[0].totalProfitChange / res[0].totalCostChange) * 100);
            res[0].totalPPCPerUnitValue = res[0].totalPPCExpensesValue / res[0].totalSalesValue;
            res[0].totalRoiValue = Math.round((res[0].totalProfitValue / res[0].totalRoiPerItem) * 100);
            res[0].totalRoiChange = res[0].totalProfitChange / (res[0].totalSalesChange * res[0].totalCostChange);
            res[0].totalSalesChange = res[0].totalSalesChange / this.countProduct;
            res[0].totalPPCChange = res[0].totalPPCChange / this.countProduct;
            res[0].totalPPCExpensesChange = res[0].totalPPCExpensesChange / this.countProduct;
            res[0].totalPromosChange = res[0].totalPromosChange / this.countProduct;
            res[0].totalRevenueChange = res[0].totalRevenueChange / this.countProduct;
            res[0].totalCostChange = res[0].totalCostChange / this.countProduct;
            res[0].totalProfitChange = res[0].totalProfitChange / this.countProduct;
            res[0].totalMarginChange = res[0].totalMarginChange / this.countProduct;
            res[0].totalRoiChange = res[0].totalRoiChange / this.countProduct;

            this.totalSalesValue += res[0].totalSalesValue;
            this.totalSalesChange += res[0].totalSalesChange;
            this.totalPPCValue += res[0].totalPPCValue;
            this.totalPPCChange += res[0].totalPPCChange;
            this.totalPPCExpensesValue += res[0].totalPPCExpensesValue;
            this.totalPPCExpensesChange += res[0].totalPPCExpensesChange;
            // this.totalPPCPerUnitValue += res[0].totalPPCPerUnitValue;
            this.totalPPCPerUnitChange += res[0].totalPPCPerUnitChange;
            this.totalPromosValue += res[0].totalPromosValue;
            this.totalPromosChange += res[0].totalPromosChange;
            this.totalRevenueValue += res[0].totalRevenueValue;
            this.totalRevenueChange += res[0].totalRevenueChange;
            this.totalCostValue += res[0].totalCostValue;
            this.totalCostChange += res[0].totalCostChange;
            this.totalProfitValue += res[0].totalProfitValue;
            this.totalProfitChange += res[0].totalProfitChange;
            this.totalRoiPerItem += res[0].totalRoiPerItem;

            this.totalMarginValue = Math.trunc((this.totalProfitValue / this.totalRevenueValue) * 100);
            this.totalMarginChange = Math.round((this.totalProfitChange / this.totalRevenueChange) * 100);
            // this.totalRoiValue = Math.round((this.totalProfitValue / this.totalCostValue) * 100);
            // this.totalRoiChange = Math.round((this.totalProfitChange / this.totalCostChange) * 100);
            this.totalPPCPerUnitValue = this.totalPPCExpensesValue / this.totalSalesValue;
            this.totalRoiValue = Math.round((this.totalProfitValue / this.totalRoiPerItem) * 100);
            this.totalRoiChange = this.totalProfitChange / (this.totalSalesChange * this.totalCostChange);
          }
        }
      });
      this.totalSalesChange = this.totalSalesChange / this.countProduct;
      this.totalPPCChange = this.totalPPCChange / this.countProduct;
      this.totalPPCExpensesChange = this.totalPPCExpensesChange / this.countProduct;
      this.totalPromosChange = this.totalPromosChange / this.countProduct;
      this.totalRevenueChange = this.totalRevenueChange / this.countProduct;
      this.totalCostChange = this.totalCostChange / this.countProduct;
      this.totalProfitChange = this.totalProfitChange / this.countProduct;
      this.totalMarginChange = this.totalMarginChange / this.countProduct;
      this.totalRoiChange = this.totalRoiChange / this.countProduct;
    }
  }

  sortData(sort: Sort) {
    let groupArr = [];
    let groupArr1 = [];
    this.oneGrouping.map(result => {
      if (result[0].title) {
        groupArr.push(result[0]);
        result[0].resD.map(resultData => {
          if (resultData[0].title) {
            groupArr1.push(resultData[0]);
          }
        });

      }
    });
    this.oneGrouping.map(result => {
      if (result[0].title) {
        result[0].resD.map(res => {
          if (res[0].title) {
            res[0].resD.sort((a, b) => {
              return this.sortDataResponse(sort, a, b);
            });
          }
        });
      }
    });
    groupArr.sort((a, b) => {
      return this.sortDataResponse(sort, a, b);
    });
    groupArr1.sort((a, b) => {
      return this.sortDataResponse(sort, a, b);
    });
    let i = 0;
    let j = 0;
    this.oneGrouping.map((res) => {
      if (res[0].title) {
        res[0] = groupArr[i];
        i++;
      }
    });
  }

  sortDataResponse(sort, a, b) {
    const isAsc = sort.direction === 'asc';
    switch (sort.active) {
      case 'title': return compare(a.title, b.title, isAsc);
      case 'asin': return compare(a.asin, b.asin, isAsc);
      case 'sku': return compare(a.sku, b.sku, isAsc);
      case 'sales': return compare((a.sales && a.sales.value != undefined) ? a.sales.value : 0, (b.sales && b.sales.value != undefined) ? b.sales.value : 0, isAsc);
      case 'ppc': return compare((a.ppc && a.ppc.value != undefined) ? a.ppc.value : 0, (b.ppc && b.ppc.value != undefined) ? b.ppc.value : 0, isAsc);
      case 'ppcExpenses': return compare((a.ppcExpenses && a.ppcExpenses.value != undefined) ? a.ppcExpenses.value : 0, (b.ppcExpenses && b.ppcExpenses.value != undefined) ? b.ppcExpenses.value : 0, isAsc);
      case 'ppcPerUnit': return compare((a.ppcPerUnit && a.ppcPerUnit.value != undefined) ? a.ppcPerUnit.value : 0, (b.ppcPerUnit && b.ppcPerUnit.value != undefined) ? b.ppcPerUnit.value : 0, isAsc);
      case 'promos': return compare((a.promos && a.promos.value != undefined) ? a.promos.value : 0, (b.promos && b.promos.value != undefined) ? b.promos.value : 0, isAsc);
      case 'revenue': return compare((a.revenue && a.revenue.value != undefined) ? a.revenue.value : 0, (b.revenue && b.revenue.value != undefined) ? b.revenue.value : 0, isAsc);
      case 'cost': return compare((a.cost && a.cost.value != undefined) ? a.cost.value : 0, (b.cost && b.cost.value != undefined) ? b.cost.value : 0, isAsc);
      case 'profit': return compare((a.profit && a.profit.value != undefined) ? a.profit.value : 0, (b.profit && b.profit.value != undefined) ? b.profit.value : 0, isAsc);
      case 'margin': return compare((a.margin && a.margin.value != undefined) ? a.margin.value : 0, (b.margin && b.margin.value != undefined) ? b.margin.value : 0, isAsc);
      case 'roi': return compare((a.roi && a.roi.value != undefined) ? a.roi.value : 0, (b.roi && b.roi.value != undefined) ? b.roi.value : 0, isAsc);
      default: return 0;
    }
  }

  expandCollapse(currentIndex) {
    if (this.expandedElement == currentIndex) {
      this.expandedElement = null;
    } else {
      this.expandedElement = currentIndex;
    }
  }

  showGraph(brand, currentIndex, sku, type) {
    if (this.chartExpandedElement == currentIndex + type) {
      this.chartExpandedElement = null;
    } else {
      this.chartExpandedElement = currentIndex + type;
      this.getTableChartData(brand, sku, type);
    }

  }

  getAmazonEnum(marketPlaceName) {
    if (marketPlaceName && marketPlaceName[1] == "com") {
      return ['AMAZON_COM'];
    }
    if (marketPlaceName && marketPlaceName[1] == "ca") {
      return ['AMAZON_CANADA'];
    }
    if (marketPlaceName && marketPlaceName[1] == "com" && marketPlaceName[2] == "mx") {
      return ['AMAZON_MEXICO'];
    }
    if (marketPlaceName && marketPlaceName[1] == "co" && marketPlaceName[2] == "uk") {
      return ['AMAZON_UK'];
    }
    if (marketPlaceName && marketPlaceName[1] == "fr") {
      return ['AMAZON_FRANCE'];
    }
    if (marketPlaceName && marketPlaceName[1] == "de") {
      return ['AMAZON_GERMANY'];
    }
    if (marketPlaceName && marketPlaceName[1] == "it") {
      return ['AMAZON_ITALY'];
    }
    if (marketPlaceName && marketPlaceName[1] == "es") {
      return ['AMAZON_SPAIN'];
    }
    if (marketPlaceName && marketPlaceName[1] == "co" && marketPlaceName[2] == "jp") {
      return ['AMAZON_JAPAN'];
    }
    if (marketPlaceName && marketPlaceName[1] == "com" && marketPlaceName[2] == "au") {
      return ['AMAZON_AUSTRALIA'];
    }
    if (marketPlaceName && marketPlaceName[1] == "in") {
      return ['AMAZON_INDIA'];
    }
    if (marketPlaceName && marketPlaceName[1] == "ae") {
      return ['AMAZON_UAE'];
    }
  }

  getTableChartData(brand, sku, type) {
    this.echartProfitBreakdownBarOptions = JSON.parse(JSON.stringify(this.echartProfitBreakdownBarOptions));////reset chart options
    this.formData.brands = [brand];
    if (brand.indexOf('Amazon') > -1) {
      let marketPlaceName = brand.split(".");
      this.formData.sc = this.getAmazonEnum(marketPlaceName);
    }
    this.formData.skus = [sku];
    if (type == 'array1' || type == 'array2') {
      this.formData.skus = sku;
    }

    this.userService.getProfitBreakdownTableChart(this.formData).subscribe((res: any) => {
      if (res) {
        this.echartPPUBarChangeOptions = JSON.parse(JSON.stringify(this.echartProfitBreakdownBarOptions));
        this.echartProfitBreakdownBarOptions = JSON.parse(JSON.stringify(this.echartProfitBreakdownBarOptions));////reinit to resolve init load issue
        this.echartProfitBreakdownBarOptions.yAxis.map((item, i) => {
          item.axisLabel.textStyle = {
            color: function (value) {
              return value == 0 ? 'red' : "rgba(36, 56, 83, 0.5)";
            }
          }
        });
        this.echartProfitBreakdownBarOptions.xAxis[0].data = res.time.map(item => moment(item).format("MMM D, YYYY"));
        // this.echartProfitBreakdownBarOptions.series[0].data = res.units;
        // this.echartProfitBreakdownBarOptions.series[1].data = res.ppc;
        // this.echartProfitBreakdownBarOptions.series[2].data = res.promotions;
        // this.echartProfitBreakdownBarOptions.series[3].data = res.profit;
        this.echartProfitBreakdownBarOptions.series = [
          this.getBarChartData('Units Sold', res.units),
          this.getBarChartData('PPC Units', res.ppc),
          this.getBarChartData('Promotions Units', res.promotions),
          this.getLineChartData('Profit', res.profit),
          this.getLineChartData('Roi', res.roi),
          this.getBarChartData('Refunds', res.refunds),
          this.getLineChartData('Cost', res.cost),
          this.getLineChartData('Revenue', res.revenue),
          this.getLineChartData('Margin', res.margin)
        ];
        //this.appLoaderService.close();
        this.formData.skus = [];
        this.formData.brands = [];
        this.formData.sc = [];
        let currency_name = this.storageBrowser.get('$Currency');
        this.echartProfitBreakdownBarOptions.yAxis[1].axisLabel = {
          // axisLabel: {
          formatter: function (value) {
            let currency_symbols = {
              'USD': '$', // US Dollar
              'AUD': '$',
              'CAD': '$',
              'MXN': '$',
              'GBP': '£', // British Pound Sterling
              'INR': '₹', // Indian Rupee
              'JPY': '¥', // Japanese Yen
            };
            if (currency_symbols[currency_name] !== undefined) {
              return value >= 1000 ? currency_symbols[currency_name] + (value / 1000) + 'k' : currency_symbols[currency_name] + value;
            } else {
              return value;
            }
          },
          textStyle: {
            color: function (value, index) {
              return value == 0 ? 'red' : "rgba(36, 56, 83, 0.5)";
            }
          },
          // },
        }
        // this.selectMatrix.valueChanges.pipe().subscribe(value => {
        //   this.echartProfitBreakdownBarOptions.series = [];
        //   if (value.length > 0) {
        //     this.echartProfitBreakdownBarOptions = JSON.parse(JSON.stringify(this.echartProfitBreakdownBarOptions));
        //     if (value.indexOf('sales') > -1) {
        //       this.echartProfitBreakdownBarOptions.series[value.indexOf('sales')] = this.getBarChartData('Units Sold', res.units);
        //     }
        //     if (value.indexOf('ppc') > -1) {
        //       this.echartProfitBreakdownBarOptions.series[value.indexOf('ppc')] = this.getBarChartData('PPC Units', res.ppc);
        //     }
        //     if (value.indexOf('promotion') > -1) {
        //       this.echartProfitBreakdownBarOptions.series[value.indexOf('promotion')] = this.getBarChartData('Promotions Units', res.promotions);
        //     }
        //     if (value.indexOf('profit') > -1) {
        //       this.echartProfitBreakdownBarOptions.series[value.indexOf('profit')] = this.getLineChartData('Profit', res.profit);
        //     }
        //     if (value.indexOf('roi') > -1) {
        //       this.echartProfitBreakdownBarOptions.series[value.indexOf('roi')] = this.getLineChartData('Roi', res.roi);
        //     }
        //     if (value.indexOf('refunds') > -1) {
        //       this.echartProfitBreakdownBarOptions.series[value.indexOf('refunds')] = this.getBarChartData('Refunds', res.refunds);
        //     }
        //     if (value.indexOf('cost') > -1) {
        //       this.echartProfitBreakdownBarOptions.series[value.indexOf('cost')] = this.getLineChartData('Cost', res.cost);
        //     }
        //     if (value.indexOf('revenue') > -1) {
        //       this.echartProfitBreakdownBarOptions.series[value.indexOf('revenue')] = this.getLineChartData('Revenue', res.revenue);
        //     }
        //     if (value.indexOf('margin') > -1) {
        //       this.echartProfitBreakdownBarOptions.series[value.indexOf('margin')] = this.getLineChartData('Margin', res.margin);
        //     }
        //   } else {
        //     this.echartPPUBarChangeOptions.xAxis[0].data = res.time.map(item => moment(item).format("MMM D, YYYY"));
        //     this.echartPPUBarChangeOptions.series[0].data = res.units;
        //     this.echartPPUBarChangeOptions.series[1].data = res.ppc;
        //     this.echartPPUBarChangeOptions.series[2].data = res.promotions;
        //     this.echartPPUBarChangeOptions.series[3].data = res.profit;
        //     this.echartProfitBreakdownBarOptions = JSON.parse(JSON.stringify(this.echartPPUBarChangeOptions));
        //   }

        // });
      }
    }, (err) => {
      this.dialog.open(ErrorsComponent, { panelClass: 'errorPopupCls' });
    });
  }

  getLineChartData(type, data) {
    return {
      name: type,
      type: 'line',
      smooth: true,
      showSymbol: false,
      data: data,
      yAxisIndex: 1,
      itemStyle: {
        emphasis: {
          barBorderRadius: [50, 50]
        },
        normal: {
          barBorderRadius: [50, 50, 0, 0]
        }
      },
      barMaxWidth: this.barMaxWidth,
      lineStyle: { width: 3 }
    }
  }
  getBarChartData(type, data) {
    // 
    return {
      name: type,
      type: 'bar',
      smooth: true,
      showSymbol: false,
      data: data,
      barGap: '-100%',
      itemStyle: {
        emphasis: {
          barBorderRadius: [50, 50]
        },
        normal: {
          barBorderRadius: [50, 50, 0, 0]
        }
      },
      barMaxWidth: this.barMaxWidth
    }
  }

  sublevel1expandCollapse(currentIndex) {
    if (this.sublevel1expandedElement == currentIndex) {
      this.sublevel1expandedElement = null;
    } else {
      this.sublevel1expandedElement = currentIndex;
    }
  }

  openProfitBrakedownDialog(brand, sku) {
    this.userService.loaderObesrver.next(true);
    // this.appLoaderService.open();
    this.formData.brands = [brand];
    this.formData.skus = Array.isArray(sku) ? sku : [sku];
    this.userService.getProfitBreakdownList("time_frame/", this.formData).subscribe((res: any) => {
      if (res) {
        res.formData = this.formData;
        this.dialog.open(ProfitbreakdownpopupComponent, {
          width: '470px',
          panelClass: 'profitbreakdownpopup',
          height: 'auto',
          data: res
        }).afterClosed().subscribe((result) => {
          if(result) {
            for (let i = 0; i < this.profitbreakdowndataSource.length; i++) {
              for (let j = 0; j < this.profitbreakdowndataSource[i][1].length; j++) {
                for (let k = 0; k < this.profitbreakdowndataSource[i][1][j].length; k++) {
                  for (let l = 0; l < this.profitbreakdowndataSource[i][1][j][1].length; l++) { 
                    if (this.profitbreakdowndataSource[i][1][j][1][l].sku === result.SKU) {
                      this.profitbreakdowndataSource[i][1][j][1][l].title = result.shortName;
                    }
                  }
                }
              }
            }
          }
        });
        this.formData.skus = [];
        this.formData.brands = [];
        this.userService.loaderObesrver.next(false);
        // this.appLoaderService.close();
      }
    }, (err) => {
      this.dialog.open(ErrorsComponent, { panelClass: 'errorPopupCls' });
    });
  }

  cancelSearch() {
    this.searchCtrl.reset();
    this.oneGrouping = JSON.parse(JSON.stringify(this.searchArray));
    this.getMessage(this.oneGrouping);
  }

  editShortName(main): void {
    this.dialog.open(EditShortNameDialogComponent, {
      data: {
        'dialogFor': 'edit-short-name-dialog',
        'product': main,
      },
      width: '800px'
    }).afterClosed().subscribe((result) => {
      if(result) {
        for (let i = 0; i < this.profitbreakdowndataSource.length; i++) {
          for (let j = 0; j < this.profitbreakdowndataSource[i][1].length; j++) {
            for (let k = 0; k < this.profitbreakdowndataSource[i][1][j].length; k++) {
              for (let l = 0; l < this.profitbreakdowndataSource[i][1][j][1].length; l++) { 
                if (this.profitbreakdowndataSource[i][1][j][1][l].sku === result.sku) {
                  this.profitbreakdowndataSource[i][1][j][1][l].title = result.defaultShortName;
                }
              }
            }
          }
        }
      }
    });
  }

  editProductCost(main): void {
    this.dialog.open(EditProductCostDialogComponent, {
      data: {
        'dialogFor': 'edit-product-dialog',
        'product': main,
      },
      panelClass: 'edit-product-dialog',
      width: '1350px'
    }).afterClosed().subscribe((result) => {
      if(result) {
        for (let i = 0; i < this.profitbreakdowndataSource.length; i++) {
          for (let j = 0; j < this.profitbreakdowndataSource[i][1].length; j++) {
            for (let k = 0; k < this.profitbreakdowndataSource[i][1][j].length; k++) {
              for (let l = 0; l < this.profitbreakdowndataSource[i][1][j][1].length; l++) { 
                if (this.profitbreakdowndataSource[i][1][j][1][l].sku === result.SKU) {
                  this.profitbreakdowndataSource[i][1][j][1][l].title = result.shortName;
                }
              }
            }
          }
        }
      }
    });
  }

};
function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}