import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';

@Injectable()

export class TextService {
      public currentText: any;
      public currentLangTitle = {
            text: '',
            img: ''
      };
      public changeLangDetect = new Subject<any>();

      public changeLoaderObserver(): Observable<any> {
            return this.changeLangDetect.asObservable();
      }

      public textEN = {
            affiliatesTitle: 'Affiliates',
            TermsConditions: 'Terms & Conditions',
            affiliateSubTitle: 'Get up to 35$ for every successful affiliation!',
            affiliateLink: 'Your Affiliate Link:',
            date: 'Date',
            homepage: 'homepage',
            coupons: 'Coupons',
            reviewRequest: 'Review Requests',
            uploadNewCouponCaps: 'UPLOAD NEW COUPON',
            couponSubTitle: 'Look at your coupons and add new ones',
            noCouponMsg: 'You are ready to start creating new coupons',
            pageNotFoundCaps: 'PAGE NOT FOUND',
            backTo: 'Back to',
            pageNotFound: 'Page not found',
            noDataFound: 'No data found',
            full: 'Full',
            getOneCode: 'Get one code',
            check: 'Check',
            delete: 'Delete',
            pending: 'Pending',
            notFound: 'Not Found',
            add: 'Add',
            asin: 'Asin',
            keyword: 'Keyword',
            marketplace: 'Marketplace',
            registration: 'Registration',
            searchAsinsByKeywords: 'search asins by keywords',
            addRegion: 'Add region',
            getAnotherLink: 'Get another link',
            AmazonJapanAccount: 'Amazon Japan account',
            AmazonAustraliaAccount: 'Amazon Australia account',
            AmazonIndiaAccount: 'Amazon India account',
            amazonRegions: '(Amazon.com, Amazon.ca, Amazon.co.mx)',
            amazonRegions2: '(Amazon.co.uk, Amazon.de, Amazon.se, Amazon.it, Amazon.fr)',
            amazonRegions3: '(Amazon.jp)',
            amazonRegions4: '(Amazon.com.au)',
            amazonRegions5: '(Amazon.com.in)',
            amazonRegions6: '(Amazon.com.in)',
            tableHeaders: {
                  earnedAmount: 'Earned Amount',
                  spot: 'Spot',
                  couponName: 'Coupon Name',
                  couponsLeft: 'Coupons left',
                  expiryDate: 'Expiry Date',
                  getCouponCode: 'Get coupon code',
                  CouponCode: 'Coupon code',
                  page: 'Page',
                  marketPlace: 'Market place',
                  userPublicID: 'User Public ID',
                  fullRegistration: 'Full Registration',
                  planType: 'Plan Type',
                  agreementStatus: 'Agreement Status',
                  lastUpdated: 'Last updated',
            },
            bulkTitle: 'new bulk rule',
            bulkSubTitle: 'Send a one time email to all your buyers who purchased in a specific date range.',
            bulkSubLimit1: 'You are limited to',
            bulkSubLimit2: 'emails per week. If you need more credit please',
            contactUs: 'Contact us',
            filters: 'Filters',
            status: 'Status',
            template: 'Template',
            from: 'From',
            to: 'To',
            bulkName: 'Bulk Name',
            createNewBulk: 'CREATE NEW BULK',
            clearAll: 'Clear all',
            email: 'Email',
            sent: 'sent',
            estimated: 'estimated',
            edit: 'Edit',
            editFbaTemplteTitle: 'EDIT FBA TEMPLATE',
            backFbaTemplate: '<  Back to FBA Templates',
            editFbmTemplteTitle: 'EDIT FBM TEMPLATE',
            backFbmTemplate: '<  Back to FBM Templates',
            createFbaTemplateTitle: 'СREATE NEW FBA TEMPLATE',
            createFbmTemplateTitle: 'СREATE NEW FBM TEMPLATE',
            success: 'Success',
            fbmMissingProductsReport: 'Report has been send, we will review your issue as soon as possible',
            invalidFormat: 'Invalid format',
            imageLoadValidation: 'File must be of type jpg, jpeg, png or gif and cannot exceed 2MB',
            fail: 'Fail',
            emptyNameError: 'Name cannot be empty',
            emptySubjectError: 'Subject cannot be empty',
            imageUploading: 'Image uploading',
            imageUploadingMessage: 'Please save the template before uploading images from your PC',
            name: 'Name',
            subject: 'Subject',
            when: 'When',
            after: 'After',
            products: 'Products',
            editTemplateAddVariableTitle: 'Add variables',
            editTemplateAddVariableSubTitle: 'Just click on certain variable and it will appear in your message, to learn more about variables',
            clickHere: 'Click here',
            product: 'Product',
            order: 'Order',
            customer: 'Customer',
            shipping: 'Shipping',
            templateOptions: 'Template options',
            templateOption1: 'Choose the status for this template',
            templateOption2: 'Send template if buyer left negative feedback',
            templateOption3: 'Send template if order refunded',
            templateOption4: 'Include repeated buyers', //'Send template to repeated buyers',
            templateOption5: 'Send template if promotion given',
            templateOption6: 'In case of promotion, send template when promotion value',
            templateOption7: 'Select Specific Sales Channels',
            templateOption8: 'Add coupon to the template',
            allChannels: 'All channels',
            editTemplateFileTitle: 'Add a file to the template',
            editTemplateFileSubtitle: 'Files total size should not exceed 7MB, to see all supported formats',
            upload: 'Upload',
            cancel: 'Cancel',
            templateTestMessage: 'You can test this template and see how it will look for your customers, or just save it',
            test: 'Test',
            save: 'Save',
            fbaEdited: 'FBA template successfully created',
            fbaCreated: 'FBA template successfully edited',
            fbmCreated: 'FBM template created successfully',
            fbmEdited: 'FBM template edited successfully',
            deleteTemplate: 'Delete template',
            copyTemplate: 'Copy template',
            exportTemplates: 'Export templates',
            templateDeleted: 'Template successfully deleted',
            templateCopied: 'Template successfully copied',
            templatesExported: 'Template successfully exported',
            fbaTitle: 'fba templates',
            createNewFba: 'CREATE NEW FBA',
            fbaSubTitle1: 'Emails will be sent from',
            fbaSubTitle2: 'If this is not the email associated to your amazon',
            fbmTitle: 'fbm templates',
            account: 'account',
            timeFrame: 'Time frame',
            event: 'Event',
            salesChannel: 'Sales channel',
            exportToCSV: 'Export to CSV',
            openRate: 'Open Rate',
            templateName: 'Template name',
            copy: 'Copy',
            createNewFbm: 'CREATE NEW FBM',
            clickHereToEdit: 'Click here to edit',
            couponCreatedTitle: 'Coupon created',
            couponEdited: 'The coupon was edited successfully',
            couponCreated: 'The coupon was created successfully',
            headerMessageSent: 'Your message has been sent',
            trialMessage1: 'Your 14 days trial expires in',
            trialMessage2: 'to upgrade your account',
            days: 'days',
            upgrade: 'Upgrade',
            user: 'User',
            settings: 'Settings',
            default: 'Default',
            warning: 'Warning',
            dashboardNoDateMessage: 'You should pick from and to dates first',
            time: 'Time',
            unitSold: 'Units Sold',
            noData: 'No data',
            profit: 'Profit',
            profitNotInclude: 'Profit on specific products does not include PPC & lightning deals expenses',
            emailsSent: 'Emails Sent',
            revenue: 'Revenue',
            unitsSoFar: 'Units so far',
            ordersLowerCase: 'orders',
            promotionLowerCase: 'promotion',
            calculate: 'Calculate',
            yesterday: 'Yesterday',
            amazonSalesData: 'Amazon sales data',
            zoom: 'Zoom',
            all: 'All',
            selectedProductsUpperCase: 'SELECTED PRODUCTS',
            daily: 'Daily',
            weekly: 'Weekly',
            conversationRate: 'Conversional Rate',
            sessions: 'Sessions',
            last12Month: '(last 12 months)',
            unitsReturned: 'Units returned',
            chartPopularProductsTitle: 'Popular Products',
            selectRegion: 'Select region',
            dashboard: 'Dashboard',
            asinAlertsViewer: 'Alerts',
            automatedMessaging: 'Automated messaging',
            FBA: 'FBA',
            FBM: 'FBM',
            bulk: 'Bulk',
            notifications: 'Notifications',
            moneyBack: 'Money Back',
            hijackedListingViewer: 'Hijacked listing viewer',
            ordersAndBL: 'Orders and blacklist',
            download: 'Download',
            keywordOptimizer: 'Keyword optimizer',
            facebookBotManager: 'Facebook bot manager',
            conversationManager: 'Conversation manager',
            autoResponse: 'Auto response',
            subscribers: 'Subscribers',
            menu: 'Menu',
            asinTracker: 'ASIN tracker',
            superUser: 'Super user',
            FAQQuestions: 'Any questions or difficulties',
            watchTutorials: 'watch tutorials',
            inviteFriends: 'Invite friends',
            autoResponseTitle: 'Facebook Posts Auto Response',
            createNewResponse: 'create new response',
            autoResponseSubTitle1: 'Some short explanation info about conversation manager and bot creation',
            autoResponseSubTitle2: 'If it’s not necessary we can delete this text',
            pageName: 'Page Name',
            responseName: 'Response Name',
            autoResponseText: 'You are ready to start creating new conversations',
            botMenuTitle: 'Create a Menu',
            botMenuSubTitle1: 'Menu can be set per page and cannot be set for a specific conversation',
            botMenuSubTitle2: 'You can add up to 2 top level menu and up to 3 second and third level menus',
            page: 'Page',
            selectPage: 'Select page',
            botTopLevel: 'Top Level',
            botSecondLevel: 'Second Level',
            botThirdLevel: 'Third Level',
            deleteMenu: 'Delete menu',
            commitMenu: 'Commit menu',
            preview: 'Preview',
            botMenuEditStep: 'Edit step',
            botMenuDeleteStep: 'Delete step',
            botMenuDeleteLevel: 'Delete level',
            action: 'Action',
            botMenuAddSubLvl: 'Add sublevel',
            botMenuAddNewStep: 'Add new step',
            botMenuChooseAction: 'Choose action',
            done: 'Done',
            botMenuTypeTitle: 'Type your title here',
            conversationManagerTitle: 'Conversation Manager (Bot)',
            createNewConversation: 'create new conversation',
            triggerBy: 'Trigger by',
            conversationManagerDirectLink: 'Direct link to conversation',
            botNewButton: 'New button',
            botTriggerConversation: 'Trigger conversation',
            unsubscribe: 'Unsubscribe',
            subscribe: 'Subscribe',
            delay: 'Delay',
            botTypingStatus: 'Typing status',
            botSelectConversation: 'Select conversation',
            botSetDelay: 'Set delay seconds',
            sec: 'Sec',
            typing: 'Typing',
            botSetSecons: 'Set seconds',
            variable: 'Variable',
            coupon: 'Coupon',
            variables: 'Variables',
            tag: 'Tag',
            botIconInside: 'Icon inside',
            botNewTag: 'add new tag',
            enterText: 'Enter text',
            button: 'Button',
            text: 'Text',
            uploadImage: 'Upload image',
            title: 'Title',
            subTitle: 'Sub title',
            botButtonTitle: 'Button title',
            setTesterMessage: 'To test the conversation select the tester first',
            payload: 'Payload',
            getJson: 'Get Json',
            start: 'Start',
            image: 'Image',
            fullScreen: 'Full screen',
            resetTester: 'Reset tester',
            withoutTesters: 'Without testers',
            tester: 'Tester',
            botLinkToConversation: 'Link to this Conversation',
            deleteAll: 'Delete All',
            saveAll: 'Save All',
            favStore: 'Favourite store',
            botUnderConstructionTitle: 'we still working on that',
            botUnderConstructionText1: 'Facebook Bot Manager is almost done, but we still have to polish and finalize a few things.',
            botUnderConstructionText2: `We’ll get back soon with the best Bot feature you’ve ever seen. Just wait a little bit. We
                                appreciate your understanding!`,
            botUnderConstructionText3: 'You can still use the bot in the old interface',
            switch: 'Switch',
            botGrantAccessSubTitle: `By using our bot you can :`,
            botGrantAccessSubTitle1: 'You can look at the demo here',
            botGrantAccessSubTitle2: 'Drive traffic from your Facebook business page directly to Amazon.',
            botGrantAccessSubTitle3: 'Distribute single use unique coupons to your prospective buyers on Facebook.',
            botGrantAccessSubTitle4: 'Get more reviews by following up on your customers on Facebook post-purchase.',
            grantAccess: 'Grant access',
            botGrantAccessPermissions: 'What permissions we need',
            botGrantAccessListTitle1: 'Pages messaging',
            botGrantAccessListText1: 'So we could actually send messages on your behal',
            botGrantAccessListTitle2: 'Pages messaging subscriptions',
            botGrantAccessListText2: 'So we could send messages on your behalf after 24 hours since the user has subscribe.',
            botGrantAccessListTitle3: 'Pages show list',
            botGrantAccessListText3: 'So we can let you choose the pages',
            botGrantAccessListTitle4: 'Manage pages',
            botGrantAccessListText4: 'So we can add a “Get started” button on your messenger',
            botGrantAccessListTitle5: 'Read page mailboxes',
            botGrantAccessListText5: 'So we can let you define an automatic reply on post’s comments.',
            subscribersTitle: 'Subscribers List',
            subscriberTotal: 'Total Subscribers:',
            taggedWith: 'Tagged with',
            notTaggedWith: 'NOT tagged with',
            conversaion: 'Conversation',
            broadcast: 'Broadcast',
            photo: 'Photo',
            connectFacebookMessage: 'You need to connect your facebook accout to the app to use this feature',
            notificationsTitle: 'facebook messenger notifications',
            notificationsSubTitle: 'We recommend choosing Telegram notification over Facebook',
            getFbInstructions: 'Get instructions to activate on Facebook',
            getTgInstructions: 'Get instructions to activate on Telegram',
            notificationsFbAlertName: 'Facebook Alert Name',
            notificationsSetAlert: 'Set Alert',
            notificationsAlertOnProducts: 'Alert on products',
            fbSalesNotificaion: 'Facebook Sales Notification',
            notificationsOnEverySale: 'On every sale',
            notificationsFbNegative: 'Facebook Negative Feedback Notification',
            notificationsFbReview: 'Facebook Review Notification',
            notificationsOnlyMaster: 'Available only for Master Ninja plan',
            notificationsEmail: 'email notifications',
            alertName: 'Alert Name',
            notificationsText1: 'Let me know when i receive new negative feedback',
            notificationsText2: 'I would like to get a daily updates on how many emails my customers received',
            notificationsText3: 'Let me know when another seller has hijacked my listing',
            notificationsReview: 'Review Notification',
            hjTitle: 'Hijacked listing viewer',
            hjSubTitle: 'to set a notification when a new seller joins your listing',
            productName: 'Product name',
            SKU: 'SKU',
            condition: 'Condition',
            numberOf: 'Number Of',
            newOffers: 'New Offers',
            usedOffers: 'Used Offers',
            hjViewOnAmazon: 'View On Amazon',
            inviteFriendsTitle: 'Invite Your Friends To Quantify Ninja',
            inviteFriendsSubTitle: 'Simply add your friend\'s email address and hit \'Send Invitation\'',
            inviteFriendsSend: 'Send Invitation',
            keywordTitle: 'Keyword optimizer',
            keywordSubTitle: `Automatically identifies and removes duplicates when you try to add keywords
                      that already exist in your title & description`,
            keywordDesired: 'Desired keyword',
            keywordNumber: 'Number of words',
            keywordListing: 'Listing text ( title, bullet points etc.)',
            result: 'Result',
            emailHasBeenSent: 'Email has been sent successfully',
            errorMoreThan30: 'Must be more than 30',
            errorMoreThan0Less20: 'Must be more than 0 and less than 20',
            mbTitle: 'Money back',
            mbTab1: 'Adjustments Requests',
            mbTab2: 'Missing Inventory',
            auto: 'auto',
            active: 'Active',
            inactive: 'Inactive',
            mbAmzAccount: 'Amazon Email Account',
            mbDaysBtwRequests: 'Days between requests',
            mbNumbersOfReqOnSingle: 'Number of requests on a single email',
            mbWhatNext: 'What’s next?',
            mbWhatNextText1: `If you plan is Active you will be getting emails according. to your configuration.
                      You will need to copy the email’s content to a new case with Amazon.`,
            mbWhatNextText2: `Please remember this is Alpha version of this feature, it might not work as expected and you are
                      encourage to send us your feedback.`,
            manual: 'Manual',
            mbOnSingleEmial: 'On a single e-mail (max.20)',
            mbMoneyBackGuarantee: 'Money back guarantee',
            mbGuaranteeText: 'Amazon guarantee (in days). For most categories use 30, for babies category use 90',
            mbOnlyAdjBefore: 'Only adjustments issued before',
            mbOnlyAdjAfter: 'Only adjustments issued after',
            send: 'Send',
            downloadTitle: 'download report',
            downloadSubTitle: 'Retarget your buyers on social media by creating a custom audience using the following data',
            downloadExludeOrders: 'Exclude promotional orders',
            downloadHistoryMessage: 'It takes up to 30 hours to retrieve the 1 year history for your account',
            refundTooltipMessage: 'Order refunded on',
            saveBeforeUploadMessage: 'Please save the template before uploading images from your PC',
            smTitle: 'Send message',
            smBackToOrders: 'Back to Orders',
            emailExample: 'example@gmail.com',
            smExcludeOrder: 'Exclude this order from future templates',
            smSendCopy: 'Send copy to my Inbox',
            errorRequired: 'This filed is required',
            templateFile: 'Template File',
            optionChanged: 'Option has been changed',
            ordersTitle: 'Orders',
            allTypes: 'All types',
            buyerName: 'Buyer Name',
            buyerEmail: 'Buyer Email',
            orderId: 'Order ID',
            search: 'Search',
            ordersExcludeOrder: 'Exclude this ORDER from future templates',
            ordersExcludeBuyer: 'Exclude this BUYER from future templates',
            sendMessage: 'Send message',
            quantity: 'Quantity',
            purchaseDate: 'Purchase Date',
            shipmentDate: 'Shipment Date',
            customerPhoneNumber: 'Customer Phone Number',
            trackingNumber: 'Traking Number',
            receipientName: 'Receipient Name',
            serviceLevel: 'Service Level',
            shipAddress: 'Ship Address',
            carrier: 'Carrier',
            shipCity: 'Ship City',
            shipState: 'Ship State',
            shipCountry: 'Ship Country',
            itemPrice: 'Item Price',
            itemTax: 'Item Tax',
            shippingPrice: 'Shipping Price',
            shippingTax: 'Shipping Tax',
            giftWrapPrice: 'Gift Wrap Price',
            giftWrapTax: 'Gift Wrap Tax',
            itemPromotionDiscount: 'Item Promotion Discount',
            shipPromotionDisc: 'Shipping Promotion Discount',
            errorEmptyPass: 'Password is empty',
            superLogInAS: 'Log in as',
            superCreateInvoice: 'Create invoice',
            superTab1: 'Show email by Msg Id',
            tab: 'Tab',
            superFillUsers: 'Fill in users',
            password: 'Password',
            go: 'GO',
            upgTitle: 'upgrade your plan',
            premium: 'Premium',
            upgPayWith: 'Pay with',
            upgAddOnly: 'add only',
            upgIncluded: 'Included Features',
            downgrade: 'Downgrade',
            upgCurrentPlan: 'current plan',
            upgDataTitle: 'Data and Management tools',
            upgDataText1: 'Statistics',
            upgDataText2: 'Pie charts for sales, profit and customer returns',
            upgDataText3: 'Product performance comparison charts',
            upgDataText4: 'Search orders by name, order and dates',
            upgDataText5: 'Profit Calculations',
            upgNotificationsText1: 'Real-teme notifications on sales via Facebook and email',
            upgNotificationsText2: 'Product review notifications via Facebook and email',
            upgNotificationsText3: 'Negative feedback notifications via Facebook and email',
            upgEmailTitle: 'emailing',
            upgEmailText1: 'Bulk emailing',
            upgEmailText2: 'Unlimited emails and templates to your customers',
            upgEmailText3: 'Open rate data',
            upgEmailText4: 'File attachment to templates',
            upgEmailText5: 'Buyers\\Orders black list',
            upgHjTitle: 'hijacking and blacklisting',
            upgHjText1: 'Hijacked listing notifier',
            upgHjText2: 'Hijacked listing view',
            upgOptimizationTitle: 'optimization tools',
            upgOptimizationText1: 'Asin tracker - see the position of your products by keywords',
            upgOptimizationText2: `Facebook bot - get more leads by using automated conversations
                            on Facebook and drive traffic to your Amazon pages`,
            upgAddingReg: 'Adding 1 region - 15$/ month',
            upgYourCurrPlan: 'Your current plan',
            upgCancelPlan: 'Cancel Plan',
            options: 'options',
            upgPriceText1: 'Default price for Premium plan (with 1 region) - $35/month, and + $15/month for every additional region',
            upgPriceText2: 'Default price for Master Ninja plan (with 1 region) - $55/month, and + $15/month for every additional region',
            upgPlanVariations: 'Available plan variations',
            upgPrem1Reg: 'Premium with 1 region',
            upgPrem2Reg: 'Premium with 2 region',
            upgPrem3Reg: 'Premium with 3 region',
            upgJoinMaster: 'Join to Master Ninja',
            notice: 'Notice',
            upgNoticeText1: 'Once subscribed to',
            upgNoticeText2: `your old plan will be canceled automatically. First payment is the fraction of the extra 20$ for this cycle.' +
                      You will be charged for total 55$ start from your next cycle.`,
            termsAndCond: 'Terms and Conditions',
            checkOur: 'Check our',
            forMoreInfo: 'for more information',
            settingsAccTitle: 'Account settings',
            settingsAccSubTitle: 'Set your profile information, business information, currency or subscription plan',
            settingsAccExpoDate: 'Good till canceled',
            errorIncorectEmail: 'Incorrect email format',
            errorIncorectPassFormat: 'Incorrect password format',
            errorPassNotMath: 'Passwords do not match',
            emailAddress: 'Email Address',
            sendEmailFrom: 'Send emails from',
            confirmPasswod: 'Confirm password',
            businessName: 'Business Name',
            businessRegNum: 'Business Registration Number',
            businessAddress: 'Business Address',
            defaultCurrency: 'Default Currency',
            linkedRegs: 'Linked Regions',
            subscrPlan: 'Subscription Plan',
            expiration: 'Expiration',
            expenseTitle: 'Expenses',
            expenseSubTitle: 'Look at your PPC and Lightning deals expence or add your custom expence',
            addExpense: 'Add expense',
            expenseAdded: 'Expense successfully added',
            editExpense: 'Edit expense',
            expenseEdited: 'Expense successfully edited',
            deleteExpense: 'Delete expense',
            expenseDeleted: 'Expense successfully deleted',
            amount: 'Amount',
            productsVisibleTooltip: 'Visible mode - product can be selected anywhere in the app',
            productsInvisibleTooltip: 'Invisible mode - we will try to hide this product anywhere in the app',
            productsTitle: 'Product settings',
            productsSubTitle: 'Set a short name of your products and select colors for your products to display on charts',
            editProduct: 'Edit product',
            productEdited: 'Product edited successfully',
            hide: 'Hide',
            shortName: 'Short name',
            displayedName: 'Displayed name',
            productAsin: 'Product Asin',
            productsPerCost: 'Per unit cost',
            productsSettings: 'Product settings',
            editResponse: 'Edit response',
            errorPostNotSelected: 'Post must be selected',
            autoRespApplyOnly: 'Apply only to direct response',
            ruleName: 'Rule Name',
            message: 'Message',
            errorPageNotSelected: 'Page must be selected',
            selectPost: 'Select a post',
            postPreview: 'Post preview',
            required: 'Required',
            errorTemplateNotSelected: 'A template must be selected',
            editBulk: 'Edit bulk',
            anyOfYourProducts: 'Any of your products',
            bulkDialogText1: 'Send this template to all your customers who purchased one of the following products',
            bulkDialogText2: 'Send this template to all your customers who purchased',
            bulkDialogTimeframe: 'Within this time frame',
            bulkDialogEmailEst: ' Email estimated (number of planned emails)',
            count: 'Count',
            set: 'Set',
            exportCSV: 'export to CSV',
            exportCSVText: 'A tab delimited file will be sent to your email',
            errorEmailInUse: 'This email address is already in use',
            ok: 'ok',
            dialogDeleteTemplate: 'Delete This Template?',
            dialogDeleteTemplateText: ' Are you sure you want to delete this template ?',
            yesDelete: 'yes, delete',
            resetPass: 'Reset password',
            resetPassText1: `A link to reset password has been sent to your email. Please check your inbox and keep in
                      mind that sometimes emails can end up in your junk/spam folder.`,
            resetPassText2: `Haven't received the email? Make sure that you used QuantifyNinja email. If you run into any issues,
                    please contact the`,
            supportEmail: 'support@quantifyninja.com',
            dialogGoToLogin: 'go to login',
            signUp: 'sign up',
            signUpSuccess: 'You signed up successfully!',
            dialogUnsaved: 'You have unsaved changes',
            dialogUnsavedText: 'Are you sure you want to leave this page?',
            yes: 'Yes',
            no: 'No',
            dialogStatus: 'Choose the status for this template',
            dialogStatusText1: 'Only active templates will be sent to customers according to the setup.',
            dialogStatusText2: 'Inactive templates will not be sent under any setup.',
            gotIt: 'Got it',
            dialogNegativeFeedback: 'Send template if buyer left negative feedback',
            dialogNegativeFeedbackText: 'Setting "No" will exclude the buyers who left negative feedback.',
            dialogRefundOrder: 'Send template if order refunded',
            dialogRefundOrderText: 'The template will be sent even if the order was refunded.',
            dialogRepBuyers: 'Send template to repeated buyers',
            dialogRepBuyersText: `Setting this option excludes sending this template to the buyers who purchased that
                          item in the past. If a buyer bought the same item more than once, he will only get
                          this template once. Note that if the same buyer purchases a different product he will
                          get this template again.`,
            dialogPromotionGiven: 'Send template if promotion given',
            dialogPromotionGivenText: 'The template will be sent in case the promotion is given.',
            dialogPromotionValue: 'In case of promotion, send template when promotion value',
            dialogPromotionValueText: `By setting this option you can exclude this email if the buyer received promotion more//equals//less
                                than a certain amount. Note that orders without any promotion will not be excluded.`,
            dialogPassChanged: 'Password changed',
            dialogPassChangedText: 'Your password successfully changed',
            dialogSpecSalesChannel: 'Select Specific Sales Channels',
            dialogSpecSalesChannelText: 'This option enables you to select specific channel through which the template will be sent.',
            dialogAddCoupon: 'Add coupon to the template',
            dialogAddCouponText1: `Coupons can be added to the template automatically. To add a coupon code you will need to add the
                            "coupon tag" in the template.`,
            dialogAddCouponText2: `To add the coupon tag: Select the coupon in the drop down coupon list on the left.
                            Once selected you wll then see the coupon tag code that you will need to add to the template.
                            Copy the name of the tag and place it in the template.`,
            dialogAddCouponText3: `Once a mail with that template is sent, a coupon will be inserted from the list of uploaded coupons.`,
            dialogAddCouponText4: `if there are no more coupon left in the uploaded list, the email with this template will not be sent.`,
            dialogBulk: 'new bulk rule',
            dilogBulkText1: 'Amazon limits the number of emails you can send to buyers per day.',
            dilogBulkText2: `The limit is based on your average daily order volume. You may send not more messages than five times your
                      average daily order volume, plus an additional 600 messages.`,
            dilogBulkText3: `For example, if you make 100 sales a day on average, in any given day you may send (5 x 100) + 600 =
                      1100 messages.`,
            dialogVaiable: 'Add variables',
            dialogVaiableText1: `Variables are template fields that are populated in real time according to the specific info of each
                          customer or order.`,
            dialogVaiableText2: 'For example, to start an email with a personal greeting, use',
            dialogVaiableText3: '‘Hi &lt;customer-first-name&gt;’',
            dialogVaiableText4: 'in your template.',
            dialogVaiableText5: 'The variable',
            dialogVaiableText6: '&lt;customer-first-name&gt;',
            dialogVaiableText7: `will be replaced in real time with John, Barbara, Melanie, and so forth… according to the customer’s
                        first name in the order.`,
            confirm: 'confirm',
            dialogConfirmTemplate: 'Confirm Template',
            dialogConfirmTemplateText1: 'Template status:',
            dialogConfirmTemplateText2: 'Will be sent',
            dialogConfirmTemplateText3: 'ASAP',
            dialogConfirmTemplateText4: 'days after',
            dialogConfirmTemplateText5: 'for customers who purchased',
            dialogConfirmTemplateText6: 'the following products:',
            dialogAddFile: 'Attachment formats',
            dialogAddFileText1: 'Text files (.txt)',
            dialogAddFileText2: 'PDFs (Adobe Portable Document Format)',
            dialogAddFileText3: 'Word documents (.doc and .docx)',
            dialogAddFileText4: 'Image files (.jpg, .gif, .tiff, .bmp, and .png)',
            dialogBulkNoedit: 'edit bulk',
            dialogBulkNoeditText: 'Bulk rule cannot be edited after execution or while processing',
            dialogEditEmail: 'Edit Email',
            dialogEditEmailText: 'Your email on your Amazon account:',
            setSender: 'Set sender',
            login: 'Login',
            dilogOnHold: 'Your account is on hold, please contact',
            dialogRegion1: 'Amazon US account',
            dialogRegion1Text: '(Amazon.com, Amazon.ca, Amazon.co.mx)',
            dialogRegion2: 'European Union account',
            dialogRegion2Text: '(Amazon.uk, Amazon.de, Amazon.se, Amazon.it, Amazon.fr)',
            dialogRegion3: 'Amazon Japan account',
            dialogRegion3Text: '(Amazon.jp)',
            dialogRegion4: 'Amazon Australia account',
            dialogRegion4Text: '(Amazon.com.au)',
            dialogPermossionDenied: 'This feature is not available',
            dialogPermossionDeniedText: 'You should upgrade your payment plan to get access to this page.',
            dialogInstructions: 'GET INSTRUCTIONS',
            dialogInstructionsText1: 'Open a facebook messanger, using the next link',
            dialogInstructionsText2: 'Quantify  Ninja chat page.',
            dialogInstructionsText3: `Click the «Get Started» button to activate the chat and copy the next code to chat
                                  and hit send`,
            copiedToClipboard: 'Copied to clipboard',
            dialogGetJsonText: `When you start a messanger campaign you need to tell facebook what to display when the user
                            click you ad. This can be done using the Json code. Copy the next code to the Json tab on
                            the ad setting on Facebook:`,
            dialogOrderBL: 'ORDER BLACK LIST',
            dialogOrderBLText: `Orders on this list will not receive any subsequent emails defined by your templates,
                            however the buyer might still receive your templates on different orders `,
            dialogBuyerBL: 'Buyers BLACK LIST',
            dialogBuyerBLText: `Buyers on this list will not receive any emails defined by your templates in any past or
                            future orders.`,
            dialogCommitMenu: 'Commit and delete menu',
            dialogCommitMenuText1: 'Committing a menu',
            dialogCommitMenuText2: `will immediately reflect on your page bot. The menu will appear on all conversation of
                                this page. It cannot be set per conversation.`,
            dialogCommitMenuText3: 'Delete a menu',
            dialogCommitMenuText4: `will immediately reflect on your page bot. The menu will be deleted from all
                                conversations of this page`,
            dialogSessionExp: 'You need to login again',
            dialogSessionExpText: 'Your current session has expired',
            dialogDownload: 'download report',
            dialogDownloadText: 'No matching results found',
            dialogDownloadFailText: 'Failed generating report, please try again and contact us if this happens again',
            dialogAsinLimit: 'Add keyword',
            dialogAsinLimitText: 'Maximum number of keywords reached',
            dialogAutoResponseUnsave: 'Cancel rule',
            dialogAutoResponseUnsaveText: `Any changes you have made to this rule wil not be applied. Are you sure you want
                                        to cancel ?`,
            dialogAutoResponseUnsaveButton1: 'back to edit',
            dialogAutoResponseUnsaveButton2: 'yes, cancel',
            dialogNeedUpgrade: 'Need upgrade',
            dialogNeedUpgradeText: 'This featue avaliable only for Master Ninja plan',
            dialogAsinAdded: 'Asin was added',
            dialogAsinAddedText: 'Asin was added to the market',
            dialogEmailChange: 'You have changed the "Send emails from" field',
            dialogEmailChangeText: 'Email must be the same email which is used to log in to your Amazon account',
            dialogDowngrade: 'Are you sure?',
            dialogDowngradeText: `You are going to downgrade your current plan, are you sure? Some of the features won’t
                          be available for you after downgrading.`,
            dialogDowngradeSuccess: 'you have been downgraded',
            dialogDowngradeSuccessText: `You have successfully downgraded your plan. This update will be executed within
                                    next 24 hours.`,
            dialogResetTester: 'Hey its your new link',
            dialogShortNameWarning: 'Short name was not set',
            dialogShortNameWarningText1: 'Product Short name variable is defined but was not set.',
            dialogShortNameWarningText2: 'When a short name is not set the system will use the full title.',
            dialogShortNameWarningText3: `Setting a short name is easy ! Go to settings-Product Settings and set a short
                                        name for your products`,
            sendAnyway: 'Send anyway',
            dialogBulkAlert: 'Pay attention',
            dialogBulkAlertText: 'To change products please change it on the selected template',
            dialogCancelPlan: 'Plan cancel',
            dialogCancelPlanText: 'Are you sure that you want to cancel the current plan?',
            dialogWentWrong: 'Something went wrong',
            dialogWentWrongText: 'Please try again later, or report an issue in the contact us section',
            dialogCancelPlanSuccessText: 'You were unsubscribed successfully',
            dialogOops: 'OOPS',
            dialogOopsText: 'We have some problem, please log out and try again',
            dialogUnsubscribeText: 'Are you sure that you want to unsubscribe the emails?',
            dialogTrialHistoryText: `The system stores 30 days of transaction history for the 14 days trial period.
                            It's possible that the order you are looking for is older than 30 days.
                            You will need to upgrade in order to get the full feature ( 1 year history)`,
            continueWithPartialData: 'continue with partial data',
            dailogTrialHistory2: `Trial version have one month history - subscribers gets one year. Partial or empty
                                results might be displayed.`,
            dialogFbmMissProducts: `We could not find FBM products on your account, are you sure you didn\'t mean to
                                create an FBA template ?`,
            dialogFbmMissProductsButton1: 'Take me to FBA templates',
            dialogFbmMissProductsButton2: 'Report FBM products missing',
            contactUsTitle: 'We Would Love To Hear From You',
            contactUsSubTitle1: 'Feedback, Suggestions, Bugs - whatever you feel like!',
            contactUsSubTitle2: 'You can message us here or at',
            yourName: 'Your Name',
            yourEmail: 'Your Email',
            yourMessage: 'Your Message',
            errorContantEmpty: 'Content cannot be empty',
            errorValidFile: 'Please upload a valid file first',
            couponNotAdded: 'Cannot add coupon',
            couponNotAddedText: 'File extension should be txt and file size can not exceed 100kb',
            uploadCoupon: 'Upload Coupon',
            addCoupon: 'Add Coupons',
            couponsName: 'Coupons Name',
            expirationDate: 'Expiration date',
            errorValidExpDate: 'Please select a valid expiration date',
            onlyTxt: 'Only txt file allowed',
            noDataYet: 'No data yet',
            profitCalc: 'Profit calculation',
            value: 'Value',
            totalItemsSold: 'Total Items sold',
            totalNumberOrders: 'Total Numbes of orders',
            pendingOrders: 'Pending Orders',
            totalFees: 'Total Fees',
            totalPromotions: 'Total Promotions',
            PPCdeals: 'PPC & Lightning deals',
            totalProductCost: 'Total Product Cost:',
            promSummary: 'Promotion summary',
            promName: 'Promotion name',
            redeemed: 'Redeemed',
            total: 'Total',
            promotions: 'Promotions',
            allPromFrom: 'All Promotions from:',
            toLowerCase: 'to',
            promValue: 'Promotion Value',
            showPromSummary: 'Show promotion summary >',
            plus: '+',
            click: 'Click',
            ordersTableText1: 'on the order to see extended data version',
            totalProfit: 'Total profit',
            promId: 'Promotion ID',
            charge: 'Charge',
            fee: 'Fee',
            channel: 'Channel',
            productCost: 'Product Cost',
            shippingPromotion: 'Shipping Promotion',
            promotion: 'Promotion',
            limitedAccess: 'Limited Access',
            ninjaAccount: 'Ninja Account',
            notDefined: 'Not Defined',
            amazonUK: 'Amazon.co.uk',
            amazonFR: 'Amazon.fr',
            amazonDE: 'Amazon.de',
            amazonIT: 'Amazon.it',
            amazonES: 'Amazon.es',
            amazonCOM: 'Amazon.com',
            amazonMX: 'Amazon.co.mx',
            amazonCA: 'Amazon.ca',
            amazonAU: 'Amazon.com.au',
            amazonJP: 'Amazon.com.jp',
            reset: 'Reset',
            addShortNameEachChannel: 'Add short name for each channel',
            resetAll: 'Reset all',
            errorEmptyExpense: 'Expense name cannot be empty',
            errorAmountNotNumber: 'Amount must be in a number format',
            currency: 'Currency',
            errorMoreThan0Less100: 'Must be more than 0 and less than 100',
            mbNumberOfRequests: 'Number of requests',
            advOptions: 'Advanced options',
            content: 'Content',
            postCreatedTime: 'Post created time',
            back: 'Back',
            select: 'Select',
            selectSpecProducts: 'select specific products',
            selectSpecProductsSub: 'Select all necessary specific products for this template',
            TCText1: `QuantifyNinja.com is a service provided by Shir Estate in Tel Aviv,
               Israel. This agreement was last updated on June 22, 2016.`,
            TCTitle2: `Description of Service`,
            TCText2: `Quantify Ninja is a service for 3rd party Amazon Sellers. It allows these sellers to send automatic
              email notifications to customers who place orders through Amazon. A user may
              customize which orders receive automatic emails and may customize the content
              of those messages and when they are sent. The purpose of these emails is to
              effectively and automatically communicate with customers, primarily to solicit
              feedback on Amazon and to proactively ask to correct problems with customers'
              orders before they leave negative feedback.`,
            TCTitle3: 'Eligibility',
            TCText3: ` You must be 18-years old to use the service. You also must have an active Amazon &quot; Sell
                Professionally &quot; account in order to use the service. Additionally, you
                must complete the registration through MWS (Amazon Marketplace Web Service) and
                provide the proper credentials. By using the service, you warrant that you have
                permission to access the Amazon Seller account for which you register.`,
            TCTitle4: `Privacy and Security`,
            TCText4: `Your privacy and the security of your account is very important to us. We use encryption on any
              areas of the site that contain sensitive information such as credit card
              numbers, account credentials, or customer information.`,
            TCTitle5: 'Intellectual Property',
            TCText5: `All logos, domain names, website content and other intellectual property that entail our products
              and services are the exclusive property of Shir Estate. You will not copy,
              imitate, modify, alter, amend or use any of the intellectual property without
              our prior written consent. You will not attempt to reverse-engineer the product
              for any reason.`,
            TCTitle6: 'User Conduct',
            TCText6: `When using this service you agree to comply with all laws and regulations. You also agree to comply
              with`,
            TCAmazPolGuide: 'Amazon\'s Policies and Guidelines',
            TCText7: `as well as any other Amazon policies. You may not use our service to engage in fraud or other
              illegal activity, or advocate illegal activity. You may not use our service to
              infringe upon or violate any right of a third party.`,
            TCText8: `You will not use the service in any manner that will interfere with the proper functioning of the
              site for any other users. You will not use any web bot or spider to access the
              service.`,
            TCText9: `You acknowledge that we may monitor and measure usage of the system to ensure compliance with these
              conditions. Failure to comply with any of these conditions may result in the
              immediate closure of your account without any refunds or credits.`,
            TCTitle7: 'Passwords and Account Access',
            TCTitle8: 'Fees and Payments',
            TCTitle9: 'Excessive Usage',
            TCTitle10: 'Abuse, Suspension, Termination',
            TCTitle11: 'Limitation of Liability',
            TCText10: `You will at all times maintain the confidentiality of your user names and passwords. In the event
               there is a breach of security through your account, you must immediately change your password.`,
            TCText11: `You agree to waive all claims against Shir Estate in the event the security of your account is
                compromised. Further, you agree to indemnify, defend, and hold Shir Estate
                harmless in the event Shir Estate incurs damages to its intellectual property
                or other financial damages due to a breach of confidentiality.`,
            TCText12: `You agree to pay all fees associated with using the service. All fees are in US Dollars. You must
                provide a credit card number to use the service in excess of any of the free
                accounts. Payment will occur automatically on or about the day shown on the
                website. In the event that your payment fails or is disputed, we may collect
                payment via other means including but not limited to collection agencies.`,
            TCText13: `Shir Estate reserves the right to limit the number of messages sent from an account if it deems, at
                its sole discression, that an account is using excessive resources.`,
            TCText14: `Without limiting our other remedies, we may limit, suspend, or terminate our service and your
                account at our sole discretion, without notice, and for any reason in the event
                we think you are abusing our services, you fail to follow the terms and
                conditions of this Agreement, you are creating problems or potential legal
                liabilities, you are infringing on the intellectual property rights of us, any
                of our affiliates, any third parties, or you are acting inconsistent with our
                policies or the spirit of our policies. In the event Shir Estate limits,
                suspends, or terminates your account, all monies paid to Shir Estate are
                non-refundable.`,
            TCText15: `Under no circumstances, including but not limited to negligence, will Shir Estate be liable to you
                or any other person or entity for any direct, indirect, incidental, punitive,
                special or consequential damages including lost profits, loss of materials,
                loss of content, loss of goodwill, costs of procuring substitute goods and
                services, and property damage, that result from (a) the use of, or the
                inability to use, the Quantify Ninja website, or (b) the conduct or actions, of
                any user of the QuantifyNinja.com website or any other person or entity, even
                if we have been advised of the possibility of such damages. In the event Shir
                Estate is found liable, Shir Estate total liability to you for all damages,
                losses and causes of action whether in contract, tort (including but not
                limited to negligence) or otherwise will not exceed the amount paid by you in
                the past three (3) months, if any, or $100, whichever is less.`,
            TCText16: `The QuantifyNinja.com website and services are provided on an as-is basis and without warranty or
              condition, express, implied or statutory. Shir Estate, its suppliers and distributors,
              specifically disclaim all implied warranties of title, merchantability, fitness
              for a particular purpose and non-infringement.`,
            TCText17: `Shir Estate reserves the right to cancel inactive accounts at our sole discretion and without
              notice. Customers may cancel the service at any time by emailing our customer
              support or clicking the Contact Us link.`,
            TCText18: `By accepting this Agreement, you agree to indemnify, defend, and hold Shir Estate and its related
              parties harmless from all claims, judgments, costs, expenses, liabilities or attorney’s
              fees.`,
            TCText19: `The enforcement of this agreement shall be interpreted under the laws of Israel. The venue for any
              legal action shall be in Tel Aviv, Israel.`,
            TCTitle12: 'No Warranty',
            TCTitle13: 'Cancellation',
            TCTitle14: 'Indemnification',
            TCTitle15: 'Governing Law',
            iAgree: 'I agree',
            privacyPolicy: 'Privacy policy',
            PPText1: 'This Privacy Policy will govern all privacy issues that may arise in relation to your access to',
            ninjaWebsite: 'www.quantifyninja.com',
            PPText2: `or use of its mobile application (Quantify Ninja, App) and related services or social media on this
              website to get sales analytics of your Amazon account and automate your marketing campaign (the Services).
              If you don’t agree with this privacy policy, please do not use the Services on Quantify Ninja or this
               website.`,
            PPText3: `This Privacy Policy shall be part of your entire agreement with Shir Estate LTD. When you access this
              website, download/install Quantify Ninja, link your Amazon account to your Quantify Ninja account, or
              use any services being offered on this website, Shir Estate LTD. shall be entitled to collect, process,
              store, use or share your personal information in accordance with this privacy policy.`,
            PPText4: `However, this Privacy Policy will not apply to third party websites or any information that you share
              on social media or we obtain through offline sources. Shir Estate LTD. reserves a right to use your shared
              profile and/or offline information for its business promotion.`,
            PPTitle1: 'What we may collect',
            PPText5: `Shir Estate LTD. will collect the following type of information on this website:`,
            PPText6: `Registration, subscription, or account information such as your name, contact number, email address,
              and office address, etc.`,
            PPText7: `Billing information such as credit card, PayPal subscription account, and billing address.`,
            PPText8: `Activity related information such as purchased subscription, defaults, breaches, violations, disputes,
              arbitrations, and other information related to your sales analytics or preferred marketing strategies on
              Quantify Ninja.`,
            PPText9: `Events related information such as interests or response to promotional offers or events being
              organized or sponsored by Shir Estate LTD.`,
            PPText10: `Browsing information such as your browser type, operating system, IP address, search history,
              entry and exit pages, clicked ads and links, total time on the website, and other details or metadata
              related to your use of the Services.
                However, we will never use any kinds of automated tools and techniques to collect your identifiable
              information on Quantify Ninja or this website. Any information that can be used or combined with other
              information to uniquely identify you will be collected only when you voluntarily submit the information
              to create your account, subscribe to any service, submit your feedback, or participate in promotional
              events or offers.`,
            PPTitle2: 'Use of Cookies',
            PPTitle3: 'How we may use your information',
            PPText11: `Shir Estate LTD. may use certain cookies and other automated tools to collect your non-identifiable
              information on/through Quantify Ninja. Almost all the websites use cookies to routinely collect certain
              non-identifiable information such as your device or hardware type, operating system, browser type,
              configuration settings and preferences, IP address, ISP, entry or exit pages, timestamps, search history,
              browsing behavior, and other metadata related to your use of the Services (collectively the User Data).
              This information can be very instrumental in resolving technical issues, improving the Services, and
              smoothly running the website.`,
            PPText12: `Please acknowledge that we may use third party services (Google AdMob, AdSense, Analytics, Facebook,
                etc.) to monetize, analyze, evaluate, and improve the Services on/through Quantify Ninja. These third
                parties may employ their own cookies to collect your personal information in relation to their services
                or ad serving on/through Quantify Ninja. Using the Services on Quantify Ninja or this website shall be
                deemed as your consent to these third party cookies.`,
            PPText13: `You may configure your device or web browser to reject cookies and tracking tools from this website.
                However, you should understand that cookies are required for the proper functioning of the Services and
                refusing them may prevent you from using certain features or services on this website.`,
            PPText14: `We will use or share your personal information solely for making a simple and straightforward presentation
                of your sales analytics and helping in your email marketing to the targeted audiences. To be more specific,
                we will use your personal information to:`,
            PPText15: `Consolidating and presenting your sales or growth analytics in the most comprehensible statistical form.
                Understanding the demographic profile of your potential clients, customers, or the leads.
                Automating your email marketing campaign, scheduling emails and targeting users, and providing engagement
                analytics.
                Tracking your preferences and offering customized services, information, or content you might be looking for.
                Sending newsletters or notifications regarding new features, services, subscription status, use of the
                Services, or any change in our terms and conditions, including this Privacy Policy.
                Receiving your feedback, resolving technical issues, and improving the features or services on this website.
                Evaluating your marketing strategies, optimizing the Services and improving your overall experience on
                Quantify Ninja. Obtaining your consent for any purpose not listed in this Privacy Policy.`,
            PPText16: `All logos, domain names, website content and other intellectual property that entail our products
                and services are the exclusive property of Shir Estate. You will not copy,
                imitate, modify, alter, amend or use any of the intellectual property without
                our prior written consent. You will not attempt to reverse-engineer the product
                for any reason.`,
            PPTitle4: 'Sharing and Disclosure',
            PPTitle5: 'User Conduct',
            PPTitle6: 'Security',
            PPTitle7: 'Privacy Options',
            PPText17: ` Shir Estate LTD. reserves a right to share your personal information with its trusted service providers or
                other third parties, who make it possible to analyze your sales information, make statistical presentations,
                and reach your targeted audiences. However, we do not disclose, sell or lease your personal information for
                direct marketing purposes unless you give your consent to receive exclusive deals and promotional offers
                from third party businesses or marketing companies.
                When sharing your personal information with the service providers or other third parties, no additional
                information will be disclosed except what may be required for them to perform/deliver their services in a
                hassle-free manner. In case we disclose or share any user data with the service providers or other third
                parties, the user-data will be aggregated and when feasible, made anonymous before such sharing. Please
                acknowledge that Shir Estate LTD. may share the user-data with service providers or other third parties
                to help them perform certain internal functions or facilitate in their targeted ads placing.
                Shir Estate LTD. also reserves a discretionary right to disclose any information, personal identity,
                record, communications, or content to relevant authorities or other third parties when it believes that
                such disclosures are required to:
                Prevent unauthorized access or activities on this website.
                Protect its business or legal rights, or the rights, property, safety, and well-being of other users
                or the general public.
                Comply with the applicable laws, subpoenas, government regulations, or the court rulings.
                Co-operate with the investigating agencies or enforce its policies and guidelines for using the Services.
                Facilitate merger, acquisition, or transfer of its business, in whole or parts thereof, in a smooth and
                hassle-free manner.`,
            PPText18: `Your privacy on this website/Quantify Ninja is very important to us. We have implemented industry standard
                security procedures and protocols to ensure that your privacy on this website is never compromised. However,
                the Service involves the international transmission of data over mobile networks and the Internet.  Absolute
                security of data transmissions is not possible and you shall bear all the risks that may be involved in using
                the Services over the mobile networks or the Internet.`,
            PPText19: `All third parties who may access your personal information will be obligated to protect and adequately
              secure your personal information. However, Shir Estate LTD. shall not be liable for any subsequent violations
              of your privacy rights by such third parties. Please use the Services at your own risk and discretion.`,
            PPText20: `Shir Estate LTD. shall not be liable for any loss, damage, liabilities, claims, or consequences that may
              arise due to unauthorized access, breach of data transmissions, hacking, abuse, misuse, alteration, deletion,
              loss, or any compromise of your privacy on Quantify Ninja or this website.`,
            PPText21: `You can take the following measures to manage your Privacy on Quantify Ninja:
                You can modify your Amazon account and remove any information that you do not want to share with your
                existing or potential clients/customers.
                You can configure your device or web browser to reject cookies and tracking tools from this website.
                You can unsubscribe from our newsletter service and revoke your consent to receive promotional offers
                from third parties in case you have already given this consent. Just follow the unsubscribe link at the
                bottom of any commercial email.
                You can cancel your subscription and discontinue the Services. Your account and associated information
                on Quantify Ninja will be permanently deleted except what we may be required to retain due to any business
                or legal obligations.`,
            PPTitle8: 'Third Party Websites',
            PPTitle9: 'California Residents',
            PPTitle10: 'COPPA Compliance',
            PPTitle11: 'Changes to this Privacy Policy',
            PPTitle12: 'Cancellation',
            PPText22: `This website/Quantify Ninja may contain ads or links to third party websites. Please acknowledge that we
          do not endorse, recommend, or renounce any product, service, or content being featured or made available on
          the linked third party websites`,
            PPText23: `Shir Estate LTD. shall not be liable for any loss, damage, liabilities, or claims that may occur due to
          any violation of your privacy rights in relation to your access, reliance, or use of any product, service
          or content being featured by third party websites.`,
            PPText24: `If you are located in California, you can request our privacy officer to inquire about the third parties
          who have access to your personal information and request the details of all such third parties to whom we
          may have disclosed your personal information immediately preceding the current year. However, we may refuse
          your request if such disclose is not allowed under the applicable laws or regulations.`,
            PPText25: `We are fully compliant with the Children’s Online Privacy Protection Act or similar legislations in your
          jurisdiction. The Services are intended for legally mature users. If you are not attained the age of legal
          maturity in your jurisdiction, it shall be deemed that you are using Quantify Ninja/Services under the
          guidance and supervisions of your legal guardians. In any case, minors of less than 13 years of age are
          not allowed to use the Services.`,
            PPText26: `However, if you suspect that any minor has submitted his or her personal information on this website,
          whether accidentally or otherwise, please contact our privacy officer at`,
            PPText27: `and we will make commercially reasonable efforts to remove such information from the website/servers.
          However, it may take some time to remove any information of the minors from the backup servers.
          Shir Estate LTD. reserves a right to delete any and all personal information of minors on this website in
          its sole discretion.`,
            PPText28: `Shir Estate LTD. reserves a right to edit, modify, or replace this Privacy Policy at any time and in
          its sole discretion. If this Privacy Policy is modified, we will publish an update in this Page and take
          commercially reasonable efforts to inform you, but it shall be your responsibility to regularly visit
          this website and stay updated. Continued access to this website or use of Quantify Ninja after such
          modifications shall be deemed as your consent to abide by the latest version of this Privacy Policy.`,
            PPText29: `Shir Estate reserves the right to cancel inactive accounts at our sole discretion and without
          notice. Customers may cancel the service at any time by emailing our customer
          support or clicking the Contact Us link.`,
            PPText30: ` If you have any question or concern regarding this privacy statement, please send your queries or
          feedback to `,
            PPText31: 'You can also write to:',
            PPText32: 'Shir Estate LTD.',
            helpEmail: 'help@quantifyninja.com',
            businessDetails: 'Business details',
            businessDetailsText: `This would be the business number on the invoice. The business number is your legal entity
                            vat number. This could be your company vat company or your personal id number.`,
            addRegionTitle: 'YOU\'RE JUST ONE STEP AWAY',
            addRegionSubTitle: 'You’re almost there, now we need permission to access your seller account, please choose your region',
            choose: 'Choose',
            northAmericaAccount: 'North America account',
            signOutHere: 'Sign out here',
            orYouCan: 'Or you can',
            passwordTooltip: `Password must be at least 6 characters long and contain digits(0-9), lowercase letters (a-z) and
                      nonalphanumeric characters:`,
            errorMoreThan6: 'Should contain at least 6 symbols',
            createNewPassword: 'Create new password',
            repeatPassword: 'Repeat password',
            loginTitle: 'What your e-business needs',
            loginTitleText: 'Our majestic web application offers you amazing features in order to',
            loginTitleText2: 'control your Amazon sales to the fullest you’ll be amazed!',
            grantAccessTitle: 'Creating a billing plan…',
            grantAccessTitle2: 'Redirecting to PayPal…',
            grantAccessTitle3: 'Checking your account details',
            grantAccessSubTitle4: 'It should take a few seconds…',
            grantAccessTitle4: 'Great news!',
            grantAccessTitle5: 'oops!',
            grantAccessText1: `We have successfully verified your account, you can start using Quantify Ninja.`,
            grantAccessText2: `This seller ID is already being used by a different user.`,
            grantAccessSellerID: `Seller ID: `,
            getStarted: 'get started',
            grantAccessTitle6: 'Thank you!',
            grantAccessText3: `We are now processing your payment, and you will soon be able to use the pro-membership features!`,
            grantAccessText4: 'Transaction completed successfuly. Redirecting to the app in…',
            grantAccessText5: `We're sorry but Paypal did not approve your payment. Payment status: Free 14 days trial`,
            grantAccessTitle7: 'One more step',
            grantAccessText6: 'Finish adding the region by completing the payment',
            currentRegions: 'Current regions:',
            grantAccessText7: 'You are going to add 1 region: ',
            price: 'Price',
            tokenUsedMessage: 'Your token has been used, try again please',
            tokenExpiredMessage: 'Your token has been expired, try again please',
            tokenNotExistMessage: 'Your token is not exist, try again please',
            tokenInvalidMessage: 'Your token is invalid, try again please',
            facebookConnected: 'You are successfully connected your facebook account to the app',
            facebookConnectFailed: 'Something went wrong please try again later',
            forgotPass: 'Forgot your password?',
            pressHere: 'Press here',
            swithToPrevInterface: 'Switch to the previous interface here',
            firstName: 'First Name',
            lastName: 'Last Name',
            and: 'and',
            loginHere: 'Login here',
            ENG: 'ENG',
            refresh: 'Refresh',
            getLink: 'Get Link',
            interrupt: 'Interrupt',
            saveConvSuccessText: 'Conversation has been saved successfully',
            PPCNotIncluded: 'PPC not included',
            dialogConfirmTemplateText8: `any of your`,
            dialogConfirmTemplateText9: `products (including products that will be added in the future)`,
            invoicing: 'Invoicing',
            invoicingTitle: 'INVOICING buyers',
            invoicingSubTitle: `If the country of your legal entity requires authorization for
                        creating billing documents please do not use this feature`,
            createNewInvoice: 'Create new invoice',
            northAmerica: 'North America',
            invoiceName: 'Invoice Name',
            companyName: 'Company Name',
            VAT: 'VAT',
            deleteTesterText1: 'Are you sure that you want to delete tester',
            fromLowerCase: 'from',
            createNewInvoiceSubTitle: 'Follow these easy 5 steps to create your new invoice',
            logo: 'Logo',
            address: 'Address',
            notes: 'Notes',
            invoiceStep1Title1: 'Drag your logo here or',
            invoiceStep1Title2: 'from your computer',
            invoiceStep1Text: 'Dimensions are to be at least 500X500 pixels. Supported formats are JPG and PNG.',
            submit: 'Submit',
            skip: 'Skip',
            invoiceStep2Title1: 'Write down',
            invoiceStep2Title2: 'Invoice Name',
            invoiceDate: 'Invoice date',
            invoiceNumber: 'Invoice number',
            billTo: 'Bill to',
            invoice: 'Invoice',
            productId: 'Product Id',
            unitPrice: 'Unit price',
            subTotal: 'Sub total',
            vat: 'Vat',
            totalPrice: 'Total price',
            invoiceStep4Title1: 'Set your',
            invoiceStep4Title2: 'VAT percentage',
            invoiceStep5Title1: 'Write here',
            invoiceStep5Title2: 'you want to be on the Invoice',
            invoiceDialogTitle: 'please, pay attention!',
            invoiceDialogText: `In some countries there are certain legal restrictions that require authorization in order to create invoices.
                        If the country of your legal entity requires authorization for creating billing documents please do not use
                         this feature.`,
            invoiceNamePlaceholder: 'Add Invoice name here',
            invoiceNotePlacehoder: 'This is a note',
            ES: 'ES',
            RO: 'RO',
            upgradeCalcCost: 'Сaclculate your cost',
            upgradeCustomPayment: 'Custom payment available!',
            dialogUpgradeTitle: 'Become a Master Ninja',
            dialogUpgradeSubTitle: 'Add just $20 a month and get our sharpest weapons',
            masterNinja: 'Master Ninja',
            addTester: 'Add a tester',
            next: 'Next',
            saveInoviceSuccessText: 'Invoice has been saved successfully',
            selectInvoice: 'Select Invoicing for the template',
            selectPageAndConversation: 'Please select page and conversation',
            invoiceDeleted: 'Invoice successfully deleted',
            dialogDeleteInvoice: 'Delete this invoice?',
            dialogDeleteInvoiceText: ' Are you sure you want to delete this invoice ?',
            dialogInoviceAgreementCancel: 'I Do Not Agree',
            imageURL: 'Image URL',
            signUpHere: 'Sign up here',
            areYouSure: 'You are going to delete this menu. Notice that delete menu will immediately reflect on ' +
                  'your page bot. The menu will be deleted from all conversations of this page, and you cannot undo this action.',
            selectPageConversation: 'Please select page',
            finish: 'Finish',
            dialogOpenRate: 'Oh No! It seems that you have zero open rate ... ',
            dialogOpenRateText1: `This usually means that you have not set up "send emails from" to your email you use
                          to login to seller central. You can change this setting at the top of this page or on
                          Account settings. `,
            dialogOpenRateText2: `If you have any question about this message please contact us through
                          the contact us menu.`,
            dialogInvoiceWarning1: 'Seems that your invoice is being used by an automated message named:',
            dialogInvoiceWarning2: 'You should first delete this message before you can delete the invoice.',
            dialogSubsNotSelected: 'Would you like to send this conversation to the current filtered list ?',
            dialogInterrupt: 'The broadcast has already been completed',
            dialogBotInstructions1: `Click this link to open a new chat between your profile and your business page:`,
            dialogBotInstructions2: 'click this link to open a new chat between your profile and your business page:',
            dialogAutoReplyComment: `If checked we will trigger the auto reply only to direct responses.
                              Sub responses (e.g comment to a comment) will not trigger this rule.`,
            saveResponseSuccess: 'Auto response has been saved successfully',
            moreLowercase: 'more',
            dialogPostAlreadyUsed: ' You already have an automatic reply on that post ',
            dialogPostAlreadyUsed2: 'would you like to overwrite the existing rule ?\n',
            saveMenuSuccess: 'Menu has been saved successfully',
            dialogOverwhriteRule: 'Overwrite Existing Rule',
            overwrite: 'Overwrite',
            sequences: 'Sequences',
            createNewSequence: 'create new sequence',
            sequenceName: 'Sequence Name',
            conversationCoun: 'Conversation Count',
            seeFollowupRules: 'See followup rules',
            continue: 'Continue',
            alreadyHaveATag: 'You have already set a tag',
            warningRemoveTag: `If you set the URL - the tag will be removed`,
            alreadyHaveAUrl: 'You have already set a URL',
            warningRemoveUrl: 'If you set the tag - the URL will be removed',
            deleteSubscriber: 'Are you sure you want to unsubscribe this profile?',
            deleteSubscriberTooltip: 'Subscriber deleted successfully',
            rulesOfSequence: 'Rules of Sequence',
            createNewRule: 'Create new rule',
            noSequenceSelected: 'No Sequence Selected',
            numberOfDays: 'Number of days',
            chooseSequence: 'Please, choose a sequence to see followup rules ',
            selectedSequence: 'Selected Sequence:',
            sequenceTrigger: 'Sequence trigger',
            create: 'Create',
            saveSequenceSuccessText: 'Sequence has been saved successfully',
            deleteSequenceSuccessText: 'Sequence has been deleted successfully',
            saveRuleSuccessText: 'Rule has been saved successfully',
            deleteRuleSuccessText: 'Rule has been deleted successfully',
            deleteAutoResponsSuccessText: 'Auto response has been deleted successfully',
            ruleStatus: 'Rule status',
            deleteConfirmSequenceText: `Are you sure you want to delete this sequence ? Any follow up rule of this
                                sequence will be deleted as well.`,
            deleteConfirmSequenceTitle: 'Delete this sequence?',
            unsubscribeTheUser: `Unsubscribe`,
            manageTags: 'Manage tags',
            addTags: 'Add tags',
            bulkInvoiceNotSupported: 'This template includes an invoice. We will not issue an invoice for a bulk rule.',
            templateOption9: 'Timing',
            addAlternatives: 'Add alternatives',
            addAlternativesText: `You can create one automatic template with multiple variations, the system will select the
                          most effective template on real time (with higher open rate percentage)`,
            addAltContent: '+ Add alternative content',
            addAltSubject: '+ Add alternative subject',
            timingTooltipText: `You can select the time we use to send your messages. Timing can be set to morning,
                        noon or evening all in PST time`,
            warningRemoveTag2: `A url button cannot be tagged.`,
            invoiceStep3Title1: 'Add your ',
            contactInfo: 'company details',
            invoiceStep3Title2: 'you wish to have on the invoice. Vat number, address, website URL , email etc.',
            billToText1: 'address1',
            billToText2: 'address2',
            billToText3: 'address3',
            billToText4: 'title first last',
            PSTTime: 'PST Time',
            timingTooltipText2: 'This option is available only when time frame is not set to "ASAP"',
            removeAlternatives: 'Remove alternatives',
            close: `close`,
            CN: 'CN',
            selectAltContentText: 'Select alternatives you want to add',
            estimatedArrival: 'Estimated arrival',
            combined: 'Combined',
            breakDown: 'Break Down',
            topProfitableSellers: 'Top profitable sellers',
            noNotifications: 'No notifications',
            emptyConversation: 'Oops, this conversation is empty',
            linkToTheConversation: 'This is a direct link to this conversation: ',
            addTag: 'Add tag',
            bulkAltContentWarning: `You have selected a template with alternate content. On a bulk operation we will
                            only send the main content to everyone in the list`,
            lastMessagesSent: 'Last messages sent',
            purchasedAfter: 'Purchased after',
            purchasedBefore: 'Purchased before',
            shippedAfter: 'Shipped after',
            shippedBefore: 'Shipped before',
            lastSent: 'Last sent',
            estimatedDelivery: 'Estimated Delivery',
            resetOpenRate: 'Reset open rate',
            resetOpenRateDialogText1: 'You are about to reset the open rate. Are you sure ? ',
            excludeTemplates: 'Exclude templates',
            includeTemplates: 'Include templates',
            addBuyerToBlackList: 'Add Buyer to Blacklist',
            addOrderToBlackList: 'Add Order to Blacklist',
            removeBuyerFromBlackList: 'Remove Buyer from Blacklist',
            removeOrderFromBlackList: 'Remove Order from Blacklist',
            resetTheOpenRate: 'Reset the open rate',
            resetOpenRateLearnMore: 'To learn more on open rate',
            jsonConfirmation: `Since Facebook runs this Json on ads, the first message with coupons
                            variables will not work. We recommend to use it after the buyer first interaction (e.g clicking a button)`,
            editRule: 'Edit rule',
            deliveredMessages: 'Delivered messages',
            deletedTemplate: 'Deleted template',
            deliveredMessagesTooltip: 'The most updated template name is displayed',
            accountSidenav: 'Account',
            userPermissions: 'User permissions',
            createNewUser: 'Create a new user',
            userName: 'User Name',
            userEmail: 'User Email',
            permissions: 'Permissions',
            actions: 'Actions',
            linkAccounts: 'Link accounts',
            addManagedAccount: 'Add manged account',
            accountEmail: 'Account Email',
            removeAccount: 'Remove account',
            editPermissions: 'Edit Permissions',
            addPassword: 'Add Password',
            addAccountEmail: 'Add Account Email',
            billing: 'Billing',
            downloadAllInvoices: 'Download All Invoices',
            invoiceNotFound: 'Invoice not found',
            invoiceNotFoundText: 'Invoice not found, we will manually search for it and send it to your email in the next 24 hours.',
            requestInvoice: 'Request invoice',
            requestInvoiceText: 'This invoice will be sent to you in the next 24 hours',
            usersFirstName: 'User’s First Name',
            usersLastName: 'User’s Last Name',
            usersEmail: 'User’s Email',
            usersPassword: 'User’s Password',
            allPermissions: 'All Permissions',
            grantedPermissions: 'Granted Permissions',
            accountSettings: 'Account settings',
            productSettings: 'Product settings',
            expenses: 'Expenses',
            unlinkPart1: 'Are you sure you want to unlink ',
            unlinkPart2: ' from your account?',
            unlink: 'unlink',
            orderDeliveredTemplateTooltip: 'The most updated template name is displayed',
            addProducts: 'Add Products',
            excludeProducts: 'Exclude Products',
            resetOpenRateNotificationText: 'Open rate has been reseted',
            sendManual: 'Send Manual',
            orderNumb: 'Order #',
            addOrderNumber: 'Add order number',
            addSubjectForEmail: 'Add Subject for Email',
            addContentForEmail: 'Add Content for Email',
            messageSentSuccess: 'Your message have been sent successfully',
            orderNumberValidation: 'Must contain 19 characters',
            invoiceOrderNotFoundText1: 'This order can\'t be found. Possible reason: ',
            invoiceOrderNotFoundText2: `Trial version have only 1 month history data, subscribers gets 1 year back.`,
            invoiceOrderNotFoundText3: `Pending orders  (order that is not yet shipped) can\'t be found because the buyer email
                                is not available.`,
            invoiceOrderNotFoundTitle: 'Order can\'t be found',
            invoiceOrderDoesntMatch: 'Order doesn’t match',
            searchOrderErrorCode3text1: 'Orders sales channel does not match invoice sales channel.',
            searchOrderErrorCode3text2: 'To send this email to this order, either set this invoice also to',
            searchOrderErrorCode3text3: 'or remove attached sales channel from invoice',
            saveInvoiceError: `This invoice is already in use in a template with sales channels which were not set on this invoice. Please make
                       sure that every template that uses this invoice is set with the same sales channels as this invoice.`,
            selectInvoiceLanguage: 'Select Invoice Language',
            invoiceSoon: 'Invoice to be issued soon',
            emailAlreadyInUsePermission: 'This email is associated with a different account, please select a different email',
            allreadyLinked: 'This user is already linked on your account',
            linkedAccMsg: 'Account successfully linked',
            unLinkedAccMsg: 'Account successfully unlinked',
            onlyPrimaryAcc: 'We can\'t link a VA account',
            emailAlreadyIsUse: 'This email is already being used by the system',
            selectMainEmailError: 'This email is associated with a different account, please select a different email',
            linkAccNotExistInDb: 'Sorry, we can`t find the account with this email. Please check it one more',
            cannotLinAcc: `We can't link this account because the access
                        token for this user is expired, please log in to that user and grant access again`,
            deletePermission: 'Permission successfully deleted',
            expiredToken: 'The access token for this user is expired, please log in to that user and grant access again',
            url: 'URL',
            urlButton: 'URL Button',
            sendReport: 'Send report',
            sendInvoiceDefaultSubject: 'Your amazon order`s invoice',
            saveTemplateErrorTilte: 'Save template failed',
            saveTemplateErrorText: 'The invoice added is attached to different sales channels',
            noExplicitName: 'No explicit name',
            addRegionSubTitle2: 'Grant access to your amazon account',
            alreadyHaveUrlError: 'YOU HAVE ALREADY SET A URL',
            alreadyHaveUrlErrorText1: 'A url button cannot be with action.',
            alreadyHaveUrlErrorText2: 'If you set the action - the URL will be removed',
            alreadyHaveActionError: 'YOU HAVE ALREADY SET AN ACTION ',
            alreadyHaveActionErrorText1: 'A url button cannot be with action.',
            alreadyHaveActionErrorText2: 'If you set the URL - the action will be removed',
            inoiceLanguage: 'Invoice language',
            invoiceMarkeplaceTooltip: `This option is designed to avoid mistakes such as sending the wrong invoice to
                                your buyer.`,
            invoiceMarkeplaceTooltip2: `You can attach a specific marketplace on this invoice to make sure it will not
                                be sent to orders that were purchased in a different channels. `,
            invoiceMarkeplaceTooltip3: `For example, If you will try to send an Amazon.de invoice to an order from Amazon.it
                                the system will prevent you from sending this invoice and will pop out an appropriate
                                 message.`,
            invoiceChannelsTitleError: 'Error - All Marketplaces Selected!',
            invoiceChannelsError: `Marketplace selection checks that invoices are sent to the right channels. To make
                                invoice available for all
                           channels uncheck all marketplaces.`,
            invoiceMarkeplaceTooltipTitle: 'Marketplace',
            testConv: 'Test on this page',
            testConv2: 'Test on target page',
            testInstructions: 'Click the chat bot button',
            testInstructions2: `on the right bottom corner and click "Test now" .  If you don\'t have a Chat Now button
                            copy the next code to the chat: `,
            warningDemoBotTest1: 'Text trigger could not be triggered in online chat bot.',
            warningDemoBotTest2: 'You will need to test it on the target page.',
            sizeLimitImage: 'Image size limit: 2 MB',
            downloadOrdersTitle: 'Download Orders report',
            uploadAudienceToFbTitle: 'Upload Audience to facebook',
            ordersAndAudience: 'Orders and audience',
            data: 'Data',
            selectAdAccount: 'Select Ad Account',
            selectAudience: 'Select Audience',
            dates: 'Dates',
            parameters: 'Parameters',
            expences: 'Expenses',
            refundStatus: 'Refund status',
            repeatBuyers: 'Repeated buyers',
            negativeFeedback: 'Negative feedback',
            promotionStatus: 'Promotion status',
            onlyFbData: 'Only data necessary for Facebook advertising',
            downloadReport: 'Download Report',
            uploadAudienceToFb: 'upload audience to Facebook',
            spentMoreThan: 'Spent more than',
            trialMessageDownlaod: 'Trial version gets only 30 days data. upgrade your account to get a 1 year history data',
            youHave: 'You have',
            unreadMessages: 'unread messages!',
            customPlanAvaliable: 'Custom payment available!',
            planPrice: 'Plan price',
            firstCharge: 'First charge',
            firstChargeOn: 'First Charge On',
            payment: 'Payment',
            changePayPalAccount: 'Change PayPal Account',
            expireTokenText1: 'Our access to your Amazon account is about to expire.',
            expireTokenText2: 'You\'ve got',
            expireTokenText3: `days left to grant access again.`,
            expireTokenText4: `Please grant access again to your amazon account.`,
            expireTokenText5: `Your token is about to expire, click`,
            expireTokenText6: `to grant access again to your amazon account.`,
            grantAccessBtn: 'Grant access',
            addRegionQuestion: 'Why can`t i add this region?',
            temporarySendEmail: 'Temporary email added',
            grantAccessBtn2: 'Grant access to amazon',
            grantAccessText8: 'Please, grant access to amazon to use Quantify Ninja again. ',
            adAccount: 'Ad account',
            customAudience: 'Custom audience',
            uploading: 'Uploading',
            addNewAudience: 'Add New Audience',
            orderFbAccessDialogText: 'Grant access to Facebook is needed to complete this action.',
            grantAccessToFb: 'grant access to fb',
            createNewAudiecnce: 'Create new audience',
            audienceName: 'Audience Name',
            description: 'Description',
            buyersAddedToFb: 'Buyers were added successfully by Facebook',
            uploadToFbSuccess: 'Audience uploaded to Facebook successfully',
            ordersDownloadText1: `You can download your data and analyse it using your favourite tool.`,
            ordersDownloadText2: `First use our rich filter to get only specific buyers`,
            ordersDownloadTitle1: 'How does it work ?',
            ordersDownloadTitle2: 'Upload filtered buyers to Facebook',
            ordersDownloadText3: `After setting the filter you can Auto-upload those buyers to Facebook.
                          To do this you will need to grant access to your Facebook.`,
            ordersDownloadText4: `With this access you will be able to select an Ad Account and create or use existing custom audience
                          and upload the filtered list to that audience.`,
            ordersDownloadText5: `If you don't want to grant access to your Facebook you can download the file and upload it manually.`,
            ordersDownloadTitle3: 'Download File',
            ordersDownloadTitle4: 'No limit, no extra charge',
            ordersDownloadText6: `You can download the list of buyers to CSV file.`,
            ordersDownloadText7: `We use 2 formats, one that has the full data on each order and one with only data necessary for creating a
                          custom audience on Facebook.`,
            ordersDownloadText8: `Like every feature in Quantify Ninja we give this option without limiting the number
                              of buyers you can upload.`,
            onFbReview: 'This feature is pending for Facebook review and will be available as soon as Facebook approve it',
            addKeywords: 'Add keywords',
            selectMarketplace: 'Select Marketplace',
            addKeywordToProduct: 'Add keywords to this product',
            keywordPlaceholder: 'Comma separated values. For example: iphone case 6s, iphone case',
            selectProductWithAsin: 'Select product with asin',
            asinAddSuccess: 'Asins were added successfully',
            notificationText: ' chat page and Click the «Start» button to activate the chat',
            notificationText2: 'Click the next link',
            botWarningMessage: 'Text trigger could only be tested on the target page.',
            warningTextKeyword: 'Please set the text that triggers the conversation.',
            here: 'here',
            productImage: 'Product Image',
            createFilters: 'Create filters',
            fulfillmentCahnnels: 'Fulfillment Channel',
            ordersDownloadTittle: 'orders — audience and report',
            addNew: 'Add New',
            excludeProductsTootltip: 'You can exclude specific products from receiving emails',
            anyAmount: 'Any amount',
            cannotChangePayPalAcc: 'You cannot change your PayPal account right now',
            invoicePreview: 'Invoice preview',
            europianUnionAccount: 'European Union account',
            amazonEmail: 'Your Amazon Email',
            AmazonNorthAmericaAccount: 'Amazon North America account',
            botIncorrectELem: "You cant add this element here",
            asinAlertsHeader: "Asin Alerts",
            emailAlerts: "Email Alerts",
            facebookAlerts: "Facebook Alerts",
            addProductAsin: "Add Product ASIN",
            myProducts: "My Products",
            myCompetetors: "My Competitors",
            chooseYourASINs: "Choose Your ASINs",
            chooseYourAlerts: "Choose Your Alerts",
            tracking: "Tracking",
            competitors: "competitors",
            zipCode: "Zip Code",
            templateOption10: 'Send only to repeated buyers',
            advertisingHeader: 'to connect your PPC information for better profit calculations.',

            advertisingBtn: 'Connect PPC',
            emailHijackNotifications: "Hijack Notifications",
            dailyReports: "Daily Reports",
            shippingInfo: 'Shipping Info',
            orderInfo: 'Order Info',
            buyerInfo: 'Buyer Info',
            mailSubject: 'Mail Subject',
            alertsNotifications: 'Alerts & Notifications',
            hijackedListings: 'Hijacked Listings',
            notificationsFb: 'Facebook Notifications',
            notificationsTG: 'Telegram Notifications',
            business: 'Business',
            subscription: 'Subscription',
            upgradesubscriptionplan: 'Upgrade Subscription Plan',
            welcomeBack: 'Welcome back! Please login to your account.',
            rememberMe: 'Remember me',
            readyTo: 'Ready to Sell Better?',
            alreadyHave: 'Already have an account? Sign in.',
            pleaseComplete: 'Please Complete to create your account.',
            couponFile: 'Coupon File',
            iAgreeWith: 'I agree with',
            termOfUse: 'Term of use.',
            northAmericaMarketplaces: 'North America Marketplaces',
            forgotPass1: 'Forgot Password',
            invoices: 'Invoices',
            permissionsGranted: 'Permissions Granted',
            grantPermissions: 'Grant Permissions',
            addRegionSubTitle3: 'To get things running please click on a region to grant permissions',
            europianUnionMarketplaces: 'Europe Marketplaces',
            farEastAccount: 'Far East & AU',
            farEastMarketplaces: 'Far East Marketplaces',
            europianUnionAccount1: 'Europe',
            addRegionSubTitle4: 'You\'re just a few steps away from selling better',
            northAmericaAccount1: 'North America',
            addRegionTitle1: 'Thank you for signing up to Quantify Ninja',
            notificationsTitle1: 'notification settings',
            fbSalesNotificaion1: 'Sales Notification',
            notificationsFbNegative1: 'Negative Feedback Notification',
            notificationsEmail1: 'Email Notifications',
            chooseHowAlerts: 'Choose how alerts are delivered',
            sendAlertsEmail: 'Send alerts to my email',
            sendAlertsFacebook: 'Send alerts to my Facebook messanger',
            chooseWhichAlerts: 'Choose which alerts you want to receive',
            myProductAlerts: 'My Product Alerts',
            competitorsAlerts: 'Competitors Alerts',
            alertsSelected: 'Alerts Selected',
            chooseProductsCompetitors: 'Choose products and competitors to monitor',
            myProduct: 'My Product',
            competitorsProducts: 'Competitors Products',
            productsMonitored: 'Products Monitored',
            competitorsSelected: 'Competitors Selected',
            myAlerts: 'My Alerts',
            competitorsAlerts1: 'Competitors\' Alerts',
            sendAutomatically: 'Send Automatically',
            autoUpload: 'Auto Upload',
            invoiceUpdate: 'Invoice Updated Successfully',
            duplicate: 'Duplicate',
            messagesDelivered: 'Messages Delivered',
            reportType: 'report Type',
            profitSubTitle: 'Product costs not configured - for accurate data',
            profitSubTitle1: 'Please Update your product costs',
            salesByProduct: 'SALES BY PRODUCT',
            cogs: 'CoGS',
            totalCost: 'TOTAL COST',
            unitCost: 'UNIT COST',
            costSave: 'Cost save successfully',
            editProductShortName: 'Edit Product Short Name',
            setdifferentnamesformarketplaces: 'Set different names for marketplaces',
            calander: 'Calander',
            cost: 'Cost',
            promoValue: 'Promo Value',
            welcomeToNinja: 'Welcome to Ninja',
            grantedAccess: 'You have granted access successfully',
            ninjaBusy: 'Ninja is busy bringing all your data from seller central.',
            everythingRunning: 'Everything will be up and running in 15 minutes to half an hour.',
            userId: 'User ID: ',
            createNewRequestReview: 'Create New Request Review',
            whatIsThis: 'What is this?',
            automatedReviewRequestservice: 'Selling Partner API access needed',
            automatedReviewRequestservice1: 'In order to use our Automated Review Request service please grant Ninja Selling Partner API access. This is a new type of access provided by Amazon (BETA).',
            reviewRequestAutomation: 'Create a review request Automation',
            reviewRequestAutomation1: 'Automatically send a Review Request email on behalf of Amazon using their brand new "Selling Partner API" (BETA).',
            reviewRequestAutomation2: 'Request is sent using a predefined text aligned with Amazon\'s TOS.',
            reviewRequestAutomation3: 'You can combine custom emails and\\or add a review request automation.',
            reviewRequestAutomation4: 'Request automation can also be used in case of email restrictions.',
      };

      public textES = {
            affiliatesTitle: `Afiliados`,
            TermsConditions: `Términos y Condiciones`,
            affiliateSubTitle: `Obtenga hasta 35$ por cada afiliación exitosa!`,
            affiliateLink: `Tu Enlace de Afiliado:`,
            date: `Fecha`,
            homepage: `página de inicio`,
            coupons: `Cupones`,
            uploadNewCouponCaps: `SUBIR NUEVO CUPÓN`,
            couponSubTitle: `Mira tus cupones y añade otros nuevos`,
            noCouponMsg: `Está listo para empezar a crear nuevos cupones`,
            pageNotFoundCaps: `PÁGINA NO ENCONTRADA`,
            backTo: `Volver a`,
            pageNotFound: `Página no encontrada`,
            noDataFound: `No se han encontrado datos`,
            full: `Lleno`,
            getOneCode: `Obtener un código`,
            check: `Comprobar`,
            delete: `Borrar`,
            pending: `Pendiente`,
            notFound: `No encontrado`,
            add: `Añadir`,
            asin: `Asin`,
            keyword: `Palabra clave`,
            marketplace: `Tienda`,
            registration: `Registro`,
            searchAsinsByKeywords: `Buscar asins por palabras clave`,
            addRegion: `Añadir región`,
            europianUnionAccount: `Cuenta de la Unión Europea`,
            getAnotherLink: `Obtener otro enlace`,
            AmazonJapanAccount: `Cuenta Amazon Japón`,
            AmazonAustraliaAccount: `Cuenta Amazon Australia`,
            amazonRegions: `Amazon.com, Amazon.ca, Amazon.co.mx)`,
            amazonRegions2: `(Amazon.co.uk, Amazon.de, Amazon.se, Amazon.it, Amazon.fr)`,
            amazonRegions3: `(Amazon.jp)`,
            amazonRegions4: `(Amazon.com.au)`,
            tableHeaders: {
                  earnedAmount: `Importe ganado`,
                  spot: `Punto`,
                  couponName: `Nombre del cupón`,
                  couponsLeft: `Cupones restantes`,
                  expiryDate: `Fecha de Expiración`,
                  getCouponCode: `Obtener código de cupón`,
                  CouponCode: `Código del cupón`,
                  page: `Página`,
                  marketPlace: `Tienda`,
                  userPublicID: `ID de usuario publico`,
                  fullRegistration: `Registro completo`,
                  planType: `Tipo de Plan`,
                  agreementStatus: `Estado del acuerdo`,
                  lastUpdated: `Última actualización`,
            },
            bulkTitle: `nueva regla de volumen`,
            bulkSubTitle: `Enviar un correo electrónico de una sola vez a todos los compradores que compraron en un rango de fechas específico`,
            bulkSubLimit1: `Estas lmitado a`,
            bulkSubLimit2: `emails por semana. Si necesitas más crédito, por favor`,
            contactUs: `Contáctenos`,
            filters: `Filtros`,
            status: `Estado`,
            template: `Plantilla`,
            from: `De`,
            to: `Para`,
            bulkName: `Nombre del Volumen`,
            createNewBulk: `CREAR NUEVO VOLUMEN`,
            clearAll: `Despejar`,
            email: `Correo electrónico`,
            sent: `enviado`,
            estimated: `Estimado`,
            edit: `Editar`,
            editFbaTemplteTitle: `EDITAR PLANTILLA FBA`,
            backFbaTemplate: `< Volver a Plantillas FBA`,
            editFbmTemplteTitle: `EDITAR PLANTILLA FBM`,
            backFbmTemplate: `< Volver a Plantillas FBM`,
            createFbaTemplateTitle: `CREAR NUEVA PLANTILLA FBA`,
            createFbmTemplateTitle: `CREAR NUEVA PLANTILLA FBA`,
            success: `Éxito`,
            fbmMissingProductsReport: `El informe ha sido enviado, revisaremos su asunto tan pronto como sea posible`,
            invalidFormat: `Formato no válido`,
            imageLoadValidation: `El archivo debe ser de tipo jpg, jpeg, png o gif y no puede exceder los 2MB`,
            fail: `Fallido`,
            emptyNameError: `El nombre no puede estar vacío`,
            emptySubjectError: `El asunto no puede estar vacío`,
            imageUploading: `Carga de imágenes`,
            imageUploadingMessage: `Por favor, guarde la plantilla antes de subir imágenes desde su PC`,
            name: `Nombre`,
            subject: `Asunto`,
            when: `Cuándo`,
            after: `Después`,
            products: `Productos`,
            editTemplateAddVariableTitle: `Agregar variables`,
            editTemplateAddVariableSubTitle: `Simplemente haga clic en cierta variable y aparecerá en su mensaje,
                                    para aprender más acerca de las variables`,
            clickHere: `haga clic aquí`,
            product: `Producto`,
            order: `Orden`,
            customer: `Cliente`,
            shipping: `Envio`,
            templateOptions: `Opciones de plantilla`,
            templateOption1: `Elegir el estado de esta plantilla`,
            templateOption2: `Enviar plantilla si el comprador dejó comentarios negativos`,
            templateOption3: `Enviar plantilla si el pedido es reembolsado`,
            templateOption4: `Enviar plantilla a compradores repetidos`,
            templateOption5: `Enviar plantilla si se ha dado promoción`,
            templateOption6: `En caso de promoción, enviar plantilla cuando valor de promoción`,
            templateOption7: `Seleccionar canales de venta específicos`,
            templateOption8: `Añadir cupón a la plantilla`,
            allChannels: `Todos los canales`,
            editTemplateFileTitle: `Agregar un archivo a la plantilla`,
            editTemplateFileSubtitle: `El tamaño total de los archivos no debe exceder los 7MB, para ver todos los formatos soportados`,
            upload: `Subir`,
            cancel: `Cancelar`,
            templateTestMessage: `Puede probar esta plantilla y ver cómo se verá para sus clientes, o simplemente guardarla`,
            test: `Probar`,
            save: `Guardar`,
            fbaEdited: `Plantilla FBA creada con éxito`,
            fbaCreated: `Plantilla FBA editada con éxito`,
            fbmCreated: `Plantilla FBM creada con éxito`,
            fbmEdited: `Plantilla FBM editada con éxito`,
            deleteTemplate: `Borrar plantilla`,
            copyTemplate: `Copiar plantilla`,
            exportTemplates: `Exportar plantillas`,
            templateDeleted: `Plantilla eliminada con éxito`,
            templateCopied: `Template successfully copied`,
            templatesExported: `Plantilla exportada con éxito`,
            fbaTitle: `plantillas fba`,
            createNewFba: `CREAR FBA NUEVO`,
            fbaSubTitle1: `Los correos electrónicos serán enviados desde`,
            fbaSubTitle2: `Si este no es el email asociado a tu amazon`,
            fbmTitle: `plantillas fbm`,
            account: `cuenta`,
            timeFrame: `Franja de tiempo`,
            event: `Evento`,
            salesChannel: `Canal de ventas`,
            exportToCSV: `Exportar a CSV`,
            openRate: `Tasa de apertura`,
            templateName: `Nombre de la plantilla`,
            copy: `Copiar`,
            createNewFbm: `CREAR FBM NUEVO`,
            clickHereToEdit: `Haga clic aquí para editar`,
            couponCreatedTitle: `Cupón creado`,
            couponEdited: `El cupón fue editado con éxito`,
            couponCreated: `El cupón se ha creado con éxito`,
            headerMessageSent: `Su mensaje ha sido enviado`,
            trialMessage1: `Su período de prueba de 14 días expira en`,
            trialMessage2: `para actualizar tu cuenta`,
            days: `days`,
            upgrade: `Actualizar`,
            user: `Usuario`,
            settings: `Ajustes`,
            default: `Predeterminado`,
            warning: `Advertencia`,
            dashboardNoDateMessage: `Usted debe elegir desde y hasta las fechas en primer lugar`,
            time: `Tiempo`,
            unitSold: `Unidades vendidas`,
            noData: `Sin datos`,
            profit: `Beneficio`,
            profitNotInclude: `Las ganancias de productos específicos no incluyen los gastos de PPC y ofertas especiales`,
            emailsSent: `Correos enviados`,
            revenue: `Ingresos`,
            unitsSoFar: `Unidades hasta ahora`,
            ordersLowerCase: `Ordenes`,
            promotionLowerCase: `promoción`,
            calculate: `Calcular`,
            yesterday: `Ayer`,
            amazonSalesData: `Datos de ventas de Amazon`,
            zoom: `Zoom`,
            all: `Todos`,
            selectedProductsUpperCase: `PRODUCTOS SELECCIONADOS`,
            daily: `Diariamente`,
            weekly: `Semanal`,
            conversationRate: `Tipo de conversión`,
            sessions: `Sesiones`,
            last12Month: `(últimos 12 meses)`,
            unitsReturned: `Unidades devueltas`,
            chartPopularProductsTitle: `Productos Populares`,
            selectRegion: `Seleccionar región`,
            dashboard: `Panel de control`,
            automatedMessaging: `Mensajería automatizada`,
            FBA: `FBA`,
            FBM: `FBM`,
            bulk: `Volumen`,
            notifications: `Notificaciones`,
            moneyBack: `Devolución del dinero`,
            hijackedListingViewer: `Visor de anuncios piratas`,
            ordersAndBL: `Ordenes y lista negra`,
            download: `Descargar`,
            keywordOptimizer: `Optimizador de palabras clave`,
            facebookBotManager: `Gestor de bots de Facebook`,
            conversationManager: `Administrador de conversación`,
            autoResponse: `Respuesta automática`,
            subscribers: `Suscriptores`,
            menu: `Menú`,
            asinTracker: `Rastreador ASIN`,
            superUser: `Super usuario`,
            FAQQuestions: `Cualquier pregunta o dificultad`,
            watchTutorials: `ver tutoriales`,
            inviteFriends: `Invitar amigos`,
            autoResponseTitle: `Mensajes de Auto Respuesta de Facebook`,
            createNewResponse: `crear una nueva respuesta`,
            autoResponseSubTitle1: `Alguna breve explicación sobre el gestor de conversación y la creación de un bot`,
            autoResponseSubTitle2: `Si no es necesario podemos borrar este texto`,
            pageName: `Nombre de página`,
            responseName: `Nombre de la respuesta`,
            autoResponseText: `Estás listo para empezar a crear nuevas conversaciones`,
            botMenuTitle: `Crear un menú`,
            botMenuSubTitle1: `El menú se puede establecer por página y no se puede establecer para una conversación específica`,
            botMenuSubTitle2: `Puede añadir hasta 2 menús de nivel superior y hasta 3 menús de segundo y tercer nivel`,
            page: `Página`,
            selectPage: `Seleccionar página`,
            botTopLevel: `Nivel superior`,
            botSecondLevel: `Segundo Nivel`,
            botTercerNivel: `Tercer Nivel`,
            deleteMenu: `Borrar menú`,
            commitMenu: `Menú de confirmación`,
            preview: `Vista previa`,
            botMenuEditStep: `Editar paso`,
            botMenuDeleteStep: `Borrar paso`,
            botMenuDeleteLevel: `Borrar nivel`,
            Action: `Acción`,
            botMenuAddSubLvl: `Añadir subnivel`,
            botMenuAddNewStep: `Agregar nuevo paso`,
            botMenuChooseAction: `Elegir acción`,
            done: `Hecho`,
            botMenuTypeTitle: `Escriba su título aquí`,
            conversationManagerTitle: `Administrador de conversación (Bot)`,
            createNewConversation: `crear nueva conversación`,
            triggerBy: `Activado por`,
            conversationManagerDirectLink: `Enlace directo a la conversación`,
            botNewButton: `Nuevo botón`,
            botTriggerConversation: `Activar conversación`,
            unsubscribe: `Cancelar la suscripción`,
            subscribe: `Suscribirse`,
            delay: `Retraso`,
            botTypingStatus: `Estado de escritura`,
            botSelectConversation: `Seleccionar conversación`,
            botSetDelay: `Configurar los segundos de retardo`,
            sec: `Segundo`,
            typing: `Escribiendo`,
            botSetSecons: `Configurar segundos`,
            variable: `Variable`,
            coupon: `Cupón`,
            variables: `Variables`,
            tag: `etiqueta`,
            botIconInside: `Icono dentro`,
            botNewTag: `añadir nueva etiqueta`,
            enterText: `Introducir texto`,
            Button: `Botón`,
            text: `Texto`,
            uploadImage: `Subir imagen`,
            title: `Título`,
            subtitle: `Subtítulo`,
            botButtonTitle: `Título del botón`,
            botWarningMessage: `No podemos añadir este componente aquí.`,
            payload: `Carga útil`,
            getJson: `Get Json`,
            start: `Inicio`,
            image: `Imagen`,
            fullScreen: `Pantalla completa`,
            resetTester: `Reiniciar tester`,
            withoutTesters: `Sin testers`,
            tester: `Tester`,
            botLinkToConversation: `Enlace a esta conversación`,
            deleteAll: `Borrar todo`,
            saveAll: `Guardar Todo`,
            favStore: `Tienda favorita`,
            botUnderConstructionTitle: `seguimos trabajando en eso`,
            botUnderConstructionText1: `El Gestor de Bots de Facebook está casi terminado, pero todavía tenemos que pulir y
                              finalizar algunas cosas`,
            botUnderConstructionText2: `Regresaremos pronto con la mejor función de Bots que hayas visto.
                              Sólo espera un poco. ¡Apreciamos su comprensión!`,
            botUnderConstructionText3: `Todavía puedes usar el bot en la interfaz antigua`,
            switch: `Interruptor`,
            botGrantAccessSubTitle: `El Gestor de Bots de Facebook es lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                            tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                            ullamco aboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in`,
            botGrantAccessSubTitle1: `Puedes ver la demostración aquí`,
            grantAccess: `Conceder acceso`,
            botGrantAccessPermissions: `Qué permisos necesitamos`,
            botGrantAccessListTitle1: `Mensajería de páginas`,
            botGrantAccessListText1: `Para que podamos enviar mensajes en su nombre`,
            botGrantAccessListTittle2: `Suscripciones de mensajería de páginas`,
            botGrantAccessListText2: `Para que podamos enviar mensajes en su nombre después de 24 horas desde que el usuario se ha suscrito`,
            botGrantAccessListTittle3: `Las páginas muestran la lista`,
            botGrantAccessListText3: `Para que puedas elegir las páginas`,
            botGrantAccessListTitle4: `Administrar páginas`,
            botGrantAccessListText4: `Para que podamos añadir un botón \`Comenzar\` en tu messenger`,
            botGrantAccessListTitle5: `Leer buzones de correo de páginas`,
            botGrantAccessListText5: `Para que puedas definir una respuesta automática a los comentarios de las entradas`,
            subscribersTitle: `Lista de suscriptores`,
            subscriberTotal: `Total de suscriptores:`,
            taggedWith: `Etiquetado con`,
            notTaggedWith: `NO etiquetado con`,
            conversaion: `Conversación`,
            broadcast: `Difusión`,
            photo: `Foto`,
            connectFacebookMessage: `Necesitas conectar tu cuenta de facebook a la aplicación para usar esta función`,
            notificationsTitle: `notificaciones de facebook messenger`,
            notificationsSubTitle: `Recomendamos elegir la notificación de Telegram sobre Facebook`,
            getFbInstructions: `Obtenga instrucciones para activar en Facebook`,
            getTgInstructions: `Obtén instrucciones para activar en Telegram`,
            notificationsFbAlertName: `Nombre de alerta de Facebook`,
            notificationsSetAlert: `Configurar Alerta`,
            notificacionesAlertOnProducts: `Alerta en productos`,
            fbSalesNotificaion: `Notificación de ventas de Facebook`,
            notificationsOnEverySale: `En cada venta`,
            notificationsFbNegative: `Notificación de comentarios negativos de Facebook`,
            notificationsFbReview: `Notificación de revisión de Facebook`,
            notificationsOnlyMaster: `Disponible sólo para el plan Ninja Maestro`,
            notificationsEmail: `Notificaciones por correo electrónico`,
            alertName: `Nombre de la alerta`,
            notificationsText1: `Avíseme cuando reciba nuevos comentarios negativos`,
            notificationsText2: `Me gustaría recibir actualizaciones diarias sobre cuántos correos electrónicos han recibido mis clientes`,
            notificationsText3: `Avíseme cuando otro vendedor haya robado mi anuncio`,
            notificationsReview: `Revisar notificación`,
            hjTitle: `Visor de listados robados`,
            hjSubTitle: `establecer una notificación cuando un nuevo vendedor se une a su anuncio`,
            productName: `Nombre del producto`,
            SKU: `SKU`,
            condition: `Condición`,
            numberOf: `Numero De`,
            newOffers: `Nuevas Ofertas`,
            usedOffers: `Ofertas usadas`,
            hjViewOnAmazon: `Ver En Amazon`,
            inviteFriendsTitle: `Invita a tus amigos a QuantifyNinja`,
            inviteFriendsSubTitle: `Simplemente añade la dirección de correo electrónico de tu amigo y pulsa \`Enviar Invitación\``,
            inviteFriendsSend: `Enviar invitación`,
            keywordTitle: `Optimizador de palabras clave`,
            keywordSubTitle: `Identifica y elimina automáticamente duplicados cuando intenta añadir palabras clave
                    que ya existen en su título y descripción`,
            keywordDesired: `Palabra clave deseada`,
            keywordNumber: `Número de palabras`,
            keywordListing: `Texto del listado (título, puntos del bulto etc.)`,
            result: `Resultado`,
            emailHasBeenSent: `El email ha sido enviado con éxito`,
            errorMoreThan30: `Debe ser más de 30`,
            errorMoreThan0Less20: `Debe ser más de 0 y menos de 20`,
            mbTitle: `Devolución de dinero`,
            mbTab1: `Solicitudes de ajuste`,
            mbTab2: `Inventario faltante`,
            auto: `auto`,
            active: `Activo`,
            inactive: `Inactivo`,
            mbAmzAccount: `Cuenta de correo electrónico de Amazon`,
            mbDaysBtwRequests: `Días entre solicitudes`,
            mbNumbersOfReqOnSingle: `Número de solicitudes en un solo correo electrónico`,
            mbWhatNext: `¿Qué sigue?`,
            mbWhatNextText1: `Si tu plan esta Activo recibirás emails de acuerdo a tu configuración.
                    Tendrá que copiar el contenido del correo electrónico a un nuevo caso con Amazon`,
            mbWhatNextText2: `Por favor, recuerde que esta es la versión Alpha de esta característica,
                    puede que no funcione como se espera y le animamos a que nos envíes tus comentarios`,
            manual: `Manual`,
            mbOnSingleEmial: `En un único correo electrónico (máx. 20)`,
            mbMoneyBackGuarantee: `Garantía de devolución de dinero`,
            mbGuaranteeText: `Garantía de Amazon (en días). Para la mayoría de las categorías use 30, para la categoría de bebés use 90`,
            mbOnlyAdjBefore: `Sólo los ajustes emitidos con anterioridad`,
            mbOnlyAdjAfter: `Sólo los ajustes realizados después de`,
            send: `Enviar`,
            downloadTitle: `descargar informe`,
            downloadSubTitle: `Vuelva a dirigir a sus compradores a los medios sociales mediante la creación de una audiencia
                     personalizada utilizando los siguientes datos`,
            downloadExludeOrders: `Excluir pedidos promocionales`,
            downloadHistoryMessage: `Se tarda hasta 30 horas en recuperar el historial de 1 año de su cuenta`,
            refundTooltipMessage: `Orden devuelta el`,
            saveBeforeUploadMessage: `Por favor, guarde la plantilla antes de subir imágenes desde su PC`,
            smTitle: `Enviar mensaje`,
            smBackToOrders: `Volver a Ordenes`,
            emailExample: `ejemplo@gmail.com`,
            smExcludeOrder: `Excluir esta orden de la plantilla futura`,
            smSendCopy: `Enviar copia a mi bandeja de entrada`,
            errorRequired: `Esta solicitud de registro es obligatoria`,
            templateFile: `Archivo de plantilla`,
            optionChanged: `Option has been changed`,
            ordersTitle: `Ordenes`,
            allTypes: `Todos los tipos`,
            buyerName: `Nombre del comprador`,
            buyerEmail: `Correo electrónico del comprador`,
            orderId: `ID del pedido`,
            search: `Búsqueda`,
            ordersExcludeOrder: `Excluir este PEDIDO de la plantilla futura`,
            ordersExcludeBuyer: `Excluir a este COMPRADOR de la plantilla futura`,
            sendMessage: `Enviar mensaje`,
            quantity: `Cantidad`,
            purchaseDate: `Fecha de compra`,
            shipmentDate: `Fecha de envío`,
            customerPhoneNumber: `Número de teléfono del cliente`,
            trackingNumber: `Numero de Rastreo`,
            receipientName: `Nombre del destinatario`,
            serviceLevel: `Nivel de servicio`,
            shipAddress: `Dirección de envío`,
            carrier: `Transportista`,
            shipCity: `Ciudad de Envio`,
            shipState: `Estado del Envio`,
            shipCountry: `País del Envio`,
            itemPrice: `Precio del artículo`,
            itemTax: `Impuesto del artículo`,
            shippingPrice: `Precio de envío`,
            shippingTax: `Impuesto del Envio`,
            giftWrapPrice: `Precio de la envoltura de regalo`,
            giftWrapTax: `Impuesto de la envoltura de regalo`,
            itemPromotionDiscount: `Descuento por promoción de artículos`,
            shipPromotionDisc: `Descuento por promoción de envío`,
            errorEmptyPass: `La contraseña está vacía`,
            superLogInAS: `Iniciar sesión como`,
            superCreateInvoice: `Crear factura`,
            superTab1: `Mostrar email por ID de Msg`,
            tab: `Pestaña`,
            superFillUsers: `Rellenar usuarios`,
            password: `Contraseña`,
            go: `IR`,
            upgTitle: `mejora tu plan`,
            premium: `Premium`,
            masteNinja: `Maestro Ninja`,
            upgPayWith: `Pagar con`,
            upgAddOnly: `Añadir sólo`,
            upgIncluded: `Características Incluidas`,
            downgrade: `Degradación`,
            upgCurrentPlan: `plan actual`,
            upgDataTitle: `Datos y herramientas de gestión`,
            upgDataText1: `Estadísticas`,
            upgDataText2: `Gráficos circulares para ventas, beneficios y devoluciones de clientes`,
            upgDataText3: `Tablas de comparación del rendimiento del producto`,
            upgDataText4: `Buscar órdenes por nombre, orden y fechas`,
            upgDataText5: `Cálculo de beneficios`,
            upgNotificationsText1: `Notificaciones en tiempo real sobre las ventas a través de Facebook y correo electrónico`,
            upgNotificationsText2: `Notificaciones de revisión de productos a través de Facebook y correo electrónico`,
            upgNotificationsText3: `Notificaciones de comentarios negativos a través de Facebook y correo electrónico`,
            upgEmailTitle: `envío de correos`,
            upgEmailText1: `Envío masivo de correo electrónico`,
            upgEmailText2: `Correo electrónico y plantillas ilimitadas para sus clientes`,
            upgEmailText3: `Datos de velocidad de apertura`,
            upgEmailText4: `Adjuntar archivos a plantillas`,
            upgEmailText5: `Compradores\\ lista negra de pedidos`,
            upgHjTitle: `secuestro y listas negras`,
            upgHjText1: `Hijacked listing notifier`,
            upgHjText2: `Vista de listado secuestrado`,
            upgOptimizationTitle: `herramientas de optimización`,
            upgOptimizationText1: `Rastreador Asin - ver la posición de sus productos por palabras clave`,
            upgOptimizationText2: `El bot de Facebook - obtener más clientes potenciales mediante el uso de conversaciones automatizadas
                          en Facebook y atraer tráfico a tus páginas de Amazon`,
            upgAddingReg: `Añadir 1 región - 15$/ mes`,
            upgYourCurrPlan: `Su plan actual`,
            upgCancelPlan: `Cancelar Plan`,
            options: `opciones`,
            upPriceText1: `Precio por defecto para el plan Premium (con 1 región) - $35/mes, y + $15/mes por cada región adicional`,
            upgPriceText2: `Precio por defecto para el plan Maestro Ninja (con 1 región) - $55/mes, y + $15/mes por cada región adicional`,
            upgPlanVariations: `Variaciones de planes disponibles`,
            upgPrem1Reg: `Premium con 1 región`,
            upgPrem2Reg: `Premium con 2 regiones`,
            upgPrem3Reg: `Premium con 3 regiones`,
            upgJoinMaster: `Unirse al plan Maestro Ninja`,
            notice: `Aviso`,
            upgNoticeText1: `Una vez suscrito a`,
            upgNoticeText2: `su antiguo plan se cancelará automáticamente. El primer pago es la fracción de los 20 dólares
                            extras de este ciclo. Se le cobrará un total de 55$ a partir de su próximo ciclo`,
            termsAndCond: `Términos y Condiciones`,
            checkOur: `Mira nuestro`,
            forMoreInfo: `para más información`,
            settingsAccTitle: `Configuración de la cuenta`,
            settingsAccSubTitle: `Establece la información de tu perfil, información de negocio, moneda o plan de suscripción`,
            settingsAccExpoDate: `Válido hasta que se cancele`,
            errorIncorectEmail: `Formato de correo electrónico incorrecto`,
            errorIncorectPassFormat: `Formato de contraseña incorrecto`,
            errorPassNotMath: `Las contraseñas no coinciden`,
            emailAddress: `Dirección de correo electrónico`,
            sendEmailFrom: `Enviar correos electrónicos desde`,
            confirmPasswod: `Confirmar contraseña`,
            businessName: `Nombre del Negocio`,
            businessRegNum: `Número de registro de la empresa`,
            businessAddress: `Dirección de la empresa`,
            defaultCurrency: `Moneda por defecto`,
            linkedRegs: `Regiones vinculadas`,
            subscrPlan: `Plan de Suscripción`,
            expiration: `Expiración`,
            expenseTitle: `Gastos`,
            expenseSubTitle: `Mira tus gastos de PPC y Ofertas especiales o añade tus gastos de aduana`,
            addExpense: `Añadir gasto`,
            expenseAdded: `Gastos añadidos con éxito`,
            editExpense: `Editar gasto`,
            expenseEdited: `Gastos editados con éxito`,
            deleteExpense: `Borrar gastos`,
            expenseDeleted: `Gasto eliminado con éxito`,
            amount: `importe`,
            productsVisibleTooltip: `Visible mode - product can be selected anywhere in the app`,
            productsInvisibleTooltip: `Modo invisible - intentaremos ocultar este producto en cualquier parte de la aplicación`,
            productsTitle: `Ajustes del producto`,
            productsSubTitle: `Establecer un nombre corto de sus productos y seleccionar colores para que sus productos se
                      muestren en los gráficos`,
            editProduct: `Editar producto`,
            productEdited: `Producto editado con éxito`,
            hide: `Ocultar`,
            shortName: `Denominación abreviada`,
            displayedName: `Nombre mostrado`,
            productAsin: `Producto Asin`,
            productsPerCost: `Coste unitario`,
            productsSettings: `Ajustes del producto`,
            editResponse: `Editar respuesta`,
            errorPostNotSelected: `El mensaje debe ser seleccionado`,
            autoRespApplyOnly: `Aplicar sólo a la respuesta directa`,
            ruleName: `Nombre de la regla`,
            message: `Mensaje`,
            errorPageNotSelected: `La página debe estar seleccionada`,
            selectPost: `Selecciona una entrada`,
            postPreview: `Vista previa del post`,
            required: `Requerido`,
            errorTemplateNotSelected: `Una plantilla debe ser seleccionada`,
            editBulk: `Editar volumen`,
            anyOfYourProducts: `Cualquiera de sus productos`,
            bulkDialogText1: `Envíe esta plantilla a todos sus clientes que compraron uno de los siguientes productos`,
            bulkDialogText2: `Envíe esta plantilla a todos sus clientes que compraron`,
            bulkDialogTimeframe: `Dentro de este plazo`,
            bulkDialogEmailEst: `Email estimado (número de emails planificados)`,
            count: `Contar`,
            set: `Configurar`,
            exportCSV: `exportar a CSV`,
            exportCSVText: `Se enviará un archivo delimitado por pestañas a su correo electrónico`,
            errorEmailInUse: `Esta dirección de correo electrónico ya está en uso`,
            ok: `ok`,
            dialogDeleteTemplate: `¿Borrar esta plantilla?`,
            dialogDeleteTemplateText: `¿Estás seguro de que quieres borrar esta plantilla?`,
            yesDelete: `sí, borrar`,
            resetPass: `Restablecer contraseña`,
            resetPassText1: `Se ha enviado un enlace para restablecer la contraseña a su correo electrónico. Por favor, compruebe
                  su bandeja de entrada y tenga en cuenta que a veces los correos electrónicos pueden terminar en su carpeta
                   de correo basura/spam`,
            resetPassText2: `¿No has recibido el email? Asegúrate de que has utilizado el correo electrónico de QuantifyNinja. Si
                  te encuentras con algún problema, por favor, póngase en contacto con el`,
            supportEmail: `support@quantifyninja.com`,
            dialogGoToLogin: `ir a inicio de sesión`,
            signUp: `inscribirse`,
            signUpSuccess: `Te inscribiste con éxito`,
            dialogUnsaved: `Tiene cambios no guardados`,
            dialogUnsavedText: `¿Estás seguro de que quieres dejar esta página?`,
            yes: `Sí`,
            no: `No`,
            dialogStatus: `Elegir el estado de esta plantilla`,
            dialogStatusText1: `Sólo las plantillas activas serán enviadas a los clientes de acuerdo con la configuración`,
            dialogStatusText2: `Las plantillas inactivas no se enviarán bajo ninguna configuración`,
            gotIt: `Entendido`,
            dialogNegativeFeedback: `Enviar plantilla si el comprador dejó retroalimentación negativa`,
            dialogNegativeFeedbackText: `El ajuste \`No\` excluirá a los compradores que dejaron comentarios negativos`,
            dialogRefundOrder: `Enviar plantilla si el pedido es reembolsado`,
            dialogRefundOrderText: `La plantilla se enviará incluso si la orden ha sido reembolsado.`,
            dialogRepBuyers: `Enviar plantilla a compradores repetidos`,
            dialogRepBuyersText: `La configuración de esta opción excluye el envío de esta plantilla a los compradores que la compraron.
                        en el pasado. Si un comprador compró el mismo artículo más de una vez, sólo obtendrá
                        esta plantilla una vez. Tenga en cuenta que si el mismo comprador compra un producto diferente, podrá
                        obtener esta plantilla de nuevo`,
            dialogPromotionGiven: `Enviar plantilla si se ha dado promoción`,
            dialogPromotionGivenText: `La plantilla será enviada en caso de que se ofrezca la promoción`,
            dialogPromotionValue: `En caso de promoción, enviar plantilla cuando valor de promoción`,
            dialogPromotionValueText: `Al configurar esta opción puede excluir este correo electrónico si el comprador recibió
                              más//iguales// menos promoción que una cierta cantidad. Tenga en cuenta que los pedidos
                               sin ninguna promoción no serán excluidos`,
            dialogPassChanged: `Contraseña cambiada`,
            dialogPassChangedText: `Su contraseña ha sido cambiada con éxito`,
            dialogSpecSalesChannel: `Seleccionar canales de venta específicos`,
            dialogSpecSalesChannelText: `Esta opción le permite seleccionar el canal específico a través del cual se enviará la plantilla`,
            dialogAddCoupon: `Añadir cupón a la plantilla`,
            dialogAddCouponText1: `Se pueden añadir cupones a la plantilla automáticamente. Para agregar un código de
                                cupón necesitará agregar la Etiqueta de cupón' en la plantilla.`,
            dialogAddCouponText2: `Para añadir la etiqueta del cupón: Seleccione el cupón en la lista desplegable de cupones a la izquierda.
                            Una vez seleccionado, verá el código de etiqueta de cupón que tendrá que añadir a la plantilla.
                            Copie el nombre de la etiqueta y colóquela en la plantilla.`,
            dialogAddCouponText3: `Una vez que se envía un correo con esa plantilla, se insertará un cupón de la lista de cupones cargados.`,
            dialogAddCouponText4: `Si no hay más cupones en la lista cargada, el email con esta plantilla no será enviado.`,
            dialogBulk: `nueva regla de volumen`,
            dilogBulkText1: `Amazon limita el número de correos electrónicos que puede enviar a los compradores por día`,
            dilogBulkText2: `El límite se basa en el volumen medio diario de pedidos. Usted no puede enviar más mensajes que cinco veces su
                    volumen medio diario de pedidos, más 600 mensajes adicionales. `,
            dilogBulkText3: `Por ejemplo, si haces 100 ventas al día en promedio, en un día cualquiera puedes enviar (5 x 100) + 600 = 600
                    1100 mensajes`,
            dialogVaiable: `Agregar variables`,
            dialogVaiableText1: `Las variables son campos de plantilla que se rellenan en tiempo real de acuerdo con la información específica
                          de cada uno de ellos. cliente o pedido.`,
            dialogVaiableText2: `Por ejemplo, para iniciar un correo electrónico con un saludo personal, use`,
            dialogVaiableText3: `Hola &lt;nombre-primer-cliente&gt;`,
            dialogVaiableText4: `en tu plantilla`,
            dialogVaiableText5: `La variable`,
            dialogVaiableText6: `&lt;nombre-del-cliente&gt;`,
            dialogVaiableText7: `será reemplazado en tiempo real con John, Barbara, Melanie, y así sucesivamente.... de
                            acuerdo a las necesidades del cliente. nombre en la orden.`,
            confirm: `confirmar`,
            dialogConfirmTemplate: `Confirmar Plantilla`,
            dialogConfirmTemplateText1: `Estado de la plantilla:`,
            dialogConfirmTemplateText2: `Será enviado`,
            dialogConfirmTemplateText3: `INMEDIATAMENTE`,
            dialogConfirmTemplateText4: `días después`,
            dialogConfirmTemplateText5: `para clientes que compraron`,
            dialogConfirmTemplateText6: `los siguientes productos:`,
            dialogConfirmTemplateText7: `cualquiera de sus productos FBA (incluyendo productos que serán añadidos en el futuro)`,
            dialogAddFile: `Formatos de adjuntos`,
            dialogAddFileText1: `Archivos de texto (.txt)`,
            dialogAddFileText2: `PDFs (Formato de documento portátil de Adobe)`,
            dialogAddFileText3: `Documentos Word (.doc y.docx)`,
            dialogAddFileText4: `Archivos de imagen (.jpg,.gif,.tiff,.bmp, y.png)`,
            dialogBulkNoedit: `editar volumen`,
            dialogBulkNoeditText: `La regla en bloque no se puede editar después de la ejecución o durante el procesamiento`,
            dialogEditEmail: `Editar Email`,
            dialogEditEmailText: `Su correo electrónico en su cuenta de Amazon:`,
            setSender: `Set sender`,
            login: `Iniciar sesión`,
            dilogOnHold: `Su cuenta está en espera, por favor contactenos`,
            dialogRegion1: `Cuenta Amazon US`,
            dialogRegion1Texto: `Amazon.com, Amazon.ca, Amazon.co.mx)`,
            dialogRegion2: `Cuenta de la Unión Europea`,
            dialogRegion2Text: `Amazon.uk, Amazon.de, Amazon.se, Amazon.it, Amazon.fr)`,
            dialogRegion3: `Cuenta de Amazon Japón`,
            dialogRegion3Text: `Amazon.jp`,
            dialogRegion4: `Cuenta Amazon Australia`,
            dialogRegion4Text: `Amazon.com.au`,
            dialogPermossionDenied: `Esta característica no está disponible`,
            dialogPermossionDeniedText: `Debe actualizar su plan de pago para tener acceso a esta página`,
            dialogInstructions: `OBTENER INSTRUCCIONES`,
            dialogInstructionsText1: `Abrir un mensajero de facebook, usando el siguiente enlace`,
            dialogInstructionsText2: `Cuantificar la página de chat ninja`,
            dialogInstructionsText3: `Haga clic en el botón \`Comenzar\` para activar el chat y copie el siguiente código para
                               chatear y pulse enviar`,
            copiedToClipboard: `Copiado al portapapeles`,
            dialogGetJsonText: `Cuando inicias una campaña de messenger tienes que decirle a facebook qué mostrar cuando el usuario hace
                        clic en tu anuncio. Esto se puede hacer usando el código Json. Copia el siguiente código a la pestaña Json
                         en la configuración del anuncio en Facebook:`,
            dialogOrderBL: `ORDENAR LISTA NEGRA`,
            dialogOrderBLText: `Los pedidos de esta lista no recibirán ningún correo electrónico posterior definido por
                            sus plantillas, sin embargo, el comprador puede recibir sus plantillas en diferentes pedidos`,
            dialogBuyerBL: `LISTA NEGRA de Compradores`,
            dialogBuyerBLText: `Los compradores de esta lista no recibirán ningún correo electrónico definido por sus plantillas en
                        ningún pedido pasado o futuro.`,
            dialogCommitMenu: `Confirme y elimine el menú`,
            dialogCommitMenuText1: `Confirmación del menú`,
            dialogCommitMenuText2: `se reflejará inmediatamente en su bot de página. El menú aparecerá en todas las
                                conversaciones de esta página. No se puede establecer por conversación`,
            dialogCommitMenuText3: `Borrar menú`,
            dialogCommitMenuText4: `se reflejará inmediatamente en su bot de página. El menú se borrará de todas las
                                conversaciones de esta página`,
            dialogSessionExp: `Necesita iniciar sesión de nuevo`,
            dialogSessionExpText: `Su sesión actual ha expirado`,
            dialogDownload: `descargar informe`,
            dialogDownloadText: `No se han encontrado resultados coincidentes`,
            dialogDownloadFailText: `Si el informe no se ha generado correctamente, inténtelo de nuevo y póngase en contacto con nosotros
                             si vuelve a ocurrir`,
            dialogAsinLimit: `Agregar palabra clave`,
            dialogAsinLimitText: `Número máximo de palabras clave alcanzadas`,
            dialogAutoResponseUnsave: `Cancelar regla`,
            dialogAutoResponseUnsaveText: `Cualquier cambio que haya hecho a esta regla no se aplicará. ¿Estás seguro de que quieres cancelar?`,
            dialogAutoResponseUnsaveButton1: `volver a editar`,
            dialogAutoResponseUnsaveButton2: `sí, cancelar`,
            dialogNeedUpgrade: `Necesita actualizar`,
            dialogNeedUpgradeText: `Esta hazaña sólo está disponible para el plan Maestro Ninja`,
            dialogTryit: 'Try it now',
            dialogAsinAdded: `Se agregó Asin`,
            dialogAsinAddedText: `Asin se incorporó al mercado`,
            dialogEmailChange: `Ha cambiado el campo Enviar emails desde`,
            dialogEmailChangeText: `El correo electrónico debe ser el mismo que se utiliza para iniciar sesión en la cuenta de Amazon`,
            dialogOpenRate: `(¡Oh, no! , Parece...)`,
            dialogOpenRateText: `Usted debe comprobar su dirección de correo electrónico de amazon`,
            dialogDowngrade: `¿Estás seguro?`,
            dialogDowngradeText: `Vas a rebajar tu plan actual, ¿estás seguro? Algunas de las funciones no estarán disponibles para usted
                        después de la actualización`,
            dialogDowngradeSuccess: `ha sido degradado`,
            dialogDowngradeSuccessText: `Usted ha rebajado con éxito su plan. Esta actualización se ejecutará en las próximas 24 horas`,
            dialogResetTester: `Hey, es tu nuevo enlace`,
            dialogShortNameWarning: `No se ha establecido el nombre corto`,
            dialogShortNameWarningText1: `La variable Product Short name está definida pero no fue establecida`,
            dialogShortNameWarningText2: `Cuando no se establece un nombre corto, el sistema utilizará el título completo`,
            dialogShortNameWarningText3: `¡Configurar un nombre corto es fácil! Vaya a Ajustes-Ajustes del Producto y establezca
                                  un nombre corto para sus productos`,
            sendAnyway: `Enviar de todos modos`,
            dialogBulkAlert: `Preste atención`,
            dialogBulkAlertText: `Para cambiar los productos, cámbielos en la plantilla seleccionada`,
            dialogCancelPlan: `Cancelar Plan`,
            dialogCancelPlanText: `¿Estás seguro de que quieres cancelar el plan actual?`,
            dialogWentWrong: `Algo salió mal`,
            dialogWentWrongText: `Por favor, inténtalo de nuevo más tarde, o informa de un problema en la sección de contacto`,
            dialogCancelPlanSuccessText: `Te has dado de baja con éxito`,
            dialogOops: `OOPS`,
            dialogOopsText: `Tenemos algún problema, por favor cierra la sesión e inténtalo de nuevo`,
            dialogUnsubscribeText: `¿Estás seguro de que quieres darte de baja de los emails?`,
            dialogTrialHistoryText: `El sistema almacena 30 días de historial de transacciones para el período de prueba de 30 días.
                           Es posible que el pedido que buscas tenga más de 14 días de antigüedad.
                           Necesitará actualizar para obtener la característica completa ( 1 año de historia)`,
            continueWithPartialData: `Continuar con datos parciales`,
            dailogTrialHistory2: `La versión de prueba tiene un mes de historial - los suscriptores reciben un año.
                              Es posible que se muestren resultados parciales o vacíos`,
            dialogFbmMissProducts: `No hemos podido encontrar productos FBM en su cuenta, ¿está seguro de que no quería
                                crear una plantilla FBA?`,
            dialogFbmMissProductsButton1: `Take me to FBA templates`,
            dialogFbmMissProductsButton2: `Report FBM products missing`,
            contactUsTitle: `Nos encantaría saber de ti`,
            contactUsSubTitle1: `Comentarios, Sugerencias, Bugs - lo que te apetezca!`,
            contactUsSubTitle2: `Puede enviarnos un mensaje aquí o en`,
            yourName: `Tu nombre`,
            yourEmail: `Tu Correo Electronico`,
            yourMessage: `Tu mensaje`,
            errorContantEmpty: `El contenido no puede estar vacío`,
            errorValidFile: `Por favor, cargue primero un archivo válido`,
            couponNotAdded: `No se puede añadir cupón`,
            couponNotAddedText: `La extensión del archivo debe ser txt y el tamaño del archivo no puede exceder los 100kb`,
            uploadCoupon: `Subir Cupón`,
            addCoupon: `Añadir Cupones`,
            couponsName: `Nombre del cupón`,
            expirationDate: `Fecha de expiración`,
            errorValidExpDate: `Por favor seleccione una fecha de expiración válida`,
            onlyTxt: `Sólo se permiten archivos txt`,
            noDataYet: `Aún no hay datos`,
            profitCalc: `Cálculo de beneficios`,
            value: `Valor`,
            totalItemsSold: `Total de artículos vendidos`,
            totalNumberOrders: `Número total de órdenes`,
            pendingOrders: `Pending Orders`,
            totalFees: `Tarifas totales`,
            totalPromotions: `Total de Promociones`,
            PPCdeals: `Ofertas especiales y de PPC`,
            totalProductCost: `costoTotaldelProducto:`,
            promSummary: `Resumen de la promoción`,
            promName: `Nombre de la promoción`,
            redeemed: `Canjeado`,
            total: `Total`,
            promotions: `Promociones`,
            allPromFrom: `Todas las promociones de:`,
            toLowerCase: `a`,
            promValue: `Valor de Promoción`,
            showPromSummary: `Mostrar resumen de la promoción >`,
            plus: `+`,
            click: `Clic`,
            ordersTableText1: `en la orden para ver la versión de datos extendida `,
            totalProfit: `Beneficio Total`,
            promId: `ID de promoción de ventas`,
            charge: `Carga`,
            fee: `Tasa`,
            channel: `Canal`,
            productCost: `Coste del producto`,
            shippingPromotion: `Promoción de Envio`,
            promotion: `Promoción`,
            limitedAccess: `Acceso Limitado`,
            ninjaAccount: `Cuenta Ninja`,
            notDefined: `No definido`,
            amazonUK: `Amazon.co.uk`,
            amazonFR: `Amazon.fr`,
            amazonDE: `Amazon.de`,
            amazonIT: `Amazon.it`,
            amazonas: `Amazon.es`,
            amazonCOM: `Amazon.com`,
            amazonMX: `Amazon.co.mx`,
            amazonCA: `Amazon.ca`,
            amazonAU: `Amazon.com.au`,
            amazonJP: `Amazon.com.jp`,
            reset: `Reiniciar`,
            addShortNameEachChannel: `Añadir nombre corto para cada canal`,
            resetAll: `Restablecer todo`,
            errorEmptyExpense: `El nombre del gasto no puede estar vacío`,
            errorAmountNotNumber: `La cantidad debe tener un formato numérico`,
            currency: `Moneda`,
            errorMoreThan0Less100: `Debe ser más de 0 y menos de 100`,
            mbNumberOfRequests: `Número de solicitudes`,
            advOptions: `Opciones avanzadas`,
            content: `Contenido`,
            postCreatedTime: `Publicar hora de creación`,
            back: `Atrás`,
            select: `Seleccionar`,
            selectSpecProducts: `seleccionar productos específicos`,
            selectSpecProductsSub: `Seleccionar todos los productos específicos necesarios para esta plantilla`,
            TCText1: `QuantifyNinja.com es un servicio proporcionado por Shir Estate en Tel Aviv,
               Israel. Este acuerdo fue actualizado por última vez el 22 de junio de 2016.`,
            TCTitle2: `Descripción del Servicio`,
            TCText2: `Quantify Ninja es un servicio para vendedores de Amazon de terceros. Permite a estos vendedores enviar automáticamente
              notificaciones por correo electrónico a los clientes que realizan pedidos a través de Amazon. Un usuario puede
              personalizar qué pedidos reciben correos electrónicos automáticos y puede personalizar el contenido
              de esos mensajes y cuando se envían. El propósito de estos correos electrónicos es
              comunicarse de manera efectiva y automática con los clientes, principalmente para solicitar
              comentarios de Amazon y pedir de forma proactiva que se corrijan los problemas con ordenes de
              clientes antes de que dejen una retroalimentación negativa`,
            TCTitle3: `Elegibilidad`,
            TCText3: `Debes tener 18 años para usar el servicio. Usted también debe tener una cuenta activa de &quot;vendedor profesional&quot;
            de Amazon con el fin de utilizar el servicio. Además, usted
            debe completar el registro a través de MWS (Amazon Marketplace Web Service) y
            proporcionar las credenciales adecuadas. Al utilizar el servicio, usted garantiza que tiene
            permiso para acceder a la cuenta de vendedor de Amazon para la que se ha registrado`,
            TCTitle4: `Privacidad y Seguridad`,
            TCText4: `Tu privacidad y la seguridad de tu cuenta es muy importante para nosotros. Usamos encriptación en todas las
            áreas del sitio que contienen información sensible como los numeros de tarjeta de crédito,
             credenciales de cuenta o información de cliente.`,
            TCTitle5: `Propiedad intelectual`,
            TCText5: `Todos los logotipos, nombres de dominio, contenidos de páginas web y otros derechos de propiedad intelectual
               que vinculan a nuestros productos y servicios son propiedad exclusiva de Shir Estate. No copiar,
              imitar, modificar, alterar, enmendar o usar cualquier parte de la propiedad intelectual sin
              nuestro consentimiento previo por escrito. Usted no intentará aplicar ingeniería inversa al producto
              por el motivo que sea`,
            TITLE6: `Conducta de Usuario`,
            TCText6: `Al utilizar este servicio usted acepta cumplir con todas las leyes y regulaciones. Usted también se compromete
            a cumplir con`,
            TCAmazPolGuide: `Políticas y directrices de Amazon`,
            TCText7: `así como cualquier otra políticade amazon. Usted no puede usar nuestro servicio para cometer fraude u otro tipo de
            actividad ilegal, o abogar por la actividad ilegal. Usted no puede utilizar nuestro servicio para
            infringir o violar cualquier derecho de un tercero`,
            TCText8: `No utilizarás el servicio de ninguna manera que interfiera con el correcto funcionamiento del sitio web
            para cualquier otro usuario. No utilizarás ningún bot o araña web para acceder al
            servicio.`,
            TCText9: `Usted reconoce que podemos monitorear y medir el uso del sistema para asegurar el cumplimiento de las siguientes normas y
            condiciones. El incumplimiento de cualquiera de estas condiciones puede dar lugar a la
            cierre inmediato de su cuenta sin ningún tipo de reembolso o crédito`,
            TCTitle7: `Contraseñas y acceso a la cuenta`,
            TCTitle8: `Tasas y pagos`,
            TCTitle9: `Uso excesivo`,
            TCTitle10: `Abuso, Suspensión, Terminación`,
            TCTitle11: `Limitación de responsabilidad`,
            TCText10: `Mantendrá en todo momento la confidencialidad de sus nombres de usuario y contraseñas. En caso de que
               haya una violación de la seguridad a través de su cuenta, debe cambiar inmediatamente su contraseña.`,
            TCText11: `Usted acepta renunciar a todas las reclamaciones contra Shir Estate en caso de que la seguridad de su cuenta sea
              comprometida. Además, usted acepta indemnizar, defender y mantener a Shir Estate
              inofensivo en el caso de que Shir Estate incurra en daños a su propiedad intelectual
              u otros daños financieros debido a una violación de la confidencialidad`,
            TCText12: `Usted acepta pagar todos los cargos asociados con el uso del servicio. Todas las tarifas están expresadas en
              dólares estadounidenses. Usted debe proporcionar un número de tarjeta de crédito para utilizar el servicio
               en exceso de cualquiera de los servicios gratuitos
              cuentas. El pago se efectuará automáticamente en o alrededor del día indicado en el
              sitio web. En caso de que su pago falle o sea impugnado, podremos cobrarle
              pagos a través de otros medios, incluidas, entre otros, las agencias de cobro`,
            TCText13: `Shir Estate se reserva el derecho de limitar el número de mensajes enviados desde una cuenta si lo considera,
             en su única discreción, que una cuenta esté usando recursos excesivos`,
            TCText14: `Sin limitar nuestros otros recursos, podemos limitar, suspender o cancelar nuestro servicio y su
              cuenta a nuestra entera discreción, sin previo aviso, y por cualquier razón en el caso de que
              creemos que estás abusando de nuestros servicios, no sigues los terminos y
              condiciones de este Acuerdo, usted está creando problemas o potencial legal
              de responsabilidad, usted está infringiendo los derechos de propiedad intelectual de nosotros, cualquiera
              de nuestros afiliados, cualquier tercero, o usted está actuando en forma inconsistente con nuestras
              o el espíritu de nuestras políticas. En el caso de que Shir Estate limite,
                suspende, o termina su cuenta, todo el dinero pagado a Shir Estate no
              es reembolsable`,
            TCText15: `Bajo ninguna circunstancia, incluyendo pero no limitado a la negligencia, Shir Estate será responsable ante usted
              o cualquier otra persona o entidad por cualquier daño directo, indirecto, incidental, punitivo,
              daños especiales o consecuentes incluyendo pérdida de beneficios, pérdida de materiales,
              pérdida de contenido, pérdida de fondo de comercio, costes de adquisición de bienes de sustitución, y
              servicios, y daños a la propiedad, que resulten de (a) el uso de, o el
              el sitio web Quantify Ninja, o (b) la conducta o acciones de
              cualquier usuario del sitio web QuantifyNinja.com o cualquier otra persona o entidad, incluso
              si hemos sido advertidos de la posibilidad de tales daños. En el caso de que Shir
              Estate es responsable, Shir Estate es totalmente responsable ante usted por todos los daños,
              pérdidas y causas de acción, ya sea en el contrato, agravio (incluyendo pero no
              limitado a negligencia) o de otra manera no excederá la cantidad pagada por usted en
              los últimos tres (3) meses, si los hay, o $100, lo que sea menos. `,
            TCText16: `El sitio web QuantifyNinja.com y los servicios se proporcionan tal cual y sin garantía o
              condición, expresa, implícita o estatutaria. Shir Estate, sus proveedores y distribuidores,
              específicamente renuncian a todas las garantías implícitas de título, comerciabilidad, idoneidad
              para un propósito particular y de no infracción. `,
            TCText17: `Shir Estate se reserva el derecho de cancelar cuentas inactivas a nuestra sola discreción y sin
              aviso. Los clientes pueden cancelar el servicio en cualquier momento enviando un correo electrónico a nuestro cliente
              o haciendo clic en el enlace Contáctenos `,
            TCText18: `Aceptando este Acuerdo, usted acuerda indemnizar, defender y retener ilesas a Shir Estate y sus partes
              relacionadas de todos los reclamos, sentencias, costos, gastos, responsabilidades o tarifas de
              abogados `,
            TCText19: `La aplicación de este acuerdo será interpretada bajo las leyes de Israel. El lugar de celebración de cualquier
               acción legal se llevará a cabo en Tel Aviv, Israel`,
            TCTitle12: `Sin garantía`,
            TCTitle13: `Cancelación`,
            TCTitle14: `Indemnización`,
            TCTitle15: `Ley Aplicable`,
            iAgree: `Estoy de acuerdo`,
            privacyPolicy: `Política de privacidad`,
            PPText1: `Esta Política de Privacidad regirá todas las cuestiones de privacidad que puedan surgir en relación con su acceso a`,
            ninjaWebsite: `www.quantifyninja.com`,
            PPText2: `o el uso de su aplicación móvil (Quantify Ninja, App) y los servicios relacionados o los medios de comunicación
              social en este para obtener análisis de ventas de su cuenta Amazon y automatizar su campaña de marketing
               (los Servicios). Si no estás de acuerdo con esta política de privacidad, por favor no utilices los Servicios
                de Quantify Ninja o este sitio web`,
            PPText3: `Esta Política de Privacidad será parte de su acuerdo completo con Shir Estate LTD. Al acceder a este
              sitio web, descargar/instalar Quantify Ninja, vincular tu cuenta de Amazon a tu cuenta de Quantify Ninja, o
              utilizar cualquier servicio que se ofrece en este sitio web, Shir Estate LTD. tendrá derecho a recoger, procesar,
                almacenar, usar o compartir su información personal de acuerdo con esta política de privacidad.`,
            PPText4: `Sin embargo, esta Política de Privacidad no se aplicará a los sitios web de terceros ni a la
                  información que usted comparta en medios sociales o que obtenemos a través de fuentes fuera de línea.
                  Shir Estate LTD. se reserva el derecho de usar los perfiles y/o información fuera de línea para
                   su promoción comercial`,
            PPTitle1: `Lo que podemos recopilar`,
            PPText5: `Shir Estate LTD. recopilará el siguiente tipo de información en este sitio web:`,
            PPText6: `Información de registro, suscripción o cuenta como su nombre, número de contacto, dirección de correo electrónico,
            y dirección de la oficina, etc.`,
            PPText7: `Información de facturación como tarjeta de crédito, cuenta de suscripción de PayPal y dirección de facturación.`,
            PPText8: `Información relacionada con la actividad, como la suscripción comprada, predeterminados, incumplimientos, violaciones,
              disputas, arbitrajes, y otra información relacionada con su análisis de ventas o estrategias de marketing preferidas
                sobre
            Quantify Ninja.`,
            PPText9: `Información relacionada con eventos tales como intereses o respuesta a ofertas promocionales o eventos que sean
            organizado o patrocinado por Shir Estate LTD.`,
            PPText10: `Información de navegación como el tipo de navegador, sistema operativo, dirección IP, historial de búsqueda,
              páginas de entrada y salida, anuncios y enlaces en los que se ha hecho clic, tiempo total en el sitio web y otros
               detalles o metadatos relacionados con el uso que usted haga de los Servicios.
              Sin embargo, nunca utilizaremos ningún tipo de herramientas y técnicas automatizadas para recopilar sus datos de
               identificación.
              información sobre Quantify Ninja o este sitio web. Cualquier información que pueda ser utilizada o combinada con otras
            informaciónes para identificarlo de manera única se recopilará sólo cuando usted envíe voluntariamente la información
            para crear su cuenta, suscribirse a cualquier servicio, enviar sus comentarios o participar en actividades promocionales.
              eventos u ofertas`,
            PPTitle2: `Uso de Cookies`,
            PPTitle3: `Cómo podemos usar su información`,
            PPText11: `Shir Estate LTD. puede utilizar ciertas cookies y otras herramientas automatizadas para recoger su información no
              identificable. información sobre/a través de Quantify Ninja. Casi todos los sitios web utilizan cookies para recopilar
               de forma rutinaria cierta información no identificable como su dispositivo o tipo de hardware, sistema operativo,
                tipo de navegador, ajustes y preferencias de configuración, dirección IP, ISP, páginas de entrada o salida,
               marcas de tiempo, historial de búsqueda, comportamiento de navegación, y otros metadatos relacionados con su
                uso de los Servicios (colectivamente los Datos del Usuario).
              Esta información puede ser muy útil para resolver problemas técnicos, mejorar los Servicios, y
              el buen funcionamiento del sitio web.`,
            PPText12: `Por favor, tenga en cuenta que podemos utilizar servicios de terceros (Google AdMob, AdSense, Analytics, Facebook,
                etc.) para monetizar, analizar, evaluar y mejorar los Servicios en/a través de Quantify Ninja. Estos terceros
                 pueden emplear sus propias cookies para recopilar su información personal en relación con sus servicios
                o publicación de anuncios en/a través de Quantify Ninja. El uso de los Servicios de Quantify Ninja o de este
                 sitio web será considerado parte de su consentimiento a estas cookies de terceros`,
            PPText13: `Puede configurar su dispositivo o navegador para que rechace las cookies y las herramientas de seguimiento desde
               este sitio web.
              Sin embargo, usted debe entender que las cookies son necesarias para el buen funcionamiento de los Servicios y
              rechazarlas puede impedirle utilizar ciertas funciones o servicios de este sitio web `,
            PPText14: `Usaremos o compartiremos su información personal únicamente para hacer una presentación simple y directa
                de su análisis de ventas y ayudar en su marketing por correo electrónico a las audiencias objetivo. Para
                 ser más específicos, utilizaremos su información personal para:`,
            PPText15: `Consolidar y presentar sus análisis de ventas o de crecimiento en la forma estadística más comprensible.
                Comprender el perfil demográfico de sus clientes potenciales, consumidores o prospectos.
                Automatizar su campaña de marketing por correo electrónico, programando correos electrónicos y dirigiéndose
                 a los usuarios, y proporcionando análisis de compromiso.
                Rastrear sus preferencias y ofrecer servicios, información o contenido personalizado que pueda estar buscando.
                Envío de boletines o notificaciones sobre nuevas funcionalidades, servicios, estado de la suscripción, uso de la función
                o cualquier cambio en nuestros términos y condiciones, incluyendo esta Política de Privacidad.
                Recibir sus comentarios, resolver problemas técnicos y mejorar las características o servicios de este sitio web.
                Evaluando sus estrategias de marketing, optimizando los servicios y mejorando su experiencia general en
                Quantify Ninja. Obtener su consentimiento para cualquier propósito no mencionado en esta Política de Privacidad`,
            PPText16: `Todos los logotipos, nombres de dominio, contenidos de páginas web y otros derechos de propiedad intelectual que
                vinculan a nuestros productos. y servicios son propiedad exclusiva de Shir Estate. No me copiar,
                imitar, modificar, alterar, enmendar o usar cualquier parte de la propiedad intelectual sin
                nuestro consentimiento previo por escrito. Usted no intentará aplicar ingeniería inversa al producto
                por motivo alguno`,
            PPTitle4: `Uso compartido y divulgación`,
            PPTitle5: `Conducta del usuario`,
            PPTitle6: `Seguridad`,
            PPTitle7: `Opciones de privacidad`,
            PPText17: `Shir Estate LTD. se reserva el derecho de compartir su información personal con sus proveedores de servicios de
            confianza o otros terceros, que permiten analizar su información de ventas, realizan presentaciones estadísticas,
                y llegar a su público objetivo. Sin embargo, no revelamos, vendemos o alquilamos su información personal para
              propósitos de marketing directo a menos que usted dé su consentimiento para recibir ofertas exclusivas y promocionales
              de terceras empresas o compañías de marketing.
                Cuando comparta su información personal con los proveedores de servicios u otras terceras partes, no se le pedirá
                 información adicional.
                la información será divulgada excepto lo que puede ser requerido para que ellos realicen/entreguen sus servicios de
              una manera sin problemas. En caso de que divulguemos o compartamos cualquier dato de usuario con los proveedores de
               servicios u otros terceros
              los datos de usuario se agregarán y, cuando sea posible, se harán anónimos antes de compartirlos. Por favor
              reconoce que Shir Estate LTD. puede compartir los datos del usuario con proveedores de servicios u otros terceros
              para ayudarles a realizar ciertas funciones internas o facilitar la colocación de sus anuncios.
                Shir Estate LTD. también se reserva el derecho discrecional de revelar cualquier información, identidad personal,
                registros, comunicaciones o contenidos a las autoridades competentes u otros terceros cuando considere que
              dichas divulgaciones están obligadas a hacerlo:
                Evitar el acceso o las actividades no autorizadas en este sitio web.
                Proteger sus derechos comerciales o legales, o los derechos, propiedad, seguridad y bienestar de otros usuarios
              o el público en general.
                Cumplir con las leyes aplicables, citaciones, regulaciones gubernamentales o fallos de los tribunales.
                Cooperar con las agencias de investigación o hacer cumplir sus políticas y lineamientos para el uso de los Servicios.
                Facilitar la fusión, adquisición o transferencia de su negocio, en su totalidad o en parte, de manera fluida y sin
                 complicaciones.
                sin problemas`,
            PPText18: `Tu privacidad en este sitio web/Quantify Ninja es muy importante para nosotros. Hemos implementado los
               estándares de la industria
              procedimientos y protocolos de seguridad para asegurar que su privacidad en este sitio web nunca se vea comprometida.
               Sin embargo,
                el Servicio implica la transmisión internacional de datos a través de redes móviles e Internet.  Seguridad
              absoluta de las transmisiones de datos no es posible y usted deberá asumir todos los riesgos que pueda conllevar el
               uso de
              los Servicios a través de las redes móviles o de Internet.`,
            PPText19: `Todos los terceros que puedan acceder a su información personal estarán obligados a proteger y asegurar
              adecuadamente su información personal. Sin embargo, Shir Estate LTD. no será responsable de ninguna violación posterior
              de sus derechos de privacidad por parte de dichos terceros. Por favor, utilice los Servicios bajo su propio riesgo y
               discreción`,
            PPText20: `Shir Estate LTD. no será responsable de ninguna pérdida, daño, responsabilidad, reclamo o consecuencia que pudiera
              surgir por acceso no autorizado, violación de las transmisiones de datos, piratería informática, abuso, uso indebido,
               alteración, eliminación,
                o cualquier compromiso de su privacidad en Quantify Ninja o en este sitio web `,
            PPText21: `Puedes tomar las siguientes medidas para gestionar tu privacidad en Quantify Ninja:
                Puede modificar su cuenta de Amazon y eliminar cualquier información que no desee compartir con tus
              clientes existentes o potenciales.
                Usted puede configurar su dispositivo o navegador para que rechace las cookies y las herramientas de seguimiento
                 desde este sitio web.
                Puede darse de baja de nuestro servicio de newsletter y revocar su consentimiento de recibir ofertas promocionales
              de terceros en caso de que usted ya haya dado su consentimiento. Sólo tienes que seguir el enlace para darte de baja en el
              fondo de cualquier correo electrónico comercial.
                Usted puede cancelar su suscripción y descontinuar los Servicios. Su cuenta e información asociada
              en Quantify Ninja será eliminada permanentemente, excepto lo que podamos tener que retener debido a cualquier negocio.
                u obligaciones legales`,
            PPTitle8: `Sitios web de terceros`,
            PPTitle9: `Residentes de California`,
            PPTitle10: `Cumplimiento de COPPA`,
            PPTitle11: `Cambios a esta Política de Privacidad`,
            PPTitle12: `Cancelación`,
            PPText22: `Este sitio web/Quantify Ninja puede contener anuncios o enlaces a sitios web de terceros. Por favor
                   reconozca que nosotros no respaldamos, recomendamos ni renunciamos a ningún producto, servicio o
                   contenido que aparezca o esté disponible en los sitios web de terceros enlazados`,
            PPText23: `Shir Estate LTD. no será responsable de ninguna pérdida, daño, responsabilidad o reclamación que pueda ocurrir debido a
              cualquier violación de sus derechos de privacidad en relación con su acceso, confianza o uso de cualquier producto o servicio
              o el contenido que aparece en sitios web de terceros.`,
            PPText24: `Si usted se encuentra en California, puede solicitar a nuestro oficial de privacidad que pregunte a los terceros
              que tienen acceso a su información personal y solicitar los detalles de todos los terceros a quienes nosotros
              pudimos haber divulgado su información personal inmediatamente antes del año en curso. Sin embargo, podemos negar
              su solicitud si dicha divulgación no está permitida en virtud de las leyes o reglamentos aplicables `,
            PPText25: `Somos totalmente compatibles con la Ley de protección de la privacidad en línea de los niños o con
                   legislaciones similares de su país. jurisdicción. Los Servicios están destinados a usuarios
                   legalmente maduros. Si no ha alcanzado la mayoría de edad legal en su jurisdicción, se considerará
                   que usted está utilizando Quantify Ninja/Services bajo la licencia orientación y supervisión de
                    sus tutores legales. En cualquier caso, los menores de menos de 13 años no tienen permitido
                     el uso de los Servicios.`,
            PPText26: `Sin embargo, si usted sospecha que algún menor ha enviado su información personal en este sitio web,
            ya sea accidentalmente o de otra manera, por favor contacte a nuestro oficial de privacidad `,
            PPText27: `y haremos todos los esfuerzos comercialmente razonables para eliminar dicha información del sitio web/servidores.
                Sin embargo, puede llevar algún tiempo eliminar cualquier información de los menores de los servidores de copia
                 de seguridad.
                Shir Estate LTD. se reserva el derecho de eliminar cualquier y toda la información personal de menores en este sitio web
              a su entera discreción`,
            PPText28: `Shir Estate LTD. se reserva el derecho de editar, modificar o reemplazar esta Política de Privacidad en cualquier momento
              y en cualquier lugar.
              a su entera discreción. Si se modifica esta Política de Privacidad, publicaremos una actualización en esta Página
               y tomaremos las siguientes medidas
              esfuerzos comercialmente razonables para informarle, pero será su responsabilidad visitar regularmente
              este sitio web y estar al día. El acceso continuado a este sitio web o el uso de Quantify Ninja después de
              las modificaciones se considerarán como su consentimiento para cumplir con la última versión de esta Política de Privacidad`,
            PPText29: `Shir Estate se reserva el derecho de cancelar cuentas inactivas a nuestra sola discreción y sin
              aviso. Los clientes pueden cancelar el servicio en cualquier momento enviando un correo electrónico a nuestro cliente
              o haciendo clic en el enlace Contáctenos`,
            PPText30: `Si usted tiene alguna pregunta o preocupación con respecto a esta declaración de privacidad, por
                   favor envíe sus preguntas o comentarios a`,
            PPText31: `También puedes escribir a:`,
            PPText32: `Shir Estate LTD`,
            helpEmail: `help@quantifyninja.com`,
            businessDetails: `Detalles del negocio`,
            businessDetailsText: `Este sería el número de la empresa en la factura. El número de la empresa es su entidad jurídica
                        número de iva. Podría ser el iva de su empresa o su número de identificación personal`,
            addRegionTitle: `ESTÁS A UN SOLO PASO`,
            addRegionSubTitle: `Ya casi estás, ahora necesitamos permiso para acceder a tu cuenta de vendedor, por favor elige tu región`,
            choose: `Elegir`,
            NorthAmericaAccount: `Cuenta de América del Norte`,
            signOutHere: `Firme aquí`,
            orYouCan: `O puedes`,
            passwordTooltip: `La contraseña debe tener al menos 6 caracteres y contener dígitos(0-9), letras minúsculas (a-z) y
                    caracteres no alfanuméricos:`,
            errorMoreThan6: `Debería contener al menos 6 símbolos`,
            createNewPassword: `Crear nueva contraseña`,
            repeatPassword: `Repetir contraseña`,
            loginTitle: `Lo que su negocio electrónico necesita`,
            loginTitleText: `Nuestra majestuosa aplicación web le ofrece increíbles características para`,
            loginTitleText2: `¡controla tus ventas en Amazon al máximo, te sorprenderás!`,
            grantAccessTitle: `Crear un plan de facturación...`,
            grantAccessTitle2: `Redirigir a PayPal...`,
            grantAccessTitle3: `Comprobando los datos de tu cuenta`,
            grantAccessSubTitle4: `Debería llevar unos segundos...`,
            grantAccessTitle4: `Buenas noticias!`,
            grantAccessTitle5: `oops!`,
            grantAccessText1: `Hemos verificado con éxito tu cuenta, puedes empezar a usar Quantify Ninja.`,
            grantAccessText2: `Este ID de vendedor ya está siendo utilizado por un usuario diferente.`,
            getStarted: `Comenzar`,
            grantAccessTitle6: `¡Gracias!`,
            grantAccessText3: `Ahora estamos procesando tu pago, y pronto podrás usar las características pro-membresía!`,
            grantAccessText4: `Transacción completada con éxito. Redireccionando a la aplicación en...`,
            grantAccessText5: `Lo sentimos pero Paypal no aprobó su pago. Estado del pago: Prueba gratuita de 14 días`,
            grantAccessTitle7: `Un paso más`,
            grantAccessText6: `Terminar de añadir la región completando el pago`,
            currentRegions: `Regiones actuales:`,
            grantAccessText7: `Va a añadir 1 región: `,
            price: `Precio`,
            tokenUsedMessage: `Su token ha sido utilizado, inténtelo de nuevo por favor`,
            tokenExpiredMessage: `Su token ha caducado, inténtelo de nuevo por favor`,
            tokenNotExistMessage: `Su token no existe, inténtelo de nuevo por favor`,
            tokenInvalidMessage: `Su token no es válido, inténtelo de nuevo por favor`,
            facebookConnected: `Has conectado correctamente tu cuenta de facebook a la aplicación`,
            facebookConnectFailed: `Algo salió mal, por favor inténtalo de nuevo más tarde`,
            forgotPass: `¿Olvidó su contraseña?`,
            pressHere: `Pulse aquí`,
            swithToPrevInterface: `Cambiar a la interfaz anterior aquí`,
            firstName: `Primer nombre`,
            lastName: `Apellido`,
            and: `y`,
            loginHere: `Inicie sesión aquí`,
            ES: `ES`,
            RO: `RO`,
            ENG: `ENG`,
      };


      public textRO = {
            affiliatesTitle: `Afiliați`,
            TermsConditions: `Termeni & Condiții`,
            affiliateSubTitle: `Primești până la 35$ pentru fiecare afiliere realizată cu succes!`,
            affiliateLink: `Link-ul tău de afiliere:`,
            date: `Data`,
            homepage: `pagina de start`,
            coupons: `Cupoane`,
            uploadNewCouponCaps: `ÎNCARCĂ CUPON NOU`,
            couponSubTitle: `Vezi cupoanele tale și adaugă unele noi`,
            noCouponMsg: `Ești gata să începi să creezi cupoane noi`,
            pageNotFoundCaps: `PAGINA NU A FOST GĂSITĂ`,
            backTo: `Înapoi la`,
            pageNotFound: `Pagina nu a fost găsită`,
            noDataFound: `Nu au fost găsite date`,
            full: `Complet`,
            getOneCode: `Obține un cod`,
            check: `Verifică`,
            delete: `Șterge`,
            pending: `În așteptare`,
            notFound: `Nu a fost găsit`,
            add: `Adaugă`,
            asin: `Asin`,
            keyword: `Cuvânt cheie`,
            marketplace: `Marketplace`,
            registration: `Înregistrare`,
            searchAsinsByKeywords: `caută asin-uri folosind cuvinte cheie`,
            addRegion: `Adaugă regiune`,
            europianUnionAccount: `Cont Uniunea Europeană`,
            getAnotherLink: `Obține alt link`,
            AmazonJapanAccount: `Cont Amazon Japonia`,
            AmazonAustraliaAccount: `Cont Amazon Australia`,
            amazonRegions: `(Amazon.com, Amazon.ca, Amazon.co.mx)`,
            amazonRegions2: `(Amazon.uk, Amazon.de, Amazon.se, Amazon.it, Amazon.fr)`,
            amazonRegions3: `(Amazon.jp)`,
            amazonRegions4: `(Amazon.com.au)`,
            tableHeaders: {
                  earnedAmount: `Suma Câștigată`,
                  spot: `Loc`,
                  couponName: `Nume Cupon`,
                  couponsLeft: `Cupoane rămase`,
                  expiryDate: `Data Expirării`,
                  getCouponCode: `Obține cod cupon`,
                  CouponCode: `Cod Cupon`,
                  page: `Pagina`,
                  marketPlace: `Marketplace`,
                  userPublicID: `ID Utilizator Public`,
                  fullRegistration: `Înregistrare Completă`,
                  planType: `Tip Plan`,
                  agreementStatus: `Stare acord`,
                  lastUpdated: `Ultima actualizare`,
            },
            bulkTitle: `regulă nouă grup`,
            bulkSubTitle: `Trimite un email tuturor clienților care au făcut o achiziție într-o anumită perioadă.`,
            bulkSubLimit1: `Ești limitat la`,
            bulkSubLimit2: `emailuri pe săptămână. Dacă ai nevoie de mai mult credit, te rugăm`,
            contactUs: `Contactează-ne`,
            filters: `Filtre`,
            status: `Statut`,
            template: `Șablon`,
            from: `De la`,
            to: `Către`,
            bulkName: `Denumire în Bloc`,
            createNewBulk: `CREAZĂ BLOC NOU`,
            clearAll: `Șterge tot`,
            email: `Email`,
            sent: `trimis`,
            estimated: `estimat`,
            edit: `Editează`,
            editFbaTemplteTitle: `EDITEAZĂ ȘABLONUL FBA`,
            backFbaTemplate: `<  Înapoi la Șabloanele FBA`,
            editFbmTemplteTitle: `EDITEAZĂ ȘABLONUL FBM`,
            backFbmTemplate: `<  Înapoi la Șabloanele FBM`,
            createFbaTemplateTitle: `СREAZĂ ȘABLON FBA NOU`,
            createFbmTemplateTitle: `СREAZĂ ȘABLON FBM NOU`,
            success: `Succes`,
            fbmMissingProductsReport: `Sesizarea a fost trimisă, vom examina problema cât de curând posibil`,
            invalidFormat: `Format invalid`,
            imageLoadValidation: `Fișierul trebuie să fie de tip jpg, jpeg, png sau gif și nu poate fi mai mare de 2MB`,
            fail: `Eșuat`,
            emptyNameError: `Numele nu poate fi gol`,
            emptySubjectError: `Subiectul nu poate fi gol`,
            imageUploading: `Imaginea se încarcă`,
            imageUploadingMessage: `Te rugăm să salvezi șablonul înainte de încărca imagini de pe PC-ul tău`,
            name: `Nume`,
            subject: `Subiect`,
            when: `Când`,
            after: `După`,
            products: `Produse`,
            editTemplateAddVariableTitle: `Adaugă variabile`,
            editTemplateAddVariableSubTitle: `Pentru a afla mai multe despre variabile, apasă pe una anumită și va apărea în mesajul tău`,
            clickHere: `apasă aici`,
            product: `Produs`,
            order: `Comandă`,
            customer: `Client`,
            shipping: `Livrare`,
            templateOptions: `Opțiuni șablon`,
            templateOption1: `Alege starea pentru acest șablon`,
            templateOption2: `Trimite șablonul dacă un client a lăsat feedback negativ`,
            templateOption3: `Trimite șablonul dacă s-a făcut rambursarea comenzii`,
            templateOption4: `Trimite șablonul clienților repetați`,
            templateOption5: `Trimite șablonul dacă a fost aplicată o promoție`,
            templateOption6: `În cazul unei promoții, trimite șablonul când valoarea promoției`,
            templateOption7: `Selectează Anumite Canale de Vânzare`,
            templateOption8: `Adaugă un cupon la șablon`,
            allChannels: `Toate canalele`,
            editTemplateFileTitle: `Adaugă un fișier la șablon`,
            editTemplateFileSubtitle: `Dimensiunea totală a fișierelor nu poate fi mai mare de 7MB. Vezi toate formatele acceptate`,
            upload: `Încarcă`,
            cancel: `Anulează`,
            templateTestMessage: `Poți testa acest șablon pentru a vedea cum arată pentru clienți sau îl poți doar salva`,
            test: `Testează`,
            save: `Salvează`,
            fbaEdited: `Șablonul FBA a fost creat cu succes`,
            fbaCreated: `Șablonul FBA a fost editat cu succes`,
            fbmCreated: `Șablonul FBM a fost creat cu succes`,
            fbmEdited: `Șablonul FBA a fost editat cu succes`,
            deleteTemplate: `Șterge șablon`,
            copyTemplate: `Copiază șablon`,
            exportTemplates: `Exportă șabloane`,
            templateDeleted: `Șablonul a fost șters cu succes`,
            templateCopied: `Șablonul a fost copiat cu succes`,
            templatesExported: `Șablonul a fost exportat cu succes`,
            fbaTitle: `fba templates`,
            createNewFba: `CREAZĂ FBA NOU`,
            fbaSubTitle1: `Emailurile vor fi trimise de la`,
            fbaSubTitle2: `Dacă acesta nu este emailul asociat contului de amazon`,
            fbmTitle: `șabloane fbm`,
            account: `cont`,
            timeFrame: `Interval de timp`,
            event: `Eveniment`,
            salesChannel: `Canal vânzări`,
            exportToCSV: `Exportă în CSV`,
            openRate: `Rată de Deschidere`,
            templateName: `Nume șablon`,
            copy: `Copiază`,
            createNewFbm: `CREAZĂ FBM NOU`,
            clickHereToEdit: `Apasă aici pentru a edita`,
            couponCreatedTitle: `Cuponul a fost creat`,
            couponEdited: `Cuponul a fost editat cu succes`,
            couponCreated: `Cuponul a fost creat cu succes`,
            headerMessageSent: `Mesajul a fost trimis`,
            trialMessage1: `Perioada de probă de 14 de zile expiră în`,
            trialMessage2: `pentru a upgrada contul`,
            days: `zile`,
            upgrade: `Upgradează`,
            user: `Utilizator`,
            settings: `Setări`,
            default: `Implicit`,
            warning: `Avertisment`,
            dashboardNoDateMessage: `Prima dată trebuie să alegi datele de la și până la`,
            time: `Ora`,
            unitSold: `Unități Vândute`,
            noData: `Nu există date`,
            profit: `Profit`,
            profitNotInclude: `Profitul pentru anumite produse nu include PPC și cheltuielile legate de ofertele fulger`,
            emailsSent: `Emailurile au fost trimise`,
            revenue: `Venit`,
            unitsSoFar: `Unități până acum`,
            ordersLowerCase: `comenzi`,
            promotionLowerCase: `promoție`,
            calculate: `Calculează`,
            yesterday: `Ieri`,
            amazonSalesData: `Date vânzări Amazon`,
            zoom: `Zoom`,
            all: `Tot`,
            selectedProductsUpperCase: `PRODUSE SELECTATE`,
            daily: `Zilnic`,
            weekly: `Săptămânal`,
            conversationRate: `Rata de Conversie`,
            sessions: `Sesiuni`,
            last12Month: `(ultimele 12 luni)`,
            unitsReturned: `Unități returnate`,
            chartPopularProductsTitle: `Produse Populare`,
            selectRegion: `Selectează regiunea`,
            dashboard: `Dashboard`,
            automatedMessaging: `Mesaje automate`,
            FBA: `FBA`,
            FBM: `FBM`,
            bulk: `lot`,
            notifications: `Notificări`,
            moneyBack: `Returnarea Banilor`,
            hijackedListingViewer: `Vizualizare listări deturnate`,
            ordersAndBL: `Comenzi și lista neagră`,
            download: `Descarcă`,
            keywordOptimizer: `Optimizator tastatură`,
            facebookBotManager: `Manager Facebook bot`,
            conversationManager: `Manager Conversație`,
            autoResponse: `Răspuns automat`,
            subscribers: `Abonați`,
            menu: `Meniu`,
            asinTracker: `Urmărire ASIN`,
            superUser: `Super utilizator`,
            FAQQuestions: `Pentru orice întrebări sau dificultăți`,
            watchTutorials: `vizionează tutorialele`,
            inviteFriends: `Invită-ți prietenii`,
            autoResponseTitle: `Răspuns Automat Posturi Facebook`,
            createNewResponse: `crează răspuns nou`,
            autoResponseSubTitle1: `Câteva explicații scurte despre managerul de conversații și crearea boților`,
            autoResponseSubTitle2: `Poți șterge acest text dacă nu este necesar`,
            pageName: `Numele Paginii`,
            responseName: `Numele Răspunsului`,
            autoResponseText: `Ești pregătit pentru a începe să creezi conversații noi`,
            botMenuTitle: `Crează un Meniu`,
            botMenuSubTitle1: `Meniul poate fi setat pentru fiecare pagină și nu poate fi setat pentru o anumită conversație`,
            botMenuSubTitle2: `Poți adăuga maxim 2 meniuri de nivel superior și maxim 3 meniuri de al doilea și al treilea nivel`,
            page: `Pagină`,
            selectPage: `Selectează pagina`,
            botTopLevel: `Nivel Superior`,
            botSecondLevel: `Nivelul Al Doilea`,
            botThirdLevel: `Nivelul Al Treilea`,
            deleteMenu: `Șterge meniul`,
            commitMenu: `Menține meniul`,
            preview: `Previzualizare`,
            botMenuEditStep: `Editează pas`,
            botMenuDeleteStep: `Șterge pas`,
            botMenuDeleteLevel: `Șterge nivel`,
            action: `Acțiune`,
            botMenuAddSubLvl: `Adaugă subnivel`,
            botMenuAddNewStep: `Adaugă pas nou`,
            botMenuChooseAction: `Alege acțiune`,
            done: `Terminat`,
            botMenuTypeTitle: `Introdu titlul aici`,
            conversationManagerTitle: `Manager Conversație (Bot)`,
            createNewConversation: `crează conversație nouă`,
            triggerBy: `Declanșat de`,
            conversationManagerDirectLink: `Link direct la conversație`,
            botNewButton: `Buton nou`,
            botTriggerConversation: `Declanșează conversație`,
            unsubscribe: `Dezabonare`,
            subscribe: `Abonare`,
            delay: `Întârziere`,
            botTypingStatus: `Se scrie status`,
            botSelectConversation: `Selectează conversație`,
            botSetDelay: `Setează secundele de întârziere`,
            sec: `Sec`,
            typing: `Se tastează`,
            botSetSecons: `Setează secunde`,
            variable: `Variabilă`,
            coupon: `Cupon`,
            variables: `Variabile`,
            tag: `Etichetă`,
            botIconInside: `Iconiță interioară`,
            botNewTag: `adaugă etichetă nouă`,
            enterText: `Introdu text`,
            button: `Buton`,
            text: `Text`,
            uploadImage: `Încarcă imagine`,
            title: `Titlu`,
            subTitle: `Subtitlu`,
            botButtonTitle: `Titlu buton`,
            botWarningMessage: `Nu putem adăuga această componentă aici.`,
            payload: `Sarcină utilă`,
            getJson: `Obține Json`,
            start: `Start`,
            image: `Imagine`,
            fullScreen: `Întregul ecran`,
            resetTester: `Resetează tester`,
            withoutTesters: `Fără testeri`,
            tester: `Tester`,
            botLinkToConversation: `Link către această Conversație`,
            deleteAll: `Șterge Tot`,
            saveAll: `Salvează Tot`,
            favStore: `Magazin favorit`,
            botUnderConstructionTitle: `încă lucrăm la asta`,
            botUnderConstructionText1: `Managerul de Boți Facebook este aproape complet, mai trebuie doar să finisăm niște lucruri.`,
            botUnderConstructionText2: `Vor reveni curând cu cea mai bună caracteristică de Boți pe care ai văzut-o vreodată.
                                    Așteaptă doar puțin. Apreciem că ne înțelegi!`,
            botUnderConstructionText3: `Poți folosi botul și în interfața veche`,
            switch: `Schimbă`,
            botGrantAccessSubTitle: `Facebook Bot Manager is lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
  tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation
  ullamco aboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in`,
            botGrantAccessSubTitle1: `Poți vedea demoul aici`,
            grantAccess: `Acordă acces`,
            botGrantAccessPermissions: `Ce permisiuni ne trebuie`,
            botGrantAccessListTitle1: `Mesagerie pagini`,
            botGrantAccessListText1: `Pentru a putea trimite mesaje în numele tău`,
            botGrantAccessListTitle2: `Abonări la mesageria paginilor`,
            botGrantAccessListText2: `Pentru a putea trimite mesaje în numele tău după 24 de ore după ce utilizatorul s-a abonat.`,
            botGrantAccessListTitle3: `Afișarea listei paginilor`,
            botGrantAccessListText3: `Pentru a-ți permite să alegi paginile`,
            botGrantAccessListTitle4: `Administrarea paginilor`,
            botGrantAccessListText4: `Pentru a putea adăuga butonul “Începe” l serviciul tău de mesagerie`,
            botGrantAccessListTitle5: `Citirea mesajelor paginilor`,
            botGrantAccessListText5: `Pentru a-ți permite să setezi un răspuns automat la comentariile postului.`,
            subscribersTitle: `Lista de Abonați`,
            subscriberTotal: `Total Abonați:`,
            taggedWith: `Etichetați cu`,
            notTaggedWith: `NEetichetați cu`,
            conversaion: `Conversație`,
            broadcast: `Broadcast`,
            photo: `Imagine`,
            connectFacebookMessage: `Trebuie să îți conectezi contul de Facebook la aplicație pentru a putea folosi această caracteristică`,
            notificationsTitle: `Notificări Facebook messenger`,
            notificationsSubTitle: `Vă recomandăm să alegeți notificarea Telegram peste Facebook`,
            getFbInstructions: `Obțineți instrucțiuni pentru activare pe Facebook`,
            getTgInstructions:'Obțineți instrucțiuni pentru activare pe Telegram',
            notificationsFbAlertName: `Nume Alertă Facebook`,
            notificationsSetAlert: `Setează Alertă`,
            notificationsAlertOnProducts: `Alertă pe produse`,
            fbSalesNotificaion: `Notificare Vânzare Facebook`,
            notificationsOnEverySale: `Pentru fiecare vânzare`,
            notificationsFbNegative: `Notificare Feedback Negativ Facebook`,
            notificationsFbReview: `Notificare Recenzie Facebook`,
            notificationsOnlyMaster: `Disponibil doar pentru planul Master Ninja`,
            notificationsEmail: `notificări email`,
            alertName: `Nume Alertă`,
            notificationsText1: `Anunță-mă când primesc un nou feedback negativ`,
            notificationsText2: `Vreau să primesc updateuri zilnice despre câte emailuri au primit clienții mei`,
            notificationsText3: `Anunță-mă când un alt vânzător mi-a deturnat listarea`,
            notificationsReview: `Notificare Recenzie`,
            hjTitle: `Vizualizator listinguri deturnate`,
            hjSubTitle: `pentru a seta o notificare pentru când un nou vânzător se alătură listingului tău`,
            productName: `Nume Produs`,
            SKU: `SKU`,
            condition: `Stare`,
            numberOf: `Număr De`,
            newOffers: `Oferte Produs Nou`,
            usedOffers: `Oferte Produs Folosit`,
            hjViewOnAmazon: `Vizualizează pe Amazon`,
            inviteFriendsTitle: `Invită-ți Prietenii pe Quantify Ninja`,
            inviteFriendsSubTitle: `Pur și simplu adaugă adresa de email a prietenilor tăi și apasă \`Trimite Invitație\``,
            inviteFriendsSend: `Trimite Invitație`,
            keywordTitle: `Optimizator Cuvinte Cheie`,
            keywordSubTitle: `Identifică și înlătură automat duplicatele când introduci cuvinte cheie care există deja în titlu și descriere`,
            keywordDesired: `Cuvând cheie dorit`,
            keywordNumber: `Număr cuvinte`,
            keywordListing: `Textul listingului ( titlu, bullet points etc.)`,
            result: `Rezultat`,
            emailHasBeenSent: `Emailul a fost trimis cu succes`,
            errorMoreThan30: `Trebuie să fie mai mare de 30`,
            errorMoreThan0Less20: `Trebuie să fie mai mare de 0 și mai mic de 20`,
            mbTitle: `Rambursarea Banilor`,
            mbTab1: `Cereri de Modificare`,
            mbTab2: `Inventar Lipsă`,
            auto: `auto`,
            active: `Activ`,
            inactive: `Inactiv`,
            mbAmzAccount: `Cont Email Amazon`,
            mbDaysBtwRequests: `Zile între cereri`,
            mbNumbersOfReqOnSingle: `Număr de cereri într-un singur email`,
            mbWhatNext: `Ce urmează?`,
            mbWhatNextText1: `Dacă planul tău este Activ, vei primi emailuri în funcție de configurările tale.
                      Va trebui să copiez conținutul emailului într-un caz nou cu Amazon.`,
            mbWhatNextText2: `Te rugăm să reții că aceasta este versiunea Alfa a funcției, se poate să nu funcționeze conform așteptărilor.
                      Te îngurajăm să ne trimiți feedbackul tău.`,
            manual: `Manual`,
            mbOnSingleEmial: `Într-un singur email (max.20)`,
            mbMoneyBackGuarantee: `Garanția rambursării banilor`,
            mbGuaranteeText: `Garanția oferită de Amazon (în zile). Pentru cele mai multe categorii,
                      folosește 30, pentru categoria pentru bebeluși folosește 90`,
            mbOnlyAdjBefore: `Doar ajustările emise înainte`,
            mbOnlyAdjAfter: `Doar ajustările emise după`,
            send: `Trimite`,
            downloadTitle: `descarcă raport`,
            downloadSubTitle: `Retargetează-ți clienții de pe social media creând o audiență personalizată folosind următoarele date`,
            downloadExludeOrders: `Exclude comenzile promoționale`,
            downloadHistoryMessage: `Durează până la 30 de ore pentru a recupera istoricul de 1 an al contului tău`,
            refundTooltipMessage: `Comanda a fost rambursată în`,
            saveBeforeUploadMessage: `Te rugăm să salvezi șablonul înainte de a încărca imagini de pe PC-ul tău`,
            smTitle: `Trimite mesaj`,
            smBackToOrders: `Înapoi la Comezi`,
            emailExample: `example@gmail.com`,
            smExcludeOrder: `Exclude această comandă din șabloane viitoare`,
            smSendCopy: `Trimite o copie în Inboxul meu`,
            errorRequired: `Acest fișier este necesar`,
            templateFile: `Fișier Șablon`,
            optionChanged: `Opțiunea a fost schimbată`,
            ordersTitle: `Comenzi`,
            allTypes: `Toate tipurile`,
            buyerName: `Nume Cumpărător`,
            buyerEmail: `Email Cumpărător`,
            orderId: `ID Comandă`,
            search: `Caută`,
            ordersExcludeOrder: `Exclude această COMANDĂ din șabloane viitoare`,
            ordersExcludeBuyer: `Exclude acest CLIENT din șabloane viitoare`,
            sendMessage: `Trimite mesaj`,
            quantity: `Cantitate`,
            purchaseDate: `Data Achiziției`,
            shipmentDate: `Dată Expediere`,
            customerPhoneNumber: `Număr Telefon Client`,
            trackingNumber: `Numărul Trimiterii`,
            receipientName: `Nume Destinatar`,
            serviceLevel: `Nivel Serviciu`,
            shipAddress: `Adresa Expediere`,
            carrier: `Curier`,
            shipCity: `Oraș Expediere`,
            shipState: `Județ Expediere`,
            shipCountry: `Țară Expediere`,
            itemPrice: `Preț Produs`,
            itemTax: `Taxă Produs`,
            shippingPrice: `Cost Livrare`,
            shippingTax: `Taxă Livrare`,
            giftWrapPrice: `Preț Ambalare Cadou`,
            giftWrapTax: `Taxă Ambalare Cadou`,
            itemPromotionDiscount: `Disount Promoțional Produs`,
            shipPromotionDisc: `Discount Promoțional Livrare`,
            errorEmptyPass: `Parola este goală`,
            superLogInAS: `Loghează-te ca`,
            superCreateInvoice: `Crează factură`,
            superTab1: `Arată emailul prin Msg Id`,
            tab: `Filă`,
            superFillUsers: `Completează utilizatorii`,
            password: `Parola`,
            go: `ÎNCEPE`,
            upgTitle: `upgradează-ți planul`,
            premium: `Premium`,
            masteNinja: `Maste Ninja`,
            upgPayWith: `Plătește cu`,
            upgAddOnly: `adaugă doar`,
            upgIncluded: `Caracteristici Incluse`,
            downgrade: `Downgrade`,
            upgCurrentPlan: `plan actual`,
            upgDataTitle: `Instrumente de Date și Management`,
            upgDataText1: `Statistici`,
            upgDataText2: `Diagramă radială pentru vânzări, profit și retururi de la clienți`,
            upgDataText3: `Diagrame de comparație a performanțelor produselor`,
            upgDataText4: `Caută comenzile după nume, comandă și date`,
            upgDataText5: `Calcul Profit`,
            upgNotificationsText1: `Notificări în timp real despre vânzări pe Facebook și email`,
            upgNotificationsText2: `Notificări despre recenzii de produse pe Facebook și email`,
            upgNotificationsText3: `Notificări despre feedback negativ pe Facebook și email`,
            upgEmailTitle: `se trimite emailul`,
            upgEmailText1: `Email în masă`,
            upgEmailText2: `Emailuri nelimitate și șabloane către clienți`,
            upgEmailText3: `Deschide rata datelor`,
            upgEmailText4: `Atașare fișier la șabloane`,
            upgEmailText5: `Lista neagră a Clienților\\Comenzilor`,
            upgHjTitle: `deturnare și adăugare pe lista neagră`,
            upgHjText1: `Notificare listing deturnat`,
            upgHjText2: `Vizualizare listing deturnat`,
            upgOptimizationTitle: `instrumente de optimizare`,
            upgOptimizationText1: `Urmăritor ASIN – vezi poziția produselor tale folosind cuvinte cheie`,
            upgOptimizationText2: `Facebook bot – obține mai multe oportunități folosing conversații generate automat
                            pe Facebook și generează trafic spre paginile tale de Amazon `,
            upgAddingReg: `Adăugarea a 1 regiune - 15$/ lună`,
            upgYourCurrPlan: `Planul tău curent`,
            upgCancelPlan: `Anulează plan`,
            options: `opțiuni`,
            upgPriceText1: `Prețul implicit pentru planul Premium (cu 1 regiune) - $35/lună, și + $15/lună pentru fiecare regiune suplimentară`,
            upgPriceText2: `Prețul implicit pentru planul Master Ninja (cu 1 regiune) - $55/lună, și + $15/lună pentru
                    fiecare regiune suplimentară`,
            upgPlanVariations: `Variații ale planurilot disponibile`,
            upgPrem1Reg: `Premium cu 1 regiune`,
            upgPrem2Reg: `Premium cu 2 regiuni`,
            upgPrem3Reg: `Premium cu 3 regiuni`,
            upgJoinMaster: `Abonează-te la planul Master Ninja`,
            notice: `Notificare`,
            upgNoticeText1: `Odată abonat la`,
            upgNoticeText2: `vechiul plan va fi anulat automat. Prima plată va fi procentul din cei $20 în plus pentru
                         această perioadă. Vei fi taxat suma de 55$ la începutul următoarei perioade.`,
            termsAndCond: `Termeni și Condiții`,
            checkOur: `Verifică`,
            forMoreInfo: `pentru mai multe informații`,
            settingsAccTitle: `Setări cont`,
            settingsAccSubTitle: `Setează informațiile profilului, ale afacerii, moneda și planul de abonament`,
            settingsAccExpoDate: `Valid până la anulare`,
            errorIncorectEmail: `Formatul emailului nu este corect`,
            errorIncorectPassFormat: `Formatul parolei nu este corect`,
            errorPassNotMath: `Parolele nu se potrivesc`,
            emailAddress: `Adresa de email`,
            sendEmailFrom: `Trimite email de la`,
            confirmPasswod: `Confirmă parola`,
            businessName: `Numele Firmei`,
            businessRegNum: `Numărul de Înregistrare al Firmei`,
            businessAddress: `Adresa Firmei`,
            defaultCurrency: `Moneda Implicită`,
            linkedRegs: `Regiuni Legate`,
            subscrPlan: `Planul de Abonament`,
            expiration: `Expirare`,
            expenseTitle: `Cheltuieli`,
            expenseSubTitle: `Vizualizează cheltuielile legate de PPC și ofertele fulger sau adaugă cheltuieli personalizate`,
            addExpense: `Adaugă cheltuială`,
            expenseAdded: `Cheltuiala a fost adăugată cu succes`,
            editExpense: `Editează cheltuială`,
            expenseEdited: `Cheltuiala a fost editată cu succes`,
            deleteExpense: `Șterge cheltuială`,
            expenseDeleted: `Cheltuiala a fost ștearsă cu succes`,
            amount: `suma`,
            productsVisibleTooltip: `Mod Vizibil – produsul poate fi selectat oriunde în aplicație`,
            productsInvisibleTooltip: `Mod Invizibil – vom încerca să ascundem aces produs de peste tot din aplicație`,
            productsTitle: `Setări produs`,
            productsSubTitle: `Setează nume scurte ale produselor și selectează culorile în care să fie prezentate în grafice`,
            editProduct: `Editează produs`,
            productEdited: `Produsul a fost editat cu succes`,
            hide: `Ascunde`,
            shortName: `Nume scurt`,
            displayedName: `Nume afișat`,
            productAsin: `ASIN Produs`,
            productsPerCost: `Cost per unitate`,
            productsSettings: `Setări produs`,
            editResponse: `Editează răspuns`,
            errorPostNotSelected: `Postul trebuie să fie selectat`,
            autoRespApplyOnly: `Aplică doar răspunsului direct`,
            ruleName: `Nume Regulă`,
            message: `Mesaj`,
            errorPageNotSelected: `Pagina trebuie să fie selectată`,
            selectPost: `Selectează un post`,
            postPreview: `Previzualizează post`,
            required: `Necesar`,
            errorTemplateNotSelected: `Un șablon trebuie să fie selectat`,
            editBulk: `Editează în bloc`,
            anyOfYourProducts: `Oricare dintre produsele tale`,
            bulkDialogText1: `Trimite acest șablon tuturor clienților care au cumpărat unul din următoarele produse`,
            bulkDialogText2: `Trimite acest șablon tuturor clienților care au cumpărat`,
            bulkDialogTimeframe: `în această perioadă`,
            bulkDialogEmailEst: ` Emailuri estimate (numărul emailurilor planificate)`,
            count: `Număr`,
            set: `Setează`,
            exportCSV: `exportă în CSV`,
            exportCSVText: `Fișierul va fi trimis pe adresa ta de email`,
            errorEmailInUse: `Acest email este deja folosit`,
            ok: `ok`,
            dialogDeleteTemplate: `Ștergi Acest Șablon?`,
            dialogDeleteTemplateText: ` Ești sigur că vrei să ștergi acest șablon ?`,
            yesDelete: `da, șterge`,
            resetPass: `Resetează parola`,
            resetPassText1: `Un link pentru resetarea parolei a fost trimis pe adresa de email. Te rugăm să verifici inboxul și să ții cont
  de faptul că din când în când emailurile pot ajunge în fișierul de junk/spam.`,
            resetPassText2: `Nu ai primit emailul? Asigură-te că ai folosit adresa de pe QuantifyNinja. Dacă ai probleme,
  te rugăm să contactezi `,
            supportEmail: `support@quantifyninja.com`,
            dialogGoToLogin: `mergi la logare`,
            signUp: `înregistrare`,
            signUpSuccess: `Te-ai înregistrat cu succes!`,
            dialogUnsaved: `Ai schimbări nesalvate`,
            dialogUnsavedText: `Ești sigur că vrei să părăsești această pagină?`,
            yes: `Da`,
            no: `Nu`,
            dialogStatus: `Alege statusul pentru acest șablon`,
            dialogStatusText1: `Conform setărilor, doar șabloanele active vor fi trimise clienților.`,
            dialogStatusText2: `Șabloanele inactive nu vor fi trimise sub nici o setare.`,
            gotIt: `Am înțeles`,
            dialogNegativeFeedback: `Trimite șablonul dacă un client a lăsat un feedback negativ`,
            dialogNegativeFeedbackText: `Alegerea variantei "Nu" va exclude clienții care au lăsat feedback negativ.`,
            dialogRefundOrder: `Trimite șablonul dacă o comandă a fost rambursată`,
            dialogRefundOrderText: `Șablonul va fi trimis în cazul în care comanda a fost rambursată.`,
            dialogRepBuyers: `Trimite șablonul clienților repetați`,
            dialogRepBuyersText: `Alegerea acestei opțiuni va exclude trimiterea șablonului care au cumpărat acest
  produs în trecut. Dacă un client a cumpărat același produs de mai multe ori, va primi
  acest șablon o singură dată. Reține că dacă același client cumpără un produs diferit, atunci
  va primi din nou acest șablon.`,
            dialogPromotionGiven: `Trimite șablonul dacă se aplică vreo promoție`,
            dialogPromotionGivenText: `Șablonul va fi trimis dacă este aplicată vreo promoție.`,
            dialogPromotionValue: `În cazul unei promoții, trimite șablonul când valoarea promoției`,
            dialogPromotionValueText: `Setând această opțiune, poți exclude acest email dacă clientul a primit o promoție
                                   mai mare//egală//mai mică de o anumită sumă. Reține că comenzile fără promoții nu
                                   vor fi excluse.`,
            dialogPassChanged: `Parola a fost schimbată`,
            dialogPassChangedText: `Parola a fost schimbată cu succes`,
            dialogSpecSalesChannel: `Selectează Canalele de Vânzare Specifice`,
            dialogSpecSalesChannelText: `Această opțiune îți dă posibilitatea să selectezi anumite canale prin care să fie trimis șablonul.`,
            dialogAddCoupon: `Adaugă cupon la șablon`,
            dialogAddCouponText1: `Cupoanele pot fi adăugate automat la șablon. Pentru a adăuga un cupon trebuie să adaugi
  "etichetă cupon" în șablon.`,
            dialogAddCouponText2: `Pentru a adăuga eticheta cuponului: Selectează cuponul din lista derulantă din partea stângă.
                              Odată selectat, vei vedea eticheta cuponului pe care trebuie să o adaugi apoi la șablon.
                              Copiază numele etichetei și plaseaz-o în șablon.`,
            dialogAddCouponText3: `În momentul în care este trimis un mail cu acel șablon, un cupon va fi introdus din lista
                               cupoanelor încărcate.`,
            dialogAddCouponText4: `dacă nu mai sunt cupoane în lista încărcată, emailul cu acest șablon nu va fi trimis.`,
            dialogBulk: `regulă nouă în bloc`,
            dilogBulkText1: `Amazon limitează numărul de emailuri pe care le puteți trimite cumpărătorilor într-o zi.`,
            dilogBulkText2: `Limita este bazată pe volumul zilnic de comenzi. Nu poți trimite un număr de mesaje care să depășească de 5 ori
  media comenzilor zilnice, plus încă 600 de mesaje.`,
            dilogBulkText3: `De exemplu, dacă media comenzilor este de 100 pe zi, poți trimite în orice zi (5 x 100) + 600 =
  1100 mesaje.`,
            dialogVaiable: `Adaugă variabile`,
            dialogVaiableText1: `Variabilele sunt câmpuri ale șablonului și sunt populate în timp real conform informațiilor
                            specifice ale fiecărui client sau comandă.`,
            dialogVaiableText2: `De exemplu, pentru a începe un email cu un salut personal, folosește`,
            dialogVaiableText3: `‘Salut &lt;customer-first-name&gt;’`,
            dialogVaiableText4: `în șablonul tău.`,
            dialogVaiableText5: `Variabila`,
            dialogVaiableText6: `&lt;customer-first-name&gt;`,
            dialogVaiableText7: `va fi înlocuită în timp real cu Ion, Maria, Cristina și așa mai departe… în funcție de prenumele
  clientului de pe comandă.`,
            confirm: `confirmă`,
            dialogConfirmTemplate: `Confirmă Șablon`,
            dialogConfirmTemplateText1: `Status șablon:`,
            dialogConfirmTemplateText2: `Va fi trimis`,
            dialogConfirmTemplateText3: `cât de curând posibil`,
            dialogConfirmTemplateText4: `zile după`,
            dialogConfirmTemplateText5: `pentru clienții care au cumpărat`,
            dialogConfirmTemplateText6: `următoarele produse:`,
            dialogConfirmTemplateText7: `oricare dintre produsele tale FBA (inclusiv produse care vor fi adăugate în viitor)`,
            dialogAddFile: `Formate atașament`,
            dialogAddFileText1: `Fișiere text (.txt)`,
            dialogAddFileText2: `PDF (Adobe Portable Document Format)`,
            dialogAddFileText3: `Documente Word (.doc and .docx)`,
            dialogAddFileText4: `Fișiere imagini (.jpg, .gif, .tiff, .bmp, and .png)`,
            dialogBulkNoedit: `editează în bloc`,
            dialogBulkNoeditText: `Regula în bloc nu poate fi editată după executare sau în timpul procesării`,
            dialogEditEmail: `Editează Email`,
            dialogEditEmailText: `Emailul de pe contul tău de Amazon:`,
            setSender: `Setează expeditor`,
            login: `Logare`,
            dilogOnHold: `Contul tău este în așteptare, te rugăm să contactezi`,
            dialogRegion1: `Cont Amazon US`,
            dialogRegion1Text: `(Amazon.com, Amazon.ca, Amazon.co.mx)`,
            dialogRegion2: `Cont Uniunea Europeană`,
            dialogRegion2Text: `(Amazon.uk, Amazon.de, Amazon.se, Amazon.it, Amazon.fr)`,
            dialogRegion3: `Cont Amazon Japonia`,
            dialogRegion3Text: `(Amazon.jp)`,
            dialogRegion4: `Cont Amazon Australia`,
            dialogRegion4Text: `(Amazon.com.au)`,
            dialogPermossionDenied: `Această caracteristică nu este disponibilă`,
            dialogPermossionDeniedText: `Trebuie să upgradezi abonamentul pentru a putea accesa această pagină.`,
            dialogInstructions: `OBȚINE INSTRUCȚIUNI`,
            dialogInstructionsText1: `Deschide un Facebook Messenger, folosind linkul următor`,
            dialogInstructionsText2: `Pagina de chat Quantify  Ninja.`,
            dialogInstructionsText3: `Apasă butonul «Începe» pentru a activa chatul, copiază codul următor în chat și apasă Trimite `,
            copiedToClipboard: `Copiat în clipboard`,
            dialogGetJsonText: `Când începi o campanie pe messenger, trebuie să îi spui programului Facebook ce să arate în momentul în care
                        utilizatorul apasă pe reclamă. Acest lucru poate fi făcut folosind codul Json. Copiază codul următor în tabul
                        Json din setările reclamelor Facebook:`,
            dialogOrderBL: `LISTA NEAGRĂ A COMENZILOR`,
            dialogOrderBLText: `Comenzile din această listă nu vor mai primi emailuri definite de șabloane, totuși
  clientul poate primi șabloanele tale pentru comenzi diferite `,
            dialogBuyerBL: `LISTA NEAGRĂ A CUMPĂRĂTORILOR`,
            dialogBuyerBLText: `Cumpărătorii de pe această listă nu vor primi nici un email definit de șabloanele tale pentru nici o comandă
                        anterioară sau viitoare.`,
            dialogCommitMenu: `Crează și șterge un meniu`,
            dialogCommitMenuText1: `Crearea unui meniu`,
            dialogCommitMenuText2: `se va reflecta imediat pe pagina botului. Meniul va apărea pe toate conversațiile de pe această pagină.
                            Nu poate fi setat pentru fiecare conversație.`,
            dialogCommitMenuText3: `Ștergerea unui meniu`,
            dialogCommitMenuText4: `va avea efect imediat pe pagina botului. Meniul va fi șters din toate conversațiile de pe această pagină`,
            dialogSessionExp: `Trebuie să te loghezi din nou`,
            dialogSessionExpText: `Sesiunea curentă a expirat`,
            dialogDownload: `descarcă raport`,
            dialogDownloadText: `Nu au fost găsite rezultate`,
            dialogDownloadFailText: `Generarea raportului a eșuat, te rugăm să reîncerci și să ne contactezi dacă acest lucru se mai întâmplă`,
            dialogAsinLimit: `Adaugă cuvânt cheie`,
            dialogAsinLimitText: `A fost atins numărul maxim de cuvinte cheie`,
            dialogAutoResponseUnsave: `Anulează regulă`,
            dialogAutoResponseUnsaveText: `Schimbările pe care le-ai făcut acestei reguli nu vor fi aplicate. Ești sigur că vrei să anulezi ?`,
            dialogAutoResponseUnsaveButton1: `înapoi la editare`,
            dialogAutoResponseUnsaveButton2: `da, anulează`,
            dialogNeedUpgrade: `Upgrade necesar`,
            dialogNeedUpgradeText: `Această caracteristică este disponibilă doar pentru planul Master Ninja`,
            dialogAsinAdded: `ASIN-ul a fost adăugat`,
            dialogAsinAddedText: `ASIN-ul a fost adăugat la canalul de vânzare`,
            dialogEmailChange: `Ai schimbat câmpul "Trimite emailuri de la"`,
            dialogEmailChangeText: `Emailul trebuie să fie același cu care te loghezi în contul de Amazon`,
            dialogOpenRate: `("Oh, nu! , Se pare ....")`,
            dialogOpenRateText: `Ar trebui să verifici emailul contului de Amazon`,
            dialogDowngrade: `Ești sigur?`,
            dialogDowngradeText: `Planul curent va fi retrogradat, ești sigur? Unele caracteristici nu vor mai fi disponibile
                              după retrogradare.`,
            dialogDowngradeSuccess: `ai fost retrogradat`,
            dialogDowngradeSuccessText: `Ți-ai retrogradat cu succes abonamentul. Acest update va fi efectuat în următoarele 24 de ore.`,
            dialogResetTester: `Hey, este noul tău link`,
            dialogShortNameWarning: `Numele scurt nu a fost setat`,
            dialogShortNameWarningText1: `Variabile Numele Scurt al Produsului este definită dar nu a fost setată.`,
            dialogShortNameWarningText2: `Atunci când numele scurt nu este setat, sistemul folosește titlul complet.`,
            dialogShortNameWarningText3: `Setarea unui nume scurt este ușoară! Mergi la Setări – Setări Produse și setează un
                                  nume scurt pentru produsele tale`,
            sendAnyway: `Trimite oricum`,
            dialogBulkAlert: `Atenție`,
            dialogBulkAlertText: `Pentru a schimba produse, te rugăm să le schimbi în șablonul selectat`,
            dialogCancelPlan: `Anulare abonament`,
            dialogCancelPlanText: `Ești sigur că vrei să anulezi abonamentul curent?`,
            dialogWentWrong: `Ceva nu a mers bine`,
            dialogWentWrongText: `Te rugăm să încerci mai târziu sau să ne raportezi problema în secțiunea de contact`,
            dialogCancelPlanSuccessText: `Ai fost dezabonat cu succes`,
            dialogOops: `UPS`,
            dialogOopsText: `Avem o problemă, te rugăm să te deloghezi și să mai încerci o dată`,
            dialogUnsubscribeText: `Ești sigur că vrei să dezabonezi emailurile?`,
            dialogTrialHistoryText: `Sistemul stochează 30 de zile din istoricul tranzacțiilor pentru perioada de probă de 30 de zile.
  Este posibil ca comanda pe care o cauți să fie mai veche de 14 de zile.
  Trebuie să upgradezi pentru a beneficia de caracteristica completă ( istoricul pentru 1 an)`,
            continueWithPartialData: `continuă cu date parțiale`,
            dailogTrialHistory2: `Versiunea de probă are istoricul pentru 1 luna – abonații beneficiază de 1 an.
                          Pot fi afișate rezultate parțiale sau goale.`,
            dialogFbmMissProducts: `Nu am putut găsi produse FBM în contul tău, ești sigur că nu ai dorit să creezi un șablon FBA ?`,
            dialogFbmMissProductsButton1: `Du-mă la șabloanele FBA`,
            dialogFbmMissProductsButton2: `Raportul pentru produsele FBM lipsește`,
            contactUsTitle: `Ne Bucurăm Să Ne Contactezi`,
            contactUsSubTitle1: `Trimite-ne Feedback, Sugestii, Erori – orice vrei!`,
            contactUsSubTitle2: `Ne poți trimite un mesaj aici sau la`,
            yourName: `Numele Tău`,
            yourEmail: `Adresa Ta de Email`,
            yourMessage: `Mesajul Tău`,
            errorContantEmpty: `Conținutul nu poate fi gol`,
            errorValidFile: `Te rugăm să încarci un fișier valid prima dată`,
            couponNotAdded: `Cuponul nu poate fi adăugat`,
            couponNotAddedText: `Extensia fișierului trebuie să fie txt iar dimensiunea fișierului nu poate fi mai mare de 100kb`,
            uploadCoupon: `Încarcă Cupon`,
            addCoupon: `Adaugă Cupoane`,
            couponsName: `Nume Cupoane`,
            expirationDate: `Data Expirării`,
            errorValidExpDate: `Te rugăm să selectezi o dată de expirare validă`,
            onlyTxt: `Sunt permise doar fișiere txt`,
            noDataYet: `Încă nu există date`,
            profitCalc: `Calcul profit`,
            value: `Valoare`,
            totalItemsSold: `Număr Total de Produse Vândute`,
            totalNumberOrders: `Număr Total de Comenzi`,
            pendingOrders: `Comenzi în Așteptare`,
            totalFees: `Total Comisioane`,
            totalPromotions: `Total Promoții`,
            PPCdeals: `PPC & Oferte Fulger`,
            totalProductCost: `totalProductCost:`,
            promSummary: `Rezumat promoție`,
            promName: `Nume promoție`,
            redeemed: `Revendicate`,
            total: `Total`,
            promotions: `Promoții`,
            allPromFrom: `Toate Promoțiile de la:`,
            toLowerCase: `până la`,
            promValue: `Valoare Promoție`,
            showPromSummary: `Afișează rezumatul promooției >`,
            plus: `+`,
            click: `Apasă`,
            ordersTableText1: `pe comandă pentru a vedea datele complete`,
            totalProfit: `Profit total`,
            promId: `ID Promoție`,
            charge: `Încasat`,
            fee: `Comision`,
            channel: `Canal`,
            productCost: `Cost Produs`,
            shippingPromotion: `Promoție Livrare`,
            promotion: `Promoție`,
            limitedAccess: `Acces Limitat`,
            ninjaAccount: `Cont Ninja`,
            notDefined: `Nedefinit`,
            amazonUK: `Amazon.co.uk`,
            amazonFR: `Amazon.fr`,
            amazonDE: `Amazon.de`,
            amazonIT: `Amazon.it`,
            amazonES: `Amazon.es`,
            amazonCOM: `Amazon.com`,
            amazonMX: `Amazon.co.mx`,
            amazonCA: `Amazon.ca`,
            amazonAU: `Amazon.com.au`,
            amazonJP: `Amazon.com.jp`,
            reset: `Resetează`,
            addShortNameEachChannel: `Adaugă un nume scurt pentru fiecare canal`,
            resetAll: `Resetează tot`,
            errorEmptyExpense: `Numele cheltuielii nu poate fi gol`,
            errorAmountNotNumber: `Suma trebuie să fie un număr`,
            currency: `Monedă`,
            errorMoreThan0Less100: `Trebuie să fie mai mare de 0 și mai mic de 100`,
            mbNumberOfRequests: `Numărul de cereri`,
            advOptions: `Opțiuni avansate`,
            content: `Conținut`,
            postCreatedTime: `Ora creării postului`,
            back: `Înapoi`,
            select: `Selectează`,
            selectSpecProducts: `selectează anumite produse`,
            selectSpecProductsSub: `Selectează toate produsele necesare pentru acest șablon`,
            TCText1: `QuantifyNinja.com este un serviciu furnizat de către Shir Estate în Tel Aviv,
                  Israel. Acest acord a fost actualizat ultima dată la data de 22 Iunie 2016.`,
            TCTitle2: `Descrierea Serviciului `,
            TCText2: `Quantify Ninja este un serviciu destinat vânzătorilor de pe Amazon. Le permite acestor vânzători să trimită
              emailuri automate clienților care au pus comenzi prin intermediul Amazon. Utilizatorul poate
              personaliza care comenzi primesc emailuri automate și poate personaliza conținutul
              acestor mesaje și când sunt trimise. Scopul acestor emailuri este de a
              comunica eficient și automat cu clienții, în principal pentru a cere
              feedback pe Amazon și de a încerca rezolvarea potențialelor probleme ale clienților'
              înainte ca aceștia să lase feedback negativ.`,
            TCTitle3: `Eligibilitate`,
            TCText3: ` Trebuie să ai 18 ani pentru a putea folosi acest serviciu. De asemenea, trebuie să ai un cont activ
                de Vânzător &quot; Profesionist Amazon &quot; pentru a putea folosi serviciul. În plus, trebuie
                să finalizezi înregistrarea prin MWS (Amazon Marketplace Web Service) și să
                furnizezi datele de conectare corespunzătoare. Folosind acest serviciu, declari că ai
                permisiunea să accesezi contul de Vânzător Amazon pentru care te înregistrezi.`,
            TCTitle4: `Confidențialitate și Securitate`,
            TCText4: `Confidențialitatea și securitatea contului tău este foarte importantă pentry noi. Folosim criptare în toate
                 zonele siteului care conțin informații sensibilie cum ar fi numere
                 de card, date de înregistrare ale contului, sau informațiile clienților.`,
            TCTitle5: `Proprietate Intelectuală`,
            TCText5: `Toate logourile, numele domeniilor, conținutul siteului și altă proprietate intelectuală care
               are legătură cu produsele și serviciile
              noastre sunt proprietatea exclusivă a Shir Estate. Nu ai dreptul să copiezi,
              să imiți, să modifici, să schimbi sau să folosești nici una din aceste proprietăți intelectuale fără
              acord în scris prealabil. Nu ai dreptul să folosești ingineria inversă asupra produsului
              sub nici o formă.`,
            TCTitle6: `Comportamentul Utilizatorului`,
            TCText6: `Prin folosirea acestui serviciu ești de acord să respecți toate regile și regulile. De asemenea, ești de acord să
                 respecți`,
            TCAmazPolGuide: `Politicile și Instrucțiunile Amazon`,
            TCText7: `precum și alte politici ale Amazon. Este interzisă utilizarea serviciului pentru fraudă sau alte
                  activități ilegale sau pentru promovarea activităților ilegale. Este interzisă utilizarea serviciului nostru pentru
                  a încălca orice drept al unei terțe părți.`,
            TCText8: `Este interziă folosirea serviciului în orice mod care ar putea interfera cu buna funcționare a
                  Siteului pentru alți utilizatpri. Este interzisă folosirea unui robot de căutare pentru a accesa
                  serviciul.`,
            TCText9: `Accepți faptul că putem monitoriza și măsura utilizarea sistemului pentru a ne asigura de respectarea
                  acestor condiții. Nerespectarea oricărei din aceste condiții poate duce la
                  închiderea imediată a contului, fără rambursări monetare.`,
            TCTitle7: `Parole și Acces în Cont`,
            TCTitle8: `Comisioane și Plăți`,
            TCTitle9: `Utilizare Excesivă`,
            TCTitle10: `Abuz, Suspendare, Încheiere`,
            TCTitle11: `Limitarea Răspunderii`,
            TCText10: `Vei menține permanent confidențialitatea numelor de utilizator și a parolelor. În cazul unei
  probleme de securitate legată de contul tău, trebuie să schimbi parola imediat.`,
            TCText11: `Ești de acord să renunți la toate reclamațiile împotriva Shit Estate în cazul în care siguranța contului tău este
  compromisă. În plus, ești de acord să despăgubești, să aperi și să consideri Shir Estate
  nevinovată în cazul în care proprietatea intelectuală a Shir Estate suferă daune
  sau Shir Estate suferă alte daune financiare datorate problemelor de securitate.`,
            TCText12: `Ești de acord să plătești toate taxele asociate cu utilizarea serviciului. Toate taxele sunt în dolari
                  americani. Trebuie să
                  furnizezi un număr de card pentru a folosi acest serviciu în afara conturilor
                  gratuite. Plata se va face automat la data sau în jurului datei afișate
                  pe site. În cazul în care plata eșuează sau este întreruptă, putem colecta
                  plata prin alte mijloace care includ dar nu sunt limitate la agenții de colectare.`,
            TCText13: `Shir Estate își rezervă dreptul de a limita numărul mesajelor trimise de pe un cont dacă consideră, la
  discreția proprie, că acel cont folosește resurse excesive.`,
            TCText14: `Fără a ne limita celelalte căi de atac, putem limita, suspenda sau întrerupe serviciie furnizate
  contului tău la discreția noastră și fără avertisment prealabil și pentru orice motiv în cazul în care
  considerăm că abuzezi de serviciile noastre, nu respecți termenii și
  condițiile acestui Angajament, creezi probleme sau potențiale probleme
  legale, ne încalci drepturile proprietății intelectuale sau
  pe cele ale afiliaților noștri sau ale unor terțe părți, sau acționezi inconsecvent în ceea
  ce privește politicile sau spiritul politicilor noastre. În cazul în care Shir Estate limitează,
  suspendă sau îți suspendă contul, toți banii plătiți lui Estate sunt
  nerambursabili.`,
            TCText15: `În nici o circumstanță, incluzând dar nelimitate la neglijență, Shir Estate nu va fi răspunzătoare față de tine
  sau altă persoană sau entitate pentru orice daune directe, indirecte, accidentale, aspre,
  speciale sau rezultante, inclusiv pierderea profitului, pierderea materialelor,
  pierderea conținutului, pierderea fondului comercial, costuri pentru procurarea de bunuri și servicii
  înlocuitoare, și pagube materiale, care rezultă din (a) folosirea, sau
  imposibilitatea de a folosi, siteul Quantify Ninja, sau (b) comportamentul sau acțiunile
  oricărui utilizator al siteului QuantifyNinja.com website sau a oricărei alte persoane sau entități, chiar
  dacă am fost preveniți despre posibilitatea unor asemenea daune. În cazul în care Shir
  Estate este găsită răspunzătoare, responsabilitatea toată a Shir Estate pentru toate daunele,
  pierderile sau cauzele de acțiune din contract, prejudiciul (inclusiv dar
  nelimitat la neglijență) sau altceva nu va depăși suma plătită de tine în
  ultiemele trei (3) luni, dacă a existat, sau $100, care este mai mică.`,
            TCText16: `Siteul QuantifyNinja.com și serviciile sale sunt furnizate cum sunt prezentate și fără garanție sau
  condiții, exprimate, implicite sau statutare. Shir Estate și furnizorii și distribuitorii acesteia
  resping în mod expres toate garanțiile implicite legate de titlu, vandabilitate, fitness
  pentru un anumit scop și neîncălcare.`,
            TCText17: `Shir Estate își rezervă dreptul de a anula conturi inactive la propria discreție și fără
  preaviz. Clienții pot anula serviciul oricând trimițând un email la serviciul
  de asistență clienți sau apăsând linkul Contactează-ne.`,
            TCText18: `Acceptând acest Angajament, ești de acord să despăgubești, să aperi și să consideri Shir Estate și asociații
  săi nevinovați cu referire la orice reclamații, judecări, costuri, cheltuieli, datorii sau onorarii
  ale avocaților.`,
            TCText19: `Aplicarea acestui acord se va face în conformitate cu legile din Israel. Locul de desfășurare a oricărei
  acțiuni legale va fi Tel Aviv, Israel.`,
            TCTitle12: `Fără Garanție`,
            TCTitle13: `Anulare`,
            TCTitle14: `Despăgubire`,
            TCTitle15: `Lege Aplicabilă`,
            iAgree: `Sunt de acord`,
            privacyPolicy: `Politica de confidențialitate`,
            PPText1: `Această politică de confidențialitate va reglementa toate problemele de confidențialitate care
              pot apărea în legătură cu accesul tău la`,
            ninjaWebsite: `www.quantifyninja.com`,
            PPText2: `sau folosirea aplicației mobile (Quantify Ninja, App) și serviciile legate sau social media pe acest
  site pentru a obține analize de vânzări ale contului tău Amazon și pentru a-ți automatiza campaniile de marketing (Serviciile).
  Dacă nu ești de acord cu această politică de confidențialitate, te rugăm să nu folosești Serviciile de pe Quantify Ninja sau acest site.`,
            PPText3: `Această politică de confidențialitate face parte din întregul tău acord cu Shir Estate LTD. Când accesezi acest
  site, descarci/instalezi Quantify Ninja, îți conectezi contul Amazon la cel Quantify Ninja, sau
  folosești orice servicii oferite pe acest site, Shir Estate LTD. are dreptul să colecteze, să proceseze,
  să stocheze, să folosească sau să distribuie informațiile tale personale în conformitate cu această politică de confidențialitate.`,
            PPText4: `Totuși, această Politică de Confidențialitate nu se aplică siteurilor terțe sau oricăror informații pe care le distribui
  pe social media sau pe care le obținem din resurse offline. Shir Estate LTD. își rezervă dreptul să îți folosească profilul
  public și/sau alte informații pentru a-și promova afacerea.`,
            PPTitle1: `Ce putem colecta`,
            PPText5: `Shir Estate LTD. va colecta de pe acest site următoarele tipuri de informații:`,
            PPText6: `Informațiile despre înregistrare, abonament sau cont cum ar fi numele, numărul de contact, adresa de email,
  adresa biroului etc.`,
            PPText7: `Informații de facturare cum ar fi datele de card, contul de PayPal folosit pentru abonare, și adresa de facturare.`,
            PPText8: `Informații referitoare la activitate cum ar fi abonamentul cumpărat, plăți neefectuate, încălcări, dispute,
  arbitraje, și alte informații referitoare la analizele tale de vânzări sau la strategiile de marketing preferate pe
  Quantify Ninja.`,
            PPText9: `Informațiile legate de evenimente, cum ar fi interesele sau răspunsurile tale la ofertele sau evenimentele promoționale
  Organizate sau sponsorizate de Shir Estate LTD.`,
            PPText10: `Informații despre navigare cum ar fi tipul de browser, sistemul de operare, adresa IP, istoricul căutărilor,
  pagini de intrare și ieșire, anunțuri și linkuri apăsate, timp total pe site și alte detalii sau metadate
  referitoare la utilizarea Serviciilor de către tine.
  Cu toate acestea, nu vom folosi niciodată niciun fel de instrumente și tehnici automate pentru a colecta informații
  Indentificabile pe Quantify Ninja sau acest site. Orice informație care poate fi folosită sau combinată cu altă
  informație pentru a te putea identifica va fi colectată doar atunci când o furnizezi voluntar
  pentru a-ți crea contul, abona la un serviciu, trimite teedback, sau pentru a participa la
  evenimente sau oferte promoționale.`,
            PPTitle2: `Folosirea Cookieurilor`,
            PPTitle3: `Cum îți putem folosi informațiile`,
            PPText11: `Shir Estate LTD. poate folosi anumite cookieuri și alte instrumente automate pentru a colecta informații
  Neidentificabile pe/prin Quantify Ninja. Aproape toate siteurile folosesc cookieuri pentru a colecta anumite
  informații neindentificabile cum ar fi dispozitivul sau tipul de hardware, sistemul de operare, tipul de browser,
  setările și preferințele de configurare, adresa IP, ISP, paginile de intrare sau ieșire, perioadele de timp, istoricul căutărilor,
  comportamentul de navigare și alte metadate legate de folosirea Serviciilor (denumite Date Utilizator).
  Aceste informații pot fi foarte importante în rezolvarea problemelor tehnice, îmbunătățirea Serviciilor și
  rularea fără probleme a siteului.`,
            PPText12: `TE rugăm să ții cont de faptul că putem folosi servicii ale terțe părți (Google AdMob, AdSense, Analytics, Facebook
  etc.) pentru a monetiza, analiza, evalua și îmbunătăți Serviciile de pe /prin Quantify Ninja. Aceste terțe
  părți pot folosi propriile cookie-uri pentru a-ți colecta informațiile personale în legătură cu serviciile lor
  sau cu reclamele de pe/prin Quantify Ninja. Folosirea Serviciilor de pe Quantify Ninja sau acest site va fi
  considerată drept acordul tău pentru aceste cookieuri ale părților terțe.`,
            PPText13: `Îți poți configura dispozitivul sau browserul web pentru a respinge cookie-urile și instrumentele de urmărire de
              pe acest site. Cu toate acestea, trebuie să înțelegi că cookie-urile sunt necesare pentru buna funcționare a Serviciilor și
  refuzarea lor te poate împiedica să utilizezi anumite funcții sau servicii pe acest site.`,
            PPText14: `Vom folosi sau distribui informațiile tale personale doar pentru a face o prezentare simplă și clară
  a analizelor de vânzări și pentru a te ajuta cu marketingul prin email pentru publicul țintă. Mai precis,
  îți vom folosi informațiile personale pentru:`,
            PPText15: `Consolidarea și prezentarea analizelor tale de vânzări sau de creștere în cea mai comprehensibilă formă statistică.
  Înțelegerea profilului demografic al potențialilor clienți, cumpărători sau al potențialilor clienți.
  Automatizarea campaniei tale de email marketing, planificarea de emailuri și targetarea utilizatorilor și furnizarea analizelor
  de angajament.
  Urmărirea preferințelor tale și oferirea de servicii personalizate, informații sau conținut pe care îl căuți.
  Trimiterea de newslettere sau notificări cu privire la noile caracteristici, servicii, starea abonamentului, folosirea
  Serviciilor, sau orice modificări ale termenilor și condițiilor, inclusiv a acestei Politici de Confidențialitate.
  Primirea feedbackului, rezolvarea problemelor tehnice și îmbunătățirea funcțiilor sau a serviciilor de pe acest site.
  Evaluarea strategiilor tale de marketing, optimizarea serviciilor și îmbunătățirea experienței tale pe
  Quantify Ninja. Obținerea consimțământului tău pentru orice scop nelistat în această politică de Confidențialitate.`,
            PPText16: `Toate logourile, numele domeniilor, conținutul siteului și altă proprietate intelectuală care are legătură cu produsele
  și serviciile noastre sunt proprietatea exclusivă a Shir Estate. Nu ai dreptul să copiezi,
  să imiți, să modifici, să schimbi sau să folosești nici una din aceste proprietăți intelectuale fără
  acord nostru prealabil în scris. Nu ai dreptul să folosești ingineria inversă asupra produsului
  sub nici o formă.`,
            PPTitle4: `Distribuire și Divulgare`,
            PPTitle5: `Comportamentul Utilizatorului`,
            PPTitle6: `Securitate`,
            PPTitle7: `Opțiuni de Confidențialitate`,
            PPText17: `Shir Estate LTD. își rezervă dreptul de a divulga informațiile tale personale furnizorilor de servicii sau
                altor terțe părți pentru analiza informațiilor despre vânzări, realizarea de prezentări statistice,
                și pentru a atingerea publicului țintă. Totuși, nu divulgăm, vindem sau închiriem datele tale personale pentru
                scopuri de marketing direct, cu excepția cazului în care îți dai acordul de a primi oferte exclusive și oferte
                 promoționale de la terțe părți sau companii de marketing.
                Atunci când informațiile personale sunt divulgate furnizorilor de servicii sau terțe părți, nici o
                altă informație nu va fi dezvăluită cu excepția celor necesare pentru a executa/livra serviciile într-un
                mod convenabil. În cazul în care dezvăluim sau împărtășim datele utilizatorilor cu furnizorii de servicii sau cu terțe
                părți, datele utilizatorilor vor fi agregate și, dacă este posibil, făcute anonime înainte de o astfel de partajare.
                 Te rugăm
                să ții cont că Shir Estate LTD. poate partaja datele utilizatorilor cu furnizorii de servicii sau alte terțe părți
                pentru a-i putea ajuta să execute anumite funcții interne sau pentru a facilita poziționarea reclamelor targetate.
                Shir Estate LTD. își rezervă dreptul de a dezvălui orice informații, identitatea,
                Înregistrări, comunicări sau conținut autorităților competente sau altor terțe părți atunci când consideră că
                astfel de dezvăluiri sunt necesare pentru a:
                Preveni accesul sau acțiunile neautorizate pe acest site.
                Proteja afacerea sau drepturi legale sau drepturile legale, proprietatea, siguranța și bunăstarea altor utilizatori
                sau a publicului larg.
                Respecta legile în vigoare, citații, regulamentele guvernamentale sau hotărâri judecătorești.
                Coopera cu agențiile de investigații sau aplica politicile regulamentele privind utilizarea serviciilor.
                Facilita fuziunea, achiziția sau transferul afacerii, în întregime sau a unei bucăți, fără
                probleme.`,
            PPText18: `Confidențialitatea ta pe acest site/Quantify Ninja este foarte importantă pentru noi. Am implementat
                   proceduri și protocoale de securitate standard pentru a ne asigura că confidențialitatea ta pe acest
                   site nu este niciodată compromisă. Totuși, Serviciul presupune transmiterea internațională de date
                   prin intermediul rețelelor mobile și al internetului. Securitatea absolută a transmiterii de date
                   nu este posibilă și ești responsabil pentru toate riscurile presupuse de folosirea
                  Serviciilor prin intermediul rețelelor mobile și al internetului.`,
            PPText19: `Toate părțile terțe care au acces la informațiile tale personale vor si obligate să le protejeze și securizeze
  în mod corespunzător. Totuși, Shir Estate LTD. nu va fi responsabilă pentru încălcări ulterioare
  ale dreptului tău la confidențialitate de către aceste terțe părți. Te rugăm să folosești Serviciile pe riscul tău.`,
            PPText20: `Shir Estate LTD. nu va fi responsabilă pentru nici o pierdere, daună, reclamație sau consecință care ar putea
  apărea în urma unui acces neautorizat, spargerea transmisiilor de date, hacking, abuz, modificare, ștergere,
  pierdere sau orice compromitere a confidențialității pe Quantify Ninja sau acest site.`,
            PPText21: `Poți lua următoarele măsuri pentru a-ți proteja confidențialitatea pe Quantify Ninja:
  Îți poți modifica contul Amazon și șterge orice informații pe care nu vrei să le împarți cu
  Clienți/cumpărători existenți sau potențiali.
  Îți poți configura dispozitivul sau browserul web pentru a respinge cookie-urile și instrumentele de urmărire de pe acest site.
  Te poți dezabona de la newsletterul nostru și îți poți retrage consimțământul de a primi oferte promoționale
  de la terțe părți în cazul în care ți l-ai dat deja. Urmează doar linkul de dezabonare din
  partea de jos a oricărui email promoțional.
  Îți poți anula abonamentul și întrerupe Serviciile. Contul tău și informațiile asociate acestuis
  pe Quantify Ninja vor fi șterse pemanent, cu excepția celor necesare să fie păstrate din motive de obligații ale afacerii
  sau legale.`,
            PPTitle8: `Siteuri ale Terțe Părți`,
            PPTitle9: `Rezidenți din California`,
            PPTitle10: `Respectarea COPPA`,
            PPTitle11: `Schimbări ale acestei Politici de Confidențialitate`,
            PPTitle12: `Anulare`,
            PPText22: `Acest site/Quantify Ninja poate conține reclame sau linkuri către siteuri deținute de terțe părți. Reține că noi
  nu aprobăm și nu recomandăm nici un produs, serviciu sau conținut care este prezentat sau pus la dispoziție pe
  linkurile terțelor părți `,
            PPText23: `Shir Estate LTD. nu este răspunzătoare pentru nici o pierdere, daună, răspundere sau revendicare care ar
              putea apărea ca urmare a oricărei încălcări a drepturilor tale de confidențialitate în ceea ce privește
               accesul, încrederea sau utilizarea oricărui produs, serviciu sau conținut care este prezentat pe siteurile
                terțelor părți.`,
            PPText24: `Dacă te afli în California, poți cere ofițerului nostru de confidențialitate să afle care terțe părți
  au acces la informațiile tale personale și poți cere detaliile acestor terțe părți cărora
  le-am fi putut divulga informațiile tale pentru anul curent și precedent. Totuși, îți putem refuza
  cererea dacă o astfel de divulgare nu este permisă de legile și reglementările în vigoare.`,
            PPText25: `Suntem pe deplin conformi cu Legea privind Protecția Confidențialității Online a Copiilor sau cu legislații similare din
  jurisdicția ta. Serviciile sunt destinate celor majori leal. Dacă nu ai atins vârsta legală a
  majoratului în jurisdicția ta, se va presupune că folosești Quantify Ninja/Serviciile sub
  ghidarea și supravegherea tutorelui legal. În orice caz, minorilor cu vârsta sub 13 ani
  le este interzisă folosirea Serviciilor.`,
            PPText26: `Cu toate acestea, dacă bănuiești că un minor și-a introdus informațiile personale pe acest site,
  fie accidental sau altfel, te rugăm să contactezi ofițerul nostru de confidențialitate la`,
            PPText27: `și vom face toate eforturile rezonabile pentru a elimina aceste informații de pe site/servere.
  Totuși, poate dura ceva timp pentru a elimina orice informație a minorilor de pe serverele de backup.
  Shir Estate LTD. își rezervă dreptul de a șterge toate informațiile personale ale minorilor de pe acest site
  La discreția sa.`,
            PPText28: `Shir Estate LTD. își rezervă dreptul de a edita, modifica sau înlocui această Politică de Confidențialitate oricând și
              la discreția sa. Dacă această Politică de Confidențialitate este modificată, vom publica o actualizare pe această Pagină
               și vom face eforturi rezonabile pentru a te informa, dar este responsabilitatea ta de a visita cu regularitate
              acest site pentru a fi la curent. Continuarea accesării sitelui și a folosirii Quantify Ninja după astfel de
              modificări va fi considerată drept consimțământul tău pentru a respecta cea mai recentă versiune a acestei Politici
                de Confidențialitate.`,
            PPText29: `Shir Estate își rezervă dreptul de a anula conturi inactive la discreția sa și fără
  avertizare. Clienții pot anula serviciul oricând trimițând un email la serviciul de
  asistență cu clienții sau apăsând linkul Contactează-ne.`,
            PPText30: ` Dacă ai orice întrebare sau nelămurire cu privire la această declarație de confidențialitate, te rugăm să ne
              trimiți întrebările sau feedbackul la `,
            PPText31: `De asemenea, ne poți scrie la:`,
            PPText32: `Shir Estate LTD.`,
            helpEmail: `help@quantifyninja.com`,
            businessDetails: `Detalii firmă`,
            businessDetailsText: `Acesta va fi numărul firmei de pe factură. Numărul firmei este numărul TVA
  al firmei. Acesta poate fi numărul VAT al companiei sau CNP-ul tău.`,
            addRegionTitle: `MAI AI DOAR UN PAS`,
            addRegionSubTitle: `Ești aproape gata, mai avem nevoie de permisiunea de a accesa contul tău de vânzător,
                            te rugăm să alegei regiunea`,
            choose: `Alege`,
            northAmericaAccount: `Cont Nord American`,
            signOutHere: `Deloghează-te aici`,
            orYouCan: `Sau poți`,
            passwordTooltip: `Parola trebuie să aibă minim 6 caractere și să conțină cifre (0-9), litere mici (a-z) și
  caractere non-alfanumerice:`,
            errorMoreThan6: `Trebuie să conțină cel puțin 6 simboluri`,
            createNewPassword: `Crează parolă nouă`,
            repeatPassword: `Repetă parolă`,
            loginTitle: `Ceea ce are nevoie e-afacerea ta`,
            loginTitleText: ` Aplicația noastră web maiestuoasă îți oferă caracteristici uimitoare pentru a`,
            loginTitleText2: `controla complet vânzările pe Amazon. Vei fi uimit!`,
            grantAccessTitle: `Se crează un plan de facturare…`,
            grantAccessTitle2: `Redirectionare la PayPal…`,
            grantAccessTitle3: `Se verifică detaliile contului`,
            grantAccessSubTitle4: `Poate dura câteva secunde…`,
            grantAccessTitle4: `Vești bune!`,
            grantAccessTitle5: `ups!`,
            grantAccessText1: `Ți-am verificat contul cu succes, poți începe să folosești Quantify Ninja.`,
            grantAccessText2: `Acest ID de vânzător este deja folosit de un alt utilizator.`,
            getStarted: `începe`,
            grantAccessTitle6: `Mulțumim!`,
            grantAccessText3: `Îți procesăm plata și te vei putea bucura în curând de caracteristicile statutului de pro-membru!`,
            grantAccessText4: `Tranzacția a fost finalizată cu succes. Te redirecționăm spre aplicație…`,
            grantAccessText5: `Ne pare rău, dar PayPal nu a aprobat plata. Statut: Perioadă de probă gratuită de 14 zile `,
            grantAccessTitle7: `Încă un pas`,
            grantAccessText6: `Finalizează adăugarea regiunii prin finalizarea plății`,
            currentRegions: `Regiuni actuale:`,
            grantAccessText7: `Vei adăuga 1 regiune: `,
            price: `Preț`,
            tokenUsedMessage: `Tokenul a mai fost folosit, te rugăm să mai încerci o dată.`,
            tokenExpiredMessage: `Tokenul a expirat, te rugăm să mai încerci o dată`,
            tokenNotExistMessage: `Tokenul nu există, te rugăm să mai încerci o dată`,
            tokenInvalidMessage: `Tokenul este invalid, te rugăm să mai încerci o dată`,
            facebookConnected: `Ți-ai conectat cu succes contul de Facebook la aplicație`,
            facebookConnectFailed: `Ceva nu a mers cum trebuie, te rugăm să încerci mai târziu`,
            forgotPass: `Ai uitat parola?`,
            pressHere: `Apasă aici`,
            swithToPrevInterface: `Schimbă aici înapoi la interfața anterioară`,
            firstName: `Prenume`,
            lastName: `Nume de Familie`,
            and: `și`,
            loginHere: `Loghează-te aici`,
            ES: `ES`,
            RO: `RO`,
            ENG: `ENG`,
      };

      public textCN = {
            affiliatesTitle: `联盟`,
            TermsConditions: `条款和条件`,
            affiliateSubTitle: `每个成功的联盟可获得高达35美元！`,
            affiliateLink: `您的联盟链接：`,
            date: `日期`,
            homepage: `主页`,
            coupons: `优惠券`,
            uploadNewCouponCaps: `上传新的优惠券`,
            couponSubTitle: `查看优惠券并添加新优惠券`,
            noCouponMsg: `您已准备好开始创建新的优惠券`,
            pageNotFoundCaps: `找不到页面`,
            backTo: `返回`,
            pageNotFound: `找不到页面`,
            noDataFound: `找不到数据`,
            full: `完整l`,
            getOneCode: `获取一个代码`,
            check: `查看`,
            delete: `删除`,
            pending: `待定`,
            notFound: `未找到`,
            add: `添加`,
            asin: `Asin`,
            keyword: `关键字`,
            marketplace: `市集`,
            registration: `注册`,
            searchAsinsByKeywords: `按关键字搜索asins`,
            addRegion: `添加区域`,
            europianUnionAccount: `欧洲联盟帐户`,
            getAnotherLink: `获取另一个链`,
            AmazonJapanAccount: `亚马逊日本帐户`,
            AmazonAustraliaAccount: `亚马逊澳大利亚账户`,
            amazonRegions: `(Amazon.com, Amazon.ca, Amazon.co.mx)`,
            amazonRegions2: `(Amazon.uk, Amazon.de, Amazon.se, Amazon.it, Amazon.fr)`,
            amazonRegions3: `(Amazon.jp)`,
            amazonRegions4: `(Amazon.com.au)`,
            tableHeaders: {
                  earnedAmount: `获得金额`,
                  spot: `点`,
                  couponName: `优惠券名称`,
                  couponsLeft: `剩余优惠券`,
                  expiryDate: `到期日`,
                  getCouponCode: `获取优惠券代码`,
                  CouponCode: `优惠券代码`,
                  page: `页面`,
                  marketPlace: `市集`,
                  userPublicID: `用户公共ID`,
                  fullRegistration: `完全注册`,
                  planType: `计划类型`,
                  agreementStatus: `协议状态`,
                  lastUpdated: `上次更新`,
            },
            bulkTitle: `新批量规则`,
            bulkSubTitle: `向在特定日期范围内购买的所有买家发送一次性电子邮件。`,
            bulkSubLimit1: `您只限于`,
            bulkSubLimit2: `每周发送电子邮件。 如果您需要更多信用请`,
            contactUs: `联系我们`,
            filters: `筛选`,
            status: `状态`,
            template: `模板`,
            from: `从`,
            to: `到`,
            bulkName: `批量名称`,
            createNewBulk: `创建新的批量`,
            clearAll: `全部清除`,
            email: `电子邮件`,
            sent: `发送`,
            estimated: `估计`,
            edit: `编辑`,
            editFbaTemplteTitle: `编辑FBA模板`,
            backFbaTemplate: `<返回FBA模板`,
            editFbmTemplteTitle: `编辑FBM模板`,
            backFbmTemplate: `<返回FBM模板`,
            createFbaTemplateTitle: `创建新的FBA模板`,
            createFbmTemplateTitle: `创建新的FBM模板`,
            success: `成功`,
            fbmMissingProductsReport: `已发送报告，我们会尽快审阅您的问题`,
            invalidFormat: `无效的格式`,
            imageLoadValidation: `文件必须是jpg，jpeg，png或gif类型，且不能超过2MB`,
            fail: `失败`,
            emptyNameError: `名称不能留空`,
            emptySubjectError: `主题不能留空`,
            imageUploading: `图片上传中`,
            imageUploadingMessage: `请在从您的计算机上传图像之前保存模板`,
            name: `姓名`,
            subject: `主题`,
            when: `当`,
            after: `之后`,
            products: `产品`,
            editTemplateAddVariableTitle: `添加变量`,
            editTemplateAddVariableSubTitle: `只需点击某个变量，它就会显示在您的消息中，以便您了解有关变量的更多信息`,
            clickHere: `点击这里`,
            product: `产品`,
            order: `订单`,
            customer: `客户`,
            shipping: `运送`,
            templateOptions: `模板选项`,
            templateOption1: `选择此模板的状态`,
            templateOption2: `如果买家留下负面反馈则发送模板`,
            templateOption3: `如果买家请求退款则发送模板`,
            templateOption4: `向重复购买者发送模板`,
            templateOption5: `如果促销适用则发送模板`,
            templateOption6: `如果促销适用，则在促销有价值时发送模板`,
            templateOption7: `选择特定销售渠道`,
            templateOption8: `将优惠券添加到模板`,
            allChannels: `所有频道`,
            editTemplateFileTitle: `将文件添加到模板`,
            editTemplateFileSubtitle: `文件总大小不应超过7MB。查看所有支持的格式`,
            upload: `上传`,
            cancel: `取消`,
            templateTestMessage: `您可以测试这个模板，看看它从客户那边看起来是如何的，或者只是保存它`,
            test: `测试`,
            save: `保存`,
            fbaEdited: `FBA模板已成功创建`,
            fbaCreated: `FBA模板已成功编辑`,
            fbmCreated: `FBM模板已成功创建`,
            fbmEdited: `FBM模板已成功编辑`,
            deleteTemplate: `删除模板`,
            fbaTitle: `fba模板`,
            createNewFba: `创建新的FBA`,
            fbaSubTitle1: `电子邮件将从'发送`,
            fbaSubTitle2: `如果这不是与您的亚马逊帐户相关联的电子邮件`,
            fbmTitle: `fbm模板`,
            account: `帐户`,
            timeFrame: `时间范围`,
            event: `活动`,
            salesChannel: `销售渠道`,
            exportToCSV: `导出为CSV`,
            openRate: `打开率`,
            templateName: `模板名称`,
            copy: `复制`,
            createNewFbm: `创建新的FBM`,
            clickHereToEdit: `点击这里编辑`,
            couponCreatedTitle: `已创建优惠券`,
            couponEdited: `已成功编辑优惠券`,
            couponCreated: `已成功创建优惠券`,
            headerMessageSent: `已发送您的消息`,
            trialMessage1: `您的14天试用期即将完结在`,
            trialMessage2: `升级您的帐户`,
            days: `天`,
            upgrade: `升级`,
            user: `用户`,
            settings: `设置`,
            default: `默认`,
            warning: `警告`,
            dashboardNoDateMessage: `您应该首先选择日期`,
            time: `时间`,
            unitSold: `出售单位`,
            noData: `没有数据`,
            profit: `利润`,
            profitNotInclude: `特定产品的利润不包括PPC和闪电交易费用`,
            emailsSent: `发送电子邮件`,
            revenue: `收入`,
            unitsSoFar: `到目前为止的单位`,
            ordersLowerCase: `订单`,
            promotionLowerCase: `促销`,
            calculate: `计算`,
            yesterday: `昨天`,
            amazonSalesData: `亚马逊销售数据`,
            zoom: `Zoom`,
            all: `全部`,
            selectedProductsUpperCase: `已选产品`,
            daily: `每日`,
            weekly: `每周`,
            conversationRate: `转换率`,
            sessions: `会话`,
            last12Month: `（过去12个月）`,
            unitsReturned: `返回的单位`,
            chartPopularProductsTitle: `热门产品`,
            selectRegion: `选择区域`,
            dashboard: `仪表板`,
            automatedMessaging: `自动消息传递`,
            FBA: `FBA`,
            FBM: `FBM`,
            bulk: `批量`,
            notifications: `通知`,
            moneyBack: `退款`,
            hijackedListingViewer: `被劫持的清单查看人`,
            ordersAndBL: `订单和黑名单`,
            download: `下载`,
            keywordOptimizer: `关键字优化工具`,
            facebookBotManager: `Facebook机器人经理`,
            conversationManager: `会话管理员`,
            autoResponse: `自动回复`,
            subscribers: `订阅者`,
            menu: `菜单`,
            asinTracker: `ASIN追踪器`,
            superUser: `超级用户`,
            FAQQuestions: `有任何问题或困难`,
            watchTutorials: `观看教程`,
            inviteFriends: `邀请朋友`,
            autoResponseTitle: `Facebook发布自动回复`,
            createNewResponse: `创建新的响应`,
            autoResponseSubTitle1: `关于会话管理器和机器人创建的一些简短说明信息`,
            autoResponseSubTitle2: `如果不重要，我们可以删除此文本`,
            pageName: `页面名称`,
            responseName: `响应名称`,
            autoResponseText: `您已准备好开始创建新的对话`,
            botMenuTitle: `创建一个菜单`,
            botMenuSubTitle1: `菜单可以在每个页面中设置，但不能为特定会话设置`,
            botMenuSubTitle2: `您可以添加最多2个顶级菜单，以及最多只能添加3个第二级和第三级菜单`,
            selectPage: `选择页面`,
            botTopLevel: `顶级`,
            botSecondLevel: `二级`,
            botThirdLevel: `第三级`,
            deleteMenu: `删除菜单`,
            commitMenu: `提交菜单`,
            preview: `预览`,
            botMenuEditStep: `编辑步骤`,
            botMenuDeleteStep: `删除步骤`,
            botMenuDeleteLevel: `删除级别`,
            action: `行动`,
            botMenuAddSubLvl: `添加子级别`,
            botMenuAddNewStep: `添加新步骤`,
            botMenuChooseAction: `选择行动`,
            done: `完成`,
            botMenuTypeTitle: `在这里输入您的标题`,
            conversationManagerTitle: `对话管理器（机器人）`,
            createNewConversation: `创建新会话`,
            triggerBy: `触发`,
            conversationManagerDirectLink: `直接链接到对话`,
            botNewButton: `新按钮`,
            botTriggerConversation: `触发对话`,
            unsubscribe: `取消订阅`,
            subscribe: `订阅`,
            delay: `延迟`,
            botTypingStatus: `打字状态`,
            botSelectConversation: `选择对话`,
            botSetDelay: `设置延迟秒`,
            sec: `秒`,
            typing: `输入中`,
            botSetSecons: `设置秒`,
            variable: `变量`,
            coupon: `优惠券`,
            variables: `变量`,
            tag: `标签`,
            botIconInside: `里面的图标`,
            botNewTag: `添加新标签`,
            enterText: `输入文字`,
            button: `按钮`,
            text: `文字`,
            uploadImage: `上传图片`,
            title: `标题`,
            subTitle: `子标题`,
            botButtonTitle: `按钮标题`,
            botWarningMessage: `我们不能在这里添加这个组件。`,
            payload: `有效载荷`,
            getJson: `获得Json`,
            start: `开始`,
            image: `图像`,
            fullScreen: `全屏`,
            resetTester: `重置测试员`,
            withoutTesters: `没有测试员`,
            tester: `测试员`,
            botLinkToConversation: `链接到此对话`,
            deleteAll: `全部删除`,
            saveAll: `全部保存`,
            favStore: `最喜欢的商店`,
            botUnderConstructionTitle: `我们仍在努力解决`,
            botUnderConstructionText1: `Facebook机器人差不多完成了,但我们仍然需要去完善和完成一些事情。`,
            botUnderConstructionText2: `我们很快就会带着最好的机器人经理功能回来。 请耐心等待。 感谢您的理解！`,
            botUnderConstructionText3: `您仍然可以在旧界面中使用机器人`,
            switch: `开关`,
            botGrantAccessSubTitle: `Facebook机器人经理是 is lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
    tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation
    ullamco aboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in`,
            botGrantAccessSubTitle1: `您可以看看这里的演示`,
            grantAccess: `授予访问权限`,
            botGrantAccessPermissions: `我们需要什么权限`,
            botGrantAccessListTitle1: `发送消息`,
            botGrantAccessListText1: `所以我们可以代表您发送消息`,
            botGrantAccessListTitle2: `发送消息订阅`,
            botGrantAccessListText2: `因此,我们可以在用户订阅后24小时内代表您发送消息。`,
            botGrantAccessListTitle3: `页面显示列表`,
            botGrantAccessListText3: `所以我们可以让您选择页面`,
            botGrantAccessListTitle4: `管理页面`,
            botGrantAccessListText4: `所以我们可以在您的信息上添加一个’开始使用’按钮`,
            botGrantAccessListTitle5: `读取页面邮箱`,
            botGrantAccessListText5: `所以我们可以让您自定义帖子评论的自动回复。`,
            subscriberTitle: `订阅者列表`,
            subscriberTotal: `总订阅者: `,
            taggedWith: `标记为`,
            notTaggedWith: `没有标记`,
            conversaion: `对话`,
            broadcast: `广播`,
            photo: `照片`,
            connectFacebookMessage: `您需要将您的Facebook帐户连接到应用程序才能使用此功能`,
            notificationsTitle: `Facebook Messenger通知`,
            notificationsSubTitle: `我们建议选择Facebook上的电报通知`,
            getFbInstructions: `获取在Facebook上激活的说明`,
            getTgInstructions:`获取在电报上激活的说明`,
            notificationsFbAlertName: `Facebook的警报名称`,
            notificationsSetAlert: `设置警报`,
            notificationsAlertOnProducts: `产品警报`,
            fbSalesNotificaion: `Facebook销售通知`,
            notificationsOnEverySale: `每次促销`,
            notificationsFbNegative: `Facebook负面反馈通知`,
            notificationsFbReview: `Facebook评论通知`,
            notificationsOnlyMaster: `仅适用于大师计划`,
            notificationsEmail: `电子邮件通知`,
            alertName: `警报名称`,
            notificationsText1: `当我收到新的负面反馈时,请告诉我`,
            notificationsText2: `我希望获得客户每天收到的电子邮件数量的每日更新`,
            notificationsText3: `让我知道其他卖家何时劫持了我的列表`,
            notificationsReview: `审核通知`,
            hjTitle: `被劫持的列表查看器`,
            hjSubTitle: `在新卖家加入您的商家信息时设置通知`,
            productName: `产品名称`,
            SKU: `SKU`,
            condition: `条件`,
            numberOf: `数量`,
            newOffers: `新优惠`,
            usedOffers: `已使用的优惠`,
            hjViewOnAmazon: `在亚马逊上查看`,
            inviteFriendsTitle: `邀请您的朋友来量化忍者`,
            inviteFriendsSubTitle: `只需添加朋友的电子邮件地址,然后点击'发送邀请`,
            inviteFriendsSend: `发送邀请`,
            keywordTitle: `关键字优化工具`,
            keywordSubTitle: `当您尝试添加标题和说明中已存在的关键字时,自动识别并删除重复项`,
            keywordDesired: `所需的关键字`,
            keywordNumber: `单词数`,
            keywordListing: `列出文本（标题,项目符号等）`,
            result: `结果`,
            errorMoreThan30: `必须超过30`,
            errorMoreThan0Less20: `必须大于0且小于20`,
            mbTitle: `退款`,
            mbTab1: `调整请求`,
            mbTab2: `缺少的库存`,
            auto: `自動`,
            active: `有效`,
            inactive: `无效`,
            mbAmzAccount: `亚马逊电子邮件帐户`,
            mbDaysBtwRequests: `请求之间的天数`,
            mbNumbersOfReqOnSingle: `单个电子邮件上的请求数`,
            mbWhatNext: `下一步是什么？`,
            mbWhatNextText1: `如果您计划处于活动状态,您将根据您的配置收到电子邮件。您需要将电子邮件的内容复制到亚马逊的新案例中。`,
            mbWhatNextText2: `请记住这只是Alpha版本。 它可能无法按预期工作。 欢迎您将反馈发送给我们。`,
            manual: `手册`,
            mbOnSingleEmial: `在一封电子邮件（最多20个）`,
            mbMoneyBackGuarantee: `退款保证`,
            mbGuaranteeText: `亚马逊保证（天数）。 对于大多数类别使用,30天,婴儿类别使用,90天`,
            mbOnlyAdjBefore: `仅在之前发出的调整`,
            mbOnlyAdjAfter: `仅在之后发出的调整`,
            send: `发送`,
            downloadTitle: `下载报告`,
            downloadSubTitle: `通过使用以下数据创建自定义受众,在社交媒体上重新定位买家`,
            downloadExludeOrders: `排除促销订单`,
            downloadHistoryMessage: `检索帐户一年的历史记录最多需要30个小时`,
            refundTooltipMessage: `订单退款于`,
            saveBeforeUploadMessage: `请在从您的计算机上传图像之前保存模板`,
            smTitle: `发送消息`,
            smBackToOrders: `返回订单`,
            emailExample: `example@gmail.com`,
            smExcludeOrder: `从未来模板中排除此订单`,
            smSendCopy: `将副本发送到我的收件箱`,
            errorRequired: `此字段是必填字段`,
            templateFile: `模板文件`,
            optionChanged: `选项已被更改`,
            ordersTitle: `订单`,
            allTypes: `所有类型`,
            buyerName: `买方名称`,
            buyerEmail: `买家电子邮件`,
            orderId: `订单ID`,
            search: `搜索`,
            ordersExcludeOrder: `从未来模板中排除此订单`,
            ordersExcludeBuyer: `从未来的模板中排除此买家`,
            sendMessage: `发送消息`,
            quantity: `数量`,
            purchaseDate: `购买日期`,
            shippingDate: `发货日期`,
            customerPhoneNumber: `客户电话号码`,
            trackingNumber: `追踪号码`,
            receipientName: `收据名称`,
            serviceLevel: `服务水平`,
            shipAddress: `送货地址`,
            carrier: `运营商`,
            shipCity: `城市`,
            shipState: `州`,
            shipCountry: `国家`,
            itemPrice: `物品价格`,
            itemTax: `物品税`,
            shippingPrice: `运费价格`,
            shippingTax: `运费税`,
            giftWrapPrice: `礼品包装的价格`,
            giftWrapTax: `礼品包装税`,
            itemPromotionDiscount: `物品促销折扣`,
            shipPromotionDisc: `送货促销折扣`,
            errorEmptyPass: `密码为空`,
            superLogInAS: `登录为`,
            superCreateInvoice: `创建发票`,
            superTab1: `显示Msg Id的电子邮件`,
            tab: `标签`,
            superFillUsers: `填写用户`,
            password: `密码`,
            go: `前往`,
            upgTitle: `升级您的计划`,
            premium: `高级`,
            masteNinja: `大师级`,
            upgPayWith: `支付`,
            upgAddOnly: `仅添加`,
            upgIncluded: `包含的功能`,
            downgrade: `降级`,
            upgCurrentPlan: `当前计划`,
            upgDataTitle: `数据和管理工具`,
            upgDataText1: `统计`,
            upgDataText2: `销售,利润和客户回报的饼图`,
            upgDataText3: `产品性能对比图`,
            upgDataText4: `按名称,顺序和日期搜索订单`,
            upgDataText5: `利润计算`,
            upgNotificationsText1: `通过Facebook和电子邮件进行销售的实时通知`,
            upgNotificationsText2: `通过Facebook和电子邮件发送产品评论`,
            upgNotificationsText3: `通过Facebook和电子邮件发送负反馈通知`,
            upgEmailTitle: `发送电子邮件`,
            upgEmailText1: `批量发送电子邮件`,
            upgEmailText2: `为您的客户提供无限的电子邮件和模板`,
            upgEmailText3: `打放率数据`,
            upgEmailText4: `文件附件到模板`,
            upgEmailText5: `买家\\订单黑名单`,
            upgHjTitle: `劫持和黑名单`,
            upgHjText1: `被劫持的列表通知者`,
            upgHjText2: `被劫持列表视图`,
            upgOptimizationTitle: `优化工具`,
            upgOptimizationText1: `Asin跟踪器 - 按关键字查看产品的位置`,
            upgOptimizationText2: `Facebook机器人 - 通过在Facebook上使用自动对话获取更多潜在客户来增加亚马逊网页的流量`,
            upgAddingReg: `添加1个区域 - 15美元/月`,
            upgYourCurrPlan: `您目前的计划`,
            upgCancelPlan: `取消计划`,
            options: `选项`,
            upgPriceText1: `高级计划的默认价格（1个区域） - 每月35美元,每个额外区域+ 15美元/月`,
            upgPriceText2: `大师级计划的默认价格（含1个地区） - 每月55美元,+每月15美元每个额外地区`,
            upgPlanVariations: `其他可用计划`,
            upgPrem1Reg: `1个区域的高级计划`,
            upgPrem2Reg: `2个地区的高级计划`,
            upgPrem3Reg: `3个区域的高级计划`,
            upgJoinMaster: `加入大师级计划`,
            notice: `通知`,
            upgNoticeText1: `一旦订阅`,
            upgNoticeText2: `您的旧计划将自动取消。 第一笔付款是这个周期额外20美元的一小部分。 +从下一个周期开始,您需要支付55美元的费用。`,
            termsAndCond: `Terms and Conditions`,
            checkOur: `查看我们的`,
            forMoreInfo: `了解更多信息`,
            settingsAccTitle: `帐户设置`,
            settingsAccSubTitle: `设置您的个人资料信息,商家信息,货币或订阅计划`,
            settingsAccExpoDate: `直到取消`,
            errorIncorectEmail: `电子邮件格式不正确`,
            errorIncorectPassFormat: `密码格式不正确`,
            errorPassNotMath: `密码不匹配`,
            emailAddress: `电子邮件地址`,
            sendEmailFrom: `发送电子邮件`,
            confirmPasswod: `确认密码`,
            businessName: `商家名称`,
            businessRegNum: `商业登记号码`,
            businessAddress: `商家地址`,
            defaultCurrency: `默认货币`,
            linkedRegs: `链接区域`,
            subscrPlan: `订阅计划`,
            expiration: `到期`,
            expenseTitle: `费用`,
            expenseSubTitle: `看看您的PPC和闪电交易费用或添加您的自定义费用`,
            addExpense: `添加费用`,
            expenseAdded: `费用已成功添加`,
            editExpense: `编辑费用`,
            expenseEdited: `费用已成功编辑`,
            deleteExpense: `删除费用`,
            expenseDeleted: `费用已成功删除`,
            amount: `金额`,
            productsVisibleTooltip: `可见模式 - 可以在应用程序的任何地方选择产品`,
            productsInvisibleTooltip: `隐形模式 - 我们将尝试在应用程序的任何位置隐藏此产品`,
            productsTitle: `产品设置`,
            productsSubTitle: `设置产品的简称并选择产品的颜色以在图表上显示`,
            editProduct: `编辑产品`,
            productEdited: `产品编辑成功`,
            hide: `隐藏`,
            shortName: `短名`,
            displayedName: `显示的名称`,
            productAsin: `产品Asin`,
            productsPerCost: `每单位成本`,
            productsSettings: `产品设置`,
            editResponse: `编辑回复`,
            errorPostNotSelected: `必须选择帖子`,
            autoRespApplyOnly: `仅应用于直接响应`,
            ruleName: `规则名称`,
            message: `消息`,
            errorPageNotSelected: `必须选择页面`,
            selectPost: `选择帖子`,
            postPreview: `发布预览`,
            required: `必填`,
            errorTemplateNotSelected: `必须选择一个模板`,
            editBulk: `编辑批量`,
            anyOfYourProducts: `您的任何产品`,
            bulkDialogText1: `将此模板发送给购买了以下某种产品的所有客户`,
            bulkDialogText2: `将此模板发送给所有购买的客户`,
            bulkDialogTimeframe: `在此时间范围内`,
            bulkDialogEmailEst: `估计的电子邮件（计划的电子邮件数）`,
            count: `计数`,
            set: `设置`,
            exportCSV: `导出到CSV`,
            exportCSVText: `制表符分隔文件将发送到您的电子邮件`,
            errorEmailInUse: `此电子邮件地址已被使用`,
            ok: `好的`,
            dialogDeleteTemplate: `删除此模板？`,
            dialogDeleteTemplateText: `您确定要删除此模板吗？`,
            yesDelete: `是的,删除`,
            resetPass: `重置密码`,
            resetPassText1: `重置密码的链接已发送到您的电子邮箱。 请检查您的收件箱。 请记住,有时电子邮件最终会出现在垃圾邮件文件夹中。`,
            resetPassText2: `还没有收到电子邮件？ 确保您使用了Quantify Ninja电子邮件。 如果您遇到任何问题,请联系`,
            supportEmail: `support@quantifyninja.com`,
            dialogGoToLogin: `登录`,
            signUp: `注册`,
            signUpSuccess: `您已注册成功！`,
            dialogUnsaved: `您有未保存的更改`,
            dialogUnsavedText: `您确定要离开这个页面吗？`,
            yes: `是`,
            no: `否`,
            dialogStatus: `选择此模板的状态`,
            dialogStatusText1: `只有活动模板才会根据设置发送给客户。`,
            dialogStatusText2: `不会在任何设置下发送非活动模板。`,
            gotIt: `明白了`,
            dialogNegativeFeedback: `如果买家留下负面反馈则发送模板。`,
            dialogNegativeFeedbackText: `'设置'否’将排除留下负面反馈的买家。`,
            dialogRefundOrder: `如果买家要求退款则发送模板。`,
            dialogRefundOrderText: `即使订单已退款安排仍会发送模板。`,
            dialogRepBuyers: `向重复购买者发送模板`,
            dialogRepBuyersText: `设置此选项不包括将此模板发送给过去购买该產品的买家。 如果买家不止一次购买同一件商品,他只会获得一次此模板。 请注意,如果同一买家购买了不同的产品,他将再次获得此模板。`,
            dialogPromotionGiven: `如果有促销,发送模板。`,
            dialogPromotionGivenText: `如果有促销,发送模板。`,
            dialogPromotionValue: `如果有促销,则在促销有价值时发送模板。`,
            dialogPromotionValueText: `通过设置此选项,如果买家收到的促销次数//等于//小于某个金额,则可以排除此电子邮件。 请注意,不会排除没有任何促销的订单。`,
            dialogPassChanged: `密码已更改`,
            dialogPassChangedText: `您的密码已成功更改`,
            dialogSpecSalesChannel: `选择特定销售渠道`,
            dialogSpecSalesChannelText: `此选项使您可以选择通过其发送模板的特定通道。`,
            dialogAddCoupon: `将优惠券添加到模板`,
            dialogAddCouponText1: `优惠券可以自动添加到模板中。要添加优惠券代码,您需要在模板中添加’优惠券代码’。`,
            dialogAddCouponText2: `添加优惠券标签: 在左侧的下拉优惠券列表中选择优惠券。选择后,您将看到需要添加到模板的优惠券代码。复制标记的名称并将其放在模板中。`,
            dialogAddCouponText3: `一旦发送带有该模板的邮件,将从上传的优惠券列表中插入优惠券。`,
            dialogAddCouponText4: `如果上传的列表中没有剩余优惠券,则不会发送包含此模板的电子邮件。`,
            dialogBulk: `新批量规则`,
            dilogBulkText1: `亚马逊限制了您每天可以发送给买家的电子邮件数量。`,
            dilogBulkText2: `限制基于您的平均每日订单量。 您可以发送的邮件数量不超过平均每日订单量的五倍,再加上600条信息。`,
            dilogBulkText3: `例如,如果您平均每天进行100次销售,那么在任何一天您都可以发送（5 x 100）+ 600 = 1100条消息。`,
            dialogVaiable: `添加变量`,
            dialogVaiableText1: `变量是根据每个客户或订单的特定信息以实时填充的模板字段。`,
            dialogVaiableText2: `例如,要启动带有个人问候语的电子邮件,请使用`,
            dialogVaiableText3: `‘嗨 &lt;customer-first-name&gt;’`,
            dialogVaiableText4: `在您的模板中。`,
            dialogVaiableText5: `变量`,
            dialogVaiableText6: `＆lt; customer-first-name＆gt;`,
            dialogVaiableText7: `将根据客户在订单中的名字如John,Barbara,Melanie等進行实时更换。`,
            confirm: `确认`,
            dialogConfirmTemplate: `确认模板`,
            dialogConfirmTemplateText1: `模板状态: `,
            dialogConfirmTemplateText2: `将被发送`,
            dialogConfirmTemplateText3: `尽快`,
            dialogConfirmTemplateText4: `天后`,
            dialogConfirmTemplateText5: `对于已购买的客户`,
            dialogConfirmTemplateText6: `以下产品: `,
            dialogConfirmTemplateText7: `您的任何FBA产品（包括将来添加的产品）`,
            dialogAddFile: `附件格式`,
            dialogAddFileText1: `文本文件（.txt）`,
            dialogAddFileText2: `PDF（Adobe可移植文档格式）`,
            dialogAddFileText3: `Word文档（.doc和.docx）`,
            dialogAddFileText4: `图像文件（.jpg,.gif,.tiff,.bmp和.png）`,
            dialogBulkNoedit: `编辑批量`,
            dialogBulkNoeditText: `执行后或处理时无法编辑批量规则`,
            dialogEditEmail: `编辑电子邮件`,
            dialogEditEmailText: `您在亚马逊帐户上的电子邮件: `,
            setSender: `设置发件人`,
            login: `登录`,
            dilogOnHold: `您的帐户处于暂停状态,请联系`,
            dialogRegion1: `亚马逊美国账号`,
            dialogRegion1Text: `（Amazon.com,Amazon.ca,Amazon.co.mx）`,
            dialogRegion2: `欧洲联盟帐户`,
            dialogRegion2Text: `（Amazon.uk,Amazon.de,Amazon.se,Amazon.it,Amazon.fr）`,
            dialogRegion3: `亚马逊日本账号`,
            dialogRegion3Text: `（Amazon.jp）`,
            dialogRegion4: `亚马逊澳大利亚账号`,
            dialogRegion4Text: `（Amazon.com.au）`,
            dialogPermossionDenied: `此功能不可用`,
            dialogPermossionDeniedText: `您需要升级您的付款计划才能访问此页面。`,
            dialogInstructions: `获取说明`,
            dialogInstructionsText1: `使用下一个链接打开Facebook Messanger`,
            dialogInstructionsText2: `量化忍者聊天页面。`,
            dialogInstructionsText3: `点击«开始使用»按钮激活聊天并将下一个代码复制到聊天并点击发送`,
            copiedToClipboard: `复制到剪贴板`,
            dialogGetJsonText: `当您开始一个消息传播活动时,您需要告诉Facebook当用户点击您的广告时要显示什么。这可以使用Json代码完成。
                            将下一个代码复制到Facebook上广告设置的Json选项卡: `,
            dialogOrderBL: `订购黑名单`,
            dialogOrderBLText: `此列表中的订单不会收到模板定义的任何后续电子邮件。但是,买家可能仍会收到不同订单上的模板`,
            dialogBuyerBL: `买家黑名单`,
            dialogBuyerBLText: `此列表中的买家将不会收到您的模板在任何过去或将来的订单中定制的任何电子邮件。`,
            dialogCommitMenu: `提交和删除菜单`,
            dialogCommitMenuText1: `提交菜单`,
            dialogCommitMenuText2: `将立即反映在您的页面机器人上。该菜单将显示在此页面的所有会话中。它不能按对话设置。`,
            dialogCommitMenuText3: `删除菜单`,
            dialogCommitMenuText4: `将立即反映在您的页面机器人上。 菜单将从此页面的所有会话中删除`,
            dialogSessionExp: `您需要再次登录`,
            dialogSessionExpText: `您当前的会话已过期`,
            dialogDownload: `下载报告`,
            dialogDownloadText: `找不到匹配的结果`,
            dialogDownloadFailText: `生成报告失败,如果再次发生,请再试一次并联系我们`,
            dialogAsinLimit: `添加关键字`,
            dialogAsinLimitText: `已达到最大关键字数`,
            dialogAutoResponseUnsave: `取消规则`,
            dialogAutoResponseUnsaveText: `您对此规则所做的任何更改都不会被应用。 您确定要取消 ？`,
            dialogAutoResponseUnsaveButton1: `回到编辑`,
            dialogAutoResponseUnsaveButton2: `是的,取消`,
            dialogNeedUpgrade: `需要升级`,
            dialogNeedUpgradeText: `这个特色只适用于大师计划`,
            dialogAsinAdded: `Asin被添加`,
            dialogAsinAddedText: `Asin已经加入市场`,
            dialogEmailChange: `您已更改’发送电子邮件来自’字段`,
            dialogEmailChangeText: `电子邮件必须与用于登录您的亚马逊帐户的电子邮件相同`,
            dialogOpenRate: `（’哦不！,好像......’）`,
            dialogOpenRateText: `您要检查您的亚马逊电子邮件地址`,
            dialogDowngrade: `您确定吗？`,
            dialogDowngradeText: `您打算降级您当前的计划,您确定吗？ 降级后,部分功能将无法使用。`,
            dialogDowngradeSuccess: `您被降级了`,
            dialogDowngradeSuccessText: `您已成功降级计划。 此更新将在24小时内执行。`,
            dialogResetTester: `这是您的新链接`,
            dialogShortNameWarning: `未设置短名`,
            dialogShortNameWarningText1: `已定义产品短名称变量,但未设置。`,
            dialogShortNameWarningText2: `如果未设置短名称,系统将使用完整标题。`,
            dialogShortNameWarningText3: `设置一个简短的名字很容易！转到设置 - 产品设置并为您的产品设置简称`,
            sendAnyway: `发送`,
            dialogBulkAlert: `注意`,
            dialogBulkAlertText: `如果您想更改产品,请在所选模板上更改`,
            dialogCancelPlan: `取消计划`,
            dialogCancelPlanText: `您确定要取消当前计划吗？`,
            dialogWentWrong: `出了问题`,
            dialogWentWrongText: `请稍后再试,或在’联系我们’部分报告问题`,
            dialogCancelPlanSuccessText: `您已成功取消订阅`,
            dialogOops: `喔`,
            dialogOopsText: `我们有一些问题,请退出并重试`,
            dialogUnsubscribeText: `您确定要取消订阅电子邮件吗？`,
            dialogTrialHistoryText: `系统存储30天试用期的30天交易历史记录。您要查找的订单可能超过14天。您需要升级才能获得完整功能
                                （1年历史）。`,
            continueWithPartialData: `继续部分数据`,
            dailogTrialHistory2: `试用版有一个月的历史记录 - 订阅者获得一年的历史记录。 可能会显示部分或空的结果。`,
            dialogFbmMissProducts: `我们在您的帐户中找不到FBM产品,您确定不想创建FBA模板吗？`,
            dialogFbmMissProductsButton1: `带我去FBA模板`,
            dialogFbmMissProductsButton2: `报告缺少的FBM产品`,
            contactUsTitle: `我们希望听到您的反馈`,
            contactUsubTitle1: `反馈,建议,错误 - 无论您想告诉我们什么！`,
            contactUsubTitle2: `您可以在这里或在给我们发消息`,
            yourName: `您的名字`,
            yourEmail: `您的电子邮件`,
            yourMessage: `您的消息`,
            errorContantEmpty: `内容不能为空白`,
            errorValidFile: `请先上传有效文件`,
            couponNotAdded: `无法添加优惠券`,
            couponNotAddedText: `文件扩展名应为txt,文件大小不能超过100kb`,
            uploadCoupon: `上传优惠券`,
            addCoupon: `添加优惠券`,
            couponsName: `优惠券名称`,
            expirationDate: `到期日`,
            errorValidExpDate: `请选择有效的截止日期`,
            onlyTxt: `只允许txt文件`,
            noDataYet: `还没有数据`,
            profitCalc: `利润计算`,
            value: `价值`,
            totalItemsSold: `已售出的商品总数`,
            totalNumberOrders: `订单总数`,
            pendingOrders: `待定订单`,
            totalFees: `总费用`,
            totalPromotions: `总促销`,
            PPCdeals: `PPC和闪电优惠`,
            totalProductCost: `总产品成本: `,
            promSummary: `促销摘要`,
            promName: `促销名称`,
            redeemed: `已兌換`,
            total: `总计`,
            promotions: `促销活动`,
            allPromFrom: `所有促销活动自: `,
            toLowerCase: `至`,
            promValue: `促销价值`,
            showPromSummary: `显示促销摘要>`,
            plus: `+`,
            click: `点击`,
            ordersTableText1: `在订单上查看扩展数据版本`,
            totalProfit: `总利润`,
            promId: `促销ID`,
            charge: `收费`,
            fee: `费`,
            channel: `频道`,
            productCost: `产品成本`,
            shippingPromotion: `寄送優惠`,
            promotion: `促销`,
            limitedAccess: `限制访问`,
            ninjaAccount: `忍者账户`,
            notDefined: `未定义`,
            amazonUK: `Amazon.co.uk`,
            amazonFR: `Amazon.fr`,
            amazonDE: `Amazon.de`,
            amazonIT: `Amazon.it`,
            amazonES: `Amazon.es`,
            amazonCOM: `Amazon.com`,
            amazonMX: `Amazon.co.mx`,
            amazonCA: `Amazon.ca`,
            amazonAU: `Amazon.com.au`,
            amazonJP: `Amazon.com.jp`,
            reset: `重置`,
            addShortNameEachChannel: `为每个频道添加短名称`,
            resetAll: `全部重置`,
            errorEmptyExpense: `费用名称不能为空`,
            errorAmountNotNumber: `金额必须是数字格式`,
            currency: `货币`,
            errorMoreThan0Less100: `必须大于0且小于100`,
            mbNumberOfRequests: `请求的数量`,
            advOptions: `高级选项`,
            content: `内容`,
            postCreatedTime: `发布时间`,
            back: ` 返回`,
            select: `选择`,
            selectSpecProducts: `选择特定产品`,
            selectSpecProductsSub: `为此模板选择所有必需的特定产品`,
            TCText1: `QuantifyNinja.com是由以色列特拉维夫的Shir Estate提供的服务。 该协议于2016年6月22日更新。`,
            TCTitle2: `服务描述`,
            TCText2: `Quantify Ninja是第三方亚马逊卖家的服务。 它允许这些卖家向通过亚马逊下订单的客户发送自动电子邮件通知
                    用户可以定制哪些订单接收自动电子邮件,并且可以定制这些消息的内容以及何时发送它们。
                    这些电子邮件的目的是有效地自动与客户沟通,
                    主要是为了在亚马逊上征求反馈,并在他们留下负面反馈之前主动要求纠正客户订单的问题。`,
            TCTitle3: `资格`,
            TCText3: `您必须年满18岁才能使用该服务。 您还必须拥有活跃的亚马逊’ 专业销售’ 帐户以使用该服务。 此外,您必须通过MWS
                （亚马逊商城网络服务）完成注册并提供适当的凭据。 通过使用该服务,您保证您有权访问您注册的亚马逊卖家帐户。`,
            TCTitle4: `隐私与安全`,
            TCText4: `您的隐私和帐户的安全性对我们非常重要。 我们在包含敏感信息的每个区域使用加密,例如信用卡号,帐户凭据或客户信息。`,
            TCTitle5: `知识产权`,
            TCText5: `所有徽标,域名,网站内容和其他涉及我们产品和服务的知识产权是Shir Estate的专有财产。 未经我们事先书面同意,您不得复制,
                    模仿,修改,更改,修改或使用任何知识产权。 您不得以任何理由尝试对产品进行逆向工程。`,
            TCTitle6: `用户行为`,
            TCText6: `使用此服务时,您同意遵守所有法律和法规。 您还同意遵守`,
            TCAmazPolGuide: `亚马逊的政策和指南`,
            TCText7: `以及任何其他亚马逊政策。 您不得使用我们的服务从事欺诈或其他非法活动,或提倡非法活动。
                    您不得使用我们的服务侵犯或侵犯第三方的任何权利。`,
            TCText8: `您不应该使用会干扰任何其他用户的网站正常运行的服务。 您不会使用任何网络机器人或蜘蛛来访问该服务。`,
            TCText9: `您確认我们可能会监控和衡量系统的使用情况,以确保符合这些条件。 如果不遵守上述任何条件,我们可能会立即关闭您的帐户而不会退款。`,
            TCTitle7: `密码和帐户访问权限`,
            TCTitle8: `费用和付款`,
            TCTitle9: `过度使用`,
            TCTitle10: `滥用,暂停,终止`,
            TCTitle11: `责任限制`,
            TCText10: `您将始终保持用户名和密码的机密性。 如果您的帐户存在安全漏洞,则必须立即更改密码。`,
            TCText11: `如果您的帐户安全受到损害,您同意免除对Shir Estate的所有索赔。 Further, 如果Shir
                    Estate因其违反保密规定而对其知识产权或其他财务损失进行赔偿,则您同意保障,辩护并使Shir Estate无害。 `,
            TCText12: `您同意支付与使用该服务相关的所有费用。 所有费用均以美元计算。 您必须提供信用卡号码才能使用超过任何免费的服务。
                    付款将在网站上显示的日期或之前自动进行。 如果您的付款失败或有争议,我们可能会通过其他方式收取付款,
                    包括但不限于收款机构。`,
            TCText13: `Shir Estate保留在认为帐户使用过多资源时限制帐户发送邮件数量的权利。`,
            TCText14: `如果我们认为您滥用我们的服务,或您未能遵守本协议的条款和条件,或者您正在制造问题或潜在的法律责任,或者您侵犯了我们、
                    任何关联公司、第三方的知识产权,或您的行为与我们的政策或政策精神不一致,在不限制我们的其他补救措施的情况下,
                    我们可以自行决定限制、暂停或终止我们的服务和您的帐户,恕不另行通知。如果Shir Estate限制,暂停或终止您的账户,
                    您支付给Shir Estate的所有款项均不予退还。 `,
            TCText15: `在任何情况下,包括但不限于疏忽,Shir Estate对您或任何其他人或实体不承担任何直接,间接,偶然,惩罚性,
                    特殊或后果性损害的责任（包括利润损失,材料损失,内容损失,商誉损失,采购替代商品和服务的成本以及财产损失）,
                    即使这些损害是由于（a）使用, 或者无法使用Quantify Ninja网站,或（b）
                    QuantifyNinja.com网站的任何用户或任何其他人或实体的行为或行为,即使我们已被告知可能发生此类损害。
                    如果发现Shir Estate有责任,Shir Estate对您在合同,侵权（包括但不限于疏忽）或其他方面的所有损害,
                    损失和诉讼原因承担的全部责任不会超过您过去支付的金额三（3）个月(如有),或100美元,以较少者为准。 `,
            TCText16: `QuantifyNinja.com网站和服务按原样提供,没有任何明示,暗示或法定的保证或条件。 Shir Estate及其供应商和分销商特别声明不对所有权,适销性,特定用途的适用性和非侵权行为进行任何默示保证。`,
            TCText17: `Shir Estate保留自行决定取消非活动账户的权利,恕不另行通知。 客户可以通过向我们的客户支持部门发送电子邮件或点击’联系我们’链接随时取消服务。`,
            TCText18: `通过接受本协议,您同意保障,抗辩并使Shir Estate及其关联方免受所有索赔,判决,费用,开支,责任或律师费的损害。`,
            TCText19: `本协议的执行应根据以色列法律解释。 任何法律诉讼的地点应在以色列特拉维夫。`,
            TCTitle12: `无保修`,
            TCTitle13: `取消`,
            TCTitle14: `赔偿`,
            TCTitle15: `适用法律`,
            iAgree: `我同意`,
            privacyPolicy: `隐私政策`,
            PPText1: `本隐私政策将适用于您访问网站时可能出现的所有隐私问题`,
            ninjaWebsite: `www.quantifyninja.com`,
            PPText2: `或使用其移动应用程序（Quantify Ninja,App）以及本网站上的相关服务或社交媒体来获取您的亚马逊帐户的销售分析并自动化您的营销活动（服务）。 如果您不同意本隐私政策,请不要使用Quantify Ninja或本网站上的服务。`,
            PPText3: `本隐私政策应成为您与Shir Estate LTD的全部协议的一部分。 当您访问本网站时,请下载/安装Quantify
                  Ninja,将您的亚马逊帐户链接到您的Quantify Ninja帐户,或使用本网站上提供的任何服务,Shir Estate LTD。
                  有权根据本隐私政策收集,处理,存储,使用或分享您的个人信息。`,
            PPText4: `但是,本隐私政策不适用于第三方网站或您在社交媒体上分享的任何信息,或者我们通过离线来源获得的信息。 Shir Estate LTD保留使用您的共享个人资料和/或离线信息进行商业促销的权利。`,
            PPTitle1: `我们可以收集什么`,
            PPText5: `Shir Estate LTD将在本网站收集以下类型的信息: `,
            PPText6: `注册,订阅或帐户信息,例如您的姓名,联系电话,电子邮件地址,和办公地址等`,
            PPText7: `结算信息,如信用卡,PayPal订阅帐户和帐单邮寄地址。`,
            PPText8: `与活动相关的信息,例如购买的订阅,默认,违规,违规,争议,仲裁以及与您的销售分析或Quantify Ninja首选营销策略相关的其他信息。`,
            PPText9: `活动相关信息,例如用户兴趣或对Shir Estate LTD组织或赞助的促销优惠或活动的回应。`,
            PPText10: `浏览诸如您的浏览器类型,操作系统,IP地址,搜索历史记录,进入和退出页面,点击的广告和链接,网站上的总时间以及与您使用服务相关的其他详细信息或元数据等信息 。但是,我们绝不会使用任何种类的自动化工具和技术来收集Quantify Ninja或本网站上的可识别信息。只有当您自愿提交信息以创建帐户,订阅任何服务,提交反馈或参与促销活动或优惠时,我们才会收集任何可以使用或与其他信息结合以识别您身份的信息。`,
            PPTitle2: `使用Cookies`,
            PPTitle3: `我们如何使用您的信息`,
            PPText11: `Shir Estate LTD'可以使用某些cookie和其他自动化工具在Quantify Ninja上/通过Quantify Ninja收集您的不可识别信息。几乎所有网站都使用cookie来定期收集某些不可识别的信息,例如您的设备或硬件类型,操作系统,浏览器类型,配置设置和首选项,IP地址,ISP,进入或退出页面,时间戳,搜索历史记录,浏览行为,以及与您使用服务相关的其他元数据（统称为用户数据）。这些信息对解决技术问题,改进服务以及顺利运行网站非常有用。`,
            PPText12: `请确认我们可能会使用第三方服务（Google AdMob,AdSense,Google Analytics,Facebook等）来分析,评估和改进Quantify Ninja上的服务。 这些第三方可以使用自己的cookie来收集与其服务相关的个人信息或通过Quantify Ninja提供的广告。 使用Quantify Ninja服务或本网站即表示您同意这些第三方cookie。`,
            PPText13: `您可以配置您的设备或网络浏览器以拒绝来自本网站的cookie和跟踪工具。但是,您应该了解服务器的正常运行是需要cookie,拒绝它们可能会阻止您使用本网站上的某些功能或服务。`,
            PPText14: `我们将使用或分享您的个人信息,仅用于简单直观地展示您的销售分析,并帮助您向目标受众进行电子邮件营销。我们将使用您的个人信息用于: `,
            PPText15: `以最易于理解的统计形式整合和展示您的销售或增长分析。了解潜在客户或潜在客户的人口统计资料。将您的电子邮件营销活动自动化,安排电子邮件和定位用户,以及提供互动分析。跟踪您的偏好并提供您可能正在寻找的定制服务,信息或内容。发送有关新功能,服务,订阅状态,服务使用或我们的条款和条件（包括本隐私政策）的任何更改的简报或通知。接收您的反馈,解决技术问题,并改进本网站上的功能或服务。评估您的营销策略,优化服务并改善您在Quantify Ninja上的整体体验。为了未在本隐私政策中列出的任何目的获得您的同意。 `,
            PPText16: `所有涉及我们产品和服务的徽标,域名,网站内容和其他知识产权均为Shir Estate的专有财产。未经我们事先书面同意,您不得复制,模仿,修改,更改或使用任何知识产权。不得出于任何原因尝试对产品进行逆向工程。`,
            PPTitle4: `分享和披露`,
            PPTitle5: `用户行为`,
            PPTitle6: `安全`,
            PPTitle7: `隐私选项`,
            PPText17: `Shir Estate LTD保留与其信赖的服务提供商或其他第三方共享您的个人信息的权利。这些服务提供商或其他第三方可以分析您的销售信息,进行统计演示并覆盖目标受众。但是,除非您同意接收来自第三方企业或营销公司的独家优惠和促销优惠,否则我们不会出于直接营销目的披露,出售或出租您的个人信息。在与服务提供商或其他第三方共享您的个人信息时,除了他们执行/提供服务所需的信息之外,不会披露任何其他信息。如果我们向服务提供商或其他第三方披露或共享任何用户数据,则用户数据将被汇总,并且在可行时,在此类共享之前匿名。请确认Shir Estate LTD可以与服务提供商或其他第三方共享用户数据,以帮助他们执行某些内部功能或促进其有针对性的广告投放。Shir Estate LTD还保留酌情权,在相信需要披露以下信息时,向相关机构或其他第三方披露任何信息,个人身份,记录,通信或内容: 防止在本网站上进行未经授权的访问或活动。保护其业务或合法权利,或其他用户或公众的权利,财产,安全和福祉。遵守适用的法律,传票,政府法规或法院裁决。与调查机构合作或执行其使用服务的政策和指南。以平稳的方式促进其全部或部分业务的合并,收购或转让。`,
            PPText18: `您在本网站或Quantify Ninja的隐私对我们非常重要。我们已实施行业标准安全程序和协议,以确保您在本网站上的隐私不会受到损害。但是,该服务涉及通过移动网络和因特网进行国际数据传输。数据传输的绝对安全是不可能的,您应承担通过移动网络或互联网使用服务可能涉及的所有风险。`,
            PPText19: `所有可能访问您个人信息的第三方都有义务保护并充分保护您的个人信息。 但是,Shir Estate LTD不对此类第三方随后侵犯您隐私权的行为负责。 请自行承担风险和自行决定使用服务。`,
            PPText20: `Shir Estate LTD不对关于Quantify Ninja或这个网站因未经授权的访问,违反数据传输,黑客攻击,滥用,更改,删除丢失或任何您的隐私泄露而导致的任何损失,损害,责任,索赔或后果承担责任 。`,
            PPText21: `您可以采取以下措施来管理您对Quantify Ninja的隐私: 您可以修改您的Amazon帐户并删除您不想与现有或潜在客户/客户共享的任何信息。您可以将您的设备或Web浏览器配置为拒绝来自本网站的cookie和跟踪工具。如果您已经同意,您可以取消订阅我们的新闻通讯服务并撤销您同意接收第三方的促销优惠。 只需按照任何商业电子邮件底部的取消订阅链接即可。 您可以取消订阅并停止服务。 您的账号和有关Quantify Ninja的相关信息将被永久删除,除非我们因任何业务或法律义务而被要求保留。`,
            PPTitle8: `第三方网站`,
            PPTitle9: `加州居民`,
            PPTitle10: `COPPA合规`,
            PPTitle11: `本隐私政策的变更`,
            PPTitle12: `取消`,
            PPText22: `本网站/ Quantify Ninja可能包含第三方网站的广告或链接。 请确认我们不认可或推荐在链接的第三方网站上展示或提供的任何产品,服务或内容。`,
            PPText23: `Shir Estate LTD不对因您访问,依赖或使用其他网站提供的任何产品,服务或内容有关而侵犯您的隐私权而可能发生的任何损失,损害,责任或索赔承担责任。`,
            PPText24: `如果您位于加利福尼亚州,您可以要求我们的隐私官查询有权访问您个人信息的第三方,并要求我们可能在当年之前披露您的个人信息的所有第三方的详细信息。 但是,如果根据适用法律或法规不允许此类披露,我们可能会拒绝您的请求。`,
            PPText25: `我们完全遵守您所在司法管辖区的儿童在线隐私保护法或类似法律。 这些服务仅供合法成熟用户使用。 如果您未达到您所在司法辖区的法定期限年龄,则应视为您在法定监护人的指导和监督下使用Quantify Ninja /服务。 无论如何,未满13岁的未成年人不得使用服务。`,
            PPText26: `但是,如果您怀疑任何未成年人在本网站上提交了他或她的个人信息,无论是出于意外还是其他目的,请联系我们的隐私官`,
            PPText27: `我们将做出商业上合理的努力从网站/服务器上删除此类信息。 但是,从备份服务器中删除未成年人的任何信息可能需要一些时间。 Shir Estate LTD保留自行决定删除本网站上任何和所有未成年人个人信息的权利。`,
            PPText28: `Shir Estate LTD保留随时自行决定编辑,修改或替换本隐私政策的权利。 如果本隐私政策被修改,我们将在本页面发布更新并采取商业上合理的努力通知您,但您有责任定期访问本网站并保持更新。 在此类修改后继续访问本网站或使用Quantify Ninja将被视为您同意遵守本隐私政策的最新版本。`,
            PPText29: `Shir Estate保留自行决定取消非活动账户的权利,恕不另行通知。 客户可以通过向我们的客户支持部门发送电子邮件或点击’联系我们’链接随时取消服务。`,
            PPText30: `如果您对本隐私声明有任何疑问或疑虑,请将您的疑问或反馈发送给`,
            PPText31: `您也可以通过电子邮件与我们联系: `,
            PPText32: `Shir Estate LTD.`,
            helpEmail: `help@quantifyninja.com`,
            businessDetails: `业务详情`,
            businessDetailsText: `这将是发票上的商业号码。 商家号码是您的法人实体增值税号码。 这可能是您的公司增值税公司或您的个人ID号。`,
            addRegionTitle: `快将完成了`,
            addRegionSubTitle: `现在我们需要获得访问您的卖家帐户的权限,请选择您所在的地区`,
            choose: `选择`,
            northAmericaAccount: `北美账户`,
            signOutHere: `退出`,
            orYouCan: `或者您可以`,
            passwordTooltip: `密码长度必须至少为6个字符,并且包含数字（0-9）,小写字母（a-z）和非字母数字字符: `,
            errorMoreThan6: `应包含至少6个符号`,
            createNewPassword: `创建新密码`,
            repeatPassword: `重复密码`,
            loginTitle: `您的电子商务需要什么`,
            loginTitleText: `我们的网站应用程序为您提供了惊人的功能`,
            loginTitleText2: `充分利用亚马逊提升销量！`,
            grantAccessTitle: `创建结算方案......`,
            grantAccessTitle2: `重定向到PayPal ...`,
            grantAccessTitle3: `检查您的帐户详细信息`,
            grantAccessSubTitle4: `需要几秒钟......`,
            grantAccessTitle4: `好消息！`,
            grantAccessTitle5: `哎呀！`,
            grantAccessText1: `我们已经成功验证了您的帐户,您可以开始使用Quantify Ninja。`,
            grantAccessText2: `此卖家ID已被其他用户使用。`,
            getStarted: `开始`,
            grantAccessTitle6: `谢谢！`,
            grantAccessText3: `我们正在处理您的付款,您很快就可以使用高级会员功能！`,
            grantAccessText4: `交易已经成功完成。 重定向到应用程序...`,
            grantAccessText5: `我们很抱歉,但Paypal未批准您的付款。 付款状态: 免费试用14天`,
            grantAccessTitle7: `只差一步即可完成`,
            grantAccessText6: `完成付款后完成添加区域`,
            currentRegions: `当前地区: `,
            grantAccessText7: `您要添加1个区域: `,
            price: `价钱`,
            tokenUsedMessage: `您的令牌已被使用,请再试一次`,
            tokenExpiredMessage: `您的令牌已过期,请再试一次`,
            tokenNotExistMessage: `您的令牌不存在,请再试一次`,
            tokenInvalidMessage: `您的令牌无效,请再试一次`,
            facebookConnected: `您已将Facebook帐户成功连接到应用`,
            facebookConnectFailed: `出了一些问题,请稍后再试`,
            forgotPass: `忘记密码？`,
            pressHere: `按这里`,
            swithToPrevInterface: `切换到上一个界面`,
            firstName: `名字`,
            lastName: `姓氏`,
            and: `和`,
            loginHere: `在这里登录`
      };

      public changeLang(currentLang, nextLang) {
            const lang1 = JSON.parse(JSON.stringify(currentLang));
            const lang2 = JSON.parse(JSON.stringify(nextLang));
            const langDefault = JSON.parse(JSON.stringify(this.textEN));
            const keyArray1 = Object.keys(lang1);
            for (let i = 0; keyArray1.length > i; i++) {
                  if (lang2[keyArray1[i]]) {
                        lang1[keyArray1[i]] = lang2[keyArray1[i]];
                  } else {
                        lang1[keyArray1[i]] = langDefault[keyArray1[i]];
                  }
            }
            this.currentText = lang1;

      }

      public setLangBadge(lang): void {
            switch (lang) {
                  case 'EN':
                        this.currentLangTitle.text = this.currentText.ENG;
                        this.currentLangTitle.img = 'assets/images/uk-translate.svg';
                        this.changeLangDetect.next();
                        break;
                  case 'ES':
                        this.currentLangTitle.text = this.currentText.ES;
                        this.currentLangTitle.img = 'assets/images/es-translate.svg';
                        this.changeLangDetect.next();
                        break;
                  case 'RO':
                        this.currentLangTitle.text = this.currentText.RO;
                        this.currentLangTitle.img = 'assets/images/ro-translate.svg';
                        this.changeLangDetect.next();
                        break;
                  case 'CN':
                        this.currentLangTitle.text = this.currentText.CN;
                        this.currentLangTitle.img = 'assets/images/ch-translate.svg';
                        this.changeLangDetect.next();
                        break;
                  default:
            }
      }


}
