<div class="profitTable">
  <table class="table" matSort (matSortChange)="sortData($event)">
    <thead>
      <tr>
        <th>
          <mat-icon [matMenuTriggerFor]="search" class="mat-icon-size mb-0 align-middle header-icon-color cursor-pointer">
            search</mat-icon>
          <mat-menu #search="matMenu">
            <div class="px-25 pt-10 pb-0 filters custom-selects main-search" (click)="$event.stopPropagation()" (keydown)="$event.stopPropagation()">
              <mat-form-field>
                <mat-label>Search</mat-label>
                <input [formControl]="searchCtrl" matInput type="text" autocomplete="off">
                <mat-icon matSuffix class="cursor-pointer align-middle" (click)="cancelSearch()">close
                </mat-icon>
              </mat-form-field>
            </div>
          </mat-menu>
        </th>
        <th [ngClass]="{'d-none': !columnFilters.title}">
          <div class="d-flex align-items-center justify-content-center">
            <span mat-sort-header="title">title</span> <i class="material-icons" [matMenuTriggerFor]="title">
              arrow_drop_down
            </i>
          </div>
        </th>
        <th [ngClass]="{'d-none': !columnFilters.asin}">
          <div class="d-flex align-items-center justify-content-center">
            <span mat-sort-header="asin">asin</span> <i class="material-icons" [matMenuTriggerFor]="asin">
              arrow_drop_down
            </i>
          </div>
        </th>
        <th [ngClass]="{'d-none': !columnFilters.sku}">
          <div class="d-flex align-items-center justify-content-center">
            <span mat-sort-header="sku">sku</span> <i class="material-icons" [matMenuTriggerFor]="sku">
              arrow_drop_down
            </i>
          </div>
        </th>
        <th [ngClass]="{'d-none': !columnFilters.sales}">
          <div class="d-flex align-items-center justify-content-center">
            <span mat-sort-header="sales">sales</span> <i class="material-icons" [matMenuTriggerFor]="sales">
              arrow_drop_down
            </i>
          </div>
        </th>
        <th [ngClass]="{'d-none': !columnFilters.revenue}">
          <div class="d-flex align-items-center justify-content-center">
            <span mat-sort-header="revenue">revenue</span> <i class="material-icons" [matMenuTriggerFor]="revenue">
              arrow_drop_down
            </i>
          </div>
        </th>
        <th [ngClass]="{'d-none': !columnFilters.cost}">
          <div class="d-flex align-items-center justify-content-center">
            <span mat-sort-header="cost">cost</span> <i class="material-icons" [matMenuTriggerFor]="cost">
              arrow_drop_down
            </i>
          </div>
        </th>
        <th [ngClass]="{'d-none': !columnFilters.profit}">
          <div class="d-flex align-items-center justify-content-center">
            <span mat-sort-header="profit">profit</span> <i class="material-icons" [matMenuTriggerFor]="profit">
              arrow_drop_down
            </i>
          </div>
        </th>
        <th [ngClass]="{'d-none': !columnFilters.margin}">
          <div class="d-flex align-items-center justify-content-center">
            <span mat-sort-header="margin">margin</span> <i class="material-icons" [matMenuTriggerFor]="margin">
              arrow_drop_down
            </i>
          </div>
        </th>
        <th [ngClass]="{'d-none': !columnFilters.promos}">
          <div class="d-flex align-items-center justify-content-center">
            <span mat-sort-header="promos">promos</span> <i class="material-icons" [matMenuTriggerFor]="promos">
              arrow_drop_down
            </i>
          </div>
        </th>
        <th [ngClass]="{'d-none': !columnFilters.ppcUnits}">
          <div class="d-flex align-items-center justify-content-center">
            <span mat-sort-header="ppc">ppc <br /> units</span> <i class="material-icons" [matMenuTriggerFor]="ppc">
              arrow_drop_down
            </i>
          </div>
        </th>
        <th [ngClass]="{'d-none': !columnFilters.ppcExpenses}">
          <div class="d-flex align-items-center justify-content-center">
            <span mat-sort-header="ppcExpenses">ppc <br /> expenses</span> <i class="material-icons" [matMenuTriggerFor]="ppcExpenses">
              arrow_drop_down
            </i>
          </div>
        </th>
        <th [ngClass]="{'d-none': !columnFilters.ppcPerUnit}">
          <div class="d-flex align-items-center justify-content-center">
            <span mat-sort-header="ppcPerUnit">PPC <br /> Per UNIT </span> <i class="material-icons" [matMenuTriggerFor]="ppcPerUnit">
              arrow_drop_down
            </i>
          </div>
        </th>
        <th [ngClass]="{'d-none': !columnFilters.roi}">
          <div class="d-flex align-items-center justify-content-center">
            <span mat-sort-header="roi">roi</span> <i class="material-icons" [matMenuTriggerFor]="roi">
              arrow_drop_down
            </i>
          </div>
        </th>
        <th [ngClass]="{'d-none': !columnFilters.detail}">detail</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let main of profitbreakdowndataSource.rowData ; let mainIndex = index">
        <td [attr.colspan]="colspanCount" class="p-0 border-0">
          <table class="table">
            <tr>
              <!-- <td>
                <mat-icon *ngIf="main && main.length > 0" class="h1 mb-0 align-middle profit-breakdown-icon-color cursor-pointer">
                  {{expandedElement == mainIndex ? 'keyboard_arrow_down' : 'keyboard_arrow_right'}}</mat-icon>
              </td> -->
              <td colspan="1" [ngClass]="{'d-none': !columnFilters.title}" class="shortNameCls tooltip-details text-left">
                <span *ngIf="main.inStock == 0" class="badge badge-pill badge-warning mb-2">Out of Stock</span>
                <div class="d-flex align-items-center overflow-hidden cursor-pointer" (click)="editShortName(main)">
                  <img class="width-32 mr-2" *ngIf="!main.image" src="assets/images/noImage.png" [alt]="main.scopType">
                  <img class="width-32 mr-2" *ngIf="main.image" [src]="main.image" [alt]="main.scopType">
                  <p class="mb-0">
                    <mat-icon class="mat-edit-icon">edit</mat-icon>
                    {{main ? main.title : '-'}}
                  </p>
                </div>
                <div class="shadow-sm card">
                  <div class="card-body">
                    <p>Product Name: {{main.title}}</p>
                    <p>Asin: {{main.asin}}</p>
                    <p>Sku: {{main.sku}}</p>
                  </div>
                </div>
              </td>
              <td [ngClass]="{'d-none': !columnFilters.asin}">
                {{main.asin}}
              </td>
              <td [ngClass]="{'d-none': !columnFilters.sku}">
                {{main.sku}}
              </td>
              <td [ngClass]="{'d-none': !columnFilters.sales}">
                {{main.sales ? (main.sales.value | number) : '&nbsp;'}}
                <span class="font-size-11 line-height-15 d-block" *ngIf="main.sales && main.sales.change"
                  [ngClass]="main.sales.change > 0 ? 'text-green' : 'profit-red-color'">
                  <mat-icon class="mat-icon-size mb-0">
                    {{main.sales.change > 0 ? 'trending_up' : 'trending_down'}}</mat-icon>
                  {{main.sales.change | number}}%
                </span>
              </td>
              <td [ngClass]="{'d-none': !columnFilters.revenue}">
                {{main.revenue ? (main.revenue.value | currency: currencySymbol : 'symbol' : '1.0-0') : ''}}
                <span class="font-size-11 line-height-15 d-block" *ngIf="main.revenue && main.revenue.change"
                  [ngClass]="main.revenue.change > 0 ? 'text-green' : 'profit-red-color'">
                  <mat-icon class="mat-icon-size mb-0">
                    {{main.revenue.change > 0 ? 'trending_up' : 'trending_down'}}</mat-icon>
                  {{main.revenue.change | number}}%
                </span>
              </td>
              <td [ngClass]="{'d-none': !columnFilters.cost}">
                <div class="cursor-pointer" (click)="editProductCost(main)">
                  {{main.cost ? (main.cost.value | currency: currencySymbol : 'symbol' : '1.0-0') : ''}}
                  <mat-icon class="mat-edit-icon">edit</mat-icon>
                  <span class="font-size-11 line-height-15 d-block" *ngIf="main.cost && main.cost.change"
                    [ngClass]="main.cost.change > 0 ? 'text-green' : 'profit-red-color'">
                    <mat-icon class="mat-icon-size mb-0">
                      {{main.cost.change > 0 ? 'trending_up' : 'trending_down'}}</mat-icon>
                    {{main.cost.change | number}}%
                  </span>
                </div>
              </td>
              <td [ngClass]="{'d-none': !columnFilters.profit}">
                {{main.profit ? (main.profit.value | currency: currencySymbol : 'symbol' : '1.0-0') : ''}}
                <span class="font-size-11 line-height-15 d-block" *ngIf="main.profit && main.profit.change"
                  [ngClass]="main.profit.change > 0 ? 'text-green' : 'profit-red-color'">
                  <mat-icon class="mat-icon-size mb-0">
                    {{main.profit.change > 0 ? 'trending_up' : 'trending_down'}}</mat-icon>
                  {{main.profit.change | number}}%
                </span>
              </td>
              <td [ngClass]="{'d-none': !columnFilters.margin}">
                {{main.margin ? (main.margin.value | number) +'%' : ''}}
                <span class="font-size-11 line-height-15 d-block" *ngIf="main.margin && main.margin.change"
                  [ngClass]="main.margin.change > 0 ? 'text-green' : 'profit-red-color'">
                  <mat-icon class="mat-icon-size mb-0">
                    {{main.margin.change > 0 ? 'trending_up' : 'trending_down'}}</mat-icon>
                  {{main.margin.change | number}}%
                </span>
              </td>
              <td [ngClass]="{'d-none': !columnFilters.promos}">
                {{main.promos ? (main.promos.value | number) : ''}}
                <span class="font-size-11 line-height-15 d-block" *ngIf="main.promos && main.promos.change"
                  [ngClass]="main.promos.change > 0 ? 'text-green' : 'profit-red-color'">
                  <mat-icon class="mat-icon-size mb-0">
                    {{main.promos.change > 0 ? 'trending_up' : 'trending_down'}}</mat-icon>
                  {{main.promos.change | number}}%
                </span>
              </td>
              <td [ngClass]="{'d-none': !columnFilters.ppcUnits}">
                {{main.ppc ? (main.ppc.value | number) : '&nbsp;'}}
                <span class="font-size-11 line-height-15 d-block" *ngIf="main.ppc && main.ppc.change"
                  [ngClass]="main.ppc.change > 0 ? 'text-green' : 'profit-red-color'">
                  <mat-icon class="mat-icon-size mb-0">
                    {{main.ppc.change > 0 ? 'trending_up' : 'trending_down'}}</mat-icon>
                  {{main.ppc.change | number}}%
                </span>
              </td>
              <td [ngClass]="{'d-none': !columnFilters.ppcExpenses}">
                {{main.ppcExpenses ? (main.ppcExpenses.value | number) : '&nbsp;'}}
                <span class="font-size-11 line-height-15 d-block" *ngIf="main.ppcExpenses && main.ppcExpenses.change"
                  [ngClass]="main.ppcExpenses.change > 0 ? 'text-green' : 'profit-red-color'">
                  <mat-icon class="mat-icon-size mb-0">
                    {{main.ppcExpenses.change > 0 ? 'trending_up' : 'trending_down'}}</mat-icon>
                  {{main.ppcExpenses.change | number}}%
                </span>
              </td>
              <td [ngClass]="{'d-none': !columnFilters.ppcPerUnit}">
                {{main.ppcPerUnit ? (main.ppcPerUnit.value | number) : '&nbsp;'}}
                <span class="font-size-11 line-height-15 d-block" *ngIf="main.ppcPerUnit && main.ppcPerUnit.change"
                  [ngClass]="main.ppcPerUnit.change > 0 ? 'text-green' : 'profit-red-color'">
                  <mat-icon class="mat-icon-size mb-0">
                    {{main.ppcPerUnit.change > 0 ? 'trending_up' : 'trending_down'}}</mat-icon>
                  {{main.ppcPerUnit.change | number}}%
                </span>
              </td>
              <td [ngClass]="{'d-none': !columnFilters.roi}">
                {{main.roi ? (main.roi.value | number)+'%' : ''}}
                <span class="font-size-11 line-height-15 d-block" *ngIf="main.roi && main.roi.change"
                  [ngClass]="main.roi.change > 0 ? 'text-green' : 'profit-red-color'">
                  <mat-icon class="mat-icon-size mb-0">
                    {{main.roi.change > 0 ? 'trending_up' : 'trending_down'}}</mat-icon>
                  {{main.roi.change | number}}%
                </span>
              </td>
              <td [ngClass]="{'d-none': !columnFilters.detail}">
                <div class="d-flex align-items-center justify-content-center">
                  <div class="cursor-pointer h1 mb-0 align-middle text-gray-light" style="display: contents;"
                    (click)="openProfitBrakedownDialog(main.brand,main.sku)">
                    <img height="18" class="d-inline-block" width="18" src="assets/images/profit_breakdown.png" />
                  </div>
                  <mat-icon class="h1 mb-0 align-middle text-gray-light cursor-pointer" (click)="showGraph(main.brand,mainIndex,main.sku)">
                    bar_chart</mat-icon>
                </div>
              </td>
            </tr>

            <tr class="barchart" *ngIf="chartExpandedElement == mainIndex">
              <td [attr.colspan]="colspanCount - 1">
                <!-- <div class="d-flex justify-content-end">
                  <mat-form-field>
                    <mat-label>Select Metrix </mat-label>
                    <mat-select multiple [formControl]="selectMatrix">
                      <mat-option>All</mat-option>
                      <mat-option value="sales">Sales</mat-option>
                      <mat-option value="ppc">PPC</mat-option>
                      <mat-option value="promotion">Promotion</mat-option>
                      <mat-option value="revenue">Revenue</mat-option>
                      <mat-option value="cost">Cost</mat-option>
                      <mat-option value="profit">Profit</mat-option>
                      <mat-option value="margin">Margin</mat-option>
                      <mat-option value="roi">Roi</mat-option>
                      <mat-option value="refunds">Refunds</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div> -->
                <div echarts [options]="echartProfitBreakdownBarOptions" style="height: 400px"></div>
              </td>
            </tr>
          </table>
        </td>
      </tr>
      <tr class="font-weight-bold backGround">
        <td>Total</td>
        <td [ngClass]="{'d-none': !columnFilters.title}">{{countProduct | number}} Products</td>
        <td [ngClass]="{'d-none': !columnFilters.asin}"></td>
        <td [ngClass]="{'d-none': !columnFilters.sku}"></td>
        <td [ngClass]="{'d-none': !columnFilters.sales}">{{totalSalesValue | number}}</td>
        <td [ngClass]="{'d-none': !columnFilters.revenue}">{{(totalRevenueValue) | currency: currencySymbol : 'symbol' : '1.0-0'}}</td>
        <td [ngClass]="{'d-none': !columnFilters.cost}">{{(totalCostValue) | currency: currencySymbol : 'symbol' : '1.0-0' }}</td>
        <td [ngClass]="{'d-none': !columnFilters.profit}">{{(totalProfitValue) | currency: currencySymbol : 'symbol' : '1.0-0'}}</td>
        <td [ngClass]="{'d-none': !columnFilters.margin}">{{totalMarginValue | number}}%</td>
        <td [ngClass]="{'d-none': !columnFilters.promos}">{{totalPromosValue | number}}</td>
        <td [ngClass]="{'d-none': !columnFilters.ppcUnits}">{{totalPPCValue | number}}</td>
        <td [ngClass]="{'d-none': !columnFilters.ppcExpenses}">{{totalPPCExpensesValue | number}}</td>
        <td [ngClass]="{'d-none': !columnFilters.ppcPerUnit}">{{totalPPCPerUnitValue | number}}</td>
        <td [ngClass]="{'d-none': !columnFilters.roi}">{{totalRoiValue}}%</td>
        <td [ngClass]="{'d-none': !columnFilters.detail}">-</td>
      </tr>
    </tbody>
  </table>
</div>
<mat-menu #title="matMenu" class="menu-form-wrapper">
  <filter-dropdown-menu [filterType]="'title'" [rowType]="'textual'" [groupType]="'none'" (messageToEmit)="getMessage($event)"
    [profitbreakdowndataSource]="profitbreakdowndataSource">
  </filter-dropdown-menu>
</mat-menu>
<mat-menu #asin="matMenu" class="menu-form-wrapper">
  <filter-dropdown-menu [filterType]="'asin'" [rowType]="'textual'" [groupType]="'none'" (messageToEmit)="getMessage($event)"
    [profitbreakdowndataSource]="profitbreakdowndataSource">
  </filter-dropdown-menu>
</mat-menu>
<mat-menu #sku="matMenu" class="menu-form-wrapper">
  <filter-dropdown-menu [filterType]="'sku'" [rowType]="'textual'" [groupType]="'none'" (messageToEmit)="getMessage($event)"
    [profitbreakdowndataSource]="profitbreakdowndataSource">
  </filter-dropdown-menu>
</mat-menu>
<mat-menu #sales="matMenu" class="menu-form-wrapper">
  <filter-dropdown-menu [filterType]="'sales'" [rowType]="'number'" [groupType]="'none'" (messageToEmit)="getMessage($event)"
    [profitbreakdowndataSource]="profitbreakdowndataSource">
  </filter-dropdown-menu>
</mat-menu>
<mat-menu #ppc="matMenu" class="menu-form-wrapper">
  <filter-dropdown-menu [filterType]="'ppc'" [rowType]="'number'" [groupType]="'none'" (messageToEmit)="getMessage($event)"
    [profitbreakdowndataSource]="profitbreakdowndataSource">
  </filter-dropdown-menu>
</mat-menu>
<mat-menu #ppcExpenses="matMenu" class="menu-form-wrapper">
  <filter-dropdown-menu [filterType]="'ppcExpenses'" [rowType]="'number'" [groupType]="'none'" (messageToEmit)="getMessage($event)"
    [profitbreakdowndataSource]="profitbreakdowndataSource">
  </filter-dropdown-menu>
</mat-menu>
<mat-menu #ppcPerUnit="matMenu" class="menu-form-wrapper">
  <filter-dropdown-menu [filterType]="'ppcPerUnit'" [rowType]="'number'" [groupType]="'none'" (messageToEmit)="getMessage($event)"
    [profitbreakdowndataSource]="profitbreakdowndataSource">
  </filter-dropdown-menu>
</mat-menu>
<mat-menu #promos="matMenu" class="menu-form-wrapper">
  <filter-dropdown-menu [filterType]="'promos'" [rowType]="'number'" [groupType]="'none'" (messageToEmit)="getMessage($event)"
    [profitbreakdowndataSource]="profitbreakdowndataSource">
  </filter-dropdown-menu>
</mat-menu>
<mat-menu #revenue="matMenu" class="menu-form-wrapper">
  <filter-dropdown-menu [filterType]="'revenue'" [rowType]="'number'" [groupType]="'none'" (messageToEmit)="getMessage($event)"
    [profitbreakdowndataSource]="profitbreakdowndataSource">
  </filter-dropdown-menu>
</mat-menu>
<mat-menu #cost="matMenu" class="menu-form-wrapper">
  <filter-dropdown-menu [filterType]="'cost'" [rowType]="'number'" [groupType]="'none'" (messageToEmit)="getMessage($event)"
    [profitbreakdowndataSource]="profitbreakdowndataSource">
  </filter-dropdown-menu>
</mat-menu>
<mat-menu #profit="matMenu" class="menu-form-wrapper">
  <filter-dropdown-menu [filterType]="'profit'" [rowType]="'number'" [groupType]="'none'" (messageToEmit)="getMessage($event)"
    [profitbreakdowndataSource]="profitbreakdowndataSource">
  </filter-dropdown-menu>
</mat-menu>
<mat-menu #margin="matMenu" class="menu-form-wrapper">
  <filter-dropdown-menu [filterType]="'margin'" [rowType]="'number'" [groupType]="'none'" (messageToEmit)="getMessage($event)"
    [profitbreakdowndataSource]="profitbreakdowndataSource">
  </filter-dropdown-menu>
</mat-menu>
<mat-menu #roi="matMenu" class="menu-form-wrapper">
  <filter-dropdown-menu [filterType]="'roi'" [rowType]="'number'" [groupType]="'none'" (messageToEmit)="getMessage($event)"
    [profitbreakdowndataSource]="profitbreakdowndataSource">
  </filter-dropdown-menu>
</mat-menu>