import { ChangeDetectorRef, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { UserService } from '../../../services/user.service';
import { AlertsService } from './../../../services/alerts.service';
import { TextService } from "../../../services/text.service";
import { Functions } from "../../../services/functions";
import { MatDialog } from '@angular/material/dialog';
import { SelectSpecificProductsDialogComponent } from '../../dialogs/select-specific-products-dialog/select-specific-products.component';
import { ConfirmationDialogComponent } from '../../dialogs/confirmation-dialog/confirmation-dialog.component';
import { ConstService } from './../../../services/const-service';
import { MixpanelService } from 'src/app/services/mixpanel.service';


@Component({
  selector: 'ninja-asin-alerts',
  templateUrl: './asin-alerts.component.html',
  styleUrls: ['./asin-alerts.component.scss']
})

export class AsinAlertsComponent implements OnInit {

  public data: any;
  public dataLoaded = false;
  public MyConf = false;
  public maxParentAsin = 3;
  public maxCompetetors = 4;
  public curEditItem: any;
  public state: string;

  constructor(public userService: UserService,
    public functions: Functions,
    public textService: TextService,
    public alertsService: AlertsService,
    public matDialog: MatDialog,
    public constService: ConstService, public mixpanelService: MixpanelService) {
    this.mixpanelService.init(null);
  }

  public ngOnInit(): void {
    this.userService.loaderObesrver.next(true);
    this.alertsService.fullSellerData(this.userService.currentUser.sellerId).subscribe((data) => {
      this.data = data;
      this.dataLoaded = true;
      this.updateView();
      this.userService.loaderObesrver.next(false);
    });
  }

  public onMyAlerts() {
    this.mixpanelService.track('q alerts - my alerts tab clicked');
  }

  public onCompetitorsAlerts() {
    this.mixpanelService.track('q alerts - competitors alerts tab clicked');
  }

  public updateView(): void {

  }

  public AddNewAsin(): void {
    /*this.matDialog.open(ConfirmationDialogComponent, {
      data: {
        'dialogFor': 'custom',
        'title': "under construction",
        'message': "This feature will be available soon"
      },
    });
    return;*/

    this.matDialog.open(SelectSpecificProductsDialogComponent, {
      data: {
        allSalesChannels: this.textService.currentText.allChannels,
        allProducts: true,
        time: 'Default',
        single: true,
        productsskus: [],
        showSaleChannel: true,
      }
    }).afterClosed().subscribe(result => {
      if ((result) && (result.length > 0)) {
        var newAsin = {
          mp: result[0].saleChannel ? result[0].saleChannel : 'US',
          productName: result[0].fullName,
          publicId: null,
          sellerId: null,
          source: result[0].asin,
          img: result[0].img,
          competitors: []
        };

        if (this.isAsinSelected(result[0].asin)) {
          this.matDialog.open(ConfirmationDialogComponent, {
            data: {
              'dialogFor': 'custom',
              'title': "Alerts",
              'message': "This Asin is already in use."
            },
          });
        } else if (((!this.curEditItem) || (this.curEditItem.itmParent == null)) ||
          (this.data.competitorsFullData.length == 0)) {

          this.data.competitorsFullData.unshift(newAsin);
          this.SendFullData();
        } else {
          for (let x = 0; x < this.data.competitorsFullData.length; x++) {
            if (this.data.competitorsFullData[x].source == this.curEditItem.itmParent) {
              this.data.competitorsFullData[x] = newAsin;
              this.SendFullData();
            }
          }
          /*var curParent=this.data.competitorsFullData.filter(item => item.source==this.curEditItem.itmParent);
          if (curParent.length)
          {
            curParent[0]=newAsin;
          }*/

        }

        this.curEditItem = null;

      }
    }
    );
  }

  public OnMenuDelete(asin, parent): void {
    if (!parent)
      return;
    for (let x = 0; x < this.data.competitorsFullData.length; x++) {
      if (this.data.competitorsFullData[x].source == parent) {
        if (asin == null) {
          this.data.competitorsFullData.splice(x, 1);
          this.SendFullData();
        } else {
          for (let c = 0; c < this.data.competitorsFullData[x].competitors.length; c++) {
            if (this.data.competitorsFullData[x].competitors[c].asin == asin) {
              let compArray = this.data.competitorsFullData[x].competitors;
              compArray.splice(c, 1);
              this.SendFullData();
            }
          }
        }

      }
    }

  }

  public isAsinSelected(asin): boolean {
    for (let x = 0; x < this.data.competitorsFullData.length; x++) {
      if (this.data.competitorsFullData[x].source == asin) {
        return true;
      }
    }

    return false;
  }

  public onAddCompetetor(itmParent): void {
    /*this.matDialog.open(ConfirmationDialogComponent, {
      data: {
        'dialogFor': 'custom',
        'title': "under construction",
        'message': "This feature will be available soon"
      },
    });
    return;*/

    let curParent = this.data.competitorsFullData.filter(item => item.source == itmParent);
    if (curParent.length) {

      curParent = curParent[0];
      if ((!curParent.competitors) || (curParent.competitors.length == 0)) {
        curParent.competitors = [];
        curParent.competitors.push(
          {
            asin: null,
            img: null,
            mp: null,
            productName: null
          }
        );
      } else {
        var isInEdit = curParent.competitors.filter(item => item.asin == null);
        if (isInEdit.length)
          return;
        curParent.competitors.unshift(
          {
            asin: null,
            img: null,
            mp: null,
            productName: null
          });
      }


      this.curEditItem = { editor: null, itmAsin: null, itmParent: itmParent };
    }
  }

  public onMenuDelete(itmAsin, itmParent): void {
    let curParent = this.data.competitorsFullData.filter(item => item.source == itmParent);
    if (curParent.length) {
      for (let c = 0; c < curParent[0].competitors.length; c++) {
        if (curParent[0].competitors[c].asin == itmAsin) {
          curParent[0].competitors.splice(c, 1);
          this.SendFullData();
        }
      }
    }
  }

  public OnMenuEdit(editor, itmAsin, itmParent): void {
    if (editor && itmAsin)
      editor.editMode = true;
    else
      this.AddNewAsin();
    this.curEditItem = { itmAsin: itmAsin, itmParent: itmParent, editor: editor };
  }


  public disableAddParents(): boolean {
    this.mixpanelService.track('q alerts - add  asin disabled clicked');
    if (!this.data.competitorsFullData)
      return false;
    return this.data.competitorsFullData.length >= this.maxParentAsin;
  }

  public onCompEditUpdate(value): void {
    if (this.curEditItem == null)
      return;
    let curParent = this.data.competitorsFullData.filter(item => item.source == this.curEditItem.itmParent);

    if (curParent.length) {
      var bCancel = false;
      {
        if ((this.curEditItem) && (value.value == this.curEditItem.itmParent)) {
          bCancel = true;
          this.matDialog.open(ConfirmationDialogComponent, {
            data: {
              'dialogFor': 'custom',
              'title': "Alerts",
              'message': "This asin is already tracked as source."
            },
          });
        }
      }
      {
        let testExist = curParent[0].competitors.filter(item => item.asin == value.value);
        if (testExist.length > 0) {
          if ((value.value) && (value.value != "")) {
            bCancel = true;
            this.matDialog.open(ConfirmationDialogComponent, {
              data: {
                'dialogFor': 'custom',
                'title': "Alerts",
                'message': "This Asin is already in use."
              },
            });
          }
        }
      }

      if (bCancel) {
        if (this.curEditItem.editor)
          this.curEditItem.editor.data = value.prevValue;
        else if (value.editor)
          value.editor.data = value.prevValue;

        if ((value.prevValue == null) && (this.curEditItem.itmAsin == null)) {
          this.onMenuDelete(null, this.curEditItem.itmParent);
        }
        return;
      }

      let curComp = curParent[0].competitors.filter(item => item.asin == this.curEditItem.itmAsin);
      if (curComp.length) {

        if (curComp[0].asin != value.value) {
          if (value.value != "") {
            curComp[0].productName = "";
            curComp[0].img = "";
            curComp[0].asin = value.value;
          } else {
            this.onMenuDelete(this.curEditItem.itmAsin, this.curEditItem.itmParent);
          }
          this.SendFullData();
        } else if (curComp[0].asin == null) {
          this.onMenuDelete(this.curEditItem.itmAsin, this.curEditItem.itmParent);
          this.SendFullData();
        }

      }
    }

    this.curEditItem = null;
    return;
  }

  public disableAddComp(itmParent): boolean {
    let curParent = this.data.competitorsFullData.filter(item => item.source == itmParent);
    if (curParent.length) {
      if ((!curParent[0].competitors) || (curParent[0].competitors.length < this.maxCompetetors))
        return false;
    }
    return true;

  }

  public onCompEditClose(value): void {
    if ((this.curEditItem) && (((value.value == '' || value.value == null) && value.prevValue == null))) {
      if (this.curEditItem.itmAsin == null)
        this.onMenuDelete(this.curEditItem.itmAsin, this.curEditItem.itmParent);
    }
    this.curEditItem = null;
    return;

  }
  public OnConfigurationChange(source, state): void {
    console.log("state 1", state)
    this.state = state.checked ? 'on' : 'off';
    console.log("state 2", this.state)
    this.alertsService.UpdateConfigurationChange(this.userService.currentUser.sellerId, source, state.checked, this.MyConf).subscribe();
  }

  public OnEmConfigurationChange(): void {
    this.mixpanelService.track('q alerts - send email alert checked', { 'state': this.data.compEmailAlert ? 'on' : 'off' });
    this.alertsService.UpdateAlertOptions(this.userService.currentUser.sellerId, this.data.compFbAlert, this.data.compEmailAlert).subscribe();
  }

  public OnFbEmConfigurationChange(): void {
    this.mixpanelService.track('q alerts - send email alert checked', { 'state': this.data.compFbAlert ? 'on' : 'off' });
    if ((this.data.compFbAlert) && (this.data.userFbCredLink) && (this.data.userFbCredLink != "")) {
      this.matDialog.open(ConfirmationDialogComponent, {
        data: {
          'dialogFor': 'messenger-alerts',
          userFbCredLink: this.data.userFbCredLink
        },
      }).afterClosed().subscribe();
    }

    this.alertsService.UpdateAlertOptions(this.userService.currentUser.sellerId, this.data.compFbAlert, this.data.compEmailAlert).subscribe();
  }
  public valueToggle(checker: boolean): string {
    return checker ? 'On' : 'Off';
  }


  public getFlagFromSaleChannel(mp): string {
    if (!mp)
      return "";
    var country = this.constService.countriesFlags.filter(function (item) {
      return (item.value == mp);
    });
    if (!country.length)
      return "";
    return country[0].image;

  }

  public SendFullData(): void {
    this.alertsService.UpdateFullData(this.userService.currentUser.sellerId, this.data.competitorsFullData).subscribe((data) => {
      return;
    });
  }

  public updateAsin(item, event) {
    return;
  }
}