<script src="../../../../services/text.service.ts"></script>
<div class="dashboard" [class.wide]="userService.smallSidebar">
  <div class="dashboard-header">
    <div class="head-container">
      <div class="container-fluid">
        <div class="row">
          <div class="col-lg-4 col-md-12">
            <h2>{{textService.currentText.fbaTitle}}</h2>
            <div class="sub-head-container">
              <p>
                {{textService.currentText.fbaSubTitle1}} <span class="email">{{user?.emailFrom}}</span>
              </p>
              <p>
                {{textService.currentText.fbaSubTitle2}}
                <ng-container *ngIf="userService.currentCountry === 'US'">
                  {{textService.currentText.northAmerica}}
                </ng-container>
                <ng-container *ngIf="userService.currentCountry === 'UK'">
                  EU
                </ng-container>
                <ng-container *ngIf="userService.currentCountry === 'JP'">
                  JP
                </ng-container>
                <ng-container *ngIf="userService.currentCountry === 'AU'">
                  AU
                </ng-container>
                {{textService.currentText.account}}
                <span class="edit-btn action-el" (click)="mixpanelService.track('q sender email popup - open');editEmail()">
                  {{textService.currentText.clickHereToEdit}}
                </span>
              </p>
            </div>
          </div>
          <div class="col-lg-8 col-md-12 d-flex flex-column flex-md-row justify-content-center justify-content-lg-end mt-4 mt-lg-0">
            <div class="d-flex flex-column order-2 order-md-0" *ngIf="isReviewRequestTemplates">
              <button mat-button mat-raised-button  (click)="googleAnalyticsEventsService.emitEvent('Button', 'Create New Request Review', 'Click', 1);
              mixpanelService.track('q request review popup - open');goToRequestReviewTemplate(); userService.currentPage = 'fba'"
                class="secondary-btn mr-0 mr-md-4 mt-4">
                {{textService.currentText.createNewRequestReview}}
              </button>
              <a [routerLink]="" class="what-is-this" (click)="whatIsThis()">
                <p>{{textService.currentText.whatIsThis}}</p>
                <img src="assets/icons/help-red.png" alt="">
              </a>
            </div>
            <div class="d-flex flex-column">
              <div class="last-messages" (click)="navigateToLastMessagesSent()">
                <img src="assets/icons/last-messages-send-icon.svg" alt="">
                <p>{{textService.currentText.lastMessagesSent}}</p>
              </div>
              <button mat-button mat-raised-button (click)="googleAnalyticsEventsService.emitEvent('Button', 'Create New FBA', 'Click', 1);
              mixpanelService.track('q create new fba - clicked');goToAddTemplate(); userService.currentPage = 'fba'" class="secondary-btn">
                {{textService.currentText.createNewFba}}
              </button>
            </div>
        </div>
      </div>
    </div>
  </div>
</div>
  <div class="dashboard-container">
    <div class="filters-container" *ngIf="user?.list?.length > 0">
      <div class="a-side">
        <div class="btn-container" (click)="isFilters = !isFilters">
          <button mat-button mat-raised-button class="default-btn filter-btn" type="button" [class.active]="isFilters">
            {{textService.currentText.filters}}
          </button>
        </div>
        <form class="filters custom-selects" [class.active]="isFilters">
          <div class="select-holder">
            <div (click)="functions.setDropDownPosition(timeFrame, 'days')" #timeFrame [class.active]="days.panelOpen" class="select-wrapper">
              <mat-form-field>
                <mat-select disableOptionCentering [placeholder]="textService.currentText.timeFrame" shouldPlaceholderFloat="false"
                  panelClass="default-dropdown days" disableRipple [(ngModel)]="filters.daySelected" #days name="days"
                  (selectionChange)="filterByDate(); checkFilters()">
                  <mat-option *ngFor="let select of constService.selectsDays" [value]="select.value">
                    {{ select.viewValue }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <div class="select-holder">
            <div (click)="functions.setDropDownPosition(event, 'events')" #event [class.active]="events.panelOpen" class="select-wrapper">
              <mat-form-field>
                <mat-select disableOptionCentering [placeholder]="textService.currentText.event" #events shouldPlaceholderFloat="false"
                  panelClass="default-dropdown events" [(ngModel)]="filters.eventSelected" disableRipple name="events"
                  (selectionChange)="checkFilters()">
                  <mat-option *ngFor="let select of constService.selectsEvents" [value]="select.value">
                    {{ select.viewValue }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <div class="select-holder">
            <div (click)="functions.setDropDownPosition(Status, 'status')" #Status [class.active]="status.panelOpen" class="select-wrapper">
              <mat-form-field class="status-filter">
                <mat-select disableOptionCentering [placeholder]="textService.currentText.status" shouldPlaceholderFloat="false"
                  panelClass="default-dropdown status" #status disableRipple [(ngModel)]="filters.status" name="status"
                  (selectionChange)="checkFilters()">
                  <mat-option *ngFor="let select of constService.selectsStatus" [value]="select.value">
                    {{ select.viewValue }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <div class="select-holder">
            <div (click)="functions.setDropDownPosition(SalesChannel, 'salesChannel')" #SalesChannel [class.active]="salesChannel.panelOpen"
              class="select-wrapper">
              <mat-form-field>
                <mat-select disableOptionCentering [placeholder]="textService.currentText.salesChannel" shouldPlaceholderFloat="false"
                  panelClass="default-dropdown salesChannel" #salesChannel disableRipple [(ngModel)]="filters.channel" name="sales-channel"
                  (selectionChange)="checkFilters()">
                  <mat-option *ngFor="let select of constService.salesChannels" [value]="select.value">
                    {{ select.viewValue }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <div class="input-wrapper">
            <mat-form-field>
              <input matInput [placeholder]="textService.currentText.name" [(ngModel)]="filters.name" name="name" (keyup)="checkFilters()">
            </mat-form-field>
            <div class="clear-input" *ngIf="filters.name" (click)="filters.name = '';
                 checkFilters()"></div>
          </div>
          <button mat-button mat-raised-button class="default-btn clear-btn new-primary-btn" type="button" (click)="clearFilter()">
            {{textService.currentText.clearAll}}
          </button>
        </form>
      </div>
      <div class="b-side">
        <button mat-button mat-raised-button class="default-btn b-side__btn secondary-outline-btn" (click)="mixpanelService.track('q automated messaging - export csv clicked');confirmExportCSV()">
          {{textService.currentText.exportToCSV}}
        </button>
      </div>
    </div>
    <div class="table-container">
      <div class="table-wrapper">
        <div class="table-header-wrapper">
          <div class="table-header fba-header">
            <div class="td"></div>
            <div class="td" (click)="functions.sortColumns(currentTemplates.list, 'percent')">
              {{textService.currentText.openRate}}</div>
            <div class="td" (click)="functions.sortColumns(currentTemplates.list, 'status')">
              {{textService.currentText.status}}</div>
            <div class="td" (click)="functions.sortColumns(currentTemplates.list, 'name', true)">
              {{textService.currentText.templateName}}</div>
            <div class="td" (click)="functions.sortColumns(currentTemplates.list, 'subject')">
              {{textService.currentText.subject}}</div>
            <div class="td" (click)="functions.sortColumns(currentTemplates.list, 'channellist')">
              {{textService.currentText.salesChannel}}</div>
            <div class="td" (click)="functions.sortColumns(currentTemplates.list, 'timeframe')">
              {{textService.currentText.timeFrame}}</div>
            <div class="td" (click)="functions.sortColumns(currentTemplates.list, 'event')">
              {{textService.currentText.event}}</div>
          </div>
        </div>
        <div class="table-holder fba-holder" *ngIf="currentTemplates?.list.length > 0">
          <div class="table fba-table">
            <ng-container *ngFor="let item of currentTemplates.list; let i = index">
              <div class="tr"  [class.with-alt]="item.showDetails"
                (click)="mixpanelService.track('q automated messaging - template clicked');navigateToEdit(item.id); userService.currentPage = 'fba'; $event.stopPropagation()">
                <div class="td">
                  <button mat-button [matMenuTriggerFor]="menu" (click)="$event.stopPropagation()"></button>
                  <mat-menu #menu="matMenu" xPosition="before" class="edit-row-menu">
                    <button mat-menu-item (click)="mixpanelService.track('q automated messaging - copy item');copyTemplate(item.id)">
                      <div class="list-inner-container">
                        <div class="menu-icon icon-copy"></div>
                        {{textService.currentText.copy}}
                      </div>
                    </button>
                    <button mat-menu-item (click)="mixpanelService.track('q automated messaging - edit item');navigateToEdit(item.id); userService.currentPage = 'fba'">
                      <div class="list-inner-container">
                        <div class="menu-icon icon-edit"></div>
                        {{textService.currentText.edit}}
                      </div>
                    </button>
                    <button mat-menu-item (click)="mixpanelService.track('q automated messaging - delete item');deleteConfirm(item.id)">
                      <div class="list-inner-container">
                        <div class="menu-icon icon-delete"></div>
                        {{textService.currentText.delete}}
                      </div>
                    </button>
                    <button mat-menu-item (click)="mixpanelService.track('q automated messaging - last sent item');navigateToLastMessagesSent(item.id)">
                      <div class="list-inner-container">
                        <div class="menu-icon icon-last-messages"></div>
                        {{textService.currentText.lastMessagesSent}}
                      </div>
                    </button>
                  </mat-menu>
                </div>
                <div *ngIf="item.type != 'REQUEST_REVIEW'" class="td relative overflow-visible">
                  <span *ngIf="item.aSubject || item.aContent">{{item.sumPercent}}%
                    ({{item.sumOpen}}/{{item.sumTotal}})</span>
                  <span *ngIf="!item.aSubject && !item.aContent">{{item.percent}}% ({{item.open}}/{{item.total}})</span>
                  <!--<div class="reset-open-rate" *ngIf="!item.showDetails" (click)="openResetOpenRateConfirm(item.id, true); $event.stopPropagation();">-->
                  <!--<img src="assets/icons/reset-open-rate.svg" alt="">-->
                  <!--</div>-->
                </div>
                <div *ngIf="item.type == 'REQUEST_REVIEW'" class="td relative overflow-visible">
                  <span *ngIf="!item.aSubject && !item.aContent">({{item.open}})</span>
                </div>
                <div class="td">
                  <mat-checkbox class="square-checkbox" [checked]="item.status"
                    (click)="item.status = !item.status; changeStatus(item.id, i, item.status); $event.stopPropagation(); $event.preventDefault()"
                    [class.uncheckedStatus]="!item.status"></mat-checkbox>
                  <!-- <mat-slide-toggle disableRipple [checked]="item.status"
                    (click)="item.status = !item.status; changeStatus(item.id, i, item.status); $event.stopPropagation(); $event.preventDefault()"
                    [class.uncheckedStatus]="!item.status">{{valueToggle(item.status)}}
                  </mat-slide-toggle> -->
                </div>
                <div class="td relative overflow-visible">
                  <div class="info-icons">
                    <img *ngIf="item.hasAttachment" src="assets/icons/clip.svg" alt="">
                    <img *ngIf="item.invoiceTemplateId" src="assets/icons/invoice-template.svg" alt="">
                  </div>
                  <p>{{item.name}}</p>
                </div>
                <div class="td">
                  <div class="d-flex justify-content-center align-items-center">
                    <img *ngIf="item.type == 'REQUEST_REVIEW'" src="assets/icons/requestareview.png" style="opacity: 0.5;margin-right: 10px;">
                    {{item.subject}}
                  </div>
                </div>
                <div class="td">
                  <ng-container *ngIf="item.channellist?.length === 0 || item.channellist?.length === 3">
                    {{textService.currentText.allChannels}}
                  </ng-container>
                  <ng-container *ngIf="item.channellist?.length === 1 || item.channellist?.length === 2">
                    {{item.channellist}}
                  </ng-container>
                </div>
                <div class="td">{{item.timeframe !== 0 ? item.timeframe + ' days' : 'ASAP'}}</div>
                <div class="td">
                  {{item.event}}
                  <ng-container *ngIf="item.aSubject || item.aContent">
                    <div class="arrow" [class.active]="item.showDetails" (click)="item.showDetails = !item.showDetails; $event.stopPropagation()">

                    </div>
                  </ng-container>

                </div>
              </div>
              <ng-container *ngIf="item.showDetails">
                <div class="tr alt with-alt">
                  <div class="td"></div>
                  <div class="td relative overflow-visible">
                    <span>{{item.percent}}% ({{item.open}}/{{item.total}})</span>
                    <!--<div class="reset-open-rate" (click)="openResetOpenRateConfirm(item.id, true); $event.stopPropagation();">-->
                    <!--<img src="assets/icons/reset-open-rate.svg" alt="">-->
                    <!--</div>-->
                  </div>
                  <div class="td"></div>
                  <div class="td"></div>
                  <div class="td">
                    {{item.subject }}
                  </div>
                  <div class="td"></div>
                  <div class="td"></div>
                </div>
                <div class="tr alt">
                  <div class="td"></div>
                  <div class="td relative overflow-visible">
                    <span>{{item.aPercent}}% ({{item.aOpen}}/{{item.aTotal}})</span>
                    <div class="reset-open-rate" (click)="mixpanelService.track('q automated messaging - reset open rate clicked');openResetOpenRateConfirm(item.id); $event.stopPropagation();">
                      <img src="assets/icons/reset-open-rate.svg" alt="">
                    </div>
                  </div>
                  <div class="td"></div>
                  <div class="td"></div>
                  <div class="td">
                    {{item.aSubject || 'Alternate content'}}
                  </div>
                  <div class="td"></div>
                  <div class="td"></div>
                </div>
              </ng-container>
            </ng-container>

          </div>
        </div>
        <div class="table-holder" *ngIf="currentTemplates?.list.length == 0">
          <div class="table">
            <div class="tr">
              <div class="td not-found">
                {{textService.currentText.noDataFound}}.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<ninja-notification [active]="userService.showNotification" [message]="notificationMessage" [title]="notificationTitle">
</ninja-notification>