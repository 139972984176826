import { ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { UserService } from '../../../../services/user.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ConstService } from '../../../../services/const-service';
import { ConfirmationDialogComponent } from '../../../dialogs/confirmation-dialog/confirmation-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { Functions } from '../../../../services/functions';
import { TemplateService } from '../../../../services/template.service';
import { GoogleAnalyticsEventsService } from "../../../../services/google-analytics.service";
import { TextService } from '../../../../services/text.service';
import { MixpanelService } from 'src/app/services/mixpanel.service';

@Component({
  selector: 'ninja-fba',
  templateUrl: './fbm.component.html',
  styleUrls: ['./fbm.component.scss']
})

export class FbmComponent implements OnInit, OnDestroy {
  public user: any;
  public currentTemplates: any;
  public notificationMessage: string;
  public notificationTitle: string;
  public filters: any;
  public templatesList: any;
  public emailsTotal = 0;
  public openRateTotal = 0;
  public isFilters: boolean;
  public isReviewRequestTemplates: boolean;

  constructor(public userService: UserService,
    public textService: TextService,
    public router: Router,
    public matDialog: MatDialog,
    public activatedRoute: ActivatedRoute,
    public templateService: TemplateService,
    public functions: Functions,
    public constService: ConstService,
    public el: ElementRef,
    public ref: ChangeDetectorRef,
    public googleAnalyticsEventsService: GoogleAnalyticsEventsService,
    public mixpanelService: MixpanelService) {
    this.mixpanelService.init(null);
    this.filters = {
      daySelected: null,
      status: null,
      name: null,
      channel: null
    };
  }

  public ngOnInit(): void {
    this.isReviewRequestTemplates = this.userService.currentUser.permissions.isReviewRequestTemplates;
    this.getTemplateData();
    this.activatedRoute.queryParams.subscribe((params) => {
      if (params.created === 'true') {
        this.notificationMessage = this.textService.currentText.fbmCreated;
        this.notificationTitle = this.textService.currentText.success;
        this.userService.showNotification = true;
      }
      if (params.created === 'false') {
        this.notificationMessage = this.textService.currentText.fbmEdited;
        this.notificationTitle = this.textService.currentText.success;
        this.userService.showNotification = true;
      }
    });
  }

  public getTemplateData(): void {
    this.userService.loaderObesrver.next(true);
    this.user = this.userService.currentUser;
    this.templateService.getFbmTemplates(this.userService.currentUser.id).subscribe(
      (data) => {
        this.templatesList = data.list;
        this.user.list = data.list;
        this.setShowDetails(this.templatesList);
        this.currentTemplates = JSON.parse(JSON.stringify(this.user));
        this.userService.loaderObesrver.next(false);
        this.ref.detectChanges();
        this.templateService.getFbmTemplatesWithRates(this.userService.currentUser.id).subscribe((data) => {
          this.templatesList = data.list;
          this.user.list = data.list;
          this.setShowDetails(this.templatesList);
          this.currentTemplates = JSON.parse(JSON.stringify(this.user));
          this.checkFilters();
          this.checkTotal();
        })
      }
    );
  }

  public checkTotal(): void {
    for (let i = 0; i < this.currentTemplates.list.length; i++) {
      this.openRateTotal += +this.currentTemplates.list[i].percent;
      this.emailsTotal += this.currentTemplates.list[i].total;
      if (this.openRateTotal === 0 && this.emailsTotal >= 30 && i === this.currentTemplates.list.length - 1) {
        this.showOpenRateWarningDialog();
      }
    }
  }

  public ngOnDestroy(): void {
    this.userService.showNotification = false;
  }

  public valueToggle(checker: boolean): string {
    return checker ? 'On' : 'Off';
  }

  public openResetOpenRateConfirm(templateId, isMain?): void {
    this.matDialog.open(ConfirmationDialogComponent, {
      data: {
        'dialogFor': 'reset-openRate',
      },
    }).afterClosed().subscribe(res => {
      if (res) {
        if (isMain) {
          this.templateService.resetMainOpenRate(this.userService.currentUser.id, templateId).subscribe(response => {
            if (response.isSuccess) {
              this.getTemplateData();
              this.userService.showNotification = true;
              this.notificationTitle = this.textService.currentText.success;
              this.notificationMessage = this.textService.currentText.resetOpenRateNotificationText;
            }
          });
        } else {
          this.templateService.resetOpenRate(this.userService.currentUser.id, templateId).subscribe(response => {
            if (response.isSuccess) {
              this.getTemplateData();
              this.userService.showNotification = true;
              this.notificationTitle = this.textService.currentText.success;
              this.notificationMessage = this.textService.currentText.resetOpenRateNotificationText;
            }
          });
        }
      }
    });
  }

  public setShowDetails(templatesList): void {
    for (let i = 0; i < templatesList.length; i++) {
      if (templatesList[i].aSubject || templatesList[i].aContent) {
        templatesList[i].showDetails = true;
      }
    }
  }

  public goToAddTemplate(): void {
    this.templateService.updateFbmStatus(this.userService.currentUser.id).subscribe();
    this.router.navigate(['dashboard/automated-messaging/add']);
  }

  public goToRequestReviewTemplate(): void {
    this.templateService.updateFbmStatus(this.userService.currentUser.id).subscribe();
    if (this.userService.currentUser.spaToken) {
      this.router.navigate(['dashboard/automated-messaging/add'], { queryParams: { reviewRequest: true } });
    } else {
      this.openGrantAccessFirst();
      this.mixpanelService.track('q request review popup - grant access');
    }
  }

  public whatIsThis(): void {
    this.matDialog.open(ConfirmationDialogComponent, {
      data: {
        'dialogFor': 'review-request-automation',
      },
    }).afterClosed().subscribe();
  }

  public openGrantAccessFirst(): void {
    this.matDialog.open(ConfirmationDialogComponent, {
      data: {
        'dialogFor': 'grant-access-first',
      },
    }).afterClosed().subscribe();
  }

  public filterByDate(): void {
    this.currentTemplates.list = this.currentTemplates.list.filter(this.isBigEnough.bind(this));
    this.ref.detectChanges();
  }

  public isBigEnough(value: any): boolean {
    return value.timeframe === this.filters.daySelected;
  }

  public filterByStatus(): void {
    this.currentTemplates.list = this.currentTemplates.list.filter(this.isStatus.bind(this));
    this.ref.detectChanges();
  }

  public isStatus(value: any): boolean {
    return value.status === this.filters.status;
  }

  public filterByChannel(): void {
    this.currentTemplates.list = this.currentTemplates.list.filter(this.isChannel.bind(this));
    this.ref.detectChanges();
  }

  public isChannel(value: any): boolean {
    if (value.channellist.length === 3 ||
      (value.channellist.length === 0 && this.filters.channel.length === 0)) {
      return true;
    }
    for (let i = 0; value.channellist.length > i; i++) {
      if (value.channellist[i] === this.filters.channel) {
        return true;
      }
    }
  }

  public liveSearch(): void {
    this.currentTemplates.list = this.currentTemplates.list.filter(this.liveSearchCallback.bind(this));
    this.ref.detectChanges();
  }

  public liveSearchCallback(value: any): boolean {
    return value.name.toLowerCase().indexOf(this.filters.name.toLowerCase()) !== -1;
  }

  public clearFilter(): void {
    this.filters = {
      daySelected: null,
      eventSelected: null,
      status: null,
      name: null
    };
    this.currentTemplates = JSON.parse(JSON.stringify(this.user));
    this.ref.detectChanges();
  }

  public changeStatus(templateId: number, id: number, status: boolean): void {
    this.mixpanelService.track('q automated messaging - switch', { 'state': status });
    this.findTemplate(templateId, true, false);
    this.templateService.changeFbmTemplateStatus(templateId, this.currentTemplates.sellerId).subscribe(() => {
      this.user.list[id].status = status;
    });
  }

  public deleteTemplate(templateId: number): void {
    this.findTemplate(templateId, false, true);
    this.templateService.deleteFBMTemplate(templateId, this.currentTemplates.id).subscribe((data) => {
      this.user.list = data.list;
      this.notificationMessage = this.textService.currentText.templateDeleted;
      this.notificationTitle = this.textService.currentText.deleteTemplate;
      this.userService.showNotification = true;
      this.templateService.getFbmTemplatesWithRates(this.userService.currentUser.id).subscribe((data) => {
        this.templatesList = data.list;
        this.user.list = data.list;
        this.currentTemplates = JSON.parse(JSON.stringify(this.user));
        this.checkFilters();
        this.checkTotal();
      })
    });
  }

  public copyTemplate(templateId: number): void {
    this.templateService.copyFBMTemplate(templateId, this.currentTemplates.id).subscribe(
      (data) => {
        this.currentTemplates.list = data.list;
        this.notificationMessage = this.textService.currentText.templateCopied;
        this.notificationTitle = this.textService.currentText.copyTemplate;
        this.userService.showNotification = true;
        this.user.list = data.list;
        this.templateService.getFbmTemplatesWithRates(this.userService.currentUser.id).subscribe((data) => {
          this.templatesList = data.list;
          this.user.list = data.list;
          this.currentTemplates = JSON.parse(JSON.stringify(this.user));
          this.checkFilters();
          this.checkTotal();
        })
      }
    );
  }

  public findTemplate(id: number, changeStatus: boolean, deleteTemplate: boolean): void {
    for (let i = 0; i < this.currentTemplates.list.length; i++) {
      if (this.currentTemplates.list[i].id === id) {
        if (deleteTemplate) {
          this.currentTemplates.list.splice(i, 1);
        }
      }
    }
  }

  public checkFilters(): void {
    this.currentTemplates = JSON.parse(JSON.stringify(this.user));
    if (this.filters.daySelected !== null) {
      this.filterByDate();
    }
    if (this.filters.status !== null) {
      this.filterByStatus();
    }
    if (this.filters.channel && this.filters.channel.length >= 0) {
      this.filterByChannel();
    }
    if (this.filters.name !== null) {
      this.liveSearch();
    }
  }

  public confirmExportCSV(): void {
    this.matDialog.open(ConfirmationDialogComponent, {
      data: {
        'dialogFor': 'export-csv',
      },
    }).afterClosed().subscribe(result => {
      if (result) {
        this.templateService.exportTemplateFBM(this.userService.currentUser.id, this.userService.isSuperUser).subscribe((data) => {
          if (data) {
            this.userService.showNotification = true;
            this.notificationMessage = this.textService.currentText.templatesExported;
            this.notificationTitle = this.textService.currentText.exportTemplates;
          }
        });
      }
    });
  }

  public editEmail(): void {
    this.matDialog.open(ConfirmationDialogComponent, {
      data: {
        'dialogFor': 'edit-email',
      },
    }).afterClosed().subscribe();
  }

  public showOpenRateWarningDialog(): void {
    this.matDialog.open(ConfirmationDialogComponent, {
      data: {
        'dialogFor': 'open-rate-warning',
      },
    }).afterClosed().subscribe();
  }

  public navigateToEdit(id: number): void {
    this.router.navigate(['/dashboard/automated-messaging/add'], { queryParams: { id: id } });
  }


  public navigateToLastMessagesSent(templateId?: number): void {
    if (templateId) {
      this.router.navigate(['/dashboard/automated-messaging/last-messages-sent'], { queryParams: { page: 'fbm', templateId: templateId } });
    } else {
      this.router.navigate(['/dashboard/automated-messaging/last-messages-sent'], { queryParams: { page: 'fbm' } });
    }
    this.userService.currentPage = 'fbm';
  }

  public deleteConfirm(id: number): void {
    this.matDialog.open(ConfirmationDialogComponent, {
      data: {
        'dialogFor': 'delete-confirm'
      },
    }).afterClosed().subscribe(result => {
      if (result) {
        this.deleteTemplate(id);
      }
    });
  }
}
