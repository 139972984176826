import { Component, OnInit, ViewChild } from '@angular/core';
import { ShareService } from 'src/app/services/share.service';
import * as _moment from 'moment';
import { TextService } from 'src/app/services/text.service';
import { UserService } from 'src/app/services/user.service';
import { ErrorsComponent } from './popups/errors/errors.component';
import { MatDialog } from '@angular/material/dialog';
import { StorageBrowser } from '../../../services/storage.browser';
import { CustomFilterMenuComponent } from '../custom-filter-menu/custom-filter-menu.component';
@Component({
  selector: 'ninja-dashboard-schedules',
  templateUrl: './dashboard-schedules.component.html',
  styleUrls: ['./dashboard-schedules.component.scss']
})

export class DashboardSchedulesComponent implements OnInit {
  @ViewChild('componentChild') customFilterMenuComponent: CustomFilterMenuComponent;
  alwaysShowCalendars: boolean;
  showCustomRangeLabel: boolean;
  profitData: any;
  formDataDashBoard: any;
  userData: any;
  dashboardSchedulesFilterFlag: boolean = true;
  defaultDataFirst: {} = {
    timeFrame: 'firstTimeFrame'
  }
  defaultDataSecond: {} = {
    timeFrame: 'secondTimeFrame'
  }
  defaultDataThird: {} = {
    timeFrame: 'thirdTimeFrame'
  }
  constructor(
    public textService: TextService,
    public userService: UserService,
    private dialog: MatDialog,
    private shareService: ShareService,
    public storageBrowser: StorageBrowser,
  ) {
    this.alwaysShowCalendars = true;
    this.showCustomRangeLabel = true;
  }
  ngOnInit() {
    this.userService.loaderObesrver.next(true);
    setTimeout(() => {
      this.shareService.currentData.subscribe(data => {
        this.profitData = data;
        this.storageBrowser.set('$Currency', this.profitData.currency);
        // this.userService.loaderObesrver.next(false);
      }, (err) => {
        this.dialog.open(ErrorsComponent, { panelClass: 'errorPopupCls' });
      });
    }, 500);

  }
  passBall(message: string) {
    this.formDataDashBoard = message;
  }
  updatedNewData (data) {
    this.profitData = data;
    this.storageBrowser.set('$Currency', this.profitData.currency);
  }
  resetFilter(filterName) {
    this.userService.loaderObesrver.next(true);
    if(filterName == 'brand') {
      this.formDataDashBoard.brands = [];
      this.customFilterMenuComponent.productForm.controls.brands.reset();
      this.getprofitData(this.formDataDashBoard);
    } else if (filterName == 'marketplace') {
      this.formDataDashBoard.sc = [];
      this.customFilterMenuComponent.productForm.controls.marketplace.reset();
      this.getprofitData(this.formDataDashBoard);
    } else if (filterName == 'asin') {
      this.formDataDashBoard.skus = [];
      this.customFilterMenuComponent.productForm.controls.asin.reset();
      this.getprofitData(this.formDataDashBoard);
    }
  }
  getprofitData(formData) {
    if (this.storageBrowser.get('$UserToken$user')) {
      this.userService.getProfitDashboardData(formData).subscribe((res: any) => {
        if (res) {
          this.profitData = res;
          this.shareService.changeData('');
          setTimeout(() => {
            this.shareService.changeData(res);
            this.userService.loaderObesrver.next(false);
          });
        }
      }, (err) => {
      });
    }
  }
  get_flag(marketPlace) {
    let mpImage = '';
    if (marketPlace == "AMAZON_COM") {
      mpImage = 'assets/images/marketplace.png'
    } else if (marketPlace == "AMAZON_CANADA") {
      mpImage = 'assets/images/flag_canada.svg'
    } else if (marketPlace == "AMAZON_MEXICO") {
      mpImage = 'assets/images/flag_mexico.svg'
    } else if (marketPlace == "AMAZON_UK") {
      mpImage = 'assets/images/flag_uk.svg'
    } else if (marketPlace == "AMAZON_FRANCE") {
      mpImage = 'assets/images/flag_france.svg'
    } else if (marketPlace == "AMAZON_GERMANY") {
      mpImage = 'assets/images/flag_germany.svg'
    } else if (marketPlace == "AMAZON_ITALY") {
      mpImage = 'assets/images/flag_italy.svg'
    } else if (marketPlace == "AMAZON_SPAIN") {
      mpImage = 'assets/images/flag_spain.svg'
    } else if (marketPlace == "AMAZON_JAPAN") {
      mpImage = 'assets/images/japan.svg'
    } else if (marketPlace == "AMAZON_AUSTRALIA") {
      mpImage = 'assets/images/australia.svg'
    } else if (marketPlace == "AMAZON_INDIA") {
      mpImage = 'assets/images/india.svg'
    } else if (marketPlace == "AMAZON_UAE") {
      mpImage = 'assets/images/UAE_Flag.svg'
    } else if (marketPlace == "AMAZON_BRAZIL") {
      mpImage = 'assets/images/Brazil_br.jpeg'
    } else if (marketPlace == "AMAZON_SAUDI_ARABIA") {
      mpImage = 'assets/images/SoudiArabia.jpeg'
    } else if (marketPlace == "AMAZON_TURKEY") {
      mpImage = 'assets/images/turkey_tr.jpeg'
    } else if (marketPlace == "AMAZON_SINGAPOR") {
      mpImage = 'assets/images/Singapore_sg.jpeg'
    } else if (marketPlace == "AMAZON_NETHERLANDS") {
      mpImage = 'assets/images/Netherlands_nl.jpeg'
    } else {
      mpImage = 'assets/images/noImage.png';
    }
    return mpImage;
  }
}
