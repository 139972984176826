import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import * as moment from 'moment';
import { DecimalPipe } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { UserService } from '../../../../../services/user.service';
import { AccountSettings } from '../../../../../services/account-settings';
import { TextService } from '../../../../../services/text.service';
import { EditShortNameDialogComponent } from '../../../../dialogs/edit-short-name/edit-short-name-dialog.component';

@Component({
  selector: 'app-product-cost',
  templateUrl: './product-cost.component.html',
  styleUrls: ['./product-cost.component.scss'],
  providers: [DecimalPipe]
})

export class ProductCostComponent implements OnInit {
  @Input() products: any;
  @Input() productsListPaginator: any;
  @Output() updatedProductsListPaginator = new EventEmitter<string>();;
  oldCostData: any;
  public notificationMessage: string;
  public notificationTitle: string;
  constructor(private decimalPipe: DecimalPipe,
    public matDialog: MatDialog,
    public textService: TextService,
    public userService: UserService,
    public accountSettings: AccountSettings) {
  }

  public ngOnInit(): void {
    this.addCostData(this.products);
  }

  addCostData(products) {
    this.oldCostData = JSON.parse(JSON.stringify(products.costData));
    if (products.cost > 0 && products.costData.length == 0) {
      products.costData = [{
        "totalPaid": products.cost * 1,
        "costTitle": 'Default Cost',
        "status": 'new',
        "showDetailedCost": true,
        "productCost": {
          "totaPaid": products.cost * 1,
          "currency": "USD",
          "units": 1,
          "costPerUnit": products.cost
        },
        "shippingCost": {
          "totaPaid": 0.00,
          "currency": "USD",
          "units": 0.00,
          "costPerUnit": 0.00
        }
      }]
    } else {
      if (products.costData.length == 0) {
        products.costData = [{
          "totalPaid": 0.00,
          "costTitle": 'Default Cost',
          "status": 'new',
          "showDetailedCost": true,
          "productCost": {
            "totaPaid": 0.00,
            "currency": "USD",
            "units": 0.00,
            "costPerUnit": 0.00
          },
          "shippingCost": {
            "totaPaid": 0.00,
            "currency": "USD",
            "units": 0.00,
            "costPerUnit": 0.00
          }
        }]
      } else {
        for (let i = 0; i < products.costData.length; i++) {
          products.costData[i]["status"] = 'edit';
          products.costData[i]["showDetailedCost"] = false;
          if (i == (products.costData.length - 1)) {
            products.costData[i].showDetailedCost = true;
          }
        }
      }
    }
    return products;
  }

  addNewCostData(products) {
    if (products.costData.length > 1) {
      products.costData.push({
        "totalPaid": 0.00,
        "costTitle": 'Default Cost',
        "status": 'new',
        "from": moment().format("YYYY-MM-DD") + "T00:00:00.000Z",
        "to": moment().add(3, 'years').format("YYYY-MM-DD") + "T00:00:00.000Z",
        "showDetailedCost": true,
        "productCost": {
          "totaPaid": 0.00,
          "currency": "USD",
          "units": 0.00,
          "costPerUnit": 0.00
        },
        "shippingCost": {
          "totaPaid": 0.00,
          "currency": "USD",
          "units": 0.00,
          "costPerUnit": 0.00
        }
      });
      for (let i = 0; i < products.costData.length; i++) {
        products.costData[i]["showDetailedCost"] = false;
        if (i == (products.costData.length - 1)) {
          products.costData[i].showDetailedCost = true;
        }
        if (i > 1) {
          if (products.costData[i].from) {
            products.costData[i].from = moment(products.costData[i - 1].to).format("YYYY-MM-DD") + "T00:00:00.000Z";
            products.costData[i].to = moment(products.costData[i - 1].to).add(3, 'years').format("YYYY-MM-DD") + "T00:00:00.000Z";
            products.costData[i - 1].to = moment(products.costData[i - 1].to).subtract(1, 'days').format("YYYY-MM-DD") + "T00:00:00.000Z";
          }
        }
      }
    } else {
      for (let i = 0; i < products.costData.length; i++) {
        products.costData[i].showDetailedCost = false;
      }
      products.costData.push({
        "totalPaid": 0.00,
        "costTitle": 'Default Cost',
        "status": 'new',
        "from": moment().format("YYYY-MM-DD") + "T00:00:00.000Z",
        "to": moment().add(3, 'years').format("YYYY-MM-DD") + "T00:00:00.000Z",
        "showDetailedCost": true,
        "productCost": {
          "totaPaid": 0.00,
          "currency": "USD",
          "units": 0.00,
          "costPerUnit": 0.00
        },
        "shippingCost": {
          "totaPaid": 0.00,
          "currency": "USD",
          "units": 0.00,
          "costPerUnit": 0.00
        }
      });
    }
  }

  effectiveDateChange(products, index, event) {
    if (products.costData.length > 1) {
      for (let i = 0; i < products.costData.length; i++) {
        if (i == index && i > 1) {
          products.costData[i].from = moment(event.value).format("YYYY-MM-DD") + "T00:00:00.000Z";
          products.costData[i - 1].to = moment(products.costData[i].from).subtract(1, 'days').format("YYYY-MM-DD") + "T00:00:00.000Z";
        }
      }
    }
  }

  delete(products, index) {
    for (let i = 0; i < products.costData.length; i++) {
      if (i == index && index != 0) {
        products.costData.splice(i, 1);
      }
      products.costData[i].showDetailedCost = false;
    }
  }

  cancel(products, index) {
    for (let i = 0; i < products.costData.length; i++) {
      if (i == 0 && i == index) {
        if (products.costData[i].status == 'new') {
          if (i == index && index != 0) {
            products.costData.splice(i, 1);
          }
        } else {
          products.costData[i] = {
            "totalPaid": this.oldCostData[i].totalPaid,
            "costTitle": this.oldCostData[i].costTitle,
            "productCost": {
              "totaPaid": this.oldCostData[i].productCost.totaPaid,
              "currency": this.oldCostData[i].productCost.currency,
              "units": this.oldCostData[i].productCost.units,
              "costPerUnit": this.oldCostData[i].productCost.costPerUnit
            },
            "shippingCost": {
              "totaPaid": this.oldCostData[i].shippingCost.totaPaid,
              "currency": this.oldCostData[i].shippingCost.currency,
              "units": this.oldCostData[i].shippingCost.units,
              "costPerUnit": this.oldCostData[i].shippingCost.costPerUnit
            },
            "showDetailedCost": products.costData[i].showDetailedCost ? true : false
          }
        }
      } else if (i == index) {
        if (products.costData[i].status == 'new') {
          if (i == index && index != 0) {
            products.costData.splice(i, 1);
          }
        } else {
          products.costData[i] = {
            "totalPaid": this.oldCostData[i].totalPaid,
            "costTitle": this.oldCostData[i].costTitle,
            "from": this.oldCostData[i].from,
            "to": this.oldCostData[i].to,
            "productCost": {
              "totaPaid": this.oldCostData[i].productCost.totaPaid,
              "currency": this.oldCostData[i].productCost.currency,
              "units": this.oldCostData[i].productCost.units,
              "costPerUnit": this.oldCostData[i].productCost.costPerUnit
            },
            "shippingCost": {
              "totaPaid": this.oldCostData[i].shippingCost.totaPaid,
              "currency": this.oldCostData[i].shippingCost.currency,
              "units": this.oldCostData[i].shippingCost.units,
              "costPerUnit": this.oldCostData[i].shippingCost.costPerUnit
            },
            "showDetailedCost": products.costData[i].showDetailedCost ? true : false
          }
        }
      }
    }
  }

  OnValueEdit(editor) {
    if (editor)
      if (editor.data == 0) {
        editor.data = '';
      }
    editor.editMode = true;
  }

  onCompEditClose(value) {
    return;
  }

  onCompEditUpdate(value, costData, id) {
    if (id == 'productCostPerUnitEdit') {
      costData.productCost.costPerUnit = value.value;
      if (costData.productCost.units == 0) {
        costData.productCost.units = 1;
      }
      costData.productCost.totaPaid = costData.productCost.costPerUnit * costData.productCost.units;
    } else if (id == 'productCostUnitsEdit') {
      costData.productCost.units = value.value;
      costData.productCost.totaPaid = costData.productCost.costPerUnit * costData.productCost.units;
    } else if (id == 'shippingCostPerUnitEdit') {
      costData.shippingCost.costPerUnit = value.value;
      if (costData.shippingCost.units == 0) {
        costData.shippingCost.units = 1;
      }
      costData.shippingCost.totaPaid = costData.shippingCost.costPerUnit * costData.shippingCost.units;
    } else if (id == 'shippingCostUnitsEdit') {
      costData.shippingCost.units = value.value;
      costData.shippingCost.totaPaid = costData.shippingCost.costPerUnit * costData.shippingCost.units;
    } else if (id == 'productCostTotaPaidEdit') {
      costData.productCost.totaPaid = value.value;
      costData.productCost.costPerUnit = this.decimalPipe.transform(costData.productCost.totaPaid / costData.productCost.units, '1.2-2');
    } else if (id == 'shippingCostTotaPaidEdit') {
      costData.shippingCost.totaPaid = value.value;
      costData.shippingCost.costPerUnit = this.decimalPipe.transform(costData.shippingCost.totaPaid / costData.shippingCost.units, '1.2-2');
    } else if (id == 'costTitleEdit') {
      costData.costTitle = value.value;
    }
    costData.totalPaid = (costData.productCost.totaPaid + costData.shippingCost.totaPaid);
    return;
  }

  saveCOGS(products) {
    this.userService.loaderObesrver.next(true);
    for (let i = 0; i < products.costData.length; i++) {
      delete products.costData[i].showDetailedCost;
      delete products.costData[i].status;
      if (products.costData[i].shippingCost.costPerUnit > 0 || products.costData[i].productCost.costPerUnit > 0) {
        if (i > 0) {
          if (products.costData[i].from && products.costData[i].to) {
            products.costData[i].from = moment(products.costData[i].from).format("YYYY-MM-DD") + "T00:00:00.000Z"
            products.costData[i].to = moment(products.costData[i].to).format("YYYY-MM-DD") + "T00:00:00.000Z"
          }
        }
      } else {
        if (i > 0) {
          if (products.costData[i].from && products.costData[i].to) {
            products.costData[i].from = moment(products.costData[i].from).format("YYYY-MM-DD") + "T00:00:00.000Z"
            products.costData[i].to = moment(products.costData[i].to).format("YYYY-MM-DD") + "T00:00:00.000Z"
          }
        }
        products.costData.splice(i, 1);
      }
    }
    this.accountSettings.setCost(this.userService.currentUser.sellerId, products.asin, products.SKU, { "costData": products.costData }).subscribe((data) => {
      this.userService.loaderObesrver.next(false);
      for (let i = 0; i < this.productsListPaginator.length; i++) {
        if (this.productsListPaginator[i].SKU === products.SKU) {
          this.productsListPaginator[i].cost = +products.costData[0].productCost.costPerUnit + +products.costData[0].shippingCost.costPerUnit;
          for (let i = 0; i < products.costData.length; i++) {
            products.costData[i]["status"] = 'edit';
            products.costData[i]["showDetailedCost"] = false;
          }
          this.oldCostData = JSON.parse(JSON.stringify(products.costData));
        }
      }
      this.updatedProductsListPaginator.emit(this.productsListPaginator);
      this.notificationMessage = this.textService.currentText.costSave;
      this.notificationTitle = this.textService.currentText.success;
      this.userService.notificationMessage = this.textService.currentText.costSave;
      this.userService.notificationTitle = this.textService.currentText.success;
      this.userService.showNotification = true;
    });
  }

  public editShortName(product): void {
    this.matDialog.open(EditShortNameDialogComponent, {
      data: {
        'dialogFor': 'edit-short-name-dialog',
        'product': product,
      },
      width: '800px'
    }).afterClosed().subscribe((result) => {
      if (result) {
        for (let i = 0; i < this.productsListPaginator.length; i++) {
          if (this.productsListPaginator[i].SKU === result.sku) {
            for (const [key, value] of Object.entries(this.productsListPaginator[i])) {
              if(key.includes('shortName')){
                this.productsListPaginator[i][key] = result[key];
              }
            }
            this.productsListPaginator[i].shortName = result.defaultShortName;
          }
        }
        this.updatedProductsListPaginator.emit(this.productsListPaginator);
      }
    });
  }

}
