import {Injectable} from '@angular/core';

declare let ga: Function;

@Injectable()
export class GoogleAnalyticsEventsService {

  public emitEvent(eventCategory: string,
                   eventAction: string,
                   eventLabel: string = null,
                   eventValue: number = null) {
   // if (document.location.hostname === 'webapp.quantifyninja.com') {
      ga('send', 'event', {
        eventCategory: eventCategory,
        eventLabel: eventLabel,
        eventAction: eventAction,
        eventValue: eventValue
      });
    }
  //}

  public emitPageView(pvPage: string=null,
    pvLocation: string = null) {
// if (document.location.hostname === 'webapp.quantifyninja.com') {
    ga('send', 'pageview', {
        page: pvPage,
        location: pvLocation
      });
    }
}
