import { Component, OnInit } from '@angular/core';
import { UserService } from '../../../services/user.service';
import { TokenService } from '../../../services/token.service';
import { ConstService } from '../../../services/const-service';
import { Router } from '@angular/router';
import { TextService } from '../../../services/text.service';

@Component({
  selector: 'ninja-choose-region',
  templateUrl: './choose-region.component.html',
  styleUrls: ['./choose-region.component.scss']
})

export class ChooseRegionPageComponent implements OnInit {
  public pattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  public userLnks: any;
  public emailInvalid = false;
  public emailInputDisabled = false;
  public currentRegion: any;
  constructor(public userService: UserService,
    public tokenService: TokenService,
    public textService: TextService,
    public constService: ConstService,
    public router: Router) {
  }

  public ngOnInit(): void {
    if (this.userService.currentUser.id) {
      this.userService.getGrantAccessUrls(this.userService.currentUser.id).subscribe((links) => {
        this.userLnks = links;
      });
    }
    if (this.tokenService.getToken() && this.tokenService.getToken().id) {
      this.userService.currentUser = this.tokenService.getToken().user;
      this.constService.setRegions(this.tokenService.getToken().user);
      this.userService.tokenCheck().subscribe((data) => {
        if (data.isLogged) {
          this.userService.currentUser = this.tokenService.getToken().user;
        } else {
          this.router.navigate(['login']);
        }
      });
    } else {
      this.router.navigate(['login']);
    }
    this.userService.loader = false;
  }

  public logOut(): void {
    this.tokenService.clear();
    window.location.reload(true);
  }

  public validateUrl(input) {
    return this.pattern.test(input.value)
  }

  public test(input, region) {
    this.currentRegion = region;
    if (!this.pattern.test(input.value)) {
      this.displayError(input)
    } else {
      return true
    }
  }

  private displayError(input): void {
    this.emailInvalid = true;
    const old_value = input.value;
    input.value = 'Set your Amazon email account';
    input.disable = true;
    input.blur();
    this.resetErrorState(old_value, input);
    return;
  }

  private resetErrorState(val: string, input: any): void {
    setTimeout(() => {
      input.value = val;
      this.emailInvalid = false;
      input.disable = false;
    }, 2000);
  }

  public navigateToAmazon(link): void {
    //if (this.pattern.test(input.value)) {
    // this.userService.temporarySendEmail(this.userService.currentUser['userId'], input.value).subscribe((links) => {
    window.location.href = link;
    // });
    //}
  }

}
