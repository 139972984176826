
import { Component, OnInit } from '@angular/core';
import { UserService } from '../../../services/user.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TextService } from '../../../services/text.service';

@Component({
  selector: 'ninja-spa',
  templateUrl: './spa.component.html',
  styleUrls: ['./spa.component.scss']
})

export class SpaComponent implements OnInit {
  isSuccess: boolean = true;
  constructor(public userService: UserService, public activatedRoute: ActivatedRoute, public textService: TextService, public router: Router) {
  }

  public ngOnInit(): void {
    var req = new XMLHttpRequest();
    var headers = req.getAllResponseHeaders().toLowerCase();
    console.log(headers)
    this.userService.loader = true;
    if (Object.keys(this.activatedRoute.snapshot.queryParams).length !== 0 &&
      this.activatedRoute.snapshot.queryParams.state !== 'null' &&
      this.activatedRoute.snapshot.queryParams.selling_partner_id &&
      this.activatedRoute.snapshot.queryParams.mws_auth_token !== 'null' &&
      this.activatedRoute.snapshot.queryParams.spapi_oauth_code !== 'null') {
      const dataToVerify = {
        state: this.activatedRoute.snapshot.queryParams.state,
        selling_partner_id: this.activatedRoute.snapshot.queryParams.selling_partner_id,
        mws_auth_token: this.activatedRoute.snapshot.queryParams.mws_auth_token,
        spapi_oauth_code: this.activatedRoute.snapshot.queryParams.spapi_oauth_code,
        referer: document.referrer
      }
      this.userService.verifyHybridUser(dataToVerify).subscribe((data) => {
        if (data.isSuccess) {
          this.router.navigate(['grant-access'], { queryParams: { promoteFBbot: "promoteFBbot" } });
        } else {
          this.isSuccess = data.isSuccess;
        }
        this.userService.loader = false;
      });
    }
  }

  public navigateToSignUp(): void {
    this.router.navigate(['choose-region']);
  }

}