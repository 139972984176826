<div class="expenses-head">
  <button mat-button class="secondary-btn" (click)="addExpence()">{{textService.currentText.addExpense}}</button>
</div>
<h3 class="font-size-18 mr-3">{{textService.currentText.expenseTitle}}</h3>
<div class="table-container">
  <div class="table-wrapper expenses-wrapper">
    <div class="table-header-wrapper">
      <div class="table-header expenses-header">
        <div class="td" (click)="functions.sortColumns(expences, 'name')">{{textService.currentText.name}}</div>
        <div class="td" (click)="functions.sortColumns(expences, 'from')">{{textService.currentText.from}}</div>
        <div class="td" (click)="functions.sortColumns(expences, 'to')">{{textService.currentText.to}}</div>
        <div class="td" (click)="functions.sortColumns(expences, 'amount')">{{textService.currentText.amount}}</div>
        <div class="td">{{textService.currentText.action}}</div>
      </div>
    </div>
    <div class="table-holder expenses-holder">
      <div class="table expenses-table">
        <div class="tr" *ngFor="let expence of expences; let i = index">
          <div class="td">
            {{expence.name}}
          </div>
          <div class="td" [class.display-none]="!expence.edit">
            <mat-form-field>
              <input matInput [matDatepicker]="pickerFrom" [(ngModel)]="expence.from" name=""
                (click)="pickerFrom.open()" (keydown)="$event.preventDefault()" [max]="expence.to"
                [disabled]="!expence.edit">
              <mat-datepicker-toggle matSuffix [for]="pickerFrom"></mat-datepicker-toggle>
              <mat-datepicker touchUi="true" panelClass="calendar-popup" #pickerFrom></mat-datepicker>
            </mat-form-field>
          </div>
          <div class="td" [class.display-none]="!expence.edit">
            <mat-form-field>
              <input matInput [matDatepicker]="pickerTo" [(ngModel)]="expence.to" name="to" (click)="pickerTo.open()"
                (keydown)="$event.preventDefault()" [min]="expence.from" [disabled]="!expence.edit">
              <mat-datepicker-toggle matSuffix [for]="pickerTo"></mat-datepicker-toggle>
              <mat-datepicker touchUi="true" panelClass="calendar-popup" #pickerTo></mat-datepicker>
            </mat-form-field>
          </div>
          <div class="td">
            {{expence.amount}}
          </div>
          <div class="td">
            <button class="cancel-btn" *ngIf="expence.edit" (click)="cancel(i)">
              {{textService.currentText.cancel}}
            </button>
            <button class="edit-btn" (click)="edit(i)" *ngIf="!expence.edit">
              <!-- <div class="edit-btn-content">
                <div class="icon edit-icon"></div>
                <span>{{textService.currentText.edit}}</span>
              </div> -->
              <img src="../../../../../assets/images/settings.png">
              <span *ngIf="expence.edit" (click)="save(i)" class="action-el">Save</span>
            </button>
            <button *ngIf="!expence.edit" (click)="deleteExpences(i)">
              <img src="../../../../../assets/images/delete.png">
              <!-- <div class="icon delete-icon"></div>
              <span>{{textService.currentText.delete}}</span> -->
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<ninja-notification [active]="userService.showNotification" [message]="notificationMessage" [title]="notificationTitle">
</ninja-notification>