import { Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ExpenceDialogComponent } from '../../../dialogs/expence-dialog/expence-dialog.component';
import { AccountSettings } from '../../../../services/account-settings';
import { UserService } from '../../../../services/user.service';
import { TextService } from '../../../../services/text.service';
import { Functions } from "../../../../services/functions";

@Component({
  selector: 'ninja-expenses',
  templateUrl: './expenses.component.html',
  styleUrls: ['./expenses.component.scss']
})

export class ExpensesComponent implements OnInit, OnDestroy {
  public expences: any;
  public showNotification = false;
  public notificationMessage: string;
  public notificationTitle: string;

  constructor(public el: ElementRef,
    public functions: Functions,
    public accountSettings: AccountSettings,
    public textService: TextService,
    public userService: UserService,
    public matDialog: MatDialog) {
  }

  public ngOnInit(): void {
    setTimeout(() => {
      this.userService.loaderObesrver.next(true);
      this.accountSettings.currentStep = 3;
      this.accountSettings.settingsTitle = this.textService.currentText.expenseTitle;
      this.accountSettings.settingsInfo = this.textService.currentText.expenseSubTitle;
      this.textService.changeLoaderObserver().subscribe(() => {
        this.accountSettings.settingsTitle = this.textService.currentText.expenseTitle;
        this.accountSettings.settingsInfo = this.textService.currentText.expenseSubTitle;
      });
      this.accountSettings.getExpensesSettings(this.userService.currentUser.id).subscribe((data) => {
        this.expences = data.list;
        this.userService.loaderObesrver.next(false);
      });
    });
  }

  public ngOnDestroy(): void {
    this.userService.showNotification = false;
  }

  public addExpence(): void {
    this.matDialog.open(ExpenceDialogComponent, {
      data: {
        'data': 'expOpen'
      }
    }).afterClosed().subscribe(result => {
      if (result) {
        this.accountSettings.createExpenses(result).subscribe((data) => {
          this.expences = data.list;
          this.notificationMessage = this.textService.currentText.expenseAdded;
          this.notificationTitle = this.textService.currentText.addExpense;
          this.userService.showNotification = true;
        });
      }
    });
  }

  public edit(id: number): void {
    this.expences[id].edit = true;
  }

  public save(id: number): void {
    const expenseData = {
      publicId: this.userService.currentUser.id,
      expensesId: this.expences[id].expensesid,
      from: this.expences[id].from.length > 0 ? this.expences[id].from :
        `${this.expences[id].from.getFullYear()}-${this.expences[id].from.getMonth() + 1}-${this.expences[id].from.getDate()}`,
      to: this.expences[id].to.length > 0 ? this.expences[id].to :
        `${this.expences[id].to.getFullYear()}-${this.expences[id].to.getMonth() + 1}-${this.expences[id].to.getDate()}`
    };
    this.accountSettings.saveExpenses(expenseData).subscribe();
    this.expences[id].edit = false;
    this.notificationMessage = this.textService.currentText.expenseEdited;
    this.notificationTitle = this.textService.currentText.editExpense;
    this.userService.showNotification = true;
  }

  public cancel(id: number): void {
    this.expences[id].edit = false;
  }

  public deleteExpences(id: number): void {
    const expenseData = {
      publicId: this.userService.currentUser.id,
      expensesId: this.expences[id].expensesid
    };
    this.accountSettings.deleteExpenses(expenseData).subscribe(() => {
      this.expences.splice(id, 1);

      this.notificationMessage = this.textService.currentText.expenseDeleted;
      this.notificationTitle = this.textService.currentText.deleteExpense;
      this.userService.showNotification = true;
    });
  }
}
