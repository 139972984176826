<div class="dashboard-holder">
  <div class="top-filter">
    <form [formGroup]="productForm" (ngSubmit)="onSubmit()">
      <div class="right-box ml-4">
        <div class="select-holder mt-3 custom-selects filters open-filters flex-column">
          <div class="select-wrapper">
            <mat-form-field class="filterChange">
              <mat-select #brandsSel disableOptionCentering formControlName="brands" placeholder="All Brands" multiple
                [disabled]="brand.length==0?true:false">
                <mat-option [value]="0" (click)="toggleAllSelection('brands')">All</mat-option>
                <mat-option *ngFor="let brand of brand" [value]="brand">{{brand}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="select-holder mt-3 custom-selects filters marketplace open-filters flex-column">
          <div class="select-wrapper">
            <mat-form-field class="filterChange">
              <mat-select #activeChannelsSel disableOptionCentering formControlName="marketplace"
                placeholder="All Marketplaces" multiple [disabled]="activeChannels.length==0?true:false">
                <mat-option [value]="0" (click)="toggleAllSelection('activeChannels')">All</mat-option>
                <mat-option *ngFor="let activeChannels of activeChannels" [value]="activeChannels">{{activeChannels}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="select-holder wide mt-3 custom-selects filters open-filters flex-column d-none">
          <div class="select-wrapper">
            <mat-form-field class="filterChange">
              <mat-select #asinSel disableOptionCentering formControlName="asin" placeholder="All ASINs" multiple
                #asinSelect [disabled]="products.length==0?true:false">
                <mat-option [value]="0" (click)="toggleAllSelection('asin')">All</mat-option>
                <mat-option class="shortNameDisply" title="{{products.shortNames.defaultShortName}}"
                  *ngFor="let products of products" [value]="products.sku">
                  {{products.shortNames.defaultShortName?products.shortNames.defaultShortName:products.defaultName}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="select-holder wide mt-3 custom-selects filters open-filters flex-column">
          <button type="button" mat-button class="default-btn all-asins" [disabled]="defaultList.length==0?true:false"
            [matMenuTriggerFor]="asinDropdown">
           {{ asinarr.length == 0 ? 'All ASINs' : asinarr }}    
            <mat-icon>keyboard_arrow_down</mat-icon>
          </button>
        </div>
        <mat-menu #asinDropdown="matMenu" class="asin-dropdown">
          <div (click)="$event.stopPropagation()">
            <div class="filters-container mb-3">
              <div class="a-side">
                <div class="btn-container" (click)="isFilters = !isFilters">
                  <button mat-button mat-raised-button class="default-btn filter-btn" type="button" [class.active]="isFilters">
                    {{textService.currentText.filters}}
                  </button>
                </div>
                <form class="filters custom-selects filters-popup" [class.active]="isFilters">
                  <div class="input-wrapper">
                    <mat-form-field>
                      <input matInput type="text" [placeholder]="textService.currentText.productName" (keyup)="checkFilters()"
                        name="defaultName" [(ngModel)]="searchFilters.defaultName">
                    </mat-form-field>
                    <div class="clear-input" *ngIf="searchFilters.defaultName"
                      (click)="searchFilters.defaultName = ''; checkFilters()"></div>
                  </div>
                  <div class="input-wrapper">
                    <mat-form-field>
                      <input matInput type="text" [placeholder]="textService.currentText.asin" (keyup)="checkFilters()"
                        name="asin" [(ngModel)]="searchFilters.asin">
                    </mat-form-field>
                    <div class="clear-input" *ngIf="searchFilters.asin" (click)="searchFilters.asin = ''; checkFilters()"></div>
                  </div>
                  <div class="input-wrapper">
                    <mat-form-field>
                      <input matInput type="text" [placeholder]="textService.currentText.SKU" (keyup)="checkFilters()" name="sku"
                        [(ngModel)]="searchFilters.sku">
                    </mat-form-field>
                    <div class="clear-input" *ngIf="searchFilters.sku" (click)="searchFilters.sku = ''; checkFilters()"></div>
                  </div>
                  <button mat-button mat-raised-button (click)="clearFilters()" class="default-btn clear-btn" type="button">
                    {{textService.currentText.clearAll}}
                  </button>
                </form>
              </div>
            </div>
            <div class="table-responsive">
              <table class="table" matSort (matSortChange)="sortData($event)">
                <thead>
                  <tr>
                    <th>
                      <mat-checkbox disableRipple (change)="toggleAllSelection('asin', $event.checked)" formControlName="checkAll" class="square-checkbox">
                                </mat-checkbox>
                    </th>
                    <th>{{textService.currentText.product}} {{textService.currentText.image}}</th>
                    <th mat-sort-header="defaultName">{{textService.currentText.productName}}</th>
                    <th mat-sort-header="asin">{{textService.currentText.asin}}</th>
                    <th mat-sort-header="sku">{{textService.currentText.SKU}}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let product of products">
                    <td>
                      <mat-checkbox disableRipple (change)="saveCurrentCheckbox(product)" class="square-checkbox" [checked]="product.checked">
                              </mat-checkbox>
                    </td>
                    <td>
                        <img [src]="product?.image" alt="">
                    </td>
                    <td>
                      <p>{{ product.shortNames.defaultShortName ? product.shortNames.defaultShortName : product.defaultName }}</p>
                    </td>
                    <td>{{product.asin}}</td>
                    <td>{{product.sku}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </mat-menu>
        <div class="d-flex mt-3 justify-content-between">
          <button type="button" mat-button class="default-btn resetFilterBtn" (click)="clearAll()">
            Reset
          </button>
          <button type="button" mat-button class="default-btn resetFilterBtn" (click)="saveFilter()">
            Save
          </button>
          <button mat-button class="default-btn saveFilterBtn" type="submit">
            Apply
          </button>
          <button mat-button class="default-btn filterres px-1 ml-4" (click)="changeDay();">
            <mat-icon class="mt-n1">refresh</mat-icon>
          </button>
        </div>
      </div>
    </form>
  </div>
  <div class="a-side">
    <!-- <form [formGroup]="productForm" (ngSubmit)="onSubmit()"> -->
    <div class="dashboard-container top-schedule custom-selects filter-section">
      <div class="dashboard-filters mobilrFilter">
        <mat-button-toggle-group class="marginleft" [(ngModel)]="selectedVal">
          <mat-button-toggle value="TODAY" class="default-btn" (click)="mixpanelService.track('q time frame - clicked',{'type':
          'today', 'page':'orders-dashboard'});changeDay()">Today</mat-button-toggle>
          <mat-button-toggle value="YESTERDAY" class="default-btn" (click)="mixpanelService.track('q time frame - clicked',{'type':
          'yesterday', 'page':'orders-dashboard'});changeDay()">Yesterday</mat-button-toggle>
          <mat-button-toggle value="LAST_7_DAYS" class="default-btn" (click)="mixpanelService.track('q time frame - clicked',{'type':
          '7 days', 'page':'orders-dashboard'});changeDay()">7 Days</mat-button-toggle>
          <mat-button-toggle value="LAST_30_DAYS" class="default-btn" (click)="mixpanelService.track('q time frame - clicked',{'type':
          '30 days', 'page':'orders-dashboard'});changeDay()">30 Days</mat-button-toggle>
          <mat-button-toggle value="MONTH_TO_DATE" class="default-btn" (click)="mixpanelService.track('q time frame - clicked',{'type':
          'month to date', 'page':'orders-dashboard'});changeDay()">Month To Date
          </mat-button-toggle>
          <mat-button-toggle value="LAST_MONTH" class="default-btn" (click)="mixpanelService.track('q time frame - clicked',{'type':
          'last month', 'page':'orders-dashboard'});changeDay()">Last Month</mat-button-toggle>
          <mat-button-toggle value="YEAR_TO_DATE" class="default-btn" (click)="mixpanelService.track('q time frame - clicked',{'type':
          'year to date', 'page':'orders-dashboard'});changeDay()">Year To Date
          </mat-button-toggle>
          <mat-button-toggle value="LAST_YEAR" class="default-btn" (click)="mixpanelService.track('q time frame - clicked',{'type':
          'last year', 'page':'orders-dashboard'});changeDay()">Last Year</mat-button-toggle>
          <mat-button-toggle [value]="selectedVal" class="default-btn" (click)="mixpanelService.track('q time frame - clicked',{'type':
          'Custom', 'page':'orders-dashboard'});$event.preventDefault();"
            [matMenuTriggerFor]="custom" #customDate="matMenuTrigger">
            Custom
          </mat-button-toggle>
          <mat-button-toggle class="default-btn dates" disabled>
            Dates:
            <span class="text-gray-light mt-1" *ngIf="formDataDashBoard">
              {{formDataDashBoard.startDate | date : 'dd/MM/yy'}} - {{formDataDashBoard.endDate | date : 'dd/MM/yy'}}
            </span>
          </mat-button-toggle>
        </mat-button-toggle-group>
        <!-- <button mat-button class="default-btn" (click)="oneDay()">
            1 Day
          </button>
          <button mat-button class="default-btn" (click)="sevenDay()">
            7 Days
          </button>
          <button mat-button class="default-btn selected" (click)="onMonth()">
            30 Days
          </button>
          <button mat-button class="default-btn" (click)="$event.preventDefault();" [matMenuTriggerFor]="custom" #customDate="matMenuTrigger">
            Custom
          </button>-->
        <!-- <div class="px-3 px-lg-4 filterres">
          <button mat-button class="default-btn filter-btn" (click)="$event.preventDefault();" [matMenuTriggerFor]="custom"
            #customDate="matMenuTrigger">
            Filter
          </button>
        </div> -->
        <!-- <button mat-button class="default-btn filterres px-1" (click)="changeDay();">
          <mat-icon class="mt-n1">refresh</mat-icon>
        </button> -->
        <!-- <div class="px-3 px-lg-5 filterres">
          <span class="text-gray-light mt-1"
            *ngIf="profitData && profitData.realTimeData && profitData.realTimeData.lastUpdated">{{profitData.realTimeData.lastUpdated | date : 'd/M/yy H:mm'}}</span>
        </div> -->
        <!-- <div class="filters-title">
              <h2>{{textService.currentText.filters}}</h2>
            </div> -->
        <!-- <div class="select-holder">
              <div class="select-wrapper">
                <mat-form-field>
                  <mat-select #brandsSel formControlName="brands" placeholder="All Brands" multiple [disabled]="brand.length==0?true:false">
                    <mat-option (click)="toggleAllSelection('brands')" [value]="0">All</mat-option>
                    <mat-option *ngFor="let brand of brand" [value]="brand">{{brand}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div> -->
        <!-- <div class="select-holder">
              <div class="select-wrapper">
                <mat-form-field>
                  <mat-select #activeChannelsSel formControlName="marketplace" placeholder="All Marketplaces" multiple
                    [disabled]="activeChannels.length==0?true:false">
                    <mat-option (click)="toggleAllSelection('activeChannels')" [value]="0">All</mat-option>
                    <mat-option *ngFor="let activeChannels of activeChannels" [value]="activeChannels">{{activeChannels}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div> -->
        <!-- <div class="select-holder wide">
              <div class="select-wrapper">
                <mat-form-field>
                  <mat-select #asinSel formControlName="asin" placeholder="All ASINs" multiple #asinSelect [disabled]="products.length==0?true:false">
                    <mat-option (click)="toggleAllSelection('asin')" [value]="0">All</mat-option>
                    <mat-option class="shortNameDisply" matTooltip="{{products.shortNames.defaultShortName}}" *ngFor="let products of products"
                      [value]="products.sku">
                      {{products.shortNames.defaultShortName?products.shortNames.defaultShortName:products.defaultName}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div> -->
        <!-- <div class="right-box"> -->
        <!-- <button mat-button class="default-btn" (click)="$event.preventDefault();" [matMenuTriggerFor]="custom" #customDate="matMenuTrigger">
                Custom
              </button>
              <button mat-button class="primary-btn refresh" type="submit">
                Search
              </button> -->
        <!-- <button mat-button class="default-btn" (click)="$event.preventDefault();" [matMenuTriggerFor]="custom" #customDate="matMenuTrigger">
                Filter
              </button> -->
        <!-- </div>
            <div class="right-box">
              <button mat-button class="default-btn" (click)="refreshData();">
                <img src="assets/icons/refresh-icon.svg" alt="">
              </button>
            </div> -->
      </div>
      <!-- <div class="right-box">
            <button mat-button class="default-btn" (click)="clearAll()">
              {{textService.currentText.clearAll}}
            </button>
            <button mat-button class="primary-btn refresh" (click)="refreshData();">
              {{textService.currentText.refresh}}
              <img src="../../../../assets/icons/refresh-icon.svg" alt="">
            </button>
          </div> -->
    </div>
    <!-- </form> -->
    <mat-menu #custom="matMenu" class="custom-menu-profit">
      <form [formGroup]="productForm" (ngSubmit)="onSubmit()"
        [ngClass]="dashboardSchedulesFilter ? 'dashboard-schedules-filter' : ''">
        <div class="row flex-column flex-md-row" (click)="$event.stopPropagation()"
          (keydown)="$event.stopPropagation()">

          <div class="">
            <ngx-daterangepicker-material [showRangeLabelOnInput]="true" [autoApply]="true"
              [showCustomRangeLabel]="false" [alwaysShowCalendars]="true" [ranges]="ranges" [linkedCalendars]="true"
              [maxDate]="startDate" [keepCalendarOpeningWithRange]="true" (rangeClicked)="choosedDate($event)"
              (choosedDate)="choosedDate($event)" [showClearButton]="false" [locale]="{applyLabel: 'Done'}">
            </ngx-daterangepicker-material>
          </div>
          <div class="right-box ml-4">
            <!-- <div class="filters-title">
              <h2 class="orange font-size-15">Product</h2>
            </div>
            <div class="select-holder mt-3">
              <div class="select-wrapper">
                <button type="button" mat-button class="default-btn resetFilterBtn" (click)="clearAll()">
                  Reset Filters
                </button>
                <button type="button" mat-button class="default-btn saveFilterBtn" (click)="saveFilter()">
                  Save Filters
                </button>
              </div>
            </div>
            <div class="select-holder mt-3 custom-selects filters open-filters flex-column">
              <div class="select-wrapper">
                <mat-form-field class="filterChange">
                  <mat-select #brandsSel disableOptionCentering formControlName="brands" placeholder="All Brands" multiple
                    [disabled]="brand.length==0?true:false">
                    <mat-option [value]="0" (click)="toggleAllSelection('brands')">All</mat-option>
                    <mat-option *ngFor="let brand of brand" [value]="brand">{{brand}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
            <div class="select-holder mt-3 custom-selects filters open-filters flex-column">
              <div class="select-wrapper">
                <mat-form-field class="filterChange">
                  <mat-select #activeChannelsSel disableOptionCentering formControlName="marketplace" placeholder="All Marketplaces" multiple
                    [disabled]="activeChannels.length==0?true:false">
                    <mat-option [value]="0" (click)="toggleAllSelection('activeChannels')">All</mat-option>
                    <mat-option *ngFor="let activeChannels of activeChannels" [value]="activeChannels">{{activeChannels}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
            <div class="select-holder wide mt-3 custom-selects filters open-filters flex-column">
              <div class="select-wrapper">
                <mat-form-field class="filterChange">
                  <mat-select #asinSel disableOptionCentering formControlName="asin" placeholder="All ASINs" multiple #asinSelect
                    [disabled]="products.length==0?true:false">
                    <mat-option [value]="0" (click)="toggleAllSelection('asin')">All</mat-option>
                    <mat-option class="shortNameDisply" title="{{products.shortNames.defaultShortName}}" *ngFor="let products of products"
                      [value]="products.sku">
                      {{products.shortNames.defaultShortName?products.shortNames.defaultShortName:products.defaultName}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div> -->
            <div class="d-flex mt-4 justify-content-between">
              <button type="button" mat-button class="default-btn orange cancelBtn" (click)="cancel()">
                Cancel
              </button>
              <button mat-button class="default-btn applyBtn" type="submit">
                Apply
              </button>
            </div>
          </div>
        </div>
      </form>
    </mat-menu>
  </div>

</div>