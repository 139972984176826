<div class="dashboard one-screen" [class.wide]="userService.smallSidebar">
  <div class="dashboard-header">
    <div class="head-container">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
            <h2 *ngIf="currentPlan?.onScreen.type == '1'">Subscription - from
              trial</h2>
            <h2 *ngIf="currentPlan?.onScreen.type == '2'">Subscription - add
              region</h2>
            <h2 *ngIf="currentPlan?.onScreen.type == '3'">Subscription - from
              premium</h2>
            <h2 *ngIf="currentPlan?.onScreen.type == '5'">Subscription -
              downgrade</h2>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="upgrade-holder">
    <div class="dashboard-container">
      <div class="row">
        <div class="col-md-9">
          <div class="table" *ngIf="((currentPlan?.onScreen.type == '1') ||
            (currentPlan?.onScreen.type == '2'))">
            <div class="upgrade-row title">
              <div class="upgrade-description">
                <!-- <h2>{{textService.currentText.upgTitle}}</h2> -->
              </div>
              <div class="upgrade-description font-color">
                {{textService.currentText.premium}}
              </div>
            </div>
            <div class="upgrade-row price">
              <div class="upgrade-description">
                <p>{{textService.currentText.upgPayWith}} </p><img
                  src="../../../../assets/icons/logo-paypal.svg" alt="">
              </div>
              <div class="upgrade-description">
                <span class="price" *ngIf="currentPlan?.onScreen.type == '2'">{{'+$'
                  + currentPlan?.onScreen.extra}}</span>
                <span class="price" *ngIf="currentPlan?.onScreen.type == '1'">
                  {{'$' + currentPlan?.onScreen.total}} </span><span
                  class="month">per
                  month</span>
              </div>
            </div>
            <div class="upgrade-row last-btn buttons">
              <div class="upgrade-description">
              </div>
              <div class="upgrade-description">
                <button class="subscribe-btn blue mt-4"
                  *ngIf="currentPlan?.onScreen.type == '1'"
                  (click)="mixpanelService.track('q upgrade - subscribe premium
                  clicked',{'price': currentPlan?.onScreen.total, 'type':
                  currentPlan?.onScreen.type,'popup':'open','location': 'up'});openDialog()">
                  {{textService.currentText.subscribe}}
                </button>
                <!-- <button class="subscribe-btn blue mt-4"
                  *ngIf="((currentPlan?.onScreen.total == '35') && (!closeDialog))"
                  (click)="subscribePlan('MONTHLY')">
                  {{textService.currentText.subscribe}}
                </button> -->
                <button class="subscribe-btn blue mt-4"
                  *ngIf="((currentPlan?.onScreen.type == '2') &&
                  (isPopup))"
                  (click)="mixpanelService.track('q upgrade - subscribe premium
                  clicked',{'price': currentPlan?.onScreen.total, 'type':
                  currentPlan?.onScreen.type,'popup':'open','location': 'up'});openDialog()">
                  {{textService.currentText.subscribe}}
                </button>
                <button class="subscribe-btn blue mt-4"
                  *ngIf="((currentPlan?.onScreen.type == '2') &&
                  (isNotPopup))"
                  (click)="mixpanelService.track('q upgrade - subscribe master clicked',{'price': currentPlan?.onScreen.total, 'type':
                  currentPlan?.onScreen.type,'location': 'up'});subscribePlan('MONTHLY_MASTER_NINJA')">
                  {{textService.currentText.subscribe}}
                </button>
              </div>
            </div>
            <div class="upgrade-row buttons">
              <div class="upgrade-description">
              </div>
            </div>
            <div class="upgrade-row mt-3">
              <div class="title">
                <h4>{{textService.currentText.upgDataTitle}}</h4>
              </div>
            </div>
            <div class="upgrade-row">
              <div class="upgrade-description">
                {{textService.currentText.upgDataText1}}
              </div>
              <div class="upgrade-description">
                <img src="assets/icons/checkmark-purple.svg" alt="">
              </div>
            </div>
            <div class="upgrade-row">
              <div class="upgrade-description">
                {{textService.currentText.upgDataText2}}
              </div>
              <div class="upgrade-description">
                <img src="assets/icons/checkmark-purple.svg" alt="">
              </div>
            </div>
            <div class="upgrade-row">
              <div class="upgrade-description">
                {{textService.currentText.upgDataText3}}
              </div>
              <div class="upgrade-description">
                <img src="assets/icons/checkmark-purple.svg" alt="">
              </div>
            </div>
            <div class="upgrade-row">
              <div class="upgrade-description">
                {{textService.currentText.upgDataText4}}
              </div>
              <div class="upgrade-description">
                <img src="assets/icons/checkmark-purple.svg" alt="">
              </div>
            </div>
            <div class="upgrade-row">
              <div class="upgrade-description">
                {{textService.currentText.upgDataText5}}
              </div>
              <div class="upgrade-description">
                <img src="assets/icons/checkmark-purple.svg" alt="">
              </div>
            </div>
            <div class="upgrade-row mt-3">
              <div class="title1">
                <h4>{{textService.currentText.notifications}}</h4>
              </div>
            </div>
            <div class="upgrade-row">
              <div class="upgrade-description">
                {{textService.currentText.upgNotificationsText1}}
              </div>
              <div class="upgrade-description">
                <img src="assets/icons/checkmark-purple.svg" alt="">
              </div>
            </div>
            <div class="upgrade-row">
              <div class="upgrade-description">
                {{textService.currentText.upgNotificationsText3}}
              </div>
              <div class="upgrade-description">
                <img src="assets/icons/checkmark-purple.svg" alt="">
              </div>
            </div>
            <div class="upgrade-row mt-3">
              <div class="title1">
                <h4>{{textService.currentText.upgEmailTitle}}</h4>
              </div>
            </div>
            <div class="upgrade-row">
              <div class="upgrade-description">
                {{textService.currentText.upgEmailText1}}
              </div>
              <div class="upgrade-description">
                <img src="assets/icons/checkmark-purple.svg" alt="">
              </div>
            </div>
            <div class="upgrade-row">
              <div class="upgrade-description">
                {{textService.currentText.upgEmailText2}}
              </div>
              <div class="upgrade-description">
                <img src="assets/icons/checkmark-purple.svg" alt="">
              </div>
            </div>
            <div class="upgrade-row">
              <div class="upgrade-description">
                {{textService.currentText.upgEmailText3}}
              </div>
              <div class="upgrade-description">
                <img src="assets/icons/checkmark-purple.svg" alt="">
              </div>
            </div>
            <div class="upgrade-row">
              <div class="upgrade-description">
                {{textService.currentText.upgEmailText4}}
              </div>
              <div class="upgrade-description">
                <img src="assets/icons/checkmark-purple.svg" alt="">
              </div>
            </div>
            <div class="upgrade-row">
              <div class="upgrade-description">
                {{textService.currentText.upgEmailText5}}
              </div>
              <div class="upgrade-description">
                <img src="assets/icons/checkmark-purple.svg" alt="">
              </div>
            </div>
            <div class="upgrade-row mt-3">
              <div class="title1">
                <h4>{{textService.currentText.upgHjTitle}}</h4>
              </div>
            </div>
            <div class="upgrade-row">
              <div class="upgrade-description">
                {{textService.currentText.upgHjText1}}
              </div>
              <div class="upgrade-description">
                <img src="assets/icons/checkmark-purple.svg" alt="">
              </div>
            </div>
            <div class="upgrade-row">
              <div class="upgrade-description">
                {{textService.currentText.upgHjText2}}
              </div>
              <div class="upgrade-description">
                <img src="assets/icons/checkmark-purple.svg" alt="">
              </div>
            </div>
            <div class="upgrade-row last-btn buttons">
              <div class="upgrade-description">
              </div>
              <div class="upgrade-description">
                <button class="subscribe-btn blue mt-4"
                  *ngIf="currentPlan?.onScreen.type == '1'"
                  (click)="mixpanelService.track('q upgrade - subscribe premium
                  clicked',{'price': currentPlan?.onScreen.total, 'type':
                  currentPlan?.onScreen.type,'location': 'down'});openDialog()">
                  {{textService.currentText.subscribe}}
                </button>
                <!-- <button class="subscribe-btn blue mt-4"
                  *ngIf="((currentPlan?.onScreen.total == '35') && (!closeDialog))"
                  (click)="subscribePlan('MONTHLY')">
                  {{textService.currentText.subscribe}}
                </button> -->
                <button class="subscribe-btn blue mt-4"
                  *ngIf="((currentPlan?.onScreen.type == '2') &&
                  (isPopup))"
                  (click)="mixpanelService.track('q upgrade - subscribe premium
                  clicked',{'price': currentPlan?.onScreen.total, 'type':
                  currentPlan?.onScreen.type,'popup':'open','location': 'down'});openDialog()">
                  {{textService.currentText.subscribe}}
                </button>
                <button class="subscribe-btn blue mt-4"
                  *ngIf="((currentPlan?.onScreen.type == '2') &&
                  (isNotPopup))"
                  (click)="mixpanelService.track('q upgrade - subscribe master clicked',{'price': currentPlan?.onScreen.total, 'type':
                  currentPlan?.onScreen.type,'location': 'down'});subscribePlan('MONTHLY_MASTER_NINJA')">
                  {{textService.currentText.subscribe}}
                </button>
              </div>
            </div>
          </div>
          <div class="master-ninja" *ngIf="currentPlan?.onScreen.type == '3'">
            <div class="upgrade-description-paypal">
              <p>{{textService.currentText.upgPayWith}} </p><img
                src="../../../../assets/icons/logo-paypal.svg" alt="">
            </div>
            <div class="p-top-21">
              <h1 class="title">{{textService.currentText.dialogUpgradeTitle}}</h1>
              <div class="content">
                <h2 class="sub-title pt-4">{{textService.currentText.dialogUpgradeSubTitle}}</h2>
                <img class="img" src="../../../../assets/images/ninja_img.png"
                  />
              </div>
              <div class="p-top-60">
                <h1 class="text-title pt-3">What's included?</h1>
                <p class="text-content pt-2">Asin tracker - see the position of
                  your
                  products by keywords</p>
                <p class="text-content pt-2">Facebook bot - get more leads by
                  using
                  automated conversations on
                  Facebook and drive traffic to your Amazon pages</p>
              </div>
              <div class="row mt-5 d-flex justify-content-center">
                <div class="upgrade-price">
                  <span class="price"> {{ '+$' + currentPlan?.onScreen.extra}}
                  </span>
                  <span class="month">per month</span>
                </div>
                <div class="upgrade-add pl-4">
                  <span class="text-content"> Add One - Get many
                  </span><br>
                  <span class="pay-regions">Pay for one and get it for ALL your
                    regions!</span>
                </div>
              </div>
              <div class="text-center">
                <button class="subscribe-btn-1 blue mt-4"
                  (click)="mixpanelService.track('q upgrade - subscribe master
                  clicked',{'price':currentPlan?.onScreen.extra,'type':currentPlan?.onScreen.type});subscribePlan('MONTHLY_MASTER_NINJA')">
                  Try it now
                </button>
              </div>
            </div>
          </div>
          <div class="master-ninja" *ngIf="currentPlan?.onScreen.type == '5'">
            <div class="upgrade-description-paypal">
              <p>{{textService.currentText.upgPayWith}} </p><img
                src="../../../../assets/icons/logo-paypal.svg" alt="">
            </div>
            <div class="p-top-21">
              <h1 class="title1">You Are a Master Ninja</h1>
              <div class="content1">
                <h2 class="sub-title pt-4">Thank you for choosing Quantify Ninja</h2>
                <img class="img" src="../../../../assets/images/ninja_img.png"
                  />
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <div class="top" [ngClass]="((currentPlan?.onScreen.type == '1') ||
            (currentPlan?.onScreen.type == '2'))?'p-top':'p-top-60'">
            <div class="plan-name-holder" *ngIf="!isCustomPaymentAvaliable">
              <h2>{{(currentPlan?.onScreen.type == '5')? 'Downgrade':
                textService.currentText.upgradeCalcCost}} </h2>
              <p class="plan">{{textService.currentText.upgYourCurrPlan}}:
                <span>{{(currentPlan?.onScreen.type == '5')?
                  'Master Ninja' :
                  'Basic'}}</span>
              </p>
              <div>
                <button mat-button mat-raised-button class="button default-btn"
                  *ngIf="currentPlan?.onScreen.type == '5'; else Subscribe"
                  [disabled]="currentPlan?.onScreen.type == '1'"
                  (click)="googleAnalyticsEventsService.emitEvent('Button',
                  'Downgrade Plan', 'Click', 1); openDowngradeDialog()">
                  {{textService.currentText.downgrade}}</button>
                <ng-template #Subscribe>
                  <button mat-button mat-raised-button class="button
                    default-btn"
                    [disabled]="currentPlan?.onScreen.type == '1'"
                    (click)="googleAnalyticsEventsService.emitEvent('Button',
                    'Cancel Plan', 'Click', 1); openCancelPlanDialog()">
                    {{textService.currentText.upgCancelPlan}}</button>
                </ng-template>
              </div>
            </div>
            <div class="custom-plan-holder" *ngIf="isCustomPaymentAvaliable">
              <p>
                <span class="bold">{{textService.currentText.customPlanAvaliable}}</span>
              </p>
              <p>
                <span class="bold">{{textService.currentText.planPrice}}:</span>
                <span>{{customDataInfo.regularPayment + '$'}}</span>
              </p>
              <p>
                <span class="bold">{{textService.currentText.firstCharge}}:</span>
                <span>{{customDataInfo.setupFee + '$'}}</span>
              </p>
              <p>
                <span class="bold">{{textService.currentText.firstChargeOn}}:</span>
                <span>{{customDataInfo.firstPaymentDate | date}}</span>
              </p>
              <div class="change-acc">
                <button mat-button class="primary-btn"
                  [disabled]="isCustomPaymentPending"
                  (click)="getCustomPaymentUrl()">
                  {{textService.currentText.subscribe}}
                </button>
                <div class="loader-block" *ngIf="isCustomPaymentPending">
                  <img src="assets/icons/mini-load.gif" alt="">
                </div>
              </div>
            </div>
            <div class="options mt-3">
              <h3>{{textService.currentText.options}}</h3>
              <p>{{textService.currentText.upgPriceText1}}</p>
              <br>
              <p>{{textService.currentText.upgPriceText2}}</p>
            </div>
            <div class="plan">
              <h5>{{textService.currentText.upgPlanVariations}}:</h5>
              <div class="plans">
                <div class="left">
                  <p class="m-0">{{textService.currentText.upgPrem1Reg}} : <b>$35/mo</b></p>
                  <p class="m-0">{{textService.currentText.upgPrem2Reg}} : <b>$50/mo</b></p>
                  <p class="m-0">{{textService.currentText.upgPrem3Reg}} : <b>$65/mo</b></p>
                  <p class="m-0">{{textService.currentText.upgJoinMaster}} : <b>+$20/mo</b></p>
                </div>
              </div>
            </div>
            <div class="options mt-3">
              <h3>{{textService.currentText.payment}}</h3>
              <div class="change-acc">
                <button mat-button [disabled]="isChangeAccRequestPending" (click)="mixpanelService.track('q upgrade - change paypal');getChangeAccountPayPalUrl()" class="default-btn">
                  {{textService.currentText.changePayPalAccount}}
                </button>
                <div class="loader-block" *ngIf="isChangeAccRequestPending">
                  <img src="assets/icons/mini-load.gif" alt="">
                </div>
              </div>

            </div>
            <div class="options bottom mt-3">
              <h3>{{textService.currentText.notice}}</h3>
              <p>{{textService.currentText.upgNoticeText1}} <span class="bold">{{textService.currentText.masterNinja}}</span>,
                <br>
                {{textService.currentText.upgNoticeText2}}</p>
            </div>
          </div>
        </div>

      </div>
    </div>
    <!-- <p class="taxes" *ngIf="costs?.isIsrael">* All prices are without taxes.</p> -->
    <!-- <div class="dashboard-container side">
      
    </div> -->
  </div>
</div>