<div class="dashboard one-screen" [class.wide]="userService.smallSidebar">
  <div class="dashboard-header">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <div class="head-container mb-0">
            <h2 class="mb-0">{{textService.currentText.ordersTitle}}</h2>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="dashboard-container">
    <div class="filters-container">
      <div class="a-side">
        <form class="filters orders custom-selects align-items-center">
          <div class="select-holder d-flex">
            <h3 class="font-size-18 mb-0 mr-3">{{textService.currentText.ordersTitle}}</h3>
            <mat-icon class="search-icon">search</mat-icon>
          </div>
          <div class="select-holder">
            <div (click)="functions.setDropDownPosition(orderTypeSelect,
              'orderType')" #orderTypeSelect
              [class.active]="orderType.panelOpen"
              class="select-wrapper">
              <mat-form-field>
                <mat-select disableOptionCentering
                  [placeholder]="textService.currentText.allTypes"
                  shouldPlaceholderFloat="false"
                  panelClass="default-dropdown orderType" #orderType
                  disableRipple [(ngModel)]="filters.orderType"
                  (selectionChange)="checkValue()"
                  name="orderType">
                  <mat-option *ngFor="let select of
                    constService.orderTypeFilter" [value]="select.value">
                    {{ select.viewValue }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <div class="select-holder" (mouseenter)="showProductTooltip= true"
            (mouseleave)="showProductTooltip= false">
            <div (click)="functions.setDropDownPosition(allProductsSelect,
              'allProducts')" #allProductsSelect
              [class.active]="allProducts.panelOpen"
              class="select-wrapper">
              <mat-form-field>
                <mat-select disableOptionCentering
                  [placeholder]="filters.productsPlaceholder"
                  shouldPlaceholderFloat="false"
                  panelClass="default-dropdown allProducts" #allProducts
                  disableRipple [(ngModel)]="filters.allProducts"
                  (selectionChange)="showSpecProducts()" name="allProducts">
                  <mat-option *ngFor="let select of
                    constService.productsDashboard" [value]="select.value">
                    {{ select.viewValue }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="spec-product-tooltip"
              *ngIf="filters.selectedProducts.length> 0 && showProductTooltip ==
              true"
              (mouseenter)="showProductTooltip = true"
              (mouseleave)="showProductTooltip = false">
              <h4>{{textService.currentText.selectedProductsUpperCase}} :</h4>
              <ol>
                <li *ngFor="let product of filters.selectedProducts">
                  <span>{{product.shortName}}</span>
                </li>
              </ol>
            </div>
          </div>
          <div class="select-holder datepicker">
            <mat-form-field>
              <input matInput [matDatepicker]="picker" [(ngModel)]="dateFrom"
                name="dateFrom" [min]="minDateFrom" [max]="dateTo"
                (click)="picker.open()" (keydown)="$event.preventDefault()"
                [placeholder]="textService.currentText.from">
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker touchUi="true" panelClass="calendar-popup"
                #picker></mat-datepicker>
            </mat-form-field>
          </div>
          <div class="select-holder datepicker">
            <div (click)="functions.setDropDownPosition(SalesChannel,
              'salesChannel')" #SalesChannel>
              <mat-form-field>
                <input matInput [matDatepicker]="picker2" [min]="dateFrom ||
                  minDateFrom" [(ngModel)]="dateTo" (click)="picker2.open()"
                  (keydown)="$event.preventDefault()" placeholder="To"
                  name="dateTo">
                <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                <mat-datepicker touchUi="true" panelClass="calendar-popup"
                  #picker2></mat-datepicker>
              </mat-form-field>
            </div>
          </div>
          <div class="input-wrapper search">
            <mat-form-field>
              <input matInput [(ngModel)]="filters.buyerName" name="buyerName"
                type="text" [disabled]="getFilterDisable()"
                [placeholder]="textService.currentText.buyerName">
              <div class="clear-input" *ngIf="filters.buyerName"
                (click)="filters.buyerName= ''"></div>
            </mat-form-field>
          </div>
          <div class="input-wrapper search">
            <mat-form-field>
              <input matInput type="text" [(ngModel)]="filters.buyerEmail"
                name="buyerEmail" [disabled]="getFilterDisable()"
                [placeholder]="textService.currentText.buyerEmail">
              <div class="clear-input" *ngIf="filters.buyerEmail"
                (click)="filters.buyerEmail= ''"></div>
            </mat-form-field>
          </div>
          <div class="input-wrapper search">
            <mat-form-field>
              <input matInput [(ngModel)]="filters.orderId" name="orderId"
                type="text" [disabled]="getFilterDisable()"
                [placeholder]="textService.currentText.orderId">
              <div class="clear-input" *ngIf="filters.orderId"
                (click)="filters.orderId= ''"></div>
            </mat-form-field>
          </div>
          <button mat-button mat-raised-button class="default-btn search-btn
            new-primary-btn" type="button"
            (click)="mixpanelService.track('q orders - search clicked', {
            'type': page});googleAnalyticsEventsService.emitEvent('Button',
            'Order Search', 'Click', 1); searchOrders()">
            {{textService.currentText.search}}
          </button>
          <button mat-button mat-raised-button class="default-btn clear-btn
            new-primary-btn" type="button" (click)="mixpanelService.track('q
            orders - clear all cilcked', { 'type': page});clearFilter()">
            {{textService.currentText.clearAll}}
          </button>
        </form>
      </div>
    </div>
    <div class="table-container orders">
      <div class="table-wrapper">
        <div class="table-header-wrapper">
          <div class="table-header" [class.fullWidth]="currentTemplates?.length
          <= 5">
            <div class="td"></div>
            <div class="td">#</div>
            <div class="td" (click)="functions.sortColumns(currentTemplates,
              'orderid')">
              {{textService.currentText.orderId}}</div>
            <div class="td" (click)="functions.sortColumns(currentTemplates,
              'productName')">
              {{textService.currentText.name}}</div>
            <div class="td" (click)="functions.sortColumns(currentTemplates,
              'purchasedDate')">
              {{textService.currentText.date}}</div>
            <div class="td" (click)="mixpanelService.track('q orders - request
              review ', { 'state': 'first', 'type':
              page});functions.sortColumns(currentTemplates, 'reviewRequest')">
              {{textService.currentText.reviewRequest}}</div>
          </div>
        </div>
        <div class="table-holder" *ngIf="currentTemplates?.length === 0">
          <div class="table">
            <div class="tr">
              <div class="td not-found">
                {{textService.currentText.noDataFound}}.
              </div>
            </div>
          </div>
        </div>
        <div class="table-holder" *ngIf="currentTemplates?.length> 0">
          <div class="table">
            <div class="table-row" *ngFor="let order of currentTemplates let i=
              index">
              <div class="tr" (click)="order.showDetails= !order.showDetails"
                [ngClass]="order.showDetails ? 'border-bottom' : ''">
                <div class="td">
                  <ng-container *ngIf="!order.showDetails">+</ng-container>
                  <ng-container *ngIf="order.showDetails">-</ng-container>
                </div>
                <div (mouseenter)="order.isTooltip= !order.isTooltip"
                  (mouseleave)="order.isTooltip= !order.isTooltip" class="td"
                  [class.refunded]="order.isRefunded">
                  <ng-container *ngIf="order.isRefunded">
                    <ninja-refund-tooltip *ngIf="order.isTooltip"
                      (toggledChange)="isTooltip= $event"
                      [refundDate]="order.refundDate"
                      class="left-align hide-icon-tooltip no-close-btn">
                    </ninja-refund-tooltip>
                  </ng-container>
                  <span>{{order.index}}</span>
                </div>
                <div class="td text-center">{{order.orderid}}</div>
                <div class="td">{{order.productName}}
                </div>
                <div class="td text-center">{{order.purchasedDate}}</div>
                <div class="td"><a *ngIf="order.reviewRequestLink"
                    class="{{order.reviewRequestClicked?'review-clicked':''}}"
                    (click)="onRequestReview($event,order); false"
                    href="{{order.reviewRequestLink}}" target="_blank">Request a
                    Review</a></div>
              </div>
              <div class="details" *ngIf="order.showDetails">
                <mat-menu #openCheckbox="matMenu" class="menu-form-wrapper">
                  <div class="checkboxes">
                    <div class="checkbox-item d-flex mb-3">
                      <mat-checkbox class="square-checkbox"
                        (click)="mixpanelService.track('q orders - exclude order
                        checked', { 'state': order.isOnBlackList?
                        'on':'off', 'type':
                        page});changeExcludeOrder(!order.isOnBlackList,
                        order.orderid, false)"
                        [(ngModel)]="order.isOnBlackList"
                        [checked]="order.isOnBlackList" disableRipple>
                        {{textService.currentText.ordersExcludeOrder}}
                      </mat-checkbox>
                      <div class="opt-tooltip" (click)="mixpanelService.track('q
                        orders - exclude order help', {
                        'type':page});openOrderBlackList()">
                        <span>?</span>
                      </div>
                    </div>
                    <div class="checkbox-item d-flex">
                      <mat-checkbox class="square-checkbox"
                        [checked]="order.isBuyerOnBlackList"
                        [(ngModel)]="order.isBuyerOnBlackList"
                        (click)="mixpanelService.track('q orders - exclude buyer
                        checked', { 'state': order.isBuyerOnBlackList?
                        'on':'off', 'type':
                        page});changeExcludeBuyer(!order.isBuyerOnBlackList,
                        order.buyerEmail)" disableRipple>
                        {{textService.currentText.ordersExcludeBuyer}}
                      </mat-checkbox>
                      <div class="opt-tooltip" (click)="mixpanelService.track('q
                        orders - exclude buyer help', {
                        'type':page});openBuyersBlackList()">
                        <span>?</span>
                      </div>
                    </div>
                  </div>
                </mat-menu>
                <!-- <div class="details-row">
                  <div class="a-side">
                    <div class="checkboxes">
                      <div class="checkbox-item">
                        <mat-checkbox (click)="changeExcludeOrder(!order.isOnBlackList, order.orderid, false)"
                          [(ngModel)]="order.isOnBlackList" [checked]="order.isOnBlackList" disableRipple>
                          {{textService.currentText.ordersExcludeOrder}}
                        </mat-checkbox>
                        <div class="opt-tooltip" (click)="openOrderBlackList()">
                          <span>?</span>
                        </div>
                      </div>
                      <div class="checkbox-item">
                        <mat-checkbox [checked]="order.isBuyerOnBlackList" [(ngModel)]="order.isBuyerOnBlackList"
                          (click)="changeExcludeBuyer(!order.isBuyerOnBlackList, order.buyerEmail)" disableRipple>
                          {{textService.currentText.ordersExcludeBuyer}}
                        </mat-checkbox>
                        <div class="opt-tooltip" (click)="openBuyersBlackList()">
                          <span>?</span>
                        </div>
                      </div>
                    </div>
                    <div class="delivered-messages" *ngIf="order?.sentTemplates?.length > 0">
                      <div class="flex relative">
                        <span class="opt-tooltip purple" (mouseenter)="order.showDeliveredTooltip = true"
                          (mouseleave)="order.showDeliveredTooltip = false">
                          <span>!</span>
                          <span class="help-tooltip" *ngIf="order.showDeliveredTooltip">
                            <p>{{textService.currentText.orderDeliveredTemplateTooltip}}</p>
                          </span>

                        </span>
                        <p>{{textService.currentText.deliveredMessages}}:</p>
                      </div>
                      <ng-container *ngFor="let template of order.sentTemplates; let i = index">
                        <span *ngIf="i <= 0"> {{template ? template : textService.currentText.deletedTemplate}},</span>
                      </ng-container>
                      <span class="more" *ngIf="order.sentTemplates.length > 1"
                        (click)="order.showMoreTemplates =  !order.showMoreTemplates">
                        + {{order.sentTemplates.length - 1}} {{textService.currentText.moreLowercase}}
                        <div class="template-list" *ngIf="order.showMoreTemplates">
                          <ng-container *ngFor="let template of order.sentTemplates; let j = index">
                            <div class="tag-item" *ngIf="j > 0">
                              {{template ? template : textService.currentText.deletedTemplate}}
                            </div>
                          </ng-container>
                        </div>
                      </span>
                    </div>
                  </div>
                  <button mat-button class="default-btn" type="button" (click)="goToSendMessage(order)">
                    {{textService.currentText.sendMessage}}
                  </button>
                </div> -->
                <!-- <div class="inputs-block">
                  <div class="column">
                    <label>{{textService.currentText.orderId}}</label>
                    <input [value]="order.orderid || '-'" matInput readonly type="text">
                    <label>{{textService.currentText.quantity}}</label>
                    <input [value]="order.quantity || '-'" matInput readonly type="text">
                    <label>{{textService.currentText.productName}}</label>
                    <input [value]="order.productName || '-'" matInput readonly type="text">
                    <label>{{textService.currentText.purchaseDate}}</label>
                    <input [value]="order.purchasedDate || '-'" matInput readonly type="text">
                    <label>{{textService.currentText.shipmentDate}}</label>
                    <input [value]="order.shipmentDate || '-'" matInput readonly type="text">
                    <label>{{textService.currentText.customerPhoneNumber}}</label>
                    <input [value]="order.phoneNumber || '-'" matInput readonly type="text">
                    <label>{{textService.currentText.trackingNumber}}</label>
                    <input [value]="order.trackingNumber || '-'" matInput readonly type="text">
                    <label>{{textService.currentText.zipCode}}</label>
                    <input [value]="order.zip || '-'" matInput readonly type="text">
                  </div>
                  <div class="column">
                    <label>{{textService.currentText.SKU}}</label>
                    <input [value]="order.sku || '-'" matInput readonly type="text">
                    <label>{{textService.currentText.buyerEmail}}</label>
                    <input [value]="order.buyerEmail || '-'" matInput readonly type="text">
                    <label>{{textService.currentText.buyerName}}</label>
                    <input [value]="order.buyerName || '-'" matInput readonly type="text">
                    <label>{{textService.currentText.receipientName}}</label>
                    <input [value]="order.receipientName || '-'" matInput readonly type="text">
                    <label>{{textService.currentText.serviceLevel}}</label>
                    <input [value]="order.serviceLevel || '-'" matInput readonly type="text">
                    <label>{{textService.currentText.shipAddress}} 1</label>
                    <input [value]="order.shipAddress1 || '-'" matInput readonly type="text">
                    <label>{{textService.currentText.carrier}}</label>
                    <input [value]="order.carrier || '-'" matInput readonly type="text">
                  </div>
                  <div class="column">
                    <label>{{textService.currentText.shipAddress}} 2</label>
                    <input [value]="order.shipAddress2 || '-'" matInput readonly type="text">
                    <label>{{textService.currentText.shipCity}}</label>
                    <input [value]="order.shipCity || '-'" matInput readonly type="text">
                    <label>{{textService.currentText.shipState}}</label>
                    <input [value]="order.shipState || '-'" matInput readonly type="text">
                    <label>{{textService.currentText.shipCountry}}</label>
                    <input [value]="order.shipCountry || '-'" matInput readonly type="text">
                    <label>{{textService.currentText.itemPrice}}</label>
                    <input [value]="order.itemPrice || '-'" matInput readonly type="text">
                    <label>{{textService.currentText.itemTax}}</label>
                    <input [value]="order.itemTax || '-'" matInput readonly type="text"> -->
                <!--<label >{{textService.currentText.asin}}</label>-->
                <!--<input-->
                <!--[value]="order.asin || '-'"-->
                <!--matInput-->
                <!--readonly-->
                <!--type="text">-->
                <!-- <label>{{textService.currentText.estimatedArrival}}</label>
                    <input [value]="order.estimatedDelivery || '-'" matInput readonly type="text">
                  </div>
                  <div class="column">
                    <label>{{textService.currentText.shippingPrice}}</label>
                    <input [value]="order.shippingPrice" matInput readonly type="text">
                    <label>{{textService.currentText.shippingTax}}</label>
                    <input [value]="order.shippingTax" matInput readonly type="text">
                    <label>{{textService.currentText.giftWrapPrice}}</label>
                    <input [value]="order.giftWrapPrice" matInput readonly type="text">
                    <label>{{textService.currentText.giftWrapTax}}</label>
                    <input [value]="order.giftWrapTax" matInput readonly type="text">
                    <label>{{textService.currentText.itemPromotionDiscount}}</label>
                    <input [value]="order.itemPromotionDiscount" matInput readonly type="text">
                    <label>{{textService.currentText.shipPromotionDisc}}</label>
                    <input [value]="order.shippingPromotionDiscount" matInput readonly type="text">
                    <label>{{textService.currentText.salesChannel}}</label>
                    <input [value]="order.salesChannel || '-'" matInput readonly type="text">
                  </div>
                </div> -->
                <div class="row">
                  <div class="col-10">
                    <div class="media mb-4 align-items-center">
                      <img *ngIf="order.img" src="{{order.img}}" width="50"
                        height="60" class="mr-3">
                      <img *ngIf="!order.img" src="assets/images/noImage.png"
                        width="50" height="60" class="mr-3">
                      <div class="media-body">
                        <div class="d-flex align-items-center mb-1">
                          <img width="30" src="{{order.mpImage}}" class="mr-3">
                          <h5 class="mt-0 mb-0 mr-4">{{textService.currentText.order}}
                            {{order.orderid}}</h5>
                          <img class="mr-3" *ngIf="order.itemPromotionDiscount
                          <0" src="assets/icons/ordertag.png">
                          <img *ngIf="order.itemStatus == 'Pending'"
                            src="assets/images/Status_Pending.png">
                        </div>
                        {{order.productName}}
                      </div>
                    </div>
                  </div>
                  <div class="col-2 d-flex justify-content-end
                    align-items-start">
                    <div class="last-messages">
                      <img class="mt-1"
                        src="assets/icons/last-messages-send-icon.svg">
                      <p>{{order?.sentTemplates?.length > 0 ?
                        order.sentTemplates.length : '0' }}
                        {{textService.currentText.messagesDelivered}}</p>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-6">
                    <div class="row">
                      <div class="col-6">
                        <h3>
                          <img class="mr-2" src="assets/icons/orderInfo.png">
                          {{textService.currentText.orderInfo}}
                        </h3>
                        <table class="details-table table">
                          <tr>
                            <th>{{textService.currentText.purchaseDate}}</th>
                            <td>{{order.purchasedDate}}</td>
                          </tr>
                          <tr>
                            <th>{{textService.currentText.quantity}}</th>
                            <td>{{order.quantity}}</td>
                          </tr>
                          <tr>
                            <th>{{textService.currentText.salesChannel}}</th>
                            <td>{{order.salesChannel}}</td>
                          </tr>
                          <tr>
                            <th>{{textService.currentText.asin}}</th>
                            <td>{{order.asin}}</td>
                          </tr>
                          <tr>
                            <th>{{textService.currentText.SKU}}</th>
                            <td>{{order.sku}}</td>
                          </tr>
                        </table>
                      </div>
                      <div class="col-6">
                        <h3>
                          <img class="mr-2" src="assets/icons/shippingInfo.png"
                            (click)="mixpanelService.track('q orders - order
                            ship details clicked', { 'type': page})">
                          {{textService.currentText.shippingInfo}}
                        </h3>
                        <table *ngIf="order.itemStatus == 'Pending'"
                          class="details-table table">
                          <tr>
                            <th>{{textService.currentText.pending}}...</th>
                          </tr>
                        </table>
                        <table *ngIf="order.itemStatus != 'Pending'"
                          class="details-table table">
                          <tr>
                            <th>{{textService.currentText.shipmentDate}}</th>
                            <td>{{order.shipmentDate}}</td>
                          </tr>
                          <tr>
                            <th>{{textService.currentText.estimatedArrival}}</th>
                            <td>{{order.estimatedDelivery}}</td>
                          </tr>
                          <tr>
                            <th>{{textService.currentText.serviceLevel}}</th>
                            <td>{{order.serviceLevel}}</td>
                          </tr>
                          <tr>
                            <th>{{textService.currentText.carrier}}</th>
                            <td>{{order.carrier}}</td>
                          </tr>
                          <tr>
                            <th>{{textService.currentText.trackingNumber}}</th>
                            <td>
                              <mat-icon>gps_fixed</mat-icon>{{order.trackingNumber}}
                            </td>
                          </tr>
                        </table>
                      </div>
                      <ul class="details-action mt-4">
                        <li *ngIf="order.reviewRequestLink"
                          class="{{order.reviewRequestClicked?'review-clicked-detail':''}}"
                          (click)="mixpanelService.track('q orders - request
                          review', { 'state': 'second', 'type':
                          page});onRequestReview($event, order);">
                          <img src="assets/icons/requestareview.png">
                          <p>Request a Review</p>
                        </li>
                        <li *ngIf="order.itemStatus == 'Pending'"
                          class="review-clicked-detail" matTooltip="You can’t
                          exclude when an order is pending">
                          <img src="assets/icons/excludefromtemplates.png">
                          <p>Exclude From Templates</p>
                        </li>
                        <li *ngIf="order.itemStatus != 'Pending'"
                          [matMenuTriggerFor]="openCheckbox">
                          <img src="assets/icons/excludefromtemplates.png">
                          <p>Exclude From Templates</p>
                        </li>
                        <li *ngIf="!order.buyerEmail"
                          class="review-clicked-detail" matTooltip="Order is
                          still pending">
                          <img src="assets/icons/sendinvoice.png">
                          <p>Send Message</p>
                        </li>
                        <li *ngIf="order.buyerEmail"
                          (click)="mixpanelService.track('q orders - send manual message view', { 'type':
                          page});goToSendMessage(order)">
                          <img src="assets/icons/sendinvoice.png">
                          <p>Send Message</p>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="col-3">
                    <h3>
                      <img class="mr-2" src="assets/icons/buyerInfo.png">
                      {{textService.currentText.buyerInfo}}
                    </h3>
                    <table *ngIf="order.itemStatus == 'Pending'"
                      class="details-table table">
                      <tr>
                        <th>{{textService.currentText.pending}}...</th>
                      </tr>
                    </table>
                    <table *ngIf="order.itemStatus != 'Pending'"
                      class="details-table table">
                      <tr>
                        <th>{{textService.currentText.buyerName}}</th>
                        <td>{{order.buyerName}}</td>
                      </tr>
                      <tr>
                        <th>{{textService.currentText.buyerEmail}}</th>
                        <td>
                          <mat-icon>mail_outline</mat-icon><span
                            class="buyerEmail">{{order.buyerEmail}}</span>
                        </td>
                      </tr>
                      <tr>
                        <th>{{textService.currentText.receipientName}}</th>
                        <td>{{order.receipientName}}</td>
                      </tr>
                      <tr>
                        <th>{{textService.currentText.shipAddress}}</th>
                        <td (click)="openAddressDialog(order)">
                          <mat-icon>location_on</mat-icon>{{order.shipAddress1}}
                        </td>
                      </tr>
                      <tr>
                        <th>{{textService.currentText.customerPhoneNumber}}</th>
                        <td>{{order.phoneNumber}}</td>
                      </tr>
                    </table>
                  </div>
                  <div class="col-3">
                    <h3>
                      <img class="mr-2" src="assets/icons/profit.png">
                      {{textService.currentText.profit}}
                    </h3>
                    <table class="details-table table">
                      <tr>
                        <th>{{textService.currentText.itemPrice}}</th>
                        <td>{{order.itemPrice}}</td>
                      </tr>
                      <tr>
                        <th>{{textService.currentText.itemTax}}</th>
                        <td>{{order.itemTax}}</td>
                      </tr>
                      <tr>
                        <th>{{textService.currentText.giftWrapPrice}}</th>
                        <td>{{order.giftWrapPrice}}</td>
                      </tr>
                      <tr>
                        <th>{{textService.currentText.giftWrapTax}}</th>
                        <td>{{order.giftWrapTax}}</td>
                      </tr>
                      <tr>
                        <th>{{textService.currentText.itemPromotionDiscount}}</th>
                        <td>{{order.itemPromotionDiscount}}</td>
                      </tr>
                      <tr>
                        <th>{{textService.currentText.shipPromotionDisc}}</th>
                        <td>{{order.shippingPromotionDiscount}}</td>
                      </tr>
                      <tr>
                        <th>{{textService.currentText.shippingPrice}}</th>
                        <td>{{order.shippingPrice}}</td>
                      </tr>
                      <tr>
                        <th>{{textService.currentText.shippingTax}}</th>
                        <td>{{order.shippingTax}}</td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mat-paginator-custom" *ngIf="currentTemplates?.length> 0">
          <p>Items per page: 25</p>
          <button (click)="searchOrders(this.prevToken)"
            [disabled]="this.nextToken === 25 || this.nextToken === 0">
            <svg class="mat-paginator-icon" focusable="false" width="24px"
              height="24" viewBox="0 0 24 24">
              <path class="prev" fill="#757575" d="M15.41 7.41L14 6l-6 6 6 6
                1.41-1.41L10.83 12z"></path>
            </svg>
          </button>
          <button (click)="searchOrders(this.nextToken)"
            [disabled]="this.nextToken === 0 || isNextDisabled">
            <svg class="mat-paginator-icon" focusable="false" width="24px"
              height="24" viewBox="0 0 24 24">
              <path class="next" fill="#757575" d="M10 6L8.59 7.41 13.17
                12l-4.58 4.59L10 18l6-6z"></path>
            </svg>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<ninja-notification [active]="userService.showNotification"
  [message]="userService.notificationMessage"
  [title]="userService.notificationTitle">
</ninja-notification>