// import {Headers, Http, RequestOptions, Response} from '@angular/http';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';


import { StorageBrowser } from './storage.browser';
import { TokenService } from './token.service';
import { DOCUMENT } from "@angular/common";
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment.prod';
import { CreateAsinInterface, User } from '../models/BaseModels';
import { AppConfigService } from './app-config.service';

// @Injectable()
@Injectable({
    providedIn: "root"
})

export class UserService {
    _apiEndpoint: string;

    // public _apiEndpoint = 'https://www.api.quantifyninja.com/rest/';
    //public _apiEndpoint = 'http://lap1-w10:8080/NinjaBackend/';
    // public _apiEndpoint = 'http://sandbox3.us-west-2.elasticbeanstalk.com/rest/';
    // public _apiEndpoint = 'http://quantifyninjastaging-env.us-west-2.elasticbeanstalk.com/';
    //public _apiEndpoint: any = AppConfigService.appConfig.SERVER_BASE_URL;
    //public _apiEndpoint: any;
    public currentUser: any;
    public conversationList: any;
    public coupons: any;
    public tags: any;
    // public tokenId: any;
    public superUserActive: boolean;
    public verifyAcc = true;
    public loader = false;
    public dashboardLoader: any = false;
    public loaderObesrver = new Subject<any>();
    public dialogLoader = false;
    public smallSidebar = false;
    public currentPage: string;
    public showNotification = false;
    public notificationMessage: string;
    public notificationTitle: string;
    public progress = 0;
    public progressObserver = new Subject<any>();
    public regionChanged = new Subject<any>();
    public fileLoadedObserver = new Subject<any>();
    public detectAddSubAccount = new Subject<any>();
    public detectDeleteSubAccount = new Subject<any>();
    public linkToRedirect;
    public dom: Document;
    public sessionData = sessionStorage;
    public botIsUnderConstruction = false;
    public isSuperUser: boolean;
    public agreementToCancel: any;
    public currentCountry: any;

    constructor(public http: HttpClient,
        public matDialog: MatDialog,
        public router: Router,
        @Inject(DOCUMENT) dom: Document,
        public storageBrowser: StorageBrowser,
        public tokenService: TokenService) {
        this.dom = dom;
        //this._apiEndpoint = require('../../assets/config.json').SERVER_BASE_URL;
        // console.log('baseUrl', this.baseUrl);
        // this.http.get('assets/config.json').subscribe((res: any) => {
        //     this._apiEndpoint = res.SERVER_BASE_URL;
        //     console.log('this._apiEndpoint', this._apiEndpoint);
        // });
        //this.getBaseUrl();
        this._apiEndpoint = AppConfigService.appConfig;
    }

    public getBaseUrl() {
        this.http.get('assets/config.json').subscribe((res: any) => {
            this._apiEndpoint = res.SERVER_BASE_URL;
        });
    }
    public changeLoaderObserver(): Observable<any> {
        return this.loaderObesrver.asObservable();
    }

    public getObserver(): Observable<any> {
        return this.progressObserver.asObservable();
    }

    public getRegion(): Observable<any> {
        return this.regionChanged.asObservable();
    }

    public fileLoaded(): Observable<any> {
        return this.fileLoadedObserver.asObservable();
    }

    public subAccAdded(): Observable<any> {
        return this.detectAddSubAccount.asObservable();
    }

    public subAccDeleted(): Observable<any> {
        return this.detectDeleteSubAccount.asObservable();
    }

    public copyToClipboard(index, value) {
        let copiedText = null;
        copiedText = document.getElementById(index);
        copiedText.value = value;
        copiedText.select();
        this.dom.execCommand('copy');
    }

    public extractData(res: Response) {
        try {
            const body = res.json();
            return body || {};
        } catch (e) {
            return {};
        }
    }

    // public _headers = new Headers({'Content-Type': 'application/json'});

    public post(url: string, data: any, auth?: boolean): Observable<any> {
        // this.getBaseUrl();
        return this.http.post(this._apiEndpoint + url, data);
    }

    // public post2(url: string, data: any, auth?: boolean): Observable<any> {
    //     return this.http.post(this._apiEndpoint2 + url, data, this.getHeaders(auth)).map(this.extractData);
    // }

    public get(url: string, auth?: boolean): Observable<any> {

        return this.http.get(this._apiEndpoint + url);
    }

    public patch(url: string, auth?: boolean): Observable<any> {
        return this.http.get(this._apiEndpoint + url);
    }

    public put(url: string, data: any, auth?: boolean): Observable<any> {
        return this.http.put(this._apiEndpoint + url, data);
    }

    public deleteRequest(url: string, auth?: boolean): Observable<any> {
        return this.http.delete(this._apiEndpoint + url);
    }

    public upload(url: string, data: any): Observable<any> {
        return this.http.post(this._apiEndpoint + url, data);
    }

    // public getHeadersForUpload(): RequestOptions {
    //     const headers = new Headers();
    //     return new RequestOptions({headers: headers});
    // }
    //
    // public getHeaders(auth?: boolean): RequestOptions {
    //     const headers = new Headers();
    //
    //     headers.append('Content-Type', 'application/json');
    //
    //     return new RequestOptions({headers: headers});
    // }

    public login(userData): Observable<any> {
        return this.post('user/login', userData);
    }

    public tokenCheck(): Observable<any> {
        return this.http.post(this._apiEndpoint + 'user/auth', {
            publicId: this.tokenService.getId()
        });
    }

    public signUp(userData): Observable<any> {
        return this.post('user/sign_up', userData);
    }

    public signUpAndLogin(userData): Observable<any> {
        return this.post('user/signup_and_login', userData);
    }

    public getGrantAccessUrls(id): Observable<any> {
        return this.post('user/grant_access', {
            publicId: id
        });
    }

    public uploadFile(files: any) {
        if (files.length > 0) {
            const file: File = files[0];
            const formData: FormData = new FormData();
            formData.append('uploadFile', file, file.name);
            formData.append('publicId', this.currentUser.id);
            // const headers = new Headers();
            // headers.append('Accept', 'application/json');
            // const options = new RequestOptions({headers: headers});
            const xhr = new XMLHttpRequest();
            xhr.open('POST', this._apiEndpoint + 'templates/upload_file', true);
            xhr.upload.onprogress = (event) => {
                this.progress = Math.round((event.loaded / event.total) * 100);
                this.progressObserver.next(this.progress);
            };
            xhr.onload = (e) => {
                this.fileLoadedObserver.next(e);
            };
            xhr.send(formData);
        }
    }

    public uploadImage(files: any, url, id?) {
        if (files.length > 0) {
            const file: File = files[0];
            const formData: FormData = new FormData();
            formData.append('uploadFile', file, file.name);
            formData.append('publicId', this.currentUser.id);
            if (id) {
                formData.append('id', id);
            }
            // const headers = new Headers();
            // headers.append('Accept', 'application/json');
            // const options = new RequestOptions({headers: headers});
            const xhr = new XMLHttpRequest();
            xhr.open('POST', this._apiEndpoint + url, true);
            xhr.upload.onprogress = (event) => {
                this.progress = Math.round((event.loaded / event.total) * 100);
                this.progressObserver.next(this.progress);
            };
            xhr.onload = (e) => {
                this.fileLoadedObserver.next(e);
            };
            xhr.send(formData);
        }
    }

    public forgotPassword(email): Observable<any> {
        return this.post('user/forget_pass', {
            email: email
        });
    }

    public checkTokenForgotPass(token): Observable<any> {
        return this.post('user/check_token', {
            token: token
        });
    }

    public changePass(token, pass): Observable<any> {
        return this.post('user/change_pass', {
            token: token,
            pass: pass
        });
    }

    public verifyUser(data): Observable<any> {
        return this.post('user/verify_user', data);
    }

    public verifyHybridUser(data): Observable<any> {
        return this.post('user/verify_hybrid_user', data);
    }

    public verifyAppstoreUser(data): Observable<any> {
        return this.post('user/verify_appstore_user', data);
    }

    public updateEmail(email: string): Observable<any> {
        return this.post('user/update_email', {
            publicId: this.currentUser.id,
            email: email
        });
    }

    public sendContactUsMessage(email, yourName, message): Observable<any> {
        return this.post('user/contact_us', {
            publicId: this.currentUser.id,
            email: email,
            yourName: yourName,
            html: message
        });
    }

    public getProductData(formData): Observable<any> {
        let id = this.storageBrowser.get('$UserToken$user').sellerId;
        return this.post(`products/${id}`, formData);
    }

    public getProfitDashboardData(formData): Observable<any> {
        const id = this.storageBrowser.get('$UserToken$user').sellerId;
        return this.post(`profit_dashboard/all/${id}`, formData);
    }

    public getUnitsSoldList(type, formData) {
        let id = type + this.storageBrowser.get('$UserToken$user').sellerId;
        return this.post(`profit_dashboard/units_clicked/${id}`, formData);
    }

    public getRevenueList(type, formData) {
        let id = type + this.storageBrowser.get('$UserToken$user').sellerId;
        return this.post(`profit_dashboard/revenue_clicked/${id}`, formData);
    }

    public getCostList(type, formData) {
        let id = type + this.storageBrowser.get('$UserToken$user').sellerId;
        return this.post(`profit_dashboard/costs_clicked/${id}`, formData);
    }

    public getProfitList(type, formData) {
        let id = type + this.storageBrowser.get('$UserToken$user').sellerId;
        return this.post(`profit_dashboard/profit_clicked/${id}`, formData);
    }

    public getMarginList(type, formData) {
        let id = type + this.storageBrowser.get('$UserToken$user').sellerId;
        return this.post(`profit_dashboard/margin_clicked/${id}`, formData);
    }

    public getProfitBreakdownList(type, formData) {
        let id = type + this.storageBrowser.get('$UserToken$user').sellerId;
        return this.post(`profit_dashboard/pnl/${id}`, formData);
    }

    public getDownloadPNL(formData) {
        let id = this.storageBrowser.get('$UserToken$user').sellerId;
        return this.post(`profit_dashboard/export_pnl/${id}`, formData);
    }

    public getProfitBreakdownTableChart(formData) {
        let id = this.storageBrowser.get('$UserToken$user').sellerId;
        return this.post(`profit_dashboard/chart_clicked/${id}`, formData);
    }

    public saveFilterData(formData) {
        let id = this.storageBrowser.get('$UserToken$user').sellerId;
        return this.post(`preset/save_preset/${id}`, formData);
    }

    public orderDashboard(formData) {
        let id = this.storageBrowser.get('$UserToken$user').sellerId;
        return this.post(`orders_dashboard/all/${id}`, formData);
    }

    public orderDashboardPaginate(formData, token) {
        let id = this.storageBrowser.get('$UserToken$user').sellerId;
        return this.post(`orders_dashboard/all/next_token/${token}/${id}`, formData);
    }

    public promotionSummary(formData) {
        let id = this.storageBrowser.get('$UserToken$user').sellerId;
        return this.post(`orders_dashboard/promotions_summary/${id}`, formData);
    }

    public currentpromotionSummary(formData) {
        let id = this.storageBrowser.get('$UserToken$user').sellerId;
        return this.post(`orders_dashboard/promotions_current_summary/${id}`, formData);
    }

    public previouspromotionSummary(formData) {
        let id = this.storageBrowser.get('$UserToken$user').sellerId;
        return this.post(`orders_dashboard/previous_promotions_summary/${id}`, formData);
    }

    public get_orders_and_promotion(type, formData) {
        let id = this.storageBrowser.get('$UserToken$user').sellerId;
        if (type == 'Orders') {
            return this.post(`orders_dashboard/get_orders/${id}`, formData);
        } else {
            return this.post(`orders_dashboard/get_promotions/${id}`, formData);
        }
    }

    public get_currentorders_and_promotion(type, formData) {
        let id = this.storageBrowser.get('$UserToken$user').sellerId;
        if (type == 'Orders') {
            return this.post(`orders_dashboard/get_current_orders/${id}`, formData);
        } else {
            return this.post(`orders_dashboard/get_current_promotions/${id}`, formData);
        }
    }

    public get_previousorders_and_promotion(type, formData) {
        let id = this.storageBrowser.get('$UserToken$user').sellerId;
        if (type == 'Orders') {
            return this.post(`orders_dashboard/get_previous_orders/${id}`, formData);
        } else {
            return this.post(`orders_dashboard/get_previous_promotions/${id}`, formData);
        }
    }

    public getAsinData(publicId): Observable<any> {
        return this.post('asin/get_asin_data', {
            publicId: publicId,
        });
    }

    public addAsinSpot(publicId: string, asinSearchData: Array<CreateAsinInterface>): Observable<any> {
        return this.post('asin/asin_search', {
            publicId: this.currentUser.id,
            asinSearchData: asinSearchData,
        });
    }

    public getAsinChartData(url: string): Observable<any> {
        return this.get(url);
    }

    public deleteAsinSopt(asin, marketplace, keyword): Observable<any> {
        return this.post('asin/delete_asin_data', {
            publicId: this.currentUser.id,
            asin: asin,
            marketPlace: marketplace,
            keyword: keyword,
        });
    }

    public checkAsinSpot(asin, marketplace, keyword): Observable<any> {
        return this.post('asin/force_check', {
            publicid: this.currentUser.id,
            asin: asin,
            marketPlace: marketplace,
            keyword: keyword,
        });
    }

    public reportFbmProductsMissing(sellerId): Observable<any> {
        return this.post('product/report_fbm_products', {
            sellerId: sellerId,
        });
    }


    public superUserCheck(pass): Observable<any> {
        return this.post('user/super_user_check', {
            pass: pass
        });
    }

    public superUserList(): Observable<any> {
        return this.post('user/super_user_list', {});
    }

    public superUser(sellerId): Observable<any> {
        return this.post('user/super_user', {
            sellerId: sellerId
        });
    }

    public switchRegion(userId): Observable<any> {
        return this.post('user/switch_region', {
            userId: userId
        });
    }

    public addRegion(region, temporarySendEmail): Observable<any> {
        return this.post('user/add_region', {
            publicId: this.currentUser.id,
            region: region,
            temporarySendEmail: temporarySendEmail
        });
    }


    public getHijacked(): Observable<any> {
        return this.post('hijacked/get_hijacked', {
            publicId: this.currentUser.id
        });
    }

    public getAffiliate(): Observable<any> {
        return this.post('affiliates/get_affiliates', {
            publicId: this.currentUser.id
        });
    }

    public getAffiliateLink(): Observable<any> {
        return this.post('affiliates/create_affiliate_link', {
            publicId: this.currentUser.id
        });
    }

    public userReport(req): Observable<any> {
        return this.post('user/report', {
            publicid: this.currentUser.id,
            component: req
        });
    }

    public unsubscribeMarketing(publicId): Observable<any> {
        return this.post('user/unsubscribe_marketing', {
            publicId: publicId
        });
    }

    public unsubscribeEmail(publicId): Observable<any> {
        return this.post('user/unsubscribe_email', {
            publicId: publicId
        });
    }

    public updateSession(publicId): Observable<any> {
        return this.post('user/update_session', {
            publicId: publicId
        });
    }

    public saveLanguage(publicId, language): Observable<any> {
        return this.post('user/last_language', {
            publicId: publicId,
            locale: language
        });
    }

    public getVaList(): Observable<any> {
        return this.post('user/va_list', {
            publicId: this.currentUser.id
        });
    }

    public getManagedList(): Observable<any> {
        return this.post('user/managed_list', {
            publicId: this.currentUser.id
        });
    }

    public addManagedAccount(login, password, isSuperUser): Observable<any> {
        return this.post('user/add_managed_account', {
            publicId: this.currentUser.id,
            login: login,
            password: password,
            isSuperUser: isSuperUser
        });
    }

    public updateManagedList(accountsIds, isSuperUser): Observable<any> {
        return this.post('user/update_managed_list', {
            publicId: this.currentUser.id,
            accountsIds: accountsIds,
            isSuperUser: isSuperUser
        });
    }

    public getFeatureList(): Observable<any> {
        return this.post('user/feature_list', {
            publicId: this.currentUser.id
        });
    }

    public editFeatureList(id, featureList): Observable<any> {
        return this.post('user/va_user', {
            publicId: this.currentUser.id,
            id: id,
            featureList: featureList
        });
    }

    public createVaUser(data): Observable<any> {
        return this.post('user/va_user', data);
    }

    public getInvoicingTransactions(): Observable<any> {
        return this.post('invoicing/transactions', {
            publicId: this.currentUser.id
        });
    }

    public getInvoicingLanguage(language): Observable<any> {
        return this.post('invoicing/language', {
            publicId: this.currentUser.id,
            language: language
        });
    }

    public requestInvoice(date): Observable<any> {
        return this.post('invoicing/request_invoice', {
            publicId: this.currentUser.id,
            date: date,
            sellerId: this.currentUser.sellerId
        });
    }

    public checkInvoiceIsExist(url): Observable<any> {
        return this.http.get(url, { responseType: 'text' });
    }

    public deleteVaUser(id): Observable<any> {
        return this.post('user/delete_va_user', {
            publicId: this.currentUser.id,
            id: id
        });
    }

    public getLanguageInvoicing(): Observable<any> {
        return this.post('invoicing/get_language', {
            publicId: this.currentUser.id
        });
    }

    public sendLoaderReport(url): Observable<any> {
        return this.post('user/report', {
            sellerId: this.storageBrowser.get('$UserToken$user').sellerId,
            emailAddress: this.storageBrowser.get('$UserToken$user').email,
            component: url,
            version: `${environment.VERSION.toString()}.${this.storageBrowser.get('$UserToken$user').version.toString()}`
        });
    }

    public hideNotification(): Observable<any> {
        return this.post('user/hide_notification', {
            publicId: this.currentUser.id
        });
    }

    public temporarySendEmail(id, temporarySendEmail): Observable<any> {
        return this.put('user/temporary_send_email', {
            userId: id,
            temporarySendEmail: temporarySendEmail
        });
    }

    public getTemporarySendEmail(id): Observable<any> {
        return this.get('user/send_email_from/' + id);
    }

    public amazonTokenStatus(): Observable<any> {
        return this.get('user/amazon_token_status/' + this.currentUser.id);
    }

    public unsubscribeService(repJson: any): Observable<any> {
        return this.post('user/usubscribe_services ', repJson);
    }

    public advertitingGrantAccess(): Observable<any> {
        return this.post('advertising/grant_access', {
            sellerId: this.currentUser.sellerId
        });
    }

    public advertitingAccessGranted(advCode): Observable<any> {
        return this.post('advertising/access_granted', {
            sellerId: this.currentUser.user.sellerId,
            code: advCode
        });
    }

    public getCurrencySymbol() {
        var currency_symbols = {
            'USD': '$', // US Dollar
            'AUD': '$',
            'CAD': '$',
            'MXN': '$',
            'GBP': '£', // British Pound Sterling
            'INR': '₹', // Indian Rupee
            'JPY': '¥', // Japanese Yen
        };
        let currency_name = this.storageBrowser.get('$UserToken$user').currency;
        if (currency_symbols[currency_name] !== undefined) {
            return currency_symbols[currency_name];
        }
    }

    public convertCurrencySymbol(currency_name) {
        var currency_symbols = {
            'USD': '$', // US Dollar
            'AUD': '$',
            'CAD': '$',
            'MXN': '$',
            'EUR': '€', // Euro
            'CRC': '₡', // Costa Rican Colón
            'GBP': '£', // British Pound Sterling
            'ILS': '₪', // Israeli New Sheqel
            'INR': '₹', // Indian Rupee
            'JPY': '¥', // Japanese Yen
            'KRW': '₩', // South Korean Won
            'NGN': '₦', // Nigerian Naira
            'PHP': '₱', // Philippine Peso
            'PLN': 'zł', // Polish Zloty
            'PYG': '₲', // Paraguayan Guarani
            'THB': '฿', // Thai Baht
            'UAH': '₴', // Ukrainian Hryvnia
            'VND': '₫', // Vietnamese Dong
        };
        if (currency_symbols[currency_name] !== undefined) {
            return currency_symbols[currency_name];
        }
    }
}
