<div class="login-container" *ngIf="loginIsHide">
  <div class="right-container">
    <div class="logo">
      <a href="https://www.quantifyninja.com">
        <img class="d-block d-lg-none" src="assets/images/ninja-logo2.svg"
          alt="">
        <img class="d-none d-lg-block" width="200"
          src="assets/images/logo-white.svg" alt="">
      </a>
    </div>
    <!-- <div class="top-container">
      <p>{{textService.currentText.swithToPrevInterface}}</p>
      <a href="https://app.quantifyninja.com/#!login"
        (click)="googleAnalyticsEventsService.emitEvent('Button', 'Switch', 'Click', 1)">
        <button mat-button class="default-btn">{{textService.currentText.switch}}</button>
      </a>
    </div> -->
    <!-- <div class="content">
      <div class="img-container">
        <img src="../../../../assets/images/login-img.png" alt="login-img">
      </div>
      <h3>{{textService.currentText.loginTitle}}</h3>
      <p>
        {{textService.currentText.loginTitleText}}
        <br />
        {{textService.currentText.loginTitleText2}}
      </p>
    </div> -->
  </div>
  <div class="left-container left-container--login">
    <div class="container">
      <h2>Quantify Ninja</h2>
      <p class="text-center">{{textService.currentText.welcomeBack}}</p>
      <form class="login-form" name="form" [formGroup]="loginForm" novalidate>
        <div class="form-group" [class.has-danger]="errors?.emailInvalid">
          <!-- <label for="email">
            <i class="icon email"
              [ngStyle]="{'background-image': 'url(' + '../../../../assets/icons/mail.svg' + ')'}"></i>
          </label> -->
          <input type="email" id="email" #email class="form-control-x"
            formControlName="email"
            [placeholder]="textService.currentText.email"
            [(ngModel)]="loginUser" (focus)="emailInput= true"
            (focusout)="emailInput= false" />
          <div class="icon-container">
            <div class="capslock-icon help-icon inside-input" title="CAPSLOCK
              enabled" *ngIf="capsOn && emailInput">
            </div>
          </div>
        </div>
        <div class="form-group two-help-icons"
          [class.has-danger]="errors?.passwordInvalid">
          <!-- <label for="password"
            (click)="passwordInputType === 'password' ? passwordInputType = 'text' : passwordInputType = 'password'">
            <i class="icon password" [class.visible]="passwordInputType === 'text'"></i>
          </label> -->
          <input [attr.type]="passwordInputType" #pass id="password"
            class="form-control-x" formControlName="password"
            [placeholder]="textService.currentText.password"
            [(ngModel)]="password" (focus)="passwordInput= true"
            (focusout)="passwordInput= false" />
          <div class="icon-container">
            <div class="capslock-icon help-icon inside-input" title="CAPSLOCK
              enabled" *ngIf="capsOn && passwordInput">
            </div>
          </div>
          <div class="icon-container inside-input">
          </div>
        </div>
        <div class="input-hint input-row justify-content-between">
          <!-- <span>{{textService.currentText.forgotPass}}
            <a (click)="googleAnalyticsEventsService.emitEvent('Button', 'Forgot Password', 'Click', 1); goToResetPassword()"
              class="pointer">{{textService.currentText.clickHere}}</a></span> -->
          <mat-checkbox color="primary">{{textService.currentText.rememberMe}}</mat-checkbox>
          <a (click)="googleAnalyticsEventsService.emitEvent('Button', 'Forgot
            Password', 'Click', 1); goToResetPassword()"
            class="pointer">{{textService.currentText.forgotPass1}}</a>
        </div>
        <div class="form-group-button d-flex justify-content-between">
          <button mat-button class="secondary-btn btn-fill"
            (click)="googleAnalyticsEventsService.emitEvent('Button', 'Login',
            'Click', 1);
            mixpanelService.track('q login page - login clicked',{'page':
            'Login','page type':'quantifyninja'}); login()">{{textService.currentText.login}}</button>
          <button mat-button class="secondary-btn btn-fill btn-outline"(click)="googleAnalyticsEventsService.emitEvent('Button',
            'Navigate to SignUp', 'Click',
            1);mixpanelService.track('q login page - sign up clicked',{'page':
            'Login', 'page type':'quantifyninja'}); goToSignUp()">{{textService.currentText.signUp}}</button>
        </div>
        <!-- <div class="login-here">
          <span>{{textService.currentText.orYouCan}} <a
              (click)="googleAnalyticsEventsService.emitEvent('Button', 'Navigate to SignUp', 'Click', 1); goToSignUp()"
              class="pointer">{{textService.currentText.signUpHere}}</a></span>
        </div> -->
      </form>
    </div>
  </div>
</div>