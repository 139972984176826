import { NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from "@angular/core";
import { CommonModule, DatePipe } from "@angular/common";
import { NgxEchartsModule } from 'ngx-echarts';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { SuperUserComponent } from './super-user/super-user.component';
import { FacebookNotificationsComponent } from './facebook-notifications/facebook-notifications.component';
import { SubscribersComponent } from './facebook-bot-messanger/subscribers/subscribers.component';
import { ConversationManagerComponent } from './facebook-bot-messanger/conversation-manager/conversation-manager.component';
import { AutoResponseComponent } from './facebook-bot-messanger/auto-response/auto-response.component';
import { CouponsComponent } from './coupons/coupons.component';
import { HijackedComponent } from './hijacked/hijacked.component';
import { KeywordsOptimizerComponent } from './keywords-optimizer/keywords-optimizer.component';
import { MoneyBackComponent } from './money-back/money-back.component';
import { FacebookGrantAccessComponent } from './facebook-bot-messanger/facebook-grant-access/facebook-grant-access.component';
import { BotMenuComponent } from './facebook-bot-messanger/bot-menu/bot-menu.componet';
import { CreateNewConversationComponent } from './facebook-bot-messanger/create-new-conversation/create-new-conversation.component';
import { AddRegionComponent } from './add-region/add-region.component';
import { OrdersComponent } from './orders/orders.component';
import { DownloadReportComponent } from './orders/download-report/download-report.component';
import { SendMessageComponent } from './orders/send-message/send-message.component';
import { AsinSpotComponent } from './asin-spot/asin-spot.component';
import { AffiliateComponent } from './affiliate/affiliate.component';
import { InviteFriendComponent } from './invite-friends/invite-friend.component';
import { FAQComponent } from './FAQ/FAQ.component';
import { InvoicingComponent } from './invoicing/invoicing.component';
import { CreateNewInvoiceComponent } from './invoicing/create-new-invoice/create-new-invoice.component';
import { SequencesComponent } from './facebook-bot-messanger/sequences/sequences.component';
import { LastMessagesSentComponent } from './automated-messaging/last-messages-sent/last-messages-sent.component';
import { UserPermissionsComponent } from './account/user-permissions/user-permissions.component';
import { LinkAccountsComponent } from './account/link-accounts/link-accounts.component';
import { BillingComponent } from './account/billing/billing.component';
import { AsinAlertsComponent } from './asin-alerts/asin-alerts.component';
import { UpgradeComponent } from './upgrade/upgrade.component';

import { UserSettingsComponent } from './user-settings/user-settings.component';
import { AccountSettingsComponent } from './user-settings/account-settings/account-settings.component';
import { ProductSettingsComponent } from './user-settings/product-settings/product-settings.component';
import { ExpensesComponent } from './user-settings/expenses/expenses.component';
import { DashboardComponent } from './dashboard.component';

import { FbaComponent } from './automated-messaging/fba/fba.component';
import { FbmComponent } from './automated-messaging/fbm/fbm.component';
import { BulkComponent } from './automated-messaging/bulk/bulk.component';
import { CreateNewTemplateComponent } from './automated-messaging/create-new-template/create-new-template.component';
import { BotMenuOptionConstructorComponent } from './facebook-bot-messanger/bot-menu/bot-menu-option-constructor/bot-menu-option-constructor.component';
import { RefundTooltipComponent } from './orders/refund-tooltip/refund-tooltip.component';
import { ConversationBotOptionConstructorComponent } from './facebook-bot-messanger/create-new-conversation/conversation-bot-option-constructor/conversation-bot-option-constructor.component';
import { ConversationBotMenuOptionComponent } from './facebook-bot-messanger/create-new-conversation/conversation-bot-menu-option/conversation-bot-menu-option.component';
import { ConversationPreviewComponent } from './facebook-bot-messanger/create-new-conversation/conversation-preview/conversation-preview.component';
import { ConversationBotMenuNavigationComponent } from './facebook-bot-messanger/create-new-conversation/conversation-bot-menu-navigation/conversation-bot-menu-navigation.component';
import { DndDirective } from './invoicing/create-new-invoice/appDnd.directive';
import { BotMenuPreviewComponent } from './facebook-bot-messanger/bot-menu/bot-menu-preview/bot-menu-preview.component';
import { AsinChartComponent } from './asin-spot/asin-chart/asin-chart.component';
import { AlertsService } from 'src/app/services/alerts.service';
import { TruncateTextPipe } from 'src/app/pipes/truncate-text.pipe';
import { NinjaMaterialModule } from 'src/app/material-module';
import { DashboardRoute } from './dashboard-route.module';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { FacebookModule } from 'ngx-facebook';
import { DashboardHeaderComponent } from './dashboard-header/dashboard-header.component';
import { DashboardSidenavComponent } from './dashboard-sidenav/dashboard-sidenav.component';
import { ShareService } from 'src/app/services/share.service';
import { TokenService } from 'src/app/services/token.service';
import { TextService } from 'src/app/services/text.service';
import { UserService } from 'src/app/services/user.service';
import { MAT_SELECT_SCROLL_STRATEGY } from '@angular/material/select';
import { Overlay, BlockScrollStrategy } from '@angular/cdk/overlay';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthenticatedHttpService } from 'src/app/services/auth-http.service';
import { DashboardService } from 'src/app/services/dashboard.service';
import { Functions } from 'src/app/services/functions';
import { ConstService } from 'src/app/services/const-service';
import { MoneyBackService } from 'src/app/services/money-back.service';
import { TemplateService } from 'src/app/services/template.service';
import { StorageBrowser } from 'src/app/services/storage.browser';
import { FbService } from 'src/app/services/fb.service';
import { SequencesService } from 'src/app/services/sequences.service';
import { UpgradeService } from 'src/app/services/upgrade.service';
import { NotificationComponent } from 'src/app/components/notification/notification.component';
import { TodaysectionComponent } from './dashboard-schedules/todaysection/todaysection.component';
import { ProfitsectionComponent } from './dashboard-schedules/profitsection/profitsection.component';
import { BestandworstsectionComponent } from './dashboard-schedules/bestandworstsection/bestandworstsection.component';
import { ProfitbreakdownComponent } from './dashboard-schedules/profitbreakdown/profitbreakdown.component';
import { DoughnutchartComponent } from './dashboard-schedules/doughnutchart/doughnutchart.component';
import { BarchartComponent } from './dashboard-schedules/barchart/barchart.component';
import { ProductBarchartComponent } from './dashboard-schedules/product-barchart/product-barchart.component';
import { ProfitbreakdownpopupComponent } from './dashboard-schedules/popups/profitbreakdownpopup/profitbreakdownpopup.component';
import { ProfitbreakdownwithoutgroupComponent } from './dashboard-schedules/profitbreakdownwithoutgroup/profitbreakdownwithoutgroup.component';
import { ListComponent } from './dashboard-schedules/popups/list/list.component';
import { ErrorsComponent } from './dashboard-schedules/popups/errors/errors.component';
import { ProfitbreakdownonegroupComponent } from './dashboard-schedules/profitbreakdownonegroup/profitbreakdownonegroup.component';
import { ProfitbreakdowntwogroupComponent } from './dashboard-schedules/profitbreakdowntwogroup/profitbreakdowntwogroup.component';
import { FilterdropdownmenuComponent } from './dashboard-schedules/filterdropdownmenu/filterdropdownmenu.component';
//import { DashboardSchedulesModule } from './dashboard-schedules/dashboard-schedules.module';
import { DashboardSchedulesComponent } from './dashboard-schedules/dashboard-schedules.component';
import { InlineEditComponent } from '../controls/inline-edit/inline-edit.component';
import { OrdersDashboardComponent } from './orders-dashboard/orders-dashboard.component';
import { CustomFilterMenuComponent } from './custom-filter-menu/custom-filter-menu.component';
import { OrdersBarchartComponent } from './orders-barchart/orders-barchart.component';
import { OrdersPopupComponent } from './orders-popup/orders-popup.component';
import { CanActivateTeam, CanDeactivateGuard } from './diactivate.guadr';
import { ProductCostComponent } from './user-settings/product-settings/product-cost/product-cost.component';
export function scrollFactory(overlay: Overlay): () => BlockScrollStrategy {
  return () => overlay.scrollStrategies.block();
}

@NgModule({
  declarations: [ConversationBotMenuNavigationComponent, ConversationPreviewComponent, ConversationBotMenuOptionComponent, ConversationBotOptionConstructorComponent, RefundTooltipComponent, BotMenuOptionConstructorComponent, SuperUserComponent, CreateNewTemplateComponent, BulkComponent, FbaComponent, DashboardComponent, DndDirective, BotMenuPreviewComponent, AsinChartComponent,
    FbmComponent, ExpensesComponent, ProductSettingsComponent, AccountSettingsComponent,
    UserSettingsComponent, UpgradeComponent, AsinAlertsComponent, BillingComponent, LinkAccountsComponent,
    UserPermissionsComponent, LastMessagesSentComponent, SequencesComponent, CreateNewInvoiceComponent,
    InvoicingComponent, FAQComponent, InviteFriendComponent, AffiliateComponent, AsinSpotComponent, SendMessageComponent, DownloadReportComponent, OrdersComponent, AddRegionComponent, CreateNewConversationComponent, BotMenuComponent, FacebookGrantAccessComponent, MoneyBackComponent,
    KeywordsOptimizerComponent, HijackedComponent, CouponsComponent, AutoResponseComponent, DashboardHeaderComponent, ConversationManagerComponent, SubscribersComponent, FacebookNotificationsComponent, TruncateTextPipe, DashboardSidenavComponent, NotificationComponent, InlineEditComponent,
    TodaysectionComponent, ProfitsectionComponent, BestandworstsectionComponent, ProfitbreakdownComponent, DoughnutchartComponent, ProductBarchartComponent, BarchartComponent, ProfitbreakdownpopupComponent, ProfitbreakdownwithoutgroupComponent, ListComponent, ErrorsComponent, ProfitbreakdownonegroupComponent, ProfitbreakdowntwogroupComponent, FilterdropdownmenuComponent, DashboardSchedulesComponent, OrdersDashboardComponent, CustomFilterMenuComponent,
    OrdersBarchartComponent, OrdersPopupComponent, ProductCostComponent
  ],
  imports: [
    CommonModule,
    NgxEchartsModule,
    ReactiveFormsModule,
    NinjaMaterialModule,
    DashboardRoute,
    FormsModule,
    // DashboardSchedulesModule,
    FacebookModule.forRoot(),
    NgxDaterangepickerMd.forRoot()
  ],
  exports: [
    ProductCostComponent,
    InlineEditComponent
  ],
  providers: [
    ShareService,
    UserService,
    TokenService,
    TextService,
    DatePipe,
    Functions,
    ConstService,
    CanDeactivateGuard,
    CanActivateTeam,
    MoneyBackService,
    TemplateService,
    StorageBrowser,
    FbService,
    DashboardService,
    AlertsService,
    SequencesService,
    UpgradeService,
    { provide: MAT_SELECT_SCROLL_STRATEGY, useFactory: scrollFactory, deps: [Overlay] },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthenticatedHttpService,
      multi: true
    },
  ],

  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA]
})
export class DashboardModule { }
